import React from 'react'
import { Button } from 'react-bootstrap'
import Papa from "papaparse";

const DownloadCsv = ({DownLoadData, Header,Name}) => {
    console.log(DownLoadData)

    const HandleDownloadCsv=(DownLoadData)=>{
        try {
            // Check if FinalDownloadData is defined and not null
            if (!DownLoadData) {
              console.error("Error: FinalDownloadData is undefined or null");
              return;
            }
      
            // Assuming you have access to the originalData
            const apiData = DownLoadData;
            console.log(DownLoadData);
      
            // Check if apiData is defined and not null, and if it has at least one element
            if (!apiData || apiData.length === 0) {
              console.error("Error: apiData is undefined, null, or empty");
              return;
            }
      
            if (!Array.isArray(apiData)) {
              console.error("Error: apiData is not an array");
              return;
            }
      
            // Check if apiData is not empty
            if (apiData.length === 0) {
              console.error("Error: apiData is an empty array");
              return;
            }
       
            const headerLabels = Header ||  Object.keys(apiData[0]);
            // console.log(headerLabels)
      
            // Extract the data from the originalData
            const apiRows = apiData.map((item) => Object?.values(item));
      
            // Combine header with data
            const dataWithHeader = [ headerLabels, ...apiRows];
      
            // Convert the data with header to CSV format using PapaParse
            const csv = Papa.unparse(dataWithHeader);
      
            // Create a Blob object from the CSV data
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = `${Name || ""}-Report.csv`; // Change the file name as needed
            link.style.display = "none";
      
            document.body.appendChild(link);
            link.click();
      
            // Clean up by removing the temporary element
            document.body.removeChild(link);
          } catch (error) {
            console.error("Error:", error);
          }
    }

  return (
    <div>
         <button className=' fs-3 btn btn-lg  bg-primary text-light' onClick={()=>HandleDownloadCsv(DownLoadData)}>Download CSV</button>
    </div>
  )
}

export default DownloadCsv
