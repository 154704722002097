import React,{useState, useEffect}from 'react'
import axios from "axios";
import "../../../assets/css/style.css";
import { MDBDataTable } from 'mdbreact';
import { useNavigate } from 'react-router-dom';
import { SWIPELINC_API } from '../../../utils/constant';
import ViewDetails1 from './ViewDetails1';
import { Container, Card, Row, Col } from "react-bootstrap";

function RejectedPayout() {
  const [rejectapplications, setrejectApplications] = useState([]);
  const [id, setID] = useState('');
  const [open, setOpen] = useState(false);
  const [token , setToken] =  useState(localStorage.getItem("token"));
  const navigate = useNavigate();
 
  useEffect(() => {
   // Create Axios instance with the default header
    const axiosInstance = axios.create({
      baseURL: `${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister`,
      headers: {
        Authentication: `Bearer ${token}`,
      },
    });
    
    // Fetch applications using the Axios instance
    axiosInstance
      .get("/getAllPayoutByStatus/Rejected",{
        params:{
          payoutStatus:"Rejected"
        }
      })
      .then((response) => {
        if (response.data.statusCode===200 && response.data.data !==null){
          setrejectApplications(response.data.data)
        }
        if (response.data.statusCode===304){
          
          return
        }
        setrejectApplications(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching applications:", error.message);
      });
  }, []);

  const ViewDetails = (id) => {
     setOpen(true)
     setID(id)
  }; 
 
  const columns= [
// {label: "ID",field: "id",width:"auto",},
    {label: "Vendor Name",field: "fullName",width:"auto"},
    {label: "e-Mail",field: "email",width:"auto",},
    {label: "Category",field: "category",width:"auto",},
    {label: "Mobile",field: "mobile",width:"auto",},
    // {label: "Bank Name",field: "bankName",width:"auto",},
    // {label: "Account Number",field: "accountNumber",width:"auto"},
    // {label: "IFSC Code",field: "ifscCode",width:"auto"},
    // {label: "Branch Name",field: "branchName",width:"auto",},
    // {label: "UPI",field: "upi",width:"auto"},
    // {label: "Payout Amount",field: "amount", width:"auto"},
    {label: "Merchant ID",field: "mid", width:"auto"},
    // {label: "Remark",field: "remark",width:"auto"},
    // {label: "Payout Status", field: "payoutStatus",width:"auto"},
    { label: 'Merchant Name', field: 'companyName', width: 'auto' },

    { label: 'Action', field: 'action', width: '150px' },
]


const mappedData = rejectapplications && rejectapplications.length> 0 
? rejectapplications.map((item)=>{
const mappedItem = {...item};
mappedItem.action=(
  <div>
   <button
    className="btn btn-primary"
    onClick={() => ViewDetails(item.id)}
  >
    View Details
  </button>
  </div>
);
return mappedItem;
}):[];

 return (
    <>

<Container>
    <Row>
      <Col xs={12} lg={12} md={12}>
      <Card className="shadow border-0 mb-4">
        <Card.Body>
        <div className="report-grid-view">
      <div className="material-table-records mb-3">
           {!open?( <MDBDataTable
                  className='dataTable'
                striped
                small
                bordered
                hover
                responsive
                data={{
                  columns:columns,
                  rows: mappedData,
                }}
                searching={true}
                entries={50}
                exportToCSV
                theadColor="#333"
                paginationLabel={['Previous', 'Next']}
                sortable={true}
                footer="none"
              />):(<ViewDetails1 id={id} setOpen={setOpen}/>)}
             </div>
              </div>
              </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container> 
    </>
  );
};
export default RejectedPayout
