import React from "react";
import { MDBDataTable } from "mdbreact";
import { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

import axios from "axios";

import { useNavigate, useParams } from "react-router-dom";
import { SWIPELINC_API } from "../utils/constant";
import DownloadCsv from "../common/DownloadCsv";
import { headerLabelsForCredit } from "../utils/DownloadHeaders";
import CompanyNameAndMid from "../common/CompanyNameAndMid";
import MyLoader from "../common/MyLoader";
const SingleCreditReport = () => {
  const [data, setData] = useState();
  const [activeButton, setActiveButton] = useState(1);
 const [showLoader , setShowLoader] = useState();
  const token = localStorage.getItem("token");
 
  const navigate = useNavigate();
  function getSearchFunctionText(activeButton) {
    let searchFunctionText;

    if (activeButton === 1) {
      searchFunctionText = "getAllBy";
    } else if (activeButton === 2) {
      searchFunctionText = "getAllDebitBy";
    }

    return searchFunctionText;
  }
  
const {mid}=useParams()
console.log(mid)
  const GetDataForCreditReport=(token)=>{
    setShowLoader(true)
    axios
    .get(`${SWIPELINC_API}MerchantAdminPanel/admin/dropCredit/${getSearchFunctionText(activeButton)}/${mid}`, {
      headers: {
        Authentication: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if(response.data.statusCode === 200){

        setData(response.data.data);
      }else{
        setData([])
      }
      setShowLoader(false)
      
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      // setShowTable(false);
    });
  }
  useEffect(() => {
    setData([])
    GetDataForCreditReport(token)
  }, [activeButton]);
 
  const handleToolsButtonClick = (activeButton) => {
    setActiveButton(activeButton);
  };
  const columnsForDebit = [
    { label: "Date", field: "createDate", width: "auto" },
    { label: "Time", field: "createTime", width: "auto" },
    { label: "Merchant Name", field: "fullName", width: "auto" },
    { label: "Merchant Id", field: "mid", width: "auto" },
    { label: "Amount", field: "orignalAmount", width: "auto" },
   
    {
      label: "Remark",
      field: "txnType",
      width: "auto",
    },
  ];
  const columnsForCredit = [
    { label: "Date", field: "createDate", width: "auto" },
    { label: "Time", field: "createTime", width: "auto" },
    { label: "Merchant Name", field: "fullName", width: "auto" },
    { label: "Merchant Id", field: "mid", width: "auto" },
    { label: "Amount", field: "orignalAmount", width: "auto" },
    { label: "Service Charges", field: "deductedAmount", width: "auto" },

    {
      label: "Charges In Percentage",
      field: "chargeInPercentage",
      width: "auto",
    },
    // { label: "Charges Amount Value", field: "settlmentTime", width: "auto" },
    {
      label: "Charges For Services",
      field: "txnType",
      width: "auto",
    },
  ];
const column=(activeButton,columnsForCredit,columnsForDebit)=>{
  if(activeButton===1){
  return columnsForCredit
  }else if(activeButton=== 2){
    return columnsForDebit
  }

}
  const mappedData = data?.map((item) => {
    const mappedItem = {};
    column(activeButton,columnsForCredit,columnsForDebit).forEach((column) => {
      mappedItem[column.field] =
        item[column.field] !== null ? item[column.field] : "-";
    });
    return mappedItem;
  });
  const handleBackButtonClick = () => {
    navigate(-1);
  };
  return (
    <>
        {showLoader ? <MyLoader /> : ""}
      <Container>
        <Row className="bg-white  ">
          <Col className=" p-3">
            <div className="merchant-tab-btn">
              <ul className="report-link nav-report-btn">
                <li
                  className={activeButton === 1 ? "active" : ""}
                  onClick={() => handleToolsButtonClick(1)}
                >
                  Credit Transactions
                </li>
                <li
                  className={activeButton === 2 ? "active" : ""}
                  onClick={() => handleToolsButtonClick(2)}
                >
                  Credit Added
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <Card className="shadow border-0 my-4">
            <Card.Header>
          
              <CompanyNameAndMid mid={mid} reportType={"Credit Report"} />
              </Card.Header>
              <Card.Body>

              <div className="d-flex justify-content-between my-2">
                  <button
                    className="btn btn-primary mt-2"
                    onClick={handleBackButtonClick}
                  >
                    Go Back
                  </button>
                </div>
                <div className="report-grid-view mt-3">
                  <div className="material-table-records mb-3">
                    <MDBDataTable
                      className="dataTable"
                      striped
                      bordered
                      small
                      data={{
                        columns:  column(activeButton,columnsForCredit,columnsForDebit),
                        rows: mappedData,
                      }}
                      searching={true}
                      sorting={true}
                      responsive
                      hover
                      footer="none"
                    />
                  </div>
                  <DownloadCsv DownLoadData={data} Header={headerLabelsForCredit}  Name={`${mid}-Credit`}/>
                </div>
               
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SingleCreditReport;
