import React, { useEffect, useState } from "react";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import { FIND_PAYIN_DETAILS, SWIPELINC_API } from "../../../utils/constant";
import "../../../assets/css/scrollbar.css"
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ViewDetailsForReconcialiation = ({ id, setIsModalOpen, isModalOpen }) => {
  console.log(setIsModalOpen, isModalOpen, id);

  const [payoutDetails, setPayinDetails] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    const axiosInstance = axios.create({
      baseURL: `${SWIPELINC_API}MerchantAdminPanel/reconciliationReport`,
      headers: {
        Authentication: `Bearer ${token}`,
      },
    });
    // setShowLoader(true)
    axiosInstance
      .get("/getPayinDetail/id", {
        params: {
          id: id,
        },
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          setPayinDetails(response.data.data);
        } else if (response.data.responseCode === 304) {
          setPayinDetails([]);
        } else {
          setPayinDetails([]);
        }
        //   setShowLoader(false)
      })
      .catch((error) => {
        console.error("Error fetching applications:", error.message);
        // setShowLoader(false)
      });
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal show={isModalOpen} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Reconcialiation Details:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className="admin-view-details">
            <Container>
              <Card className="shadow border-0 mb-4 ds-header-card">
                <Card.Body>
                  <div className="merchant-report-view">
                    <div className="report-table-view">
                      <div className="containerflag ">
                        <div className="two-column-layout">
                          <div className="vertical-table">
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell  text-left">
                                {" "}
                                Merchant ID
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {payoutDetails.mid}
                              </div>
                            </div>
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell  text-left">
                                Transaction Id
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {payoutDetails.txn_id}
                              </div>
                            </div>
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell  text-left">
                                Payment Type
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {payoutDetails.paymentType}
                              </div>
                            </div>
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell  text-left">
                                {" "}
                                Amount
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {payoutDetails.amount}
                              </div>
                            </div>
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell  text-left">
                                {" "}
                                Actual Amount
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {payoutDetails.actualAmount}
                              </div>
                            </div>
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell  text-left">
                                {" "}
                                Transaction Date
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {payoutDetails.txnDate }
                              </div>
                            </div>
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell  text-left">
                                {" "}
                                Response Date
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {payoutDetails.responseDate }
                              </div>
                            </div>
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell  text-left">
                                {" "}
                                Transaction Status
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {payoutDetails.txnStatus}
                              </div>
                            </div>
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell  text-left">
                                UTR Number
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {payoutDetails.custRefNo}
                              </div>
                            </div>
                           
                            {/* <div className="vertical-table-row">
                              <div className="vertical-table-cell  text-left">
                                Payment Type
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {payoutDetails.paymentType}
                              </div>
                            </div> */}
                            {/* <div className="vertical-table-row">
                              <div className="vertical-table-cell  text-left">
                                {" "}
                                IFSC Code
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {payoutDetails.ifscCode}
                              </div>
                            </div>
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell  text-left">
                                {" "}
                                Branch Name
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {payoutDetails.branchName}
                              </div>
                            </div> */}
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell  text-left">
                                {" "}
                                Order Number
                              </div>
                              <div className="vertical-table-cell-value text-left overflow-x-scroll scrollbar" >
                                {payoutDetails.orderNo}
                              </div>
                            </div>
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell  text-left">
                                {" "}
                                e-Mail
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {payoutDetails.email}
                              </div>
                            </div>
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell  text-left">
                                UPI
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {payoutDetails.userVpa}
                              </div>
                            </div>

                            {/* <div className="vertical-table-row">
                          <div className="vertical-table-cell  text-left">
                            {" "}
                            Merchant ID
                          </div>
                          <div className="vertical-table-cell-value  text-left">
                            {payoutDetails.mid}
                          </div>
                        </div> */}
                            {/* <div className="vertical-table-row">
                          <div className="vertical-table-cell  text-left">
                            Rejection Remark
                          </div>
                          <div className="vertical-table-cell-value  text-left">
                            {payoutDetails.remark}
                          </div>
                        </div> */}
                            {/* <div className="vertical-table-row">
                          <div className="vertical-table-cell  text-left">
                            Payout Status
                          </div>
                          <div className="vertical-table-cell-value  text-left">
                            {payoutDetails.payoutStatus}
                          </div>
                        </div> */}
                          </div>
                        </div>
                        <div className="button-container">
                          <button
                            className="btn btn-primary"
                            onClick={handleCloseModal}
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Container>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewDetailsForReconcialiation;
