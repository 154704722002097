import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../stylesheet/ViewDetails.css";
import { Card, Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { FIND_BY_MID_AND_ORDERNO } from "../../utils/constant";
import swal from "sweetalert";

const ViewResponse = ({ orderNo, mid,onCloseModal }) => {
  const [records, setRecords] = useState([]);
  const [recordsData, setRecordsData] = useState([]);
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  // const {orderNo,mid}=useParams()

  const payload = {
    mid: mid,
    orderNo: orderNo,
  };
  console.log(payload);

  const findByMidAndOrderNo = async (payload, token) => {
    try {
      const response = await axios.post(FIND_BY_MID_AND_ORDERNO, payload, {
        headers: {
          Authentication: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === 200) {
        setRecords(response?.data?.data);
        setRecordsData(response?.data?.data?.data[0]);
        console.log(records, recordsData);
      } else if (response.data.statusCode === 304) {
        swal({
          title: "Alert!",
          text: "No Data Found",
          icon: "error",
        });
        onCloseModal()
        
      } else {
        swal({
          title: "Alert!",
          text: "Something Went Wrong.",
          icon: "error",
        });
        onCloseModal()
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    findByMidAndOrderNo(payload, token);
  }, []);

  const columns = [
    {
      label: "UPI ID",
      field: "upiId",
    },
    {
      label: "Customer Name",
      field: "customerName",
    },
    {
      label: "MCC",
      field: "mcc",
    },
    {
      label: "Request Time",
      field: "requestTime",
    },
    {
      label: "Response Message",
      field: "respMessge",
    },
    {
      label: "Transaction Time",
      field: "txnTime",
    },
    {
      label: "Payee VPA",
      field: "payeeVpa",
    },
    {
      label: "Terminal ID",
      field: "terminalId",
    },
    {
      label: "Response Code",
      field: "respCode",
    },
    {
      label: "UPI Transaction ID",
      field: "upiTxnId",
    },
    {
      label: "Amount",
      field: "amount",
    },
    {
      label: "Customer Reference No",
      field: "custRefNo",
    },
  ];

  console.log("GJGHHJ-----------------", records.amount);

  return (
    <section className="admin-view-details">
      <Container>
        <Card className="shadow border-0 mb-4 ds-header-card">
          <Card.Body>
            <Row>
              <Col xs={12} xl={12} lg={12} sm={12}>
                <div className="two-column-layout">
                  <div className="vertical-table">
                    <div className="vertical-table-row">
                      <div className="vertical-table-cell">Customer Name</div>
                      <div className="vertical-table-cell-value">
                        {records.customerName || recordsData?.customerName}
                      </div>
                    </div>
                    <div className="vertical-table-row">
                      <div className="vertical-table-cell">Error code</div>
                      <div className="vertical-table-cell-value">
                        {records.errCode}
                      </div>
                    </div>
                    <div className="vertical-table-row">
                      <div className="vertical-table-cell text-left">
                        Response time
                      </div>
                      <div className="vertical-table-cell-value text-left">
                        {records.responseTime || recordsData?.requestTime}
                      </div>
                    </div>
                    <div className="vertical-table-row">
                      <div className="vertical-table-cell text-left">
                        Amount
                      </div>
                      <div className="vertical-table-cell-value text-left">
                        {records.amount || recordsData?.amount}
                      </div>
                    </div>
                    {/* <div className="vertical-table-row">
            <div className="vertical-table-cell text-left">checksum</div>
            <div className="vertical-table-cell-value text-left">{records.checksum || "NA"}</div>
        </div> */}
                    {/* <div className="vertical-table-row">
            <div className="vertical-table-cell text-left">custRefNo</div>
            <div className="vertical-table-cell-value text-left">{records.custRefNo || "NA"}</div>
        </div> */}
                    <div className="vertical-table-row">
                      <div className="vertical-table-cell text-left">
                        Transaction ID
                      </div>
                      <div className="vertical-table-cell-value text-left">
                        {records.txnId}
                      </div>
                    </div>
                    <div className="vertical-table-row">
                      <div className="vertical-table-cell text-left">
                        Customer VPA
                      </div>
                      <div className="vertical-table-cell-value text-left">
                        {records.customer_vpa}
                      </div>
                    </div>

                    <div className="vertical-table-row">
                      <div className="vertical-table-cell text-left">
                        RRN Number
                      </div>
                      <div className="vertical-table-cell-value text-left">
                        {records.rrn || recordsData?.custRefNo}
                      </div>
                    </div>
                    <div className="vertical-table-row">
                      <div className="vertical-table-cell text-left">
                        Payee VPA / UPI ID
                      </div>
                      <div className="vertical-table-cell-value text-left">
                        {records.payeeVpa || recordsData?.payeeVpa}
                      </div>
                    </div>
                    <div className="vertical-table-row">
                      <div className="vertical-table-cell text-left">
                        Status
                      </div>
                      <div className="vertical-table-cell-value text-left">
                        {records.status || recordsData.respMessge}
                      </div>
                    </div>

                    <div className="vertical-table-row">
                      <div className="vertical-table-cell text-left">
                        Request time
                      </div>
                      <div className="vertical-table-cell-value text-left">
                        {recordsData?.requestTime}
                      </div>
                    </div>

                    <div className="vertical-table-row">
                      <div className="vertical-table-cell text-left">
                        Terminal ID
                      </div>
                      <div className="vertical-table-cell-value text-left">
                        {records.terminalId || recordsData?.terminalId}
                      </div>
                    </div>

                    <div className="vertical-table-row">
                      <div className="vertical-table-cell text-left">
                        UPI ID{" "}
                      </div>
                      <div className="vertical-table-cell-value text-left">
                        {recordsData?.upiId}
                      </div>
                    </div>

                    <div className="vertical-table-row">
                      <div className="vertical-table-cell text-left">
                        {" "}
                        UPI Transaction ID{" "}
                      </div>
                      <div className="vertical-table-cell-value text-left">
                        {recordsData?.upiTxnId}
                      </div>
                    </div>

                    <div className="vertical-table-row">
                      <div className="vertical-table-cell text-left">
                        Response Code{" "}
                      </div>
                      <div className="vertical-table-cell-value text-left">
                        {records.respCode || recordsData?.respCode}
                      </div>
                    </div>
                  </div>
                  <div className="button-toolbar groups-btn text-end">
                    <button
                      className="btn btn-primary"
                      onClick={() => navigate("/Reports")}
                    >
                      Go Back
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </section>
  );
};

export default ViewResponse;
