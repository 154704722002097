import React, { useEffect, useState } from "react";
import InactiveServices from "../../InactiveServices";
import { Row, Col, Card, Container } from "react-bootstrap";
import CallbackPayout from "./CallbackPayout";
import CallbackPayin from "./CallbackPayin";

const CallbackSubTab = ({setShowLoader}) => {
  const [activePhase, setActivePhase] = useState("Phase1");
 
useEffect(() => {
    setActivePhase("Phase1");
   
  }, []);

  const handlePhaseClick = (phase) => {
    console.log(phase)
    setActivePhase(phase);
  };

  return (
    <>
     <section className="dashboard-header-sec subtab-card">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-3  ds-inner-card">
                  <Card.Body>
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn submenu-tab-admin">
                        <li
                          className={activePhase === "Phase1" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase1")}
                        >
                          Payout{activePhase === "Phase1" }
                        </li>
                        <li
                          className={activePhase === "Phase2" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase2")}
                        >
                          Payin{activePhase === "Phase2" }
                        </li>
                      
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
              
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <div className="admin-tab-content mat-bt-table">
        {activePhase === "Phase1" ? (
          <CallbackPayout
          setShowLoader = {setShowLoader} />
        // <InactiveServices />

        ) : activePhase === "Phase2" ? (
          <CallbackPayin 
          setShowLoader = {setShowLoader}/>
        // <InactiveServices />
        ) 
        : (
          <InactiveServices />
        )}
      </div>
    </>
  );
};

export default CallbackSubTab;