import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { SWIPELINC_API } from "../utils/constant";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Card, Row, Button, Col } from "react-bootstrap";
import { getAllDailyTransactionDataPayout } from "../utils/ApiServices";
import DownloadCsv from "../common/DownloadCsv";
import { headerLabelsForPayout } from "../utils/DownloadHeaders";
import MidFiter from "./MidFiter";
import LivePayoutMultipleFilter from "./LivePayoutMultipleFilter";
import LivePayoutAdvanceFilter from "./LivePayoutAdvanceFilter";

function LivePayoutReport({reportType,setShowLoader,dropDownData}) {
  const [data, setData] = useState();
  const [show2, setShow2] = useState(false);
  const token = localStorage.getItem("token");
  const [isAdvanceFilterOpen, setIsAdvanceFilterOpen] = useState(false);
  const [isMultipleFilterOpen, setIsMultipleFilterOpen] = useState(false);
  const [reset, setReset] = useState(false);
  const { mid } = useParams();
  const navigate = useNavigate();

  const getDailyTransactionData = (token) => {
    setShowLoader(true)
    const response = getAllDailyTransactionDataPayout(token)
      .then((response) => {
        if (response.statusCode === 200) {
          setData(response?.data);
          setShowLoader(false)
        } else {
          setData([]);
          console.error("Error fetching data:", response.data.message);
          setShowLoader(false)
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setShowLoader(false)
      });
  };

  const handleMidFilter = () => {
    setShow2(true);
  };

  const handleAdvanceFilter = () => {
    setIsAdvanceFilterOpen(true);
  };

  const handleMultipleFilter = () => {
    setIsMultipleFilterOpen(true);
  };

  const columns = [
    { label: "Merchant ID", field: "mid", width: "auto" },
    { label: "Company Name", field: "companyName", width: "auto" },
    // { label: "Vendor Name", field: "fullName", width: "auto" },
    { label: "e-Mail", field: "email", width: "auto" },
    { label: "Mobile Number", field: "mobile", width: "auto" },
    { label: "Reference Id", field: "reference", width: "auto" },
    { label: "VPA / UPI", field: "upi", width: "auto" },
    { label: "Date", field: "createDate", width: "auto" },
    { label: "Amount", field: "amount", width: "auto" },
    { label: "RRN Number", field: "rrn", width: "auto" },
    { label: "Transaction Id", field: "txnId", width: "auto" },
    { label: "Transfer Type", field: "transferType", width: "auto" },
    { label: "Transaction Status", field: "txnStatus", width: "auto" },
    { label: "Payout Status", field: "payoutStatus", width: "auto" },
    { label: "Category", field: "category", width: "auto" },
   
  ];
  const mappedData = data?.map((item) => {
    const mappedItem = {};
    columns.forEach((column) => {
      if (column.field === "createDate") {
        const dateValue = item[column.field] ? item[column.field].split(' ')[0] : '';
        mappedItem[column.field] = dateValue;
      } else {
        mappedItem[column.field] = item[column.field];
      }
    });
    return mappedItem;
  });
   

  
 

  const HandleReset = () => {
    setReset(!reset);
  };
  useEffect(() => {
    getDailyTransactionData(token);
  }, [reset]);
  return (
    <>
      <section className="lincpay-dashboard-sec">
        {/* <Container>  */}
        <Col className="d-flex justify-content-end gap-2">
          <Button onClick={handleMidFilter}>Merchant</Button>
          <Button onClick={handleMultipleFilter}>More Filter</Button>
          <Button onClick={handleAdvanceFilter}>Advance Filter</Button>


          <Button
            className="d-flex align-items-center justify-content-center gap-2"
            onClick={HandleReset}
          >
            Reset<i class="bi bi-arrow-clockwise"></i>
          </Button>
        </Col>
        <div className="dasboard-view-count-boxes">
          <Card.Body>
            <div className="report-grid-view">
              <div className="material-table-records mb-3">
                <MDBDataTable
                  className="dataTable"
                  striped
                  bordered
                  small
                  data={{
                    columns: columns,
                    rows: mappedData,
                  }}
                  searching={true}
                  responsive
                  sorting={true}
                  hover
                  footer="none"
                />
              </div>
              <DownloadCsv
                DownLoadData={data}
                // Header={headerLabelsForPayout}
                Name={`Payout`}
              />
            </div>
          </Card.Body>

          <MidFiter
            reportType={reportType}
            setShow2={setShow2}
            show2={show2}
            setLiveData={setData}
            
          />
           {isAdvanceFilterOpen ? (
        <LivePayoutAdvanceFilter
          setShowLoader={setShowLoader}
          setTransactionReportData={setData}
          setIsAdvanceFilterOpen={setIsAdvanceFilterOpen}
        />
      ) : isMultipleFilterOpen ? (
        <LivePayoutMultipleFilter
          setShowLoader={setShowLoader}
          setTransactionReportData={setData}
          setIsMultipleFilterOpen={setIsMultipleFilterOpen}
          dropDownData={dropDownData}
        />
      ) : (
        ""
      )}
        </div>
        {/* </Container>  */}
      </section>
    </>
  );
}

export default LivePayoutReport;
