import React, { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { getMechantForPayoutApiTest } from "../../../utils/ApiServices";
import axios from "axios";
import { SWIPELINC_API } from "../../../utils/constant";
import swal from "sweetalert";
import MyLoader from "../../../common/MyLoader";

const PayoutApiTest = ({ setShowLoader }) => {
  const [merchant, setMerchant] = useState([]);
  const token = localStorage.getItem("token");

  const [transferType, setTransferType] = useState("upi");
  const [merchantData, setMerchantData] = useState({
    referenceId: "",
    mid: "",
    clientId: "",
    providerSecret: "",
    masterVirtualAccount: "",
    AccountModuleSecret: "",
    callbackURL: "",
    apiKey: "",
    secrete: "",
  });

  const [formData, setFormData] = useState({
    merchantMid: "",
    upi: "",
    accNumber: "",
    ifscCode: "",
  });

  const handelInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const getMerchantInfo = async (mid) => {
    if (mid === "") {
      swal({
        title: "Alert",
        text: "Please Select A Merchant",
        icon: "error",
      });
      return;
    }

    setMerchantData({
      referenceId: "",
      mid: "",
      clientId: "",
      providerSecret: "",
      masterVirtualAccount: "",
      AccountModuleSecret: "",
      callbackURL: "",
      apiKey: "",
      secrete: "",
    });
    try {
      setShowLoader(true);
      const res = await axios.get(
        `${SWIPELINC_API}MerchantAdminPanel/payoutRouting/findMerchantByMid/${mid}`,
        {
          headers: {
            Authentication: "Bearer " + token,
          },
        }
      );

      setShowLoader(false);
      if (res.data.statusCode === 306) {
        swal({
          title: "Alert",
          text: res.data.message,
          icon: "error",
        });
      }

      if (res.data.statusCode === 200) {
        console.log(
          "------>>>>>>>>",
          transferType.toLocaleLowerCase() ===
            res.data.data[0]?.transferType?.toLocaleLowerCase()
        );

        if (
          transferType.toLocaleLowerCase() ===
          res.data.data[0].transferType?.toLocaleLowerCase()
        ) {
          setMerchantData({
            ...merchantData,
            referenceId: "REFID" + Date.now(),
            mid: res.data.data[0]?.mid,
            callbackURL: res.data.data[0]?.callbackUrl,
            AccountModuleSecret: res.data.data[0]?.accountsModuleSecret,
            masterVirtualAccount: res.data.data[0]?.masterVirtualAccount,
            providerSecret: res.data.data[0]?.providerSecret,
            clientId: res.data.data[0]?.clientId,
            apiKey: res.data.data[0]?.apiKey,
            secrete: res.data.data[0]?.secrete,
          });
        } else {
          setMerchantData({
            ...merchantData,
            referenceId: "REFID" + Date.now(),
            mid: res.data.data[1]?.mid,
            callbackURL: res.data.data[1]?.callbackUrl,
            AccountModuleSecret: res.data.data[1]?.accountsModuleSecret,
            masterVirtualAccount: res.data.data[1]?.masterVirtualAccount,
            providerSecret: res.data.data[1]?.providerSecret,
            clientId: res.data.data[1]?.clientId,
            apiKey: res.data.data[0]?.apiKey,
            secrete: res.data.data[0]?.secrete,
          });
        }
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const getAllMerchantForApiTest = async () => {
    setShowLoader(true);
    let res = await getMechantForPayoutApiTest(token);
    setShowLoader(false);
    setMerchant(res?.data);
  };

  const handelSubmit = async () => {
    if (formData.merchantMid === "") {
      swal({
        title: "Alert",
        text: "Please Select A Merchant",
        icon: "error",
      });
      return;
    }

    if (transferType === "upi") {
      if (formData.upi === "") {
        swal({
          title: "Alert",
          text: "Please Enter UPI ID",
          icon: "error",
        });
        return;
      }
    }

    if (transferType === "imps") {
      if (formData.accNumber === "") {
        swal({
          title: "Alert",
          text: "Please Enter Account number",
          icon: "error",
        });
        return;
      }

      if (formData.ifscCode === "") {
        swal({
          title: "Alert",
          text: "Please Enter IFSC Code",
          icon: "error",
        });
        return;
      }
    }

    const payload = {
      accountNo: transferType === "upi" ? "" : formData.accNumber,
      apikey: merchantData.apiKey,
      ifscCode: transferType === "upi" ? "" : formData.ifscCode,
      mid: merchantData.mid,
      referenceId: merchantData.referenceId,
      secreteKey: merchantData.secrete,
      transferType: transferType,
      upiId: transferType === "imps" ? "" : formData.upi,
    };

    setShowLoader(true);

    try {
      const res = await axios.post(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/payout-api-test`,
        payload,
        {
          headers: {
            Authentication: "Bearer " + token,
          },
        }
      );

      setShowLoader(false);
      console.log("RRREEESS>>>>>", res);

      console.log(res.data.data);

      if (res.data.statusCode === 200) {
        swal({
          title: "Success",
          text: res.data.data.requestStatusDescription,
          icon: "success",
        });

        setFormData({
          merchantMid: "",
          upi: "",
          accNumber: "",
          ifscCode: "",
        });

        setMerchantData({
          referenceId: "",
          mid: "",
          clientId: "",
          providerSecret: "",
          masterVirtualAccount: "",
          AccountModuleSecret: "",
          callbackURL: "",
          apiKey: "",
          secrete: "",
        });
      } else {
        swal({
          title: "Alert",
          text: res.data.message,
          icon: "error",
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  useState(() => {
    getAllMerchantForApiTest();
  }, []);

  return (
    <>
      <section className="dashboard-header-sec subtab-card">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-3  ds-inner-card">
                  <Card.Body className="">
                    <Row>
                      <Col xs={12} lg={6} md={6} className=" ">
                        <Row className="justify-content-center  ">
                          <Col xs={12} lg={8} className="mt-3 ">
                            <div className="form-group">
                              <label className="form-label">
                                Select A Merchant
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="d-flex gap-2">
                                <select
                                  name="merchantMid"
                                  value={formData.mid}
                                  onChange={(e) => {
                                    handelInput(e);
                                    getMerchantInfo(e.target.value);
                                  }}
                                  className="form-control"
                                >
                                  <option>Select A Merchant</option>
                                  {merchant?.map((elem) => (
                                    <option key={elem.mid} value={elem.mid}>
                                      {elem.merchantName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            {/* ./form-group */}
                          </Col>
                          <Col xs={12} lg={8}>
                            <div className="d-flex justify-content-evenly gap-5 mt-4   ">
                              <div
                                className={`${
                                  transferType === "upi"
                                    ? "rounded-5 text-light bg-dark "
                                    : ""
                                }d-flex justify-content-evenly align-items-center gap-3 p-3 py-2`}
                              >
                                <input
                                  type="radio"
                                  className="form-check-input mb-1 "
                                  name="bankDetails"
                                  checked={
                                    transferType === "upi" ? true : false
                                  }
                                  onChange={() => {
                                    setTransferType("upi");
                                    getMerchantInfo(formData.merchantMid);
                                  }}
                                />
                                <label htmlFor="detailsRadio">
                                  <span>UPI </span>
                                </label>
                              </div>
                              <div
                                className={`${
                                  transferType === "imps"
                                    ? "rounded-5 text-light bg-dark "
                                    : ""
                                }d-flex justify-content-evenly align-items-center gap-3 p-3 py-2`}
                              >
                                <input
                                  type="radio"
                                  className="form-check-input mb-1 "
                                  name="bankDetails"
                                  checked={
                                    transferType === "imps" ? true : false
                                  }
                                  onChange={() => {
                                    setTransferType("imps");
                                    getMerchantInfo(formData.merchantMid);
                                  }}
                                />
                                <label htmlFor="detailsRadio">
                                  <span> IMPS </span>
                                </label>
                              </div>
                            </div>
                          </Col>
                          <Col xs={12} lg={8} className="mt-3">
                            {transferType === "upi" ? (
                              <div className="form-group">
                                <label className="form-label">
                                  Enter UPI ID
                                  <sup className="text-danger">*</sup>
                                </label>
                                <div className="d-flex gap-2">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="accountsModuleSecrete"
                                    value={formData.upi}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        upi: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </Col>
                          {transferType === "imps" ? (
                            <div className=" col-lg-8">
                              <Col className="mt-3">
                                <div className="form-group">
                                  <label className="form-label">
                                    Enter Account Number
                                    <sup className="text-danger">*</sup>
                                  </label>
                                  <div className="d-flex gap-2">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="accountsModuleSecrete"
                                      value={formData.accNumber}
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          accNumber: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                                {/* ./form-group */}
                              </Col>
                              <Col className="mt-3">
                                <div className="form-group">
                                  <label className="form-label">
                                    Enter IFSC Code
                                    <sup className="text-danger">*</sup>
                                  </label>
                                  <div className="d-flex gap-2">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="accountsModuleSecrete"
                                      value={formData.ifscCode}
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          ifscCode: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                                {/* ./form-group */}
                              </Col>
                            </div>
                          ) : (
                            ""
                          )}
                        </Row>

                        <div className="form-group mx-auto d-flex justify-content-center mt-2">
                          <button
                            className="btn btn-success"
                            onClick={handelSubmit}
                          >
                            Submit
                          </button>
                        </div>
                        {/* ./form-group */}
                      </Col>

                      <Col xs={12} lg={6} md={6} className="">
                        <Row>
                          <Col xs={12} lg={6} className="mt-1">
                            <div className="form-group">
                              <label className="form-label">
                                Merchant Id
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="d-flex gap-2">
                                <input
                                  type="text"
                                  readOnly
                                  className="form-control"
                                  name="accountsModuleSecrete"
                                  value={merchantData.mid}
                                  // onChange={(e) =>
                                  //   setEditData({ ...editData, type: e.target.value })
                                  // }
                                />
                              </div>
                            </div>
                            {/* ./form-group */}
                          </Col>
                          <Col xs={12} lg={6} className="mt-1">
                            <div className="form-group">
                              <label className="form-label">
                                Reference Id
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="d-flex gap-2">
                                <input
                                  type="text"
                                  readOnly
                                  className="form-control"
                                  name="accountsModuleSecrete"
                                  value={merchantData.referenceId}
                                  // onChange={(e) =>
                                  //   setEditData({ ...editData, type: e.target.value })
                                  // }
                                />
                              </div>
                            </div>
                            {/* ./form-group */}
                          </Col>

                          <Col xs={12} lg={6} className="mt-1">
                            <div className="form-group">
                              <label className="form-label">
                                Client Id
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="d-flex gap-2">
                                <input
                                  type="text"
                                  readOnly
                                  className="form-control"
                                  name="accountsModuleSecrete"
                                  value={merchantData.clientId}
                                  // onChange={(e) =>
                                  //   setEditData({ ...editData, type: e.target.value })
                                  // }
                                />
                              </div>
                            </div>
                            {/* ./form-group */}
                          </Col>
                          <Col xs={12} lg={6} className="mt-1">
                            <div className="form-group">
                              <label className="form-label">
                                Provider Secret
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="d-flex gap-2">
                                <input
                                  type="text"
                                  readOnly
                                  className="form-control"
                                  name="accountsModuleSecrete"
                                  value={merchantData.providerSecret}
                                  // onChange={(e) =>
                                  //   setEditData({ ...editData, type: e.target.value })
                                  // }
                                />
                              </div>
                            </div>
                            {/* ./form-group */}
                          </Col>
                          <Col xs={12} lg={6} className="mt-1">
                            <div className="form-group">
                              <label className="form-label">
                                Master Virtual Account
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="d-flex gap-2">
                                <input
                                  type="text"
                                  readOnly
                                  className="form-control"
                                  name="accountsModuleSecrete"
                                  value={merchantData.masterVirtualAccount}
                                  // onChange={(e) =>
                                  //   setEditData({ ...editData, type: e.target.value })
                                  // }
                                />
                              </div>
                            </div>
                            {/* ./form-group */}
                          </Col>
                          <Col xs={12} lg={6} className="mt-1">
                            <div className="form-group">
                              <label className="form-label">
                                Account Module Secret
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="d-flex gap-2">
                                <input
                                  type="text"
                                  readOnly
                                  className="form-control"
                                  name="accountsModuleSecrete"
                                  value={merchantData.AccountModuleSecret}
                                  // onChange={(e) =>
                                  //   setEditData({ ...editData, type: e.target.value })
                                  // }
                                />
                              </div>
                            </div>
                            {/* ./form-group */}
                          </Col>
                          <Col xs={12} lg={6} className="mt-1">
                            <div className="form-group">
                              <label className="form-label">
                                Callback URL
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="d-flex gap-2">
                                <input
                                  type="text"
                                  readOnly
                                  className="form-control"
                                  name="accountsModuleSecrete"
                                  value={merchantData.callbackURL}
                                  // onChange={(e) =>
                                  //   setEditData({ ...editData, type: e.target.value })
                                  // }
                                />
                              </div>
                            </div>
                            {/* ./form-group */}
                          </Col>
                          <Col xs={12} lg={6} className="mt-1">
                            <div className="form-group">
                              <label className="form-label">
                                API Key
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="d-flex gap-2">
                                <input
                                  type="text"
                                  readOnly
                                  className="form-control"
                                  name="apiKey"
                                  value={merchantData.apiKey}
                                  // onChange={(e) =>
                                  //   setEditData({ ...editData, type: e.target.value })
                                  // }
                                />
                              </div>
                            </div>
                            {/* ./form-group */}
                          </Col>
                          <Col xs={12} lg={6} className="mt-1">
                            <div className="form-group">
                              <label className="form-label">
                                Secret Key
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="d-flex gap-2">
                                <input
                                  type="text"
                                  readOnly
                                  className="form-control"
                                  name="secrete"
                                  value={merchantData.secrete}
                                  // onChange={(e) =>
                                  //   setEditData({ ...editData, type: e.target.value })
                                  // }
                                />
                              </div>
                            </div>
                            {/* ./form-group */}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default PayoutApiTest;