import React, { useEffect, useState } from "react";
import WithdrawAmount from "../../../Merchant/MerchantManagement/WithdrawAmount";
import Withdrawl from "./Withdrawl";
import InactiveServices from "../../InactiveServices";
import { Card, Col, Container, Row } from "react-bootstrap";
import MyLoader from "../../../common/MyLoader";
import DropCredit from "./DropCredit";
import { getAllMerchantPhase2Approved } from "../../../utils/ApiServices";

const WithdrawSection = ({setShowLoader}) => {
  const [activePhase, setActivePhase] = useState("Phase1");
  const [data, setData] = useState([]);
  // const [showLoader, setShowLoader] = useState(false);

  const token = localStorage.getItem("token");

  const getMerchantData = async (token) => {
    try {
      setShowLoader(true);
      const response = await getAllMerchantPhase2Approved(token);
      setShowLoader(false);
      if (response?.statusCode === 200) {
        setData(response?.data);
        console.log(response?.data)
      } else {
        setData([]);
      }
    } catch (error) {
      setShowLoader(false);

      setData([]);
    }
  };

  useEffect(() => {
    setActivePhase("Phase1");
    getMerchantData(token);
  }, []);
  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  return (
    <>
     {/* {showLoader ? <MyLoader /> : ""} */}
      <div className="subtab-sec-admin">
      
        <Container>
          <Row>
            <Col xs={12} lg={12} md={12}>
              <Card className="shadow border-0 mb-4">
                <div className="merchant-tab-btn">
                  <ul className="report-link nav-report-btn">
                    <li
                      className={activePhase === "Phase1" ? "active" : ""}
                      onClick={() => handlePhaseClick("Phase1")}
                    >
                       Manual / Upload
                    </li>
                    <li
                      className={activePhase === "Phase2" ? "active" : ""}
                      onClick={() => handlePhaseClick("Phase2")}
                    >
                      Requested
                    </li>
                    <li
                      className={activePhase === "Phase3" ? "active" : ""}
                      onClick={() => handlePhaseClick("Phase3")}
                    >
                      Drop Credit
                    </li>
                    
                  </ul>
                </div>
                <Card.Body>
                  <div className="admin-tab-content">
                    {activePhase === "Phase1" ? (
                      <WithdrawAmount
                      setShowLoader={setShowLoader}  data={data}/>
                    ) : activePhase === "Phase2" ? (
                      <Withdrawl 
                      setShowLoader={setShowLoader}/>
                    ) : activePhase === "Phase3" ? (
                        <DropCredit
                        setShowLoader={setShowLoader} data={data}/>
                    ) : (
                      <InactiveServices />
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default WithdrawSection;
