import React, { useEffect, useState } from "react";
import DailyTransactionBox from "./DailyTransactionBox";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import {
  getAllDailyTransactionData,
  getAllDailyTransactionDataByMid,
  getAllDailyTransactionDataTable,
  getAllDailyTransactionDataTableByMid,
} from "../utils/ApiServices";
import DownloadCsv from "../common/DownloadCsv";
import LivePayinAdvanceFilter from "./LivePayinAdvanceFilter";
import LivePayinMutipleFilter from "./LivePayinMutipleFilter";
import MidFiter from "./MidFiter";

const LivePayinReport = ({ reportType, setShowLoader, dropDownData }) => {
  const [data, setData] = useState([]);
  const [show2, setShow2] = useState(false);
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
  const [showMultipleFilter, setShowMultipleFilter] = useState(false);
  const [reset, setReset] = useState(false);
  const [merchantID, setMerchantID] = useState("");
  const [tableData, setTableData] = useState([]);

  const token = localStorage.getItem("token");

  const getDailyTransactionData = (token) => {
    setShowLoader(true)
    const response = getAllDailyTransactionData(token)
      .then((response) => {
        if (response.statusCode === 200) {
          setData(response?.data);
          setShowLoader(false)
        } else {
          setData([]);
          console.error("Error fetching data:", response.data.message);
          setShowLoader(false)
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setShowLoader(false)
      });
  };

  const getDailyTransactionDataTable = (token) => {
    setShowLoader(true)
    const response = getAllDailyTransactionDataTable(token)
      .then((response) => {
        if (response.statusCode === 200) {
          setTableData(response?.data);
          setShowLoader(false)
        } else {
          setTableData([]);
          console.error("Error fetching data:", response.data.message);
          setShowLoader(false)
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setShowLoader(false)
      });
  };

  // filtered Data Call
  const getDailyTransactionDataByMId = (merchantID, token) => {
    const response = getAllDailyTransactionDataByMid(merchantID, token)
      .then((response) => {
        if (response.statusCode === 200) {
          setData(response?.data);
          setMerchantID("");
        } else {
          setData([]);
          console.error("Error fetching data:", response.data.message);
        }
        setMerchantID("");
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  const getDailyTransactionDataTableByMId = (merchantID, token) => {
    const response = getAllDailyTransactionDataTableByMid(merchantID, token)
      .then((response) => {
        if (response.statusCode === 200) {
          setTableData(response?.data);
          setMerchantID("");
        } else {
          setTableData([]);
          console.error("Error fetching data:", response.data.message);
        }
        setMerchantID("");
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  const handleFilter = () => {
    setShow2(true);
  };

  const handleAdvanceFilter = () => {
    setShowAdvanceFilter(true);
  };

  const handleMultipleFilter = () => {
    setShowMultipleFilter(true);
  };

  const HandleReset = () => {
    setReset(!reset);
  };

  useEffect(() => {
    getDailyTransactionData(token);
    getDailyTransactionDataTable(token);
  }, [reset]);

  const columns = [
    { label: "Company Name", field: "companyName", width: "auto" },
    { label: "MID", field: "mid", width: "auto" },
    { label: "SID", field: "sid", width: "auto" },
    { label: "Transaction Status", field: "txnStatus", width: "auto" },
    {
      label: "Transaction Response Status",
      field: "txnRespCode",
      width: "auto",
    },
    { label: "Bank Response Status", field: "evokTxnCode", width: "auto" },
    { label: "Payment Type", field: "paymentType", width: "auto" },
    { label: "Order Number", field: "orderNo", width: "auto" },
    { label: "Transaction ID", field: "txn_id", width: "auto" },
    { label: "Transaction Date", field: "txnDate", width: "auto" },
    { label: "Transaction Time", field: "txnTime", width: "auto" },
    { label: "Terminal ID", field: "terminalId", width: "auto" },
    { label: "Amount", field: "amount", width: "auto" },
    { label: "Currency", field: "currency", width: "auto" },
    { label: "Response Date", field: "responseDate", width: "auto" },
    { label: "UTR Number", field: "custRefNo", width: "auto" },
    { label: "Payer VPA", field: "userVpa", width: "auto" },
    { label: "Callback Response", field: "callbackRespJson", width: "auto" },
    { label: "Mobile Number", field: "mobileNo", width: "auto" },
    { label: "e-Mail", field: "email", width: "auto" },
    { label: "Customer IP Address", field: "customerIp", width: "auto" },
    { label: "Customer Location", field: "customerLocation", width: "auto" },
    { label: "Remark", field: "remark", width: "auto" },
    { label: "Customer IFSC", field: "customerIfsc", width: "auto" },
    { label: "Bank Location", field: "bankLocation", width: "auto" },
  ];

  const MappedData = tableData?.map((item) => {
    return {
      ...item,
      evokTxnCode: item.txnStatus === "success" ? "0" : item.evokTxnCode,
    };
  });

  const boxComponentsUp = data.map((entry, index) => (
    <Col xs={2} lg={1} md={1} key={index}>
      <DailyTransactionBox data={entry} />
    </Col>
  ));

  return (
    <>
      <section className="lincpay-dashboard-sec">
        <Container>
          <Col className="d-flex justify-content-end gap-2">
            <Button onClick={handleFilter}>Merchant</Button>
            <Button onClick={handleAdvanceFilter}>Advance Filter</Button>
            <Button onClick={handleMultipleFilter}>More Filter</Button>

            <Button
              className="d-flex align-items-center justify-content-center gap-2"
              onClick={HandleReset}
            >
              Reset<i class="bi bi-arrow-clockwise"></i>
            </Button>
          </Col>
          <div className="dasboard-view-count-boxes">
            <Container>
              <Card.Body>
                <Row>{boxComponentsUp}</Row>
              </Card.Body>
            </Container>
          </div>
          <div className="dasboard-view-count-boxes">
            <Card.Body>
              <div className="report-grid-view">
                <div className="material-table-records mb-3">
                  <MDBDataTable
                    className="dataTable"
                    striped
                    small
                    bordered
                    hover
                    responsive
                    data={{
                      columns: columns,
                      rows: MappedData,
                    }}
                    searching={true}
                    entries={50}
                    paginationLabel={["Previous", "Next"]}
                    sortable={true}
                    footer="none"
                  />
                </div>
                <DownloadCsv
                  DownLoadData={tableData}
                  Name={`Daily-Transaction`}
                />
              </div>
              <div className="mt-2 text-danger d-flex justify-content-evenly">
                <div>
                  <span>U03 - Above Allowed Limit</span>
                  <br />
                  <span>U16 - Daily Limit Of Exceeded</span>
                  <br />
                  <span>U28 - Unable To Connect Sender Application </span>
                  <br />
                </div>
                <div>
                  <span>U30 - Unable To Debit</span>
                  <br />
                  <span>U31 - Denied By Reciever</span>
                  <br />
                  <span>U67 - Debit Timeout</span>
                  <br />
                </div>
                <div>
                  <span>U90 - Txn Deemed</span>
                  <br />
                  <span>U91 - Sender Bank Rejected</span>
                  <br />
                  <span>E05 - User Left Txn Incomplete</span>
                  <br />
                </div>
              </div>
            </Card.Body>
          </div>
        </Container>
      </section>
      <MidFiter
        reportType={reportType}
        setShow2={setShow2}
        show2={show2}
        setLiveData={setTableData}
        setLiveBoxData={setData}
      />
      {showAdvanceFilter ? (
        <LivePayinAdvanceFilter
          setShowLoader={setShowLoader}
          setTransactionReportData={setTableData}
          setIsAdvanceFilterOpen={setShowAdvanceFilter}
        />
      ) : showMultipleFilter ? (
        <LivePayinMutipleFilter
          setShowLoader={setShowLoader}
          setTransactionReportData={setTableData}
          setShowMultipleFilter={setShowMultipleFilter}
          dropDownData={dropDownData}
        />
      ) : (
        ""
      )}
      
    </>
  );
};

export default LivePayinReport;
