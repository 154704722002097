import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import { SWIPELINC_API } from "../utils/constant";
import axios from "axios";
import swal from "sweetalert";
import { stopCharacterInput, stopSpecialCharacterInput } from "../utils/helper";
import MyLoader from "../common/MyLoader";
import { Checkbox } from "@mui/material";
import { MDBDataTable } from "mdbreact";

const Routing = ({ setShowLoader }) => {
  const [merchantData, setMerchantData] = useState([]);
  const [SidData, setSidData] = useState([]);
  // const [showLoader, setShowLoader] = useState(false);
  const [routingData, setRoutingData] = useState([]);
  const token = localStorage.getItem("token");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sidDetailsData, setSidDetailsData] = useState([]);
  const [keyName, setKeyName] = useState("");
  const [selectedData, setSelectedData] = useState([]);

  const [data, setData] = useState({
    sid: "",
    txnAmt: "",
    merchantSelect: "",
    routingPattern: "",
  });

  useEffect(() => {
    setShowLoader(true);
    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/getallmerchant-validation`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          setMerchantData(response.data.data);
          // setSidData(response.data.data);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const getAllInitialSID = () => {
    setShowLoader(true);
    axios
      .get(`${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/getAllSid`, {
        headers: {
          Authentication: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          setSidData(response.data.data);
          let res = response.data.data.map((elem) => {
            return { ...elem, maxTxnAmtInputVal: "" };
          });
          setSidData(res);
        }

        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // useEffect(() => {

  //   getAllInitialSID()
  // }, []);

  const handelDataChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });

    if (e.target.name === "merchantSelect") {
      getAllInitialSID();
    }

    if (e.target.name === "sid") {
      let val = e.target.value;
      let select = SidData.filter((data) => {
        if (data.sid === val) return data;
      });

      const remaining = selectedData.filter((elem) => {
        return elem.sid !== select[0].sid;
      });

      setSelectedData([...remaining, ...select]);
      setSidData(
        SidData.filter((elem) => {
          return elem.sid !== val;
        })
      );
    }
  };

  const getKeyName = (e) => {
    setKeyName(e.key);
  };

  const sidDetailsColumn = [
    { label: "Merchant ID", field: "mid", width: "auto" },
    { label: "Merchant Name", field: "companyName", width: "auto" },
    { label: "SID", field: "sid", width: "auto" },
    { label: "SID Limit", field: "sidLimit", width: "auto" },
  ];

  const handelSearchLimit = (e, sid) => {
    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/admin/MerchantRouting/getall-mapped-sid/${sid}`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log(response);
        if (response.data.statusCode === 200) {
          setSidDetailsData(response.data.data);
        } else {
          setSidDetailsData([]);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    setIsModalOpen(true);
  };

  const handelTxnInput = (e, sid) => {
    if (stopSpecialCharacterInput(keyName) || stopCharacterInput(keyName)) {
      return;
    }

    let data = SidData.map((elem) => {
      if (elem.sid === sid) {
        return { ...elem, maxTxnAmtInputVal: e.target.value };
      }
      return elem;
    });
    setSidData(data);

    let data1 = selectedData.map((elem) => {
      if (elem.sid === sid) {
        return { ...elem, maxTxnAmtInputVal: e.target.value };
      }
      return elem;
    });
    setSelectedData(data1);
  };

  const checkMaxTxnAmtLimit = (payload) => {
    
    let flag = true

    // for (let data of payload) {
    //   for (let sData of selectedData) {
    //     if (
    //       data.sid === sData.sid &&
    //       parseInt(data.sidLimit) > parseInt(sData.remainingLimit)
    //     ) {
    //       flag= false;
    //     } else {
    //       flag = true
    //     }
    //   }
    // }

    for(var i=0 ; i<payload.length ; i++ ) {
      
      let sidLimit = parseInt(payload[i].sidLimit)
      let remainingLimit = parseInt(selectedData[i].remainingLimit)
      let company = selectedData[i].company
      if(sidLimit > remainingLimit) {
        swal({
          title: "Alert!",
          text: "Maximum transaction amount can not be greater than remaining limit of "+ company,
          icon: "error",
        });
        flag = false
      }
    }

    return flag
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (data.merchantSelect === "") {
      swal({
        title: "ALERT!",
        text: "Please select a merchant",
        icon: "error",
      });
      return;
    }

    if (data.routingPattern === "") {
      swal({
        title: "ALERT!",
        text: "Please select a routing pattern",
        icon: "error",
      });
      return;
    }

    if (selectedData.length === 0) {
      swal({
        title: "ALERT!",
        text: "Please select SID's",
        icon: "error",
      });
      return;
    }

    const payload = selectedData.map((elem) => {
      if (elem.maxTxnAmtInputVal === "") {
        swal({
          title: "ALERT!",
          text: "Please enter maximum transaction amount",
          icon: "error",
        });
        return [];
      }

      return {
        mid: data.merchantSelect,
        routingType: data.routingPattern,
        sid: elem.sid,
        sidLimit: elem.maxTxnAmtInputVal,
      };
    });

    console.log(payload);

    const save = checkMaxTxnAmtLimit(payload);

    if (save) {
      axios
        .post(
          `${SWIPELINC_API}MerchantAdminPanel/admin/MerchantRouting/save`,
          payload,
          {
            headers: {
              Authentication: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            swal({
              title: "ALERT!",
              text: response.data.message,
              icon: "success",
            });
            getAllInitialSID();
            setSelectedData([]);
            setData({
              sid: "",
              txnAmt: "",
              merchantSelect: "",
              routingPattern: "",
            });
          } else {
            swal({
              title: "ALERT!",
              text: response.data.message,
              icon: "error",
            });
          }
          setShowLoader(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const removeSelectedSid = (sid, elem) => {
    setSelectedData(
      selectedData.filter((elem) => {
        return elem.sid !== sid;
      })
    );

    setSidData([...SidData, elem]);
    setData({
      ...data,
      sid: "",
    });
  };

  return (
    <>
      <section className="lincpay-dashboard-sec merchant-admin-info-form company-add-form card py-3 container">
        {/* {showLoader ? <MyLoader /> : ""} */}
        <div className="containerflag">
          <div className="row mt-4">
            <div className="col-md-6 offset  ">
              <div className="col-sm-12 col-md-6 offset-md-3">
                <label htmlFor="email">Select A Merchant</label>
                <select
                  id="companyDropdown"
                  className="form-control"
                  onChange={handelDataChange}
                  value={data.merchantSelect}
                  name="merchantSelect"
                >
                  <option value="">Select a Merchant</option>
                  {merchantData.map((merchant) => (
                    <option key={merchant.mid} value={merchant.mid}>
                      {merchant.merchantName}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-12 col-md-6 offset-md-3 mt-2">
                <label htmlFor="email">Routing Pattern</label>
                <select
                  id=""
                  className="form-control"
                  onChange={handelDataChange}
                  value={data.routingPattern}
                  name="routingPattern"
                >
                  <option value="">Select a Pattern</option>
                  <option value="RR">Round Robin</option>
                  <option value="SQ">Sequential</option>
                </select>
              </div>

              <div className="row mt-2">
                <div className="col-sm-12 col-md-6 offset-md-3"></div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="col-sm-12 col-md-6 offset-md-3 mt-2">
                <label htmlFor="email">Select SID's</label>
                <select
                  id=""
                  className="form-control"
                  onChange={handelDataChange}
                  value={data.sid}
                  name="sid"
                >
                  <option value="">Select SID</option>
                  {SidData.map((elem) => {
                    return (
                      <option key={elem.sid} value={elem.sid}>
                        {elem.company}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* <button className="btn btn-primary" onClick={HandleSubmit}>
            Submit
          </button> */}
            </div>
            <div className="d-flex justify-content-center">
              <div className="col-sm-8 col-md-8 ">
                {selectedData.map((elem) => {
                  // console.log("elem---", elem);
                  return (
                    <div
                      key={elem.sid}
                      className=" d-flex align-items-center mt-2  py-2 "
                    >
                      <div className="form-group d-flex flex-column col-sm-12 col-md-5  mt-2">
                        <div className=" ">
                          {/* <Checkbox
                          {...label}
                          onChange={(e) =>
                             handelSearchLimit(e, elem.sid)
                            
                          }
                        /> */}
                          {elem.company}
                        </div>
                        <span className="  text-danger">
                          Maximum Transaction Amount : {`${elem.maxTxnAmount}`}
                        </span>
                        <span className=" text-danger">
                          Remaining Limit : {`${elem.remainingLimit}`}
                        </span>
                      </div>
                      <div className="form-group col-sm-12 col-md-4 mt-2">
                        <label htmlFor="email">Enter Maximum Txn Amount</label>
                        <input
                          type="text"
                          className="form-control"
                          id="txnAmt"
                          name="txnAmt"
                          placeholder="Enter Maximum Txn Amount"
                          value={elem.maxTxnAmtInputVal}
                          onKeyDown={getKeyName}
                          onChange={(e) => handelTxnInput(e, elem.sid)}
                        />
                      </div>
                      <div className="col-sm-12 col-md-2 mx-3 mt-4 ">
                        <Button
                          className="bg-success"
                          size="sm"
                          onClick={(e) => handelSearchLimit(e, elem.sid)}
                        >
                          View Mapped SID's
                        </Button>
                      </div>
                      <div className="col-sm-12 col-md-1  mt-4 ">
                        <span
                          onClick={() => removeSelectedSid(elem.sid, elem)}
                          style={{ cursor: "pointer" }}
                          className="text-danger fs-4"
                        >
                          <i className="bi bi-trash-fill"></i>
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <button className="btn btn-success" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
        {/* // EDIT FORM MODEL STARTS */}
        <Modal show={isModalOpen} onHide={handleCloseModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>SID Details:</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card className="shadow border-0 mb-4">
              <Card.Body>
                <div className="merchant-report-view">
                  <div className="report-table-view">
                    <div className="containerflag ">
                      <div className="row mt-4 ">
                        <MDBDataTable
                          className="dataTable"
                          striped
                          small
                          bordered
                          hover
                          responsive
                          data={{
                            columns: sidDetailsColumn,
                            rows: sidDetailsData,
                          }}
                          searching={true}
                          entries={50}
                          exportToCSV
                          theadColor="#333"
                          paginationLabel={["Previous", "Next"]}
                          sortable={true}
                          footer="none"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default Routing;