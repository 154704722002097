import React, { useEffect, useState } from "react";
import InactiveServices from "../../InactiveServices";
import AllPayoutReport from "./AllPayoutReport";
import SubmittedPayout from "./SubmittedPayout";
import ApprovedPayout from "./ApprovedPayout";
import RejectedPayout from "./RejectedPayout";
import { Card, Col, Container, Row } from "react-bootstrap";
import MyLoader from "../../../common/MyLoader";

const PayOutSection = ({setShowLoader}) => {
  const [activePhase, setActivePhase] = useState("Phase2");
  // const [showLoader, setShowLoader] = useState(false);
  // const [activeSubtab, setActiveSubtab] = useState("MerchantManagement");

  useEffect(() => {
    setActivePhase("Phase2");
    //   setActiveSubtab("MerchantManagement");
  }, []);

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  return (
    <>
     {/* {showLoader ? <MyLoader /> : ""} */}
      <div className="subtab-sec-admin">
        <Container>
          <Row>
            <Col xs={12} lg={12} md={12}>
              <Card className="shadow border-0 mb-4">
                <div className="merchant-tab-btn">
                  <ul className="report-link nav-report-btn">
                    
                    <li
                      className={activePhase === "Phase2" ? "active" : ""}
                      onClick={() => handlePhaseClick("Phase2")}
                    >
                      Submitted
                    </li>
                  
                  </ul>
                </div>
                <Card.Body>
                  <div className="admin-tab-content">
                    { 
                    // activePhase === "Phase1" ? (
                    //   <AllPayoutReport />
                    // ) : 
                    activePhase === "Phase2" ? (
                      <SubmittedPayout
                      setShowLoader={setShowLoader} />
                    ) 
                    // : activePhase === "Phase3" ? (
                    //   <ApprovedPayout />
                    // ) : activePhase === "Phase4" ? (
                    //   <RejectedPayout />
                    // )
                     : (
                      <InactiveServices />
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PayOutSection;
