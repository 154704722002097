import axios from "axios";
import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import swal from "sweetalert";
import { SWIPELINC_API } from "../../../utils/constant";
import {
  getAllRoutingView,
  getAllSubmittedPayoutRouting,
} from "../../../utils/ApiServices";

const SubmittedRoutingPayout = ({ setShowLoader }) => {
  const [applications, setApplications] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");

  const [companyMid, setCompanyMid] = useState("");
  const [editCallback, setEditCallback] = useState({
    id: "",
    callbackUrl: "",
    transferType: "",
    mappedMid:"",
    usedFor:"",
    mid:""
  });
  const columns = [
    { label: "Merchant ID", field: "mid", width: "auto" },
    { label: "Merchant Name", field: "companyName", width: "auto" },
    // { label: "SID", field: "sid", width: "auto" },
    { label: "Mapped With", field: "mappedWithCompanyName", width: "auto" },
    { label: "Callback URL", field: "callbackUrl", width: "auto" },
    { label: "Transfer Type", field: "transferType", width: "auto" },
    { label: "Access Type", field: "usedFor", width: "auto" },
    // {
    //   label: "Maximum Transaction Amount",
    //   field: "sidLimit",
    //   width: "auto",
    // },
    { label: "Action", field: "action", width: "auto" },
  ];

  const mappedData = applications?.map((item) => {
    const mappedItem = { ...item };
    mappedItem.maxTxnAmount = (
      <sapan>
        {" "}
        <b>&#8377;</b> {`${mappedItem.maxTxnAmount}`}
      </sapan>
    );

    console.log(mappedItem.active);

    if (mappedItem.active === true) {
      mappedItem.action = (
        <div className="d-flex gap-2 justify-content-evenly">
          <button
            className="btn btn-warning btn-sm"
            onClick={() =>
              openDetails(item.id, item.callbackUrl, item.transferType, item.mappedMid, item.mappedWithCompanyName, item.usedFor,item.mid )
            }
          >
            Edit
          </button>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => disableSID(item.id)}
          >
            Disable
          </button>
          {/* <button
            className="btn btn-primary btn-sm"
            onClick={() => Details(item.id)}
          >
            View Details
          </button> */}
        </div>
      );
    }

    if (mappedItem.active === false) {
      mappedItem.action = (
        <div className="d-flex gap-2 justify-content-evenly">
          <button
            className="btn btn-warning btn-sm"
            onClick={() => openDetails(item.id, item.callbackUrl,item.mid)}
          >
            Edit
          </button>

          <button
            className="btn btn-success btn-sm"
            onClick={() => enableSID(item.id)}
          >
            Enable
          </button>
        </div>
      );
    }

    return mappedItem;
  });

  const getVirtualAccountData = (type) => {
    setShowLoader(true);
    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/merchantPayoutMaster/getAllMerchantPayoutMasterDistinctData/${type}`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          setCompanyData(response.data.data);
        } else setCompanyData();
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setShowLoader(false);
      });
  }

  // const getAllInitialSID = () => {
  //   setShowLoader(true);
  //   axios
  //     .get(`${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/getAllSid`, {
  //       headers: {
  //         Authentication: `Bearer ${token}`,
  //       },
  //     })
  //     .then((response) => {

  //       console.log(response.data.data);

  //       if (response.data.statusCode === 200) {
  //         setSidData(response.data.data);
  //         let res = response.data.data.map((elem) => {
  //           return { ...elem, maxTxnAmtInputVal: "" };
  //         });

  //         let selectedData = response.data.data.filter(
  //           (elem) => editFormData.sid === elem.sid
  //         );
  //         setCheckTxnLimit(selectedData[0]);
  //         setSidData(res);
  //       } else {
  //         console.error("Error fetching data:", response.data.message);
  //       }

  //       setShowLoader(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // };

  
  const openDetails = (id, callbackUrl, transferType, mappedMid, mappedWithCompanyName, usedFor,mid) => {
    setEditCallback({
      id,
      callbackUrl,
      transferType,
      mappedMid, 
      usedFor,
      mid
    });
    console.log("mid is :", mid)
    setIsModalOpen(true);
    getVirtualAccountData(transferType)
    setSelectedCompany(mappedWithCompanyName)
    setCompanyMid(mappedMid)
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditCallback({
      id: "",
      callbackUrl: "",
      transferType: "",
      mappedMid:"",
      mid:""
    })
  };

  const disableSID = (id) => {
    // Show swal confirmation before proceeding
    swal({
      title: "Alert",
      text: "Are you sure, do you want to disable?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDisable) => {
      if (willDisable) {
        const formData = new FormData();
        formData.append("id", id);
        setShowLoader(true);

        axios
          .put(
            `${SWIPELINC_API}MerchantAdminPanel/payoutRouting/disable/${id}`, // Fix the template string
            formData,
            {
              headers: {
                Authentication: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log(response);

            if (response.data.statusCode === 200) {
              swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
              });

              handleCloseModal();
              FetchData(token);
            } else {
              swal({
                title: "Alert!",
                text: response.data.message,
                icon: "error",
              });
            }
            setShowLoader(false);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            setShowLoader(false);
          });
      } else {
        // User clicked cancel in the swal confirmation
        setShowLoader(false);
      }
    });
  };

  const enableSID = (id) => {
    // Show swal confirmation before proceeding
    swal({
      title: "Alert",
      text: "Are you sure, do you want to enable?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDisable) => {
      if (willDisable) {
        const formData = new FormData();
        formData.append("id", id);
        setShowLoader(true);

        axios
          .put(
            `${SWIPELINC_API}MerchantAdminPanel/payoutRouting/enable/${id}`, // Fix the template string
            formData,
            {
              headers: {
                Authentication: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log(response);

            if (response.data.statusCode === 200) {
              swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
              });

              handleCloseModal();
              FetchData(token);
            } else {
              swal({
                title: "Alert!",
                text: response.data.message,
                icon: "error",
              });
            }
            setShowLoader(false);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            setShowLoader(false);
          });
      } else {
        // User clicked cancel in the swal confirmation
        setShowLoader(false);
      }
    });
  };

  const handelDataChange = (e) => {
    // console.log("name", e.target.name);
    setEditCallback({ ...editCallback, [e.target.name]: e.target.value });
  };

  const handleUpdateSubmit = (e) => {
    e.preventDefault();

    if(editCallback.usedFor === "Api") {
      if (editCallback.callbackUrl === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Callback URL",
          icon: "error",
        });
        return;
      }
    }

    const payload = {
      id: editCallback.id,
      callbackUrl: editCallback.callbackUrl,
      mappedMid: companyMid,
      transferType: editCallback.transferType,
      mid:editCallback.mid
    };
    setShowLoader(true);
    axios
      .put(
        `${SWIPELINC_API}MerchantAdminPanel/payoutRouting/updateMerchantPayoutMaster`,
        payload,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);

        if (response.data.statusCode === 200) {
          swal({
            title: "Success!",
            text:  "Details Updated Successfully.",
            icon: "success",
          });
          handleCloseModal();
          FetchData(token);
        } else {
          swal({
            title: "Alert!",
            text: response.data.message,
            icon: "error",
          });
        }
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setShowLoader(false);
      });
  };

  const FetchData = async (token) => {
    setShowLoader(true);
    try {
      const apiResponse = await getAllSubmittedPayoutRouting(token);
      console.log("apiRes", apiResponse);
      if (apiResponse.statusCode === 200) {
        setApplications(apiResponse?.data);
        console.log(apiResponse.data);
      } else {
      }
      setShowLoader(false);
    } catch (error) {
      // console.error(error);
      console.log(error);
      setShowLoader(false);
    }
  };

  const handleCompanyChange = (event) => {
  
    

    const selectedCompany = event.target.value;
    const companyMid = companyData.find(
      (company) => company.companyName === selectedCompany
    )?.mid || "";
    setSelectedCompany(selectedCompany);
    setCompanyMid(companyMid);
    // setEditCallback({...editCallback, mappedMid : companyMid})
    console.log(companyMid);
  };

  useEffect(() => {
    FetchData(token);
  }, []);

  // useEffect(() => {
  //   getAllInitialSID(token);
  // }, [editFormData.id]);

  return (
    <>
      <div className="card p-4">
        <MDBDataTable
          className="dataTable"
          striped
          small
          bordered
          hover
          responsive
          data={{
            columns: columns,
            rows: mappedData,
          }}
          searching={true}
          entries={50}
          exportToCSV
          theadColor="#333"
          paginationLabel={["Previous", "Next"]}
          sortable={true}
          footer="none"
        />
      </div>

      {/* EDIT FORM MODEL STARTS */}
      <Modal show={isModalOpen} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Callback URL:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="shadow border-0 mb-4">
            <Card.Body>
              <div className="merchant-report-view">
                <div className="report-table-view">
                  <div className="containerflag ">
                    <div className="row mt-4 ">
                      <div className="col-sm-12 col-md-8 offset-md-2">
                        {
                          editCallback.usedFor === "Api"? <div className="form-group mt-3">
                          <label htmlFor="email">Enter Callback URL</label>
                          <input
                            type="text"
                            className="form-control"
                            id="callbackUrl"
                            name="callbackUrl"
                            placeholder="Enter Maximum Txn Amount"
                            value={editCallback.callbackUrl}
                            onChange={handelDataChange}
                          />
                        </div>:""
                        }

                        <div className="row mt-2">
                          <div className=" my-2">
                            <label htmlFor="holdAmount">
                              Select Virtual Account
                            </label>

                            <select
                              className="form-control"
                              id="holdAmount"
                              value={selectedCompany}
                              onChange={handleCompanyChange}
                            >
                              <option value="">Select a Company</option>
                              {companyData?.map((company) => (
                                <option
                                  key={company.mid}
                                  value={company.companyName}
                                >
                                  {company.companyName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center mt-4">
                        <button
                          className="btn btn-primary"
                          onClick={handleUpdateSubmit}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubmittedRoutingPayout;