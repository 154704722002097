import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import FRManagement from './FRManagement';
import SubmittedFRMApplications from './SubmittedFRMApplications';
import InactiveServices from '../components/InactiveServices';
import MyLoader from '../common/MyLoader';

const SubTabForFRM = ({setShowLoader}) => {
    const [activePhase, setActivePhase] = useState("Phase1");
    // const [showLoader, setShowLoader] = useState(false);
    // const [token, setToken] = useState(localStorage.getItem("token"));
    // const [merchantData, setMerchantData] = useState([]);
    // const [selectedMerchant, setSelectedmerchant] = useState([]);
    // const [selectedCompany, setSelectedCompany] = useState("");
    // const [paymentServices, setPaymentServices] = useState([]);
    // const [formData, setFormData] = useState({
    //   email: "",
    //   mid: "",
    //   fullName: "",
    // });
  
    useEffect(() => {
      setActivePhase("Phase1");
    }, []);
  
    const handlePhaseClick = (phase) => {
      setActivePhase(phase);
    };
  
    // useEffect(() => {
    //   const selectedMerchant = merchantData.find(
    //     (merchant) => merchant.companyName === selectedCompany
    //   );
    //   if (selectedMerchant) {
    //     setPaymentServices(selectedMerchant.paymentService);
    //     console.log(paymentServices);
    //   }
    // }, [selectedCompany]);
  
    // useEffect(() => {
    //   axios
    //     .get(
    //       `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/findbystage2/Approved`,
  
    //       {
    //         headers: {
    //           Authentication: `Bearer ${token}`,
    //         },
    //       }
    //     )
    //     .then((response) => {
    //       if (response.status === 200) {
    //         setMerchantData(response.data.data);
    //       }
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching data:", error);
    //     });
    // }, []);
  
    return (
      <>
      {/* {showLoader ? <MyLoader/> : ""} */}
        <section className="dashboard-header-sec subtab-card">
          <Container>
            <div className="ds-header-sec">
              <Row>
                <Col xs={12} lg={12} md={12}>
                  <Card className="shadow border-0 mb-3  ds-inner-card">
                    <Card.Body>
                      <div className="merchant-tab-btn">
                        <ul className="report-link nav-report-btn submenu-tab-admin">
                          <li
                            className={activePhase === "Phase1" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase1")}
                          >
                            Froud & Risk Management Form {activePhase === "Phase1"}
                          </li>
                          <li
                            className={activePhase === "Phase2" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase2")}
                          >
                            Submitted Applications {activePhase === "Phase2"}
                          </li>
                          {/* <li
                            className={activePhase === "Phase3" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase3")}
                          >
                            Account phase 3 {activePhase === "Phase3"}
                          </li> */}
                        </ul>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <div className="admin-tab-content">
              {activePhase === "Phase1" ? (
                  <FRManagement
                  setShowLoader = {setShowLoader} />
                ) : activePhase === "Phase2" ? (
                  <SubmittedFRMApplications
                  setShowLoader = {setShowLoader} />
                ) : (
                  <InactiveServices />
                )}
              </div>
            </div>
          </Container>
        </section>
      </>
    );
  };

export default SubTabForFRM
