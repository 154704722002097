import React, { useState, useEffect } from 'react';
import { MDBDataTable } from 'mdbreact';
import "../assets/css/style.css";
import { FetchTemporaryRequest } from '../utils/ApiServices';


const TemporaryAutoWithdrawlRequest = ({setShowLoader}) => {
  const [data, setData] = useState([]);
  const [token,setToken] = useState(localStorage.getItem ("token"));

 
    const TemporaryRequest= (token)=>{
      setShowLoader(true)
    const response = FetchTemporaryRequest(token)
     .then((response) => {
      if(response.statusCode=== 200 ){
        setData(response?.data);
        setShowLoader(false)
      }
      else{
        setData([])
        setShowLoader(false)
      }
    })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setShowLoader(false)
      });

}
useEffect(() => {
    TemporaryRequest(token);
}, [])
  const columns = [
    // {label: 'UTR Number',field: 'utr',width:'auto' },
    {label: 'Merchant ID',field: 'mid',width:'auto'},
    {label: 'Amount',field: 'amount',width:'auto'},
    {label: 'Transfer Type',field: 'transferType',width:'auto'},
    {label: 'Virtual Account Type',field: 'virtualAccountType',width:'auto'},
    {label: 'Status',field: 'status',width:'auto'},
    // { label: 'Remark',field: 'remark',width:'auto' },
    { label: 'Withdrawal Manual',field: 'isWithdrawalManual',width:'auto' },
    { label: 'Transaction Date',field: 'transactionDate',width:'auto' },
    { label: 'Transfer Mode',field: 'transferMode',width:'auto' },
    // { label: 'isDepositeForPayout',field: 'isDepositeForPayout',width:'auto' },

  ];

 const mappedData = data.map((item)=>{
    const mappedItem = {};
    columns.forEach ((column)=>{
        if(column.field=== "isWithdrawalManual"){
            mappedItem[column.field] = item[column.field] ? 'Yes' : 'No';
        }else{
            mappedItem[column.field] = item[column.field];
        }
   
    })
    return mappedItem; 
 })

  return (
    <>
       <main className="">
        <div className="reportSection">
          <div className="">
            <div className="tabs-content active" id="tab1">
              <div className="card py-3">

        <div id = "EKyc-datatable-container ">
       <MDBDataTable className='dataTable overflow-x-scroll p-5' 
         hover 
        striped
         small
        bordered
        responsive
        sortable = {true}
        searching = {true}
        entries={10}
        data={{
        columns : columns,
        rows : mappedData
        }}
        footer = "none"
        theadColor='#333'
        paginationLabel={['Previous', 'Next']}
        />
      </div>
      </div>
      </div>
      </div>
      </div>
      </main>
    </>
  );
};

export default TemporaryAutoWithdrawlRequest;