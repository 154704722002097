import React, { useEffect, useState } from 'react'
import SingleSubmittedBankForm from './SingleSubmittedBankForm';
import { MDBDataTable } from 'mdbreact';
import { fetchAllBankSubmittedForm } from '../utils/ApiServices';
import { useNavigate } from 'react-router-dom';

const SubmittedBankForm = ({show,setShowLoader}) => {
    const [applications, setApplications] = useState([]);
    const [openView, setOpenView] = useState(false);
    const [mid, setMid] = useState('');
    const [name, setName] = useState('');
    const [token, setToken] = useState(localStorage.getItem("token"));
    const navigate = useNavigate();
  
    useEffect(() => {
      FetchData(token)
    }, []);
  
    const FetchData=async (token)=>{
      setShowLoader(true)
      try {
        const apiResponse = await fetchAllBankSubmittedForm(token);
        if (apiResponse.statusCode === 200) {
          
  
          setApplications(apiResponse?.data);
          console.log(apiResponse.data)
        } else {
        }
        setShowLoader(false)
      } catch (error) {
        // console.error(error);
        console.log(error);
        setShowLoader(false)
      }
    }
  
    const columns = [
   
   
      { label: "Merchant Name", field: "companyName", width: "auto" }, 
      { label: "Merchant ID", field: "mid", width: "auto" }, 
      { label: "Name Of Service", field: "service", width: "auto" }, 
      { label: "View Details", field: "action", width: "auto" }, 
    ];
  
    const mappedData = applications?.map((item) => {
      const mappedItem = { ...item };
      mappedItem.action = (
        <div>
          <button
            className="btn btn-primary"
            onClick={() => ViewDetails( item.mid,item.companyName )}
          >
            View Details
          </button>
        </div>
      );
      return mappedItem;
    });
  
   const ViewDetails=(mid,name)=>{
          setName(name)
          setMid(mid)
          setOpenView(true)
   }
    return (
      <>
       
      <div className="card p-4">
        {openView?(<SingleSubmittedBankForm setOpenView={setOpenView} mid={mid} openView={openView} show={show} name={name}/>):( <MDBDataTable
          className="dataTable"
          striped
          small
          bordered
          hover
          responsive
          data={{
            columns: columns,
            rows: mappedData,
          }}
          searching={true}
          entries={50}
          exportToCSV
          theadColor="#333"
          paginationLabel={["Previous", "Next"]}
          sortable={true}
          footer="none"
          />)}
      </div>
          </>
  
    );
  };

export default SubmittedBankForm
