import React, { useEffect, useState } from "react";
import ApplicationSection from "./ApplicationSection";
import Stage2AppSections from "../applicationsstage2/Stage2AppSections";
import InactiveServices from "../components/InactiveServices";

import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
// import MyLoader from "../common/MyLoader";

const SubTabManage = ({setShowLoader}) => {
  const [activePhase, setActivePhase] = useState("Phase1");
  // const [showLoader, setShowLoader] = useState(false);


  useEffect(() => {
    setActivePhase("Phase1");
  }, []);

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  return (
    <>
    {/* {showLoader ? <MyLoader/> :""} */}
      <section className="dashboard-header-sec subtab-card">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-3  ds-inner-card">
                  <Card.Body>
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn submenu-tab-admin">
                        <li
                          className={activePhase === "Phase1" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase1")}
                        >
                          Phase 1 {activePhase === "Phase1"}
                        </li>
                        <li
                          className={activePhase === "Phase2" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase2")}
                        >
                          Phase 2 {activePhase === "Phase2"}
                        </li>
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>
            </Row>
            <div className="admin-tab-content">
              {activePhase === "Phase1" ? (
                <ApplicationSection 
                setShowLoader = {setShowLoader}/>
              ) : activePhase === "Phase2" ? (
                <Stage2AppSections
                setShowLoader = {setShowLoader} />
              ) : (
                <InactiveServices />
              )}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default SubTabManage;
