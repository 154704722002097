import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import PayInRouting from "../../../Merchant/MerchantManagement/PayInRouting";
import InactiveServices from "../../InactiveServices";
import SubmittedRoutingPayin from "./SubmittedRoutingPayin";
import ViewMappedSID from "./ViewMappedSID ";
 

const RoutingSubTabForPayin = ({ setShowLoader }) => {
    const [activePhase, setActivePhase] = useState("Phase1");
    // const [activeSubtab, setActiveSubtab] = useState("MerchantManagement");
    // const [showLoader, setShowLoader] = useState(false);
  
    useEffect(() => {
      setActivePhase("Phase1");
    //   setActiveSubtab("MerchantManagement");
    }, []);
  
    const handlePhaseClick = (phase) => {
      setActivePhase(phase);
    };
  
    return (
      <>
      
       {/* {showLoader ? <MyLoader/> : ""} */}
        <section className="dashboard-header-sec">
          
            <div className="ds-header-sec">
              <Row>
                <Col xs={12} lg={12} md={12}>
                  <Card className="shadow border-0 mb-4 ds-header-card">
                    <Card.Body>
                      {/* <Card.Title className="h3">Reports</Card.Title>  */}
                      <div className="merchant-tab-btn">
                        <ul className="report-link nav-report-btn">
                          <li
                            className={activePhase === "Phase1" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase1")}
                          >
                            Routing
                          </li>
                      
                          <li
                            className={activePhase === "Phase2" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase2")}
                          >
                             Submitted
                          </li>
                          <li
                            className={activePhase === "Phase3" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase3")}
                          >
                             View Mapped SID
                          </li>
                          
                        </ul>
                      </div>
                    </Card.Body>
                  </Card>
                  
                </Col>
                
              </Row>
            </div>
          
        </section>
  
        <div>
          {activePhase === "Phase1" ? (
            <PayInRouting
            setShowLoader = {setShowLoader} />
          ) : activePhase === "Phase2" ? (
            <SubmittedRoutingPayin setShowLoader={setShowLoader} />
            ) : activePhase === "Phase3" ? (
              // <SubmittedRoutingPayin setShowLoader={setShowLoader} />
              <ViewMappedSID setShowLoader={setShowLoader} />
          ) : (
            <InactiveServices />
          )}
        </div>
      </>
    );
  };
  
export default RoutingSubTabForPayin;