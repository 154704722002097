import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MDBDataTable } from 'mdbreact';
import "../assets/css/style.css";
import { SWIPELINC_API } from '../utils/constant';


const Ekyc = ({setShowLoader}) => {
  const [ekycData, setEkycData] = useState([]);
  const [token,setToken] = useState(localStorage.getItem ("token"));

  // useEffect(() => {
  //   // Make an Axios GET request to fetch the data from the API
  //   axios
  //     .get(`${SWIPELINC_API}`,
  //     {
  //       headers : {
  //           Authentication : `Bearer ${token}`
  //       }
  //     })
  //     .then((response) => {
  //     if(response.data.statusCode=== 200 && Array.isArray(response.data.data)){
  //       setEkycData(response.data.data);
  //     }
  //   })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     });
  // }, []);

  // Define the columns for the MDBDataTable
  const columns = [
    {label: 'ID',field: 'id',width:'auto' },
    {label: 'Request For',field: 'requestFor',width:'auto'},
    {label: 'Request Date and Time',field: 'requestDateAndTime',width:'auto'},
    {label: 'Request Parameter',field: 'requestParameter',width:'auto'},
    {label: 'Response Parameter',field: 'responseParameter',width:'auto'},
    {label: 'Merchant ID',field: 'merchantId',width:'auto'},
    { label: 'Amount',field: 'amount',width:'auto' },
  ];

 const mappedData = ekycData.map((item)=>{
    const mappedItem = {};
    columns.forEach ((column)=>{
    mappedItem[column.field] = item[column.field];
    })
    return mappedItem; 
 })

  return (
    <>
       <main className="">
        <div className="reportSection">
          <div className="">
            <div className="tabs-content active" id="tab1">
              <div className="card py-3">

        <div id = "EKyc-datatable-container ">
       <MDBDataTable className='dataTable overflow-x-scroll p-5' 
         hover 
        striped
         small
        bordered
        responsive
        sortable = {true}
        searching = {true}
        entries={10}
        data={{
        columns : columns,
        rows : mappedData
        }}
        footer = "none"
        theadColor='#333'
        paginationLabel={['Previous', 'Next']}
        />
      </div>
      </div>
      </div>
      </div>
      </div>
      </main>
    </>
  );
};

export default Ekyc;
