import { MDBDataTable } from 'mdbreact';
import React, { useEffect, useState } from 'react'
import { disabledDataPayoutMaster } from '../utils/ApiServices';
import swal from 'sweetalert';
import { SWIPELINC_API } from '../utils/constant';
import axios from 'axios';

const Disabled = ({setShowLoader}) => {
    const [applications, setApplications] = useState([]);
    
    const [token, setToken] = useState(localStorage.getItem("token"));
    // const [isModalOpen, setIsModalOpen] = useState(false);
    const [callAgain, setCallAgain] = useState(false);
    // const [editFormData, setEditFormData] = useState({});
  
    const columns = [
      { label: "Merchant ID", field: "mid", width: "auto" },
      { label: "Merchant Name", field: "companyName", width: "auto" },
      { label: "Client ID", field: "clientIds", width: "auto" },
      { label: "Client Secret", field: "clientSecrete", width: "auto" },
      {
        label: "Account Module Secret",
        field: "accountsModuleSecrete",
        width: "auto",
      },
      {
        label: "Payment Module Secret",
        field: "paymentModuleSecrete",
        width: "auto",
      },
      { label: "Provider Secret", field: "providerSecrete", width: "auto" },
      {
        label: "master Virtual Account",
        field: "masterVirtualAccount",
        width: "auto",
      },
      { label: "Transfer Type", field: "transferType", width: "auto" },
      { label: "MVA Customer Id", field: "mvaCustomerId", width: "auto" },
      { label: "MVA Mobile Number", field: "mvaMobileNumber", width: "auto" },
      { label: "Master Alius Id", field: "masterAliusId", width: "auto" },
      { label: "IFSC Code", field: "ifsc", width: "auto" },
      { label: "Password", field: "password", width: "auto" },
      { label: "User Name", field: "userName", width: "auto" },
      // { label: "Merchant Name", field: "company", width: "auto" },
      // { label: "SID", field: "sid", width: "auto" },
      // { label: "Maximum Transaction Amoumt", field: "maxTxnAmount", width: "auto" },
      { label: "Action", field: "action", width: "auto" },
    ];
  
    const mappedData = applications.length ? applications?.map((item) => {
      const mappedItem = { ...item };
      mappedItem.maxTxnAmount = (
        <sapan>
          {" "}
          <b>&#8377;</b> {`${mappedItem.maxTxnAmount}`}
        </sapan>
      );
      mappedItem.action = (
        <div className="d-flex gap-2 justify-content-evenly">
          {/* <button
            className="btn btn-warning btn-sm"
            onClick={() => openDetails({ ...item })}
          >
            Edit
          </button> */}
          <button
            className="btn btn-success btn-sm"
            onClick={() => disablePayout(item.id)}
          >
            Enable
          </button>
        </div>
      );
      return mappedItem;
    }):[];
  
   
  
  
  
    const disablePayout = (id) => {
      setShowLoader(true);
      axios
        .put(
          `${SWIPELINC_API}MerchantAdminPanel/merchant/merchantPayoutMaster/enable/${id}`,
          "",
          {
            headers: {
              Authentication: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
  
          if (response.data.statusCode === 200) {
            swal({
              title: "Success!",
              text: response.data.message,
              icon: "success",
            });
            // setEditFormData({
            //     sid: "",
            //     txnAmt: "",
            //     mid: "",
            //     merchantName: "",
            //     id: ""
            // })
            // handleCloseModal()
            FetchData(token);
          } else {
            swal({
              title: "Alert!",
              text: response.data.message,
              icon: "error",
            });
          }
          setCallAgain(!callAgain)
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setShowLoader(false);
        });
    };
   
  
  
  
    const FetchData = async (token) => {
      setShowLoader(true);
      try {
        const apiResponse = await disabledDataPayoutMaster(token);
        if (apiResponse.statusCode === 200) {
          setApplications(apiResponse?.data);
          console.log(apiResponse.data);
        } else {
          setApplications([]);
        }
        setShowLoader(false);
      } catch (error) {
        // console.error(error);
        console.log(error);
        setShowLoader(false);
      }
    };
  
    useEffect(() => {
      FetchData(token);
    }, [callAgain]);
  
    return (
      <>
        <div className="card p-4">
          <MDBDataTable
            className="dataTable"
            striped
            small
            bordered
            hover
            responsive
            data={{
              columns: columns,
              rows: mappedData,
            }}
            searching={true}
            entries={50}
            exportToCSV
            theadColor="#333"
            paginationLabel={["Previous", "Next"]}
            sortable={true}
            footer="none"
          />
        </div>
  
        {/* EDIT FORM MODEL STARTS */}
         
      </>
    );
  };

export default Disabled