import React, { useEffect, useState } from "react";
import InactiveServices from "../../components/InactiveServices";
import GuarenteeAmount from "./GuarenteeAmount";
import Hold from "./Hold";
import { Container, Card, Row, Col } from "react-bootstrap";
import ManageAmount from "./ManageAmount";
import MyLoader from "../../common/MyLoader";
import Release from "./Release";
import { getAllMerchantPhase2Approved } from "../../utils/ApiServices";

const HoldandDepositSection = ({ setShowLoader }) => {
  const [activePhase, setActivePhase] = useState("Phase1");
  const [data, setData] = useState([]);
  const [callAgain, setCallAgain] = useState(false);
  // const [showLoader, setShowLoader] = useState(false);
  const token = localStorage.getItem("token");

  const getMerchantData = async (token) => {
    try {
      setShowLoader(true);
      const response = await getAllMerchantPhase2Approved(token);
      setShowLoader(false);
      if (response?.statusCode === 200) {
        setData(response?.data);
        console.log(response?.data)
      } else {
        setData([]);
      }
    } catch (error) {
      setShowLoader(false);

      setData([]);
    }
  };

  useEffect(() => {
    setActivePhase("Phase1");
    getMerchantData(token);
  }, []);

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  return (
    <>
      <div className="subtab-sec-admin">
        <Container>
          <Row>
            <Col xs={12} lg={12} md={12}>
              <Card className="shadow border-0 mb-4">
                <div className="merchant-tab-btn">
                  <ul className="report-link nav-report-btn">
                    <li
                      className={activePhase === "Phase1" ? "active" : ""}
                      onClick={() => handlePhaseClick("Phase1")}
                    >
                      Deposit
                    </li>
                    <li
                      className={activePhase === "Phase2" ? "active" : ""}
                      onClick={() => handlePhaseClick("Phase2")}
                    >
                      Hold
                    </li>
                    <li
                      className={activePhase === "Phase3" ? "active" : ""}
                      onClick={() => handlePhaseClick("Phase3")}
                    >
                      Guarantee Amount
                    </li>
                    <li
                      className={activePhase === "Phase4" ? "active" : ""}
                      onClick={() => handlePhaseClick("Phase4")}
                    >
                      Release Amount
                    </li>
                  </ul>
                </div>
                <Card.Body>
                  <div className="admin-tab-content">
                    {activePhase === "Phase1" ? (
                      <ManageAmount setShowLoader={setShowLoader} data={data}/>
                    ) : activePhase === "Phase2" ? (
                      <Hold setShowLoader={setShowLoader}  data={data}/>
                    ) : activePhase === "Phase3" ? (
                      <GuarenteeAmount setShowLoader={setShowLoader}  data={data}/>
                    ) : activePhase === "Phase4" ? (
                      <Release setShowLoader={setShowLoader} data={data}/>
                    ) : (
                      <InactiveServices />
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HoldandDepositSection;
