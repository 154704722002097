import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { SWIPELINC_API } from "../utils/constant";
import swal from "sweetalert";


const VehicleCommercialForm = ({setShowLoader}) => {
  const [data, setData] = useState({
    type: '',
  });
  const [token, setToken] = useState(localStorage.getItem("token"));

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!data.type ) {
      swal({
        title: 'Alert!',
        text: 'Please Enter Vehicle Commercial.',
        icon: 'error',
      });
      return;
    }

    setShowLoader(true);
    axios
      .post(
        `${SWIPELINC_API}MerchantAdminPanel/admin/saveVehicleCommercial`,
        { type: data.type },
        {
          headers:{
            Authentication: `Bearer ${token}`
          }
        }
      )
      .then((response) => {
        console.log(response);

        if (response.data.statusCode === 200) {
          swal({
            title: 'Success!',
            text: response.data.message,
            icon: 'success',
          });
          setData({ type: '' });
        }
        else if(response.statusCode=== 309) {
          swal({
            title: "Alert",
            text: "Service already exists",
            icon: "alert"
          })
        } 
        else {
          swal({
            title: 'Alert!',
            text: response.data.message,
            icon: 'error',
          });
        }
        setShowLoader(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setShowLoader(false);
      });
  };

  const handleTypeChange = (e) => {
    setData({ ...data, type: e.target.value });
  };

  return (
    <section className="lincpay-dashboard-sec merchant-admin-info-form company-add-form">
        {/* <Container> */}
          <div className="cart-view-sec mt-4">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow-cst border-0 mb-3 chart-boxes merchant-info-form-sec">
                  <Card.Body>
                    <div className="merchant-form-info">
                      <form
                        className="form-section-form-group"
                       
                      >
                        <Row className="d-flex flex-column gap-4">
                          <Col xs={12} lg={6} md={6}>
                            <div className="form-group">
                          <label htmlFor="type">Enter Type</label>
                          <input
                            type="text"
                            className="form-control"
                            id="type"
                            name="type"
                            placeholder="Enter Type"
                            value={data.type}
                            onChange={handleTypeChange}
                          />
                        </div>
                    
                    </Col>
                  </Row>
                  </form>
                  <Col xs={12} lg={6} md={6}>
                        <div className="d-flex justify-content-end">
                          <div className="form-group  tool-bar-btn mb-0 mt-3">
                    <button className="submit-button submit_link_info btn btn-primary"
                     onClick={handleSubmit}
                      id="submitDetailsBtn"
                      >
                      Submit
                      </button>
                          </div>
                        </div>
                      </Col>
                      </div>
                  </Card.Body>
                </Card>
              
              </Col>
            
            </Row>
          </div>
          </section>
  );
};

export default VehicleCommercialForm;
