import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { isUtrNumRegexIsValid } from '../utils/helper';
import { USE_LIVE_WITHDRAW_MULTIPLE_FILTER_API } from '../utils/ApiServices';

// import { USE_LIVE_WITHDRAW_MULTIPLE_FILTER_API as getApiData } from "../../utils/ApiServices";

const LiveWithdrawlMultipleFilter = ({
    setShowLoader,
    setTransactionReportData,
    setShowMultipleFilter,
    dropDownData,
}) => {
    const token = localStorage.getItem("token");
    const [startAmount, setStartAmount] = useState(Number);
    const [range, setRange] = useState("");
    const [endAmount, setEndAmount] = useState(Number);
    const [startDate, setStartDate] = useState("");
    const [createStartDate,  setCreateStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [createEndDate, setCreateEndDate] = useState("");
    const [searchOption, setSearchOption] = useState("");
    const [searchValue, setSearchValue] = useState("test");
    const [transectionStatus, setTransectionStatus] = useState("");
    const [searchOption2, setSearchOption2] = useState("");
    const [searchOption3, setSearchOption3] = useState("");
    const [searchValue2, setSearchValue2] = useState("");
    const [searchValue3, setSearchValue3] = useState("");
    const [show2, setShow2] = useState(true);
    const [formData, setFormData] = useState({
        mid: "",
      });
      const [selectedCompany, setSelectedCompany] = useState("");
    
    const exampleValues = {
      amount: "Ex- 5500",
      amountInBetween: "Ex- 100-500",
      utr:"Ex- 123456789065"
      // Add more options and example values as needed
    };
  
    // const startDate = useSelector((store) => store.report.startDate);
    // const endDate = useSelector((store) => store.report.endDate);
    // const storeSearchValue = useSelector((state) => state.report.searchValue);
    // const dispatch = useDispatch();
    // console.log(amount)
    const [searchValueError, setSearchValueError] = useState("");
    const [isSearchValueError, setIsSearchValueError] = useState(false);
    
     
    const handleAmountRange = (e) => {
      setRange(e.target.value);
      function splitString(inputString) {
        const parts = inputString.split("-").map((part) => part.trim());
        return parts;
      }
  
      const [startAmount, endAmount] = splitString(e.target.value);
      setStartAmount(startAmount);
      setEndAmount(endAmount);
  
      console.log(startAmount, endAmount);
    };

    const handleCompanyChange = (event) => {
        const selectedCompany = event.target.value;
        if (selectedCompany === "") {
          setFormData({
            mid: "",
          });
    
          setSelectedCompany("");
          return;
        }
    
        setSelectedCompany(selectedCompany);
    
        const selectedMerchant = dropDownData.find(
          (merchant) => merchant.merchantName === selectedCompany
        );
        console.log(selectedMerchant);
    
        if (selectedMerchant) {
          setFormData({
            mid: selectedMerchant.mid || "",
          });
        }
      };
  
    useEffect(() => {
      setSearchValue("");
    }, [searchOption]);
    useEffect(() => {
      setSearchValue2("");
    }, [searchOption2]);

    const withOrWithoutMid = () => {
        if (formData.mid === "") {
          return true;
        } else {
         return false;
        }
      };
    
    
    const submitHandler = () => {
      // console.log(searchOption2, searchValue2);
      USE_LIVE_WITHDRAW_MULTIPLE_FILTER_API(
        withOrWithoutMid(formData.mid),
        searchOption === "amount" ? searchValue : 0,
        searchOption === "amountInBetween" ? startAmount : 0,
        searchOption === "amountInBetween" ? endAmount : 0,
        searchOption2 === "dateInBetween" ? startDate : "",
        searchOption2 === "dateInBetween" ? endDate : "",
        searchOption3 === "dateInBetween" ? createStartDate : "",
        searchOption3 === "dateInBetween" ? createEndDate : "",
        searchOption2 === "date" ? searchValue2 : "",
        searchOption3 === "date" ? searchValue3 : "",
        "",
        transectionStatus,
        formData.mid,
        token,
        setShowLoader,
        setTransactionReportData,
       
      );
      handleModalClose();
      setFormData({
        mid:"",
      })
    };
  
    const handleModalClose = () => {
      setShow2(false);
      setShowMultipleFilter(false)
      
    };
    const handleDateInputChange = (event) => {
      const newDate = event.target.value;
      // Check if the input date is greater than today's date
      const inputDateTime = DateTime.fromISO(newDate, { zone: "UTC" });
      const today = DateTime.now().startOf("day");
  
      if (inputDateTime.toISODate() > today.toISODate()) {
        return true;
      }
      return false;
    };
  
    const handelSearchData = (e) => {
      setSearchValue(e.target.value);
      setIsSearchValueError(false);
      setSearchValueError("");
    };
  
  
    const handelValidations = (e) => {
      if (searchOption === "utr") {
        if (!isUtrNumRegexIsValid(searchValue)) {
          setIsSearchValueError(true);
          setSearchValueError("Please Enter Valid UTR Number");
        }
        return;
      }
  
      
    };
  
    const handleFromToDateInputChange = (from, to) => {
      console.log(from, ":", to);
  
      const inputFromDateTime = DateTime.fromISO(from, { zone: "utc" });
      const inputToDateTime = DateTime.fromISO(to, { zone: "utc" });
      if (inputToDateTime >= inputFromDateTime) {
        return true;
      } else {
        return false;
      }
    };
  
    // Modal Popup filter conts
    // const [show, setShow] = useState(false);
  
    // const handleModalClose = () => setShow(false);
    // const handleModalShow = () => setShow(true);
    return (
      <div>
        <div className="d-flex flex-row">
          <Modal show={show2} size="lg" centered>
            <Modal.Header className="bg-dark ">
              <Modal.Title className="text-white">Multiple Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="filter-field-area">
                <fieldset className="rounded-0 mb-3">
                  <legend className="fs-5 fw-bold">Select Date</legend>
                  <div className="field-set-form">
                    <Row className="g-2">
                      <Col lg={6} sm={12}>
                        <div>
                          <select
                            className="form-control"
                            value={searchOption2}
                            onChange={(e) => setSearchOption2(e.target.value)}
                          >
                            <option value="">Select</option>
                            <option value="date">Date</option>
                            <option value="dateInBetween">Date In Between</option>
                            <i class="bi bi-caret-down-fill"></i>
                          </select>
                        </div>
                      </Col>
  
                      {searchOption2 === "date" ? (
                        <Col lg={6} sm={12}>
                          <div className="form-group">
                            <input
                              className="advanced-input-box form-control"
                              type="date"
                              value={searchValue2}
                              onChange={(e) => {
                                if (handleDateInputChange(e)) {
                                  setSearchValue2("");
                                  // console.log("true..........");
                                  document
                                    .getElementById("dateErr")
                                    .classList.remove("d-none");
                                } else {
                                  document
                                    .getElementById("dateErr")
                                    .classList.add("d-none");
                                  setSearchValue2(e.target.value);
                                  // console.log("false..........");
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                              }}
                              placeholder={
                                searchOption === "Date" ? "Enter Date" : ""
                              }
                            />
                            <span id="dateErr" className=" text-danger d-none">
                              *Date must be lesser than current date.
                            </span>
                          </div>
                        </Col>
                      ) :searchOption2 === "dateInBetween" ? (
                        <>
                          <Col lg={6} sm={12}>
                            <div className="form-group">
                              <input
                                className="advanced-input-box form-control"
                                type="date"
                                value={startDate}
                                onChange={(e) => {
                                  if (handleDateInputChange(e)) {
                                    setStartDate("");
                                    document
                                      .getElementById("startDateErr")
                                      .classList.remove("d-none");
                                  } else {
                                    document
                                      .getElementById("startDateErr")
                                      .classList.add("d-none");
                                    setStartDate(e.target.value);
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                }}
                                placeholder="Enter Start Date"
                              />
                              <span
                                id="startDateErr"
                                className=" text-danger d-none"
                              >
                                *Please Enter Valid Date!
                              </span>
                            </div>
                            <div className="form-group">
                              <input
                                className="advanced-input-box form-control"
                                type="date"
                                value={endDate}
                                onChange={(e) => {
                                  if (handleDateInputChange(e)) {
                                    setEndDate("");
                                    document
                                      .getElementById("endDateErr")
                                      .classList.remove("d-none");
                                  } else {
                                    if (
                                      handleFromToDateInputChange(
                                        startDate,
                                        e.target.value
                                      )
                                    ) {
                                      document
                                        .getElementById("endDateErr")
                                        .classList.add("d-none");
                                      document
                                        .getElementById("fromToDateErr")
                                        .classList.add("d-none");
                                      setEndDate(e.target.value);
                                    } else {
                                      document
                                        .getElementById("fromToDateErr")
                                        .classList.remove("d-none");
                                    }
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                }}
                                placeholder="End Date"
                              />
                              <span
                                id="endDateErr"
                                className=" text-danger d-none"
                              >
                                *Please Enter Valid Date!
                              </span>
                              <span
                                id="fromToDateErr"
                                className=" text-danger d-none"
                              >
                                *TO Date can't be less than FROM Date!
                              </span>
                            </div>
                          </Col>
                        </>
                      ):""}
                    </Row>
                  </div>
                </fieldset>
                <fieldset className="rounded-0 mb-3">
                  <legend className="fs-5 fw-bold">Select Create Date</legend>
                  <div className="field-set-form">
                    <Row className="g-2">
                      <Col lg={6} sm={12}>
                        <div>
                          <select
                            className="form-control"
                            value={searchOption3}
                            onChange={(e) => setSearchOption3(e.target.value)}
                          >
                            <option value="">Select</option>
                            <option value="date">Create Date</option>
                            <option value="dateInBetween">Create Date In Between</option>
                            <i class="bi bi-caret-down-fill"></i>
                          </select>
                        </div>
                      </Col>
  
                      {searchOption3 === "date" ? (
                        <Col lg={6} sm={12}>
                          <div className="form-group">
                            <input
                              className="advanced-input-box form-control"
                              type="date"
                              value={searchValue3}
                              onChange={(e) => {
                                if (handleDateInputChange(e)) {
                                  setSearchValue3("");
                                  // console.log("true..........");
                                  document
                                    .getElementById("dateErr")
                                    .classList.remove("d-none");
                                } else {
                                  document
                                    .getElementById("dateErr")
                                    .classList.add("d-none");
                                  setSearchValue3(e.target.value);
                                  // console.log("false..........");
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                              }}
                              placeholder={
                                searchOption === "Date" ? "Enter Date" : ""
                              }
                            />
                            <span id="dateErr" className=" text-danger d-none">
                              *Date must be lesser than current date.
                            </span>
                          </div>
                        </Col>
                      ) : searchOption3 === "dateInBetween" ?  (
                        <>
                          <Col lg={6} sm={12}>
                            <div className="form-group">
                              <input
                                className="advanced-input-box form-control"
                                type="date"
                                value={createStartDate}
                                onChange={(e) => {
                                  if (handleDateInputChange(e)) {
                                    setCreateStartDate("");
                                    document
                                      .getElementById("startDateErr")
                                      .classList.remove("d-none");
                                  } else {
                                    document
                                      .getElementById("startDateErr")
                                      .classList.add("d-none");
                                    setCreateStartDate(e.target.value);
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                }}
                                placeholder="Enter Start Date"
                              />
                              <span
                                id="startDateErr"
                                className=" text-danger d-none"
                              >
                                *Please Enter Valid Date!
                              </span>
                            </div>
                            <div className="form-group">
                              <input
                                className="advanced-input-box form-control"
                                type="date"
                                value={createEndDate}
                                onChange={(e) => {
                                  if (handleDateInputChange(e)) {
                                    setCreateEndDate("");
                                    document
                                      .getElementById("endDateErr")
                                      .classList.remove("d-none");
                                  } else {
                                    if (
                                      handleFromToDateInputChange(
                                        createStartDate,
                                        e.target.value
                                      )
                                    ) {
                                      document
                                        .getElementById("endDateErr")
                                        .classList.add("d-none");
                                      document
                                        .getElementById("fromToDateErr")
                                        .classList.add("d-none");
                                      setCreateEndDate(e.target.value);
                                    } else {
                                      document
                                        .getElementById("fromToDateErr")
                                        .classList.remove("d-none");
                                    }
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                }}
                                placeholder="End Date"
                              />
                              <span
                                id="endDateErr"
                                className=" text-danger d-none"
                              >
                                *Please Enter Valid Date!
                              </span>
                              <span
                                id="fromToDateErr"
                                className=" text-danger d-none"
                              >
                                *TO Date can't be less than FROM Date!
                              </span>
                            </div>
                          </Col>
                        </>
                      ):""}
                    </Row>
                  </div>
                </fieldset>
  
                <fieldset className="border-2 rounded-0 mt-3">
                <legend className="fs-5 fw-bold">Select Merchant</legend>
                <div className="field-set-form">
                  <Row className="g-2">
                    <Col lg={6}>
                      <div className="form-group">
                        <div className=" ">
                          <select
                            id="companyDropdown"
                            className="form-control"
                            onChange={handleCompanyChange}
                            value={selectedCompany}
                          >
                            <option value="">Select a Company</option>
                            {dropDownData.map((merchant) => (
                              <option
                                key={merchant.mid}
                                value={merchant.merchantName}
                              >
                                {merchant.merchantName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="">
                        <input
                          id="companyDropdown"
                          className="form-control"
                          value={formData.mid}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </fieldset>
                <fieldset className="border-2 rounded-0 mt-3">
                  <legend className="fs-5 fw-bold">Select Parameter</legend>
                  <div className="field-set-form">
                    <Row className="g-2">
                      <Col lg={6}>
                        <div className="form-group">
                          <select
                            className="form-control"
                            value={searchOption}
                            onChange={(e) => setSearchOption(e.target.value)}
                          >
                            <option type="number" value="">
                              Select
                            </option>
                            <option type="number" value="amount">
                              Amount
                            </option>
                            <option type="number" value="amountInBetween">
                              Amount Range
                            </option>
                          
                          </select>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="form-group">
                          {searchOption === "amountInBetween" ? (
                            <>
                              <input
                                className="advanced-input-box form-control"
                                type="text"
                                min={0}
                                value={range}
                                onChange={(e) => handleAmountRange(e)}
                              />
                              <span className="ml-2">
                                {searchOption && exampleValues[searchOption]}
                              </span>
                            </>
                          ) : searchOption === "amount" ? (
                            <div>
                              <input
                                className="advanced-input-box form-control"
                                type="number"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                              />
                              <span className="ml-2 mt-1">
                                {searchOption && exampleValues[searchOption]}
                              </span>
                            </div>
                          ) : (
                           ""
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </fieldset>
  
                {/* <fieldset  className='border-2 rounded-0 mt-3'>
                                  <legend className='fs-5 fw-bold'>Enter VPA / UPI ID</legend>
                                  <div className="field-set-form">
                                      <Row className='g-2'>
                                          <Col lg={12} sm={12}>
                                              <div className='form-group'>
                                                  <input type="text" className='advanced-input-box form-control' value={payerVPA}
                                                      onChange={(e) => setPayerVPA(e.target.value)}
                                                  />
                                              </div>
                                          </Col>
                                      </Row>
                                  </div>
                              </fieldset> */}
  
                <fieldset className="border-2 rounded-0 mt-3">
                  <legend className="fs-5 fw-bold">Transaction Status</legend>
                  <div className="field-set-form">
                    <Row className="g-2">
                      <Col lg={12} sm={12}>
                        <div className="form-group">
                          <select
                            className="form-control"
                            value={transectionStatus}
                            onChange={(e) => setTransectionStatus(e.target.value)}
                          >
                            <option value="">Select</option>
                            <option value="success">Success</option>
                            <option value="reject">Reject</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </fieldset>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="danger"
                size="sm"
                className="text-capitlize"
                onClick={handleModalClose}
              >
                Close
              </Button>
              <Button
                className="text-capitlize"
                size="sm"
                variant="primary"
                onClick={submitHandler}
              >
                <i class="bi bi-search mx-1"></i> Search
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
  
        {/* {multiApiResponse && multiApiResponse.length === 0 && (
      <div className="no-data-message">No data available.</div>
    )} */}
      </div>
    );
  };
export default LiveWithdrawlMultipleFilter
