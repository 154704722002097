import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { SWIPELINC_API } from "../../utils/constant";
import { useParams } from "react-router-dom";
import { Container, Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import GuaranteeMultipleFilter from "../MultipleFilter/GuaranteeMultipleFilter";
import DownloadCsv from "../../common/DownloadCsv";
import { headerLabelsForGaurantee } from "../../utils/DownloadHeaders";
import CompanyNameAndMid from "../../common/CompanyNameAndMid";
import MyLoader from "../../common/MyLoader";

function SingleGuarantee({reportType}) {
 
  const [data, setData] = useState();
  const token = localStorage.getItem("token");
  const [showTable, setShowTable] = useState(false);
  const [isMultipleFilterOpen, setIsMultipleFilterOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const { mid } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (mid) {
      setShowLoader(true)
      axios
        .get(
          `${SWIPELINC_API}/MerchantAdminPanel/admin/guarantAmount/findAllByMid/${mid}`,
          {
            headers: {
              Authentication: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {setData(response.data.data);
          setShowTable(true);}
          else{
            setData([])
          }
          setShowLoader(false)
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setShowTable(false);
        });
    }
  }, [mid]);

  const columns = [
    { label: "Guarantee Amount", field: "guarantyAmount", width: "auto" },
    { label: "Net Amount", field: "netAmount", width: "auto" },
    { label: "Transaction Type", field: "transactionType", width: "auto" },
    { label: "Transaction Date", field: "transactionDate", width: "auto" },
    { label: "UTR Number", field: "utrNumber", width: "auto" },
    { label: "Create Date", field: "createDate", width: "auto" },
    { label: "Create Time", field: "createTime", width: "auto" },
  ];
  const mappedData = data?.map((item) => {
    const mappedItem = {};
    columns.forEach((column) => {
      mappedItem[column.field] = item[column.field];
    });
    return mappedItem;
  });
  const handleBackButtonClick = () => {
    navigate(-1);
  };

  
  const handleMultipleFilter = () => {
    setIsMultipleFilterOpen(!isMultipleFilterOpen);
    // setIsDateFilterOpen(false);
    // setShow2(!show2);
  };

  const handleReset = () => {
    window.location.reload();
  };

  return (
    <>
        {showLoader ? <MyLoader /> : ""}
      <Container>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <Card className="shadow border-0 my-4">
            <Card.Header>
              
              <CompanyNameAndMid mid={mid} reportType={"Guarantee Report"} />
              </Card.Header>
              <Card.Body>
                <div className="d-flex justify-content-between mt-2">
                  <button
                    className="btn btn-primary"
                    onClick={handleBackButtonClick}
                  >
                    Go Back
                  </button>
                  <div className=" d-flex justify-content-end">
                    {/* multiple filter starts */}
                    <div className="Advanced-btn d-flex justify-content-end mx-1">
                      <button
                        className={`advanced-btn btn btn-primary   text-capitlize ${
                          isMultipleFilterOpen ? "active" : ""
                        }`}
                        onClick={handleMultipleFilter}
                      >
                        <i className="bi bi-funnel-fill mx-1"></i>More Filter
                      </button>
                    </div>
                    {/* multiple filter ends */}

                    {/* reset btn code starts */}
                    <div className="Advanced-btn  d-flex justify-content-end mx-1">
                      <button
                        className={`advanced-btn btn btn-primary   text-capitlize`}
                        onClick={handleReset}
                      >
                        <i class="bi bi-arrow-clockwise mx-1"> </i>Reset
                      </button>
                    </div>
                    {/* reset btn coden ends  */}

                    {isMultipleFilterOpen ? (
                      <GuaranteeMultipleFilter
                        setGuranteeReportData={setData}
                        setIsMultipleFilterOpen={setIsMultipleFilterOpen}
                        mid={mid}
                        setShowLoader={setShowLoader}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="report-grid-view mt-3">
                  <div className="material-table-records mb-3">
                    <MDBDataTable
                      className="dataTable"
                      striped
                      bordered
                      small
                      data={{
                        columns: columns,
                        rows: mappedData,
                      }}
                      searching={true}
                      sorting={true}
                      responsive
                      hover
                      footer="none"
                    />
                  </div>
                  <DownloadCsv DownLoadData={data} Header={headerLabelsForGaurantee}  Name={`${mid}-Gaurantee`}/>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SingleGuarantee;
