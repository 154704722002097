import React, { useEffect, useState } from "react";
import { SWIPELINC_API } from "../../utils/constant";
import axios from "axios";
import { Alert, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Notification = ({
  setIsShowNotify,
  setGetNotify,
  notifications,
  getNotify,
}) => {
  //   const [notifications, setNotifications] = useState(notifications);
  //   const [ getNotify, setGetNotify] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));

  const handledisable = (id) => {
    axios
      .put(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/notification/Disable/${id}`,
        "",
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          setGetNotify(!getNotify);
        }
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
      });
  };

  //   useEffect(() => {
  //     // Fetch notifications from your API here
  //     axios
  //       .get(`${SWIPELINC_API}MerchantAdminPanel/merchant/notification/findAll`, {
  //         headers: {
  //           Authentication: `Bearer ${token}`,
  //         },
  //       })
  //       .then((response) => {
  //         setNotifications(response.data.data);
  //         setCount(response.data.size)
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching notifications:", error);
  //       });
  //   }, [getNotify]);

  const style = {
    width: "25vw",
    height: "100vh",
    backgroundColor: "grey",
    opacity: 1,
    position: "fixed",

    right: "0",
    top: "0",
    paddingTop: "70px",
    zIndex: "999",
  };
  const style2 = {
    width: "100vw",
    height: "100vh",
    // position: "absolute",
    // backgroundColor: "red",
    opacity: 1,
    position: "fixed",
    right: "0",
    top: "0",
    zIndex: "999",
  };
  // const style3 = {
  //    PointerEvent: "none",
  //    cursor: "pointer",
  // };

  const closeBtnStyle = {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "25px",
    cursor: "pointer",
  };
  const handleCloseNotify=(e)=>{
 console.log(e.target.id)
 if(e.target.id === "OuterDiv" || e.target.id === "CrossBTN"){
  setIsShowNotify(false)
 }
  }

  return (
    // <div>
    <div className="d-flex">
      <div style={style2} id="OuterDiv" onClick={handleCloseNotify}></div>
      <div style={style} className="shadow-lg overflow-y-scroll p-2 ">
        <div className="d-flex justify-content-end px-2 mt-2 ">
          <p style={closeBtnStyle}   className="text-light mt-5 ">
            <i
               id="CrossBTN"
              class="bi bi-x-circle"
              onClick={handleCloseNotify}
            ></i>
          </p>
        </div>
        <div className="d-flex flex-column text-light p-2 justify-content-center ">
          {notifications === null ? (
            <h5 className="text-white  mt-3">No Notifications Yet!!</h5>
          ) : (
            " "
          )}

          {notifications?.map((notify) => {
            return (
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      handledisable(notify.id);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {`${notify.companyName}  ${notify.notification}`}
              </Alert>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Notification;
