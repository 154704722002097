import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SWIPELINC_API } from "../utils/constant";
import { fetchAllAccountdetails } from "../utils/ApiServices";
import { MDBDataTable } from "mdbreact";
import ViewSingleSubmittedApplication from "./ViewSingleSubmittedApplication";

const SubmittedAccountApplications = ({setShowLoader}) => {
  const [applications, setApplications] = useState([]);
  const [openView, setOpenView] = useState(false);
  const [mid, setMid] = useState('');
  const [token, setToken] = useState(localStorage.getItem("token"));
  const navigate = useNavigate();

  useEffect(() => {
    FetchData(token)
  }, []);

  const FetchData=async (token)=>{
    setShowLoader(true)
    try {
      const apiResponse = await fetchAllAccountdetails(token);
      if (apiResponse.statusCode === 200) {
        

        setApplications(apiResponse?.data);
        console.log(apiResponse.data)
      } else {
      }
      setShowLoader(false)
    } catch (error) {
      // console.error(error);
      console.log(error);
    }
  }

  const columns = [
 
    { label: "Merchant Name", field: "companyName", width: "auto" },
    { label: "e-Mail", field: "email", width: "auto" }, 
    { label: "Merchant ID", field: "mid", width: "auto" }, 
  
    // { label: "Merchant Name", field: "companyName", width: "auto" },
    { label: "Action", field: "action", width: "150px" },
  ];

  const mappedData = applications?.map((item) => {
    const mappedItem = { ...item };
    mappedItem.action = (
      <div>
        <button
          className="btn btn-primary"
          onClick={() => ViewDetails( item.mid )}
        >
          View Details
        </button>
      </div>
    );
    return mappedItem;
  });

 const ViewDetails=(mid)=>{
        setMid(mid)
        setOpenView(true)
 }
  return (
    <>
     
    <div className="card p-4">-
      {openView?(<ViewSingleSubmittedApplication setOpenView={setOpenView} mid={mid} openView={openView}/>):( <MDBDataTable
        className="dataTable"
        striped
        small
        bordered
        hover
        responsive
        data={{
          columns: columns,
          rows: mappedData,
        }}
        searching={true}
        entries={50}
        exportToCSV
        theadColor="#333"
        paginationLabel={["Previous", "Next"]}
        sortable={true}
        footer="none"
        />)}
    </div>
        </>

  );
};

export default SubmittedAccountApplications;
