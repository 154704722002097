import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import InactiveServices from '../components/InactiveServices';
import VehicalSettlementForm from './VehicalSettlementForm';
import SubmittedVehicalCommercialType from './SubmittedVehicalCommercialType';
import SubmittedVehicalSettlementType from './SubmittedVehicalSettlementType';



const VehicleSettlementSubTab = ({setShowLoader}) => {
    const [activePhase, setActivePhase] = useState("Phase1");

    useEffect(() => {
      setActivePhase("Phase1");
    }, []);
  
    const handlePhaseClick = (phase) => {
      setActivePhase(phase);
    };
  

  return (
    <>
    <section className="dashboard-header-sec subtab-card">
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-3  ds-inner-card">
                  <Card.Body>
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn submenu-tab-admin">
                        <li
                          className={activePhase === "Phase1" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase1")}
                        >
                          Vehicle Settlement Form Type{activePhase === "Phase1"}
                        </li>
                        <li
                          className={activePhase === "Phase2" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase2")}
                        >
                         Submitted {activePhase === "Phase2"}
                        </li>    
                       
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>
            </Row>
            <div className="admin-tab-content">
              {activePhase === "Phase1" ? (
               <VehicalSettlementForm setShowLoader={setShowLoader} />
                
              ) : activePhase === "Phase2" ? (
                
                <SubmittedVehicalSettlementType setShowLoader={setShowLoader} />
              
              ) : (
                <InactiveServices />
              )}
            </div>
          </div>
      </section>
    </>
  )
}


export default VehicleSettlementSubTab;