import React, { useEffect, useState } from "react";
import { fetchAllVehicalCommercialType } from "../utils/ApiServices";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import swal from "sweetalert";
import { SWIPELINC_API } from "../utils/constant";
import axios from "axios";

const SubmittedVehicalCommercialType = ({ setShowLoader }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [vehicalCommercialTypeData, setVehicalCommercialTypeData] = useState(
    []
  );
  const [callAgain, setCallAgain] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editData, setEditData] = useState({
    vehicleCommercialTypeId: "",
    type: "",
  });

  useEffect(() => {
    GetVehicalCommercialType(token);
  }, [callAgain]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const GetVehicalCommercialType = async (token) => {
    try {
      const response = await fetchAllVehicalCommercialType(token); // Add the await keyword here

      if (response.statusCode === 200) {
        setVehicalCommercialTypeData(response?.data);
      } else {
        setVehicalCommercialTypeData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setDataToEditForm = (vehicleCommercialTypeId, type) => {
    setEditData({
      type: type,
      vehicleCommercialTypeId: vehicleCommercialTypeId,
    });
    setIsModalOpen(true)
    
  }

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();

    if(editData.type === "") {
      swal({
        title: "Alert",
        text: "Please enter Vehical Commercial Type?",
        icon: "error",
      })
      return
    }

    try { 
      const res = await axios.put( `${SWIPELINC_API}MerchantAdminPanel/api/vehicle-commercial-types/update/${editData.vehicleCommercialTypeId}` , { type:editData.type }, {
        headers: {
          Authentication: "Bearer " + token,
        },
      });
      if(res.data.statusCode === 200) {
        swal({
          title: "Success",
          text: "Vehical Commercial Type Updated Successfully!",
          icon: "success",
        }).then(() => {
          GetVehicalCommercialType(token)
        })
        handleCloseModal()
      }
    } catch (error) {
      throw new Error(error);
    }

  }

  const columns = [
    { label: "Vehical Type", field: "type", width: "auto" },
    {
      label: "Vehical Commercial Type ID",
      field: "vehicleCommercialTypeId",
      width: "auto",
    },
    { label: "Status", field: "active", width: "auto" },
    { label: "Action", field: "action", width: "150px" },
  ];

  const mappedData = vehicalCommercialTypeData
    ? vehicalCommercialTypeData?.map((item) => {
        const mappedItem = { ...item };
        mappedItem.active = item.active ? "Active" : "Not Active";
        mappedItem.action = (
          <div className="d-flex gap-2 justify-content-evenly">
          <button
              className={`btn btn-primary ${
                item.active ? "bg-danger" : "bg-success"
              } border border-none`}
              onClick={() =>
                EnableDisable(
                  item.vehicleCommercialTypeId,
                  item.active ? "Disable" : "Enable"
                )
              }
            >
              {item.active ? "Disable" : "Enable"}
            </button>
            <button
              className="btn btn-warning border border-none"
              onClick={() => setDataToEditForm(mappedItem.vehicleCommercialTypeId, mappedItem.type)}
            >
              Edit
            </button>

          </div>
        );
        return mappedItem;
      })
    : [];

  const EnableDisable = (id, ActionType) => {
    swal({
      title: "Alert",
      text: "Are you sure, do you want to disable?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDisable) => {
      if (willDisable) {
        setShowLoader(true);
        axios
          .post(
            `${SWIPELINC_API}MerchantAdminPanel/api/vehicle-commercial-types/${ActionType.toLowerCase()}/${id}`,
            "",
            {
              headers: {
                Authentication: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log(response);

            if (response.data.statusCode === 200) {
              swal({
                title: "Success!",
                text: `Vehical Commercial Type ${ActionType}d Successfully`,
                icon: "success",
              });

              //   FetchData(token);
            } else {
              swal({
                title: "Alert!",
                text: response.data.message,
                icon: "error",
              });
            }
            setCallAgain(!callAgain);
            setShowLoader(false);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            setShowLoader(false);
          });
      }
    });
  };
  return (
    <>
      <Row>
        <Col xs={12} lg={12} md={12}>
          <Card className="shadow border-0 mb-4">
            <Card.Body>
              <div className="report-grid-view">
                <div className="material-table-records mb-3">
                  <MDBDataTable
                    className="dataTable"
                    striped
                    small
                    bordered
                    hover
                    responsive
                    data={{
                      columns: columns,
                      rows: mappedData,
                    }}
                    searching={true}
                    entries={50}
                    exportToCSV
                    theadColor="#333"
                    paginationLabel={["Previous", "Next"]}
                    sortable={true}
                    footer="none"
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* EDIT FORM MODEL STARTS */}
      <Modal show={isModalOpen} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Vehical Commercial Type:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="shadow border-0 mb-4">
            <Card.Body>
              <div className="merchant-report-view">
                <div className="report-table-view">
                  <div className="containerflag ">
                    <div className="row">
                      {/* <div className="col-sm-12 col-md-8  offset-md-2 ">
                        <label htmlFor="email">Merchant Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          placeholder="Merchant Name"
                          // value={companyName}
                          readOnly
                        />
                      </div> */}

                      {/* <div className="col-sm-12 col-md-8 offset-md-2">
                        <div className="form-group mt-3">
                          <label htmlFor="email">Merchant MID</label>
                          <input
                            type="text"
                            className="form-control"
                            id="email"
                            placeholder="Company Name"
                            // value={updateMId}
                            readOnly
                          />
                        </div>
                      </div> */}
                    </div>
                    <Row className="mt-2">
                      <Col xs={12} lg={4} md={6} className="mt-3">
                        <div className="form-group">
                          <label className="form-label">
                            Vehical Commercial Type
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              type="text"
                              className="form-control"
                              name="accountsModuleSecrete"
                              value={editData.type}
                              onChange={(e) => setEditData({...editData, type:e.target.value})}
                            />
                          </div>
                        </div>
                        {/* ./form-group */}
                      </Col>

                      <div>
                        <div className="d-flex justify-content-center mt-3">
                          <Button
                            id="UpdateFormBtn"
                            onClick={handleUpdateSubmit}
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubmittedVehicalCommercialType;