import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { SWIPELINC_API } from "../../utils/constant";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Card, Row, Col } from "react-bootstrap";
import DownloadCsv from "../../common/DownloadCsv";
import { headerLabelsForRelease } from "../../utils/DownloadHeaders";
import CompanyNameAndMid from "../../common/CompanyNameAndMid";
import MyLoader from "../../common/MyLoader";

function SingleReleaseAmount() {
  const [data, setData] = useState();
  const token = localStorage.getItem("token");
  const [showTable, setShowTable] = useState(false);
  const[showLoader,setShowLoader] = useState()

  const { mid } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
     if (mid) {
      setShowLoader(true)
      axios
        .get(
          `${SWIPELINC_API}MerchantAdminPanel/merchant/release/getBy/${mid}`,
          {
            headers: {
              Authentication: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200){ setData(response.data.data);
          setShowTable(true);
        }else{
          setData([])
        }
        setShowLoader(false)
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setShowTable(false);
        });
    }
  }, [mid]);

  const columns = [
    { label: "Merchant ID", field: "mid", width: "auto" },
    { label: "Amount", field: "amount", width: "auto" },
    { label: "Release From", field: "releaseFrom", width: "auto" },
    { label: "Date", field: "createDate", width: "auto" },
    // { label: "Create Time", field: "createTime", width: "auto" },
  ];
  const mappedData = data?.map((item) => {
    const mappedItem = {};
    columns.forEach((column) => {
      if (column.field === "releaseFrom") {
       
        mappedItem[column.field] = item[column.field].charAt(0).toUpperCase() + item[column.field].slice(1).toLowerCase();
      } else {
        mappedItem[column.field] = item[column.field];
      }
    });
    return mappedItem;
  });
  
  const handleBackButtonClick = () => {
    navigate(-1);
  };
  return (
    <>
        {showLoader ? <MyLoader /> : ""}
      <Container>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <Card className="shadow border-0 my-4">
            <Card.Header>
          <CompanyNameAndMid mid={mid} reportType={"Release Report"} />
              </Card.Header>
              <Card.Body>
                <div className="d-flex justify-content-between my-2">
                  <button
                    className="btn btn-primary mt-2"
                    onClick={handleBackButtonClick}
                  >
                    Go Back
                  </button>
                </div>

                <div className="report-grid-view">
                  <div className="material-table-records mb-3">
                    <MDBDataTable
                      className="dataTable"
                      striped
                      bordered
                      small
                      data={{
                        columns: columns,
                        rows: mappedData,
                      }}
                      searching={true}
                      sorting={true}
                      responsive
                      hover
                      footer="none"
                    />
                  </div>
                  <DownloadCsv DownLoadData={data} Header={headerLabelsForRelease}  Name={`${mid}-ReleaseAmount`}/>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SingleReleaseAmount;
