import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { SWIPELINC_API } from '../utils/constant';
import swal from 'sweetalert';

const CompanyNameAndMid = ({mid,reportType}) => {

    const [companyName, setCompanyName] = useState("")
    const [token, setToken] = useState(localStorage.getItem("token"));

    const fetchmerchantDetails = async (mid,token) => {
        try {
          
          const response = await axios.get(
           ` ${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getByMid/${mid}`,
            {
              headers: {
                Authentication: `Bearer ${token}`,
              },
            }
          );
    
          if (response.data.statusCode === 200) {
            setCompanyName(response.data.data.companyName);
           
          } else if (response.data.statusCode === 304) {
            swal({
              title: "Failed",
              text: "Merchant not on-Boarded",
              icon: "error",
            });
           
          } else {
            swal({
              title: "Failed",
              text: response.data.message,
              icon: "error",
            });
          }
         
        } catch (error) {
          console.error("Error fetching merchant details:", error);
     
        }
      };

      useEffect(() => {
        fetchmerchantDetails(mid,token)
      }, [])
      

  return (
    <div>
      <h4 className='fs-3 fw-semibold'>{`${reportType}`}</h4>
      <h4>{`Company Name :- ${companyName}`}</h4>
      <h4>{`Merchant ID  :- ${mid}`}</h4>
    </div>
  )
}

export default CompanyNameAndMid