import SwipelincLogo from "../assets/images/SwipelincLogo.png";
import LincpayLogo from "../assets/images/LincpayLogo.jpeg";
import SilverPeLogo from "../assets/images/vector.svg";

// REGEX CONSTANTS
export const VPA_UPI_REGEX = new RegExp(
  /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$|^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+$/
  
);

export const PAN_CARD_REGEX = new RegExp(
  /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
);
export const EMAIL_ID_REGEX = new RegExp(
  /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/
);


export const WEBSITE_URL_REGEX = new RegExp(/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/);
export const UTR_NUM_REGEX = new RegExp(/^[a-zA-Z0-9]*$/);
export const TRANSACTION_ID_REGEX = new RegExp(/^[0-9]*$/);
export const ORDER_NUMBER_REGEX = new RegExp(/^[0-9]*$/);

export const dateConvert = (date) => {
  const date1 = new Date(date);
  const formattedDate = date1.toLocaleDateString("en-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  return formattedDate;
};

 const IFSC_REGEX = new RegExp(/^[a-zA-Z]{4}0[A-Z0-9]{6}$/);
 
export const isIfscRegexIsValid = (inputVpaValue) => {
  const isValidIfsc = IFSC_REGEX.test(inputVpaValue);
  if (isValidIfsc) {
    return true;
  }
  return false;
};

export function convertDateFormat(inputDate) {
  if (!inputDate) {
    return null;
  }

  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

export const isPanNumRegexIsValid = (inputValue) => {
  const isValidPan = PAN_CARD_REGEX.test(inputValue);
  if (isValidPan) return true;
  return false;
};
export const getKeyPressed = (e) => {
  return e.key;
};

export const stopSpecialCharacterInput = (inputValue) => {
  if (
    inputValue === "!" ||
    inputValue === "@" ||
    inputValue === "#" ||
    inputValue === "$" ||
    inputValue === "%" ||
    inputValue === "^" ||
    inputValue === "&" ||
    inputValue === "*" ||
    inputValue === "(" ||
    inputValue === ")" ||
    inputValue === "+" ||
    inputValue === "-" ||
    inputValue === "*" ||
    inputValue === "/" ||
    inputValue === ";" ||
    inputValue === ":" ||
    inputValue === "'" ||
    inputValue === '"' ||
    inputValue === "," ||
    inputValue === "<" ||
    // inputValue === "." ||
    inputValue === ">" ||
    inputValue === "?" ||
    inputValue === "{" ||
    inputValue === "}" ||
    inputValue === "[" ||
    inputValue === "]" ||
    inputValue === "|" ||
    inputValue === "=" ||
    inputValue === "_" ||
    inputValue === "-"
  )
    return true;
  return false;
};
export const stopCharacterInput = (inputValue, float) => {
  if (
    inputValue === "a" ||
    inputValue === "b" ||
    inputValue === "c" ||
    inputValue === "d" ||
    inputValue === "e" ||
    inputValue === "f" ||
    inputValue === "g" ||
    inputValue === "h" ||
    inputValue === "i" ||
    inputValue === "j" ||
    inputValue === "k" ||
    inputValue === "l" ||
    inputValue === "m" ||
    inputValue === "n" ||
    inputValue === "o" ||
    inputValue === "p" ||
    inputValue === "q" ||
    inputValue === "r" ||
    inputValue === "s" ||
    inputValue === "t" ||
    (float ? inputValue === "u" : inputValue === ".") ||
    inputValue === "u" ||
    inputValue === "v" ||
    inputValue === "w" ||
    inputValue === "x" ||
    inputValue === "y" ||
    inputValue === "z" ||
    inputValue === "`" ||
    inputValue === "~" ||
    inputValue === "A" ||
    inputValue === "B" ||
    inputValue === "C" ||
    inputValue === "D" ||
    inputValue === "E" ||
    inputValue === "F" ||
    inputValue === "G" ||
    inputValue === "H" ||
    inputValue === "I" ||
    inputValue === "J" ||
    inputValue === "K" ||
    inputValue === "L" ||
    inputValue === "M" ||
    inputValue === "N" ||
    inputValue === "O" ||
    inputValue === "P" ||
    inputValue === "Q" ||
    inputValue === "R" ||
    inputValue === "S" ||
    inputValue === "T" ||
    inputValue === "U" ||
    inputValue === "V" ||
    inputValue === "W" ||
    inputValue === "X" ||
    inputValue === "Y" ||
    inputValue === "Z"
  )
    return true;
  return false;
};

export const isVpaUpiRegexIsValid = (inputVpaValue) => {
  const isValidVpa = VPA_UPI_REGEX.test(inputVpaValue);
  if (isValidVpa) {
    return true;
  }
  return false;
};

export const isTransactionIdRegexIsValid = (inputValue) => {
  const isValidTransaction = TRANSACTION_ID_REGEX.test(inputValue);
  if (isValidTransaction) return true;
  return false;
};

export const isEmailIdRegexIsValid = (inputValue) => {
  const isValidEmail = EMAIL_ID_REGEX.test(inputValue);
  if (isValidEmail) return true;
  return false;
};

export const isUtrNumRegexIsValid = (inputValue) => {
  const isValidUTR = UTR_NUM_REGEX.test(inputValue);
  if (isValidUTR) return true;
  return false;
};

export const isOrderNumberRegexIsValid = (inputValue) => {
  const isValidOrderNumber = ORDER_NUMBER_REGEX.test(inputValue);
  if (isValidOrderNumber) return true;
  return false;
};

export const convertStringDateToDateType = (inputDateString) => {
  const dateParts = inputDateString.split(" ");

  const months = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11,
  };

  const day = parseInt(dateParts[2], 10);
  const month = months[dateParts[1]];
  const year = parseInt(dateParts[5], 10);
  const timeParts = dateParts[3].split(":");
  const hours = parseInt(timeParts[0], 10);
  const minutes = parseInt(timeParts[1], 10);
  const seconds = parseInt(timeParts[2], 10);

  // Replace time zone abbreviation with standard offset (e.g., 'IST' with '+0530')
  const offsetString = dateParts[4];
  const offsetHours = parseInt(offsetString.slice(0, 3), 10);
  const offsetMinutes = parseInt(offsetString.slice(3), 10);
  const timeZoneOffset = (offsetHours * 60 + offsetMinutes) * 60000;

  const date = new Date(year, month, day, hours, minutes, seconds);
  // Adjust the date with the time zone offset
  date.setTime(date.getTime() + timeZoneOffset);

  return date;
};

 


 


export const getMonthName = (monthNumber) => {
  const months = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
  ];

  // Check if the monthNumber is valid (between 1 and 12)
  if (monthNumber >= 1 && monthNumber <= 12) {
    // Array indices are 0-based, so subtract 1 from the monthNumber
    return months[monthNumber];
  } else {
    return 'Invalid month number';
  }
}

export const isExpired = (toDate, expiryDate) => {
  const expirationDate = new Date(expiryDate);

  // Compare the two dates
  if (expirationDate < toDate) {
    return true; // The expiry date has passed
  } else {
    return false; // The expiry date is in the future
  }
}

export const config= (serverEnvironment) => {
  const logos = {
    productionLincpay: LincpayLogo,
    productionSilverPe: SilverPeLogo,
    productionSwipelinc: SwipelincLogo,
    development: LincpayLogo,
    
  };
  // const bankDetails = {
  //   productionLincpay:{
  //     BName: "LincPay Solutions Private Limited",
  //     bankName: "IDFC Bank",
  //     branch: "Bhopal Kolar Road",
  //     Account: "10152683089",
  //     ifsc: "IDFB0041386",
  //   } ,
  //   productionSwipelinc: {
  //     BName: "Zenex e-Commerce Private Limited",
  //     bankName: "AU Small Finance Bank",
  //     branch: "Malad Branch",
  //     Account: "2021234226901022",
  //     ifsc: "AUBL0002342",
  //   },
  //   development: {
  //     BName: "LincPay Solutions Private Limited",
  //     bankName: "IDFC Bank",
  //     branch: "Bhopal Kolar Road",
  //     Account: "10152683089",
  //     ifsc: "IDFB0041386",
  //   } ,
  //   productionSilverPe: {
  //     BName: "LincPay Solutions Private Limited",
  //     bankName: "IDFC Bank",
  //     branch: "Bhopal Kolar Road",
  //     Account: "10152683089",
  //     ifsc: "IDFB0041386",
  //   } ,
  // };
  const Names = {
    productionLincpay: "Lincpay",
    productionSilverPe: "SilverPe",
    productionSwipelinc: "Swipelinc",
    development: "Lincpay",
    
  };
  const Urls = {
    productionLincpay: 'https://api.lincpay.in/',
    productionSwipelinc: 'https://api.swipelinc.com/',
    productionSilverPe: 'https://api.swipelinc.com/',
   
    development: 'https://uatmerchant.lincpay.in/',
    
  };
  const  logoPath = logos[serverEnvironment] ;
  const  Name = Names[serverEnvironment] ;
  const  Url = Urls[serverEnvironment] ;
  // const  bankDeatails = bankDetails[serverEnvironment] ;
 
  return {
    logoPath,
    Name,
    Url,
    // bankDeatails
  }

}

export const stopNumberCharInput = (inputValue) => {
  // alert("called")
  if (
    inputValue === "1" ||
    inputValue === "2" ||
    inputValue === "3" ||
    inputValue === "4" ||
    inputValue === "5" ||
    inputValue === "6" ||
    inputValue === "7" ||
    inputValue === "8" ||
    inputValue === "9" ||
    inputValue === "0"
  )
    return true;
  return false;
};

export const stopAlphabetInput = (inputValue) => {
  // alert("called")
  if (
    inputValue !== "1" &&
    inputValue !== "2" &&
    inputValue !== "3" &&
    inputValue !== "4" &&
    inputValue !== "5" &&
    inputValue !== "6" &&
    inputValue !== "7" &&
    inputValue !== "8" &&
    inputValue !== "9" &&
    inputValue !== "0" &&
    inputValue !== "!" &&
    inputValue !== "@" &&
    inputValue !== "#" &&
    inputValue !== "$" &&
    inputValue !== "%" &&
    inputValue !== "^" &&
    inputValue !== "&" &&
    inputValue !== "*" &&
    inputValue !== "(" &&
    inputValue !== ")" &&
    inputValue !== "+" &&
    inputValue !== "-" &&
    inputValue !== "*" &&
    inputValue !== "/" &&
    inputValue !== ";" &&
    inputValue !== ":" &&
    inputValue !== "'" &&
    inputValue !== '"' &&
    inputValue !== "," &&
    inputValue !== "<" &&
    inputValue !== "." &&
    inputValue !== ">" &&
    inputValue !== "?" &&
    inputValue !== "{" &&
    inputValue !== "}" &&
    inputValue !== "[" &&
    inputValue !== "]" &&
    inputValue !== "|" &&
    inputValue !== "=" &&
    inputValue !== "_" &&
    inputValue !== "-"
  )
    return true;
  return false;
};

export const isWebsiteURLRegexIsValid = (inputValue) => {
  const isValidWebURL = WEBSITE_URL_REGEX.test(inputValue);
  if (isValidWebURL) return true;
  return false;
};