import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MDBDataTable } from 'mdbreact';
import "../assets/css/style.css";
import { SWIPELINC_API } from '../utils/constant';

const Deposite = () => {
  const [depositeData, setDepositeData] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    // Make an Axios GET request to fetch the data from the API for Deposits
    axios
      .get(`${SWIPELINC_API}`, {
        headers: {
          Authentication: `Bearer ${token}`
        }
      })
      .then((response) => {
        if (response.data.statusCode === 200 && Array.isArray(response.data.data)) {
          setDepositeData(response.data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching deposit data:', error);
      });
  }, []);

  // Define the columns for the MDBDataTable for Deposits
  const columns = [
    { label: 'Deposit Date', field: 'Deposit Date', width: 'auto' },
    { label: 'Deposit Method', field: 'Deposit Method', width: 'auto' },
    { label: 'Amount', field: 'amount', width: 'auto' },
    // Add more columns as needed
  ];

  const mappedData = depositeData.map((item) => {
    const mappedItem = {};
    columns.forEach((column) => {
      mappedItem[column.field] = item[column.field];
    });
    return mappedItem;
  });

  return (
    <>
      <main className="">
        <div className="reportSection">
          <div className="container">
            <div className="tabs-content active" id="tab1">
              <div className="card py-3">
                <div id="Deposite-datatable-container">
                  <MDBDataTable
                    className='dataTable overflow-x-scroll'
                    hover
                    striped
                    small
                    bordered
                    responsive
                    sortable={true}
                    searching={true}
                    entries={10}
                    data={{
                      columns: columns,
                      rows: mappedData
                    }}
                    footer="none"
                    theadColor='#333'
                    paginationLabel={['Previous', 'Next']}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Deposite;
