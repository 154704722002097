import React, { useState, useEffect } from 'react';
import { MDBDataTable } from 'mdbreact';
import "../assets/css/style.css";
import { FetchTemporaryTxnReport } from '../utils/ApiServices';


const MerchantTxnReport = ({setShowLoader}) => {
  const [data, setData] = useState([]);
  const [token,setToken] = useState(localStorage.getItem ("token"));

 
    const TemporaryRequest= (token)=>{
      setShowLoader(true)
    const response = FetchTemporaryTxnReport(token)
     .then((response) => {
      if(response.statusCode=== 200 ){
        setData(response?.data);
        setShowLoader(false)
      }
      else{
        setData([])
        setShowLoader(false)
        
      }
      
    })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setShowLoader(false)
     });
     
}
useEffect(() => {
    TemporaryRequest(token);
}, [])
  const columns = [
    // {label: 'UTR Number',field: 'utr',width:'auto' },
    {label: 'Merchant ID',field: 'mid',width:'auto'},
    {label: 'Name',field: 'name',width:'auto'},
    {label: 'Total Transaction Amount',field: 'totalTransactionAmount',width:'auto'},
    {label: 'e-Mail',field: 'email',width:'auto'},
    {label: 'Mobile Number',field: 'mobile',width:'auto'},
    { label: 'ChargeBack Amount',field: 'chargeBackAmount',width:'auto' },
    { label: 'Settlement Amount',field: 'settlmentAmount',width:'auto' },
    { label: 'Payout Amount',field: 'payoutAmount',width:'auto' },
     { label: 'Hold Amount',field: 'holdAmount',width:'auto' },
     { label: 'Security Deposit',field: 'securityDeposit',width:'auto' },
     { label: 'Service Charge',field: 'serviceCharge',width:'auto' },
     { label: 'Remaning Amount',field: 'remaningAmount',width:'auto' },
     { label: 'Withdrawal Amount',field: 'withdrawalAmount',width:'auto' },
     { label: 'Guarantee Amount',field: 'guaranteeAmount',width:'auto' },
     { label: 'Ballance Payout UPI',field: 'ballancePayoutUpi',width:'auto' },
     { label: 'Ballance Payout IMPS',field: 'ballancePayoutImps',width:'auto' },
     { label: 'Total Vendors',field: 'totalVendors',width:'auto' },
     { label: 'Credit Balance',field: 'creditBalance',width:'auto' },
     { label: 'Sum Of Settlement Adjustment Amount',field: 'sumOfSettlemenetAdjustAmount',width:'auto' },
     { label: 'Release Amount',field: 'releaseAmount',width:'auto' },
     { label: 'Wallet Balance',field: 'walletBalance',width:'auto' },
     { label: 'Deleted',field: 'deleted',width:'auto' },

  ];

 const mappedData = data.map((item)=>{
    const mappedItem = {};
    columns.forEach ((column)=>{
        if(column.field=== "isWithdrawalManual"){
            mappedItem[column.field] = item[column.field] ? 'Yes' : 'No';
        }else{
            mappedItem[column.field] = item[column.field];
        }
   
    })
    return mappedItem; 
 })

  return (
    <>
       <main className="">
        <div className="reportSection">
          <div className="">
            <div className="tabs-content active" id="tab1">
              <div className="card py-3">

        <div id = "EKyc-datatable-container ">
       <MDBDataTable className='dataTable overflow-x-scroll p-5' 
         hover 
        striped
         small
        bordered
        responsive
        sortable = {true}
        searching = {true}
        entries={10}
        data={{
        columns : columns,
        rows : mappedData
        }}
        footer = "none"
        theadColor='#333'
        paginationLabel={['Previous', 'Next']}
        />
      </div>
      </div>
      </div>
      </div>
      </div>
      </main>
    </>
  );
};

export default MerchantTxnReport;