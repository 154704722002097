import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { isVpaUpiRegexIsValid } from '../utils/helper';
import { USE_LIVE_CHARGEBACK_MULTIPLE_FILTER_API } from '../utils/ApiServices';

const LiveChargebackMultipleFilter = ({
    setShowLoader,
    setChargebackReportData,
    setIsMultipleFilterOpen,
    dropDownData,
}) => {
    const token = localStorage.getItem("token");
    const [startAmount, setStartAmount] = useState(Number);
    const [range, setRange] = useState("");
    const [endAmount, setEndAmount] = useState(Number);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [startDate1, setStartDate1] = useState("");
    const [endDate1, setEndDate1] = useState("");
    const [searchOption, setSearchOption] = useState("");
    const [searchValue, setSearchValue] = useState("test");
    const [searchOption2, setSearchOption2] = useState("");
    const [searchOption3, setSearchOption3] = useState("");
    const [searchValue2, setSearchValue2] = useState("");
    const [searchValue3, setSearchValue3] = useState("");
    const [show2, setShow2] = useState(true);
    const [formData, setFormData] = useState({
      mid: "",
    });
    const [selectedCompany, setSelectedCompany] = useState("");

    
    const exampleValues = {
      amount: "Ex- 5500",
      amountInBetween: "Ex- 100-500",
      custLocation: "Ex- Madhya Pradesh",
      bankLocation: "Ex- Bhopal",
      // Add more options and example values as needed
    };
  
    const [searchValueError, setSearchValueError] = useState("");
    const [isSearchValueError, setIsSearchValueError] = useState(false);
    const [isAlphabet, setIsAlphabet] = useState(false);
  
  
    const handleAmountRange = (e) => {
      setRange(e.target.value);
      function splitString(inputString) {
        const parts = inputString.split("-").map((part) => part.trim());
        return parts;
      }
  
      const [startAmount, endAmount] = splitString(e.target.value);
      setStartAmount(startAmount);
      setEndAmount(endAmount);
  
      console.log(startAmount, endAmount);
    };

    const handleCompanyChange = (event) => {
      const selectedCompany = event.target.value;
      if (selectedCompany === "") {
        setFormData({
          mid: "",
        });
  
        setSelectedCompany("");
        return;
      }
  
      setSelectedCompany(selectedCompany);
  
      const selectedMerchant = dropDownData.find(
        (merchant) => merchant.merchantName === selectedCompany
      );
      console.log(selectedMerchant);
  
      if (selectedMerchant) {
        setFormData({
          mid: selectedMerchant.mid || "",
        });
      }
    };
  
    useEffect(() => {
      setSearchValue("");
    }, [searchOption]);
    useEffect(() => {
      setSearchValue2("");
    }, [searchOption2]);
    

    const withOrWithoutMid = () => {
      if (formData.mid === "") {
        return true;
      } else {
       return false;
      }
    };
  
    const submitHandler = () => {
      // console.log(searchOption2, searchValue2);
      USE_LIVE_CHARGEBACK_MULTIPLE_FILTER_API(
        withOrWithoutMid(formData.mid),
        searchOption === "amount" ? searchValue : 0,
        searchOption === "amountInBetween" ? startAmount : 0,
        searchOption === "amountInBetween" ? endAmount : 0,
        searchOption2 === "date" ? searchValue2 : "",
        searchOption2 === "dateInBetween" ? startDate : "",
        searchOption2 === "dateInBetween" ? endDate : "",
        searchOption3 === "date" ? searchValue3 : "",
        searchOption3 === "dateInBetween" ? startDate1 : "",
        searchOption3 === "dateInBetween" ? endDate1 : "",
        "",
        "",
        "",
        "",
        "",
        formData.mid,
        token,
        setChargebackReportData,
        setShowLoader
        //   searchOption === "bankLocation" ? searchValue : "",
        //   searchOption === "custLocation" ? searchValue : "",
      );
      handleModalClose();
      setFormData({
        mid:"",
      })
    };
  
    const handleModalClose = () => {
      setShow2(false);
      setIsMultipleFilterOpen(false);
    };
    const handleDateInputChange = (event) => {
      const newDate = event.target.value;
      // Check if the input date is greater than today's date
      const inputDateTime = DateTime.fromISO(newDate, { zone: "UTC" });
      const today = DateTime.now().startOf("day");
  
      if (inputDateTime.toISODate() > today.toISODate()) {
        return true;
      }
      return false;
    };
  
    const handelSearchData = (e) => {
      setSearchValue(e.target.value);
      setIsSearchValueError(false);
      setSearchValueError("");
    };
  
    const getKeyCode = (e) => {
      // get alphabet
      if (
        (e.keyCode >= 65 && e.keyCode <= 90) ||
        e.key === "Backspace" ||
        e.keyCode === 32
      ) {
        setSearchValue(e.target.value);
        setIsAlphabet(true);
        return;
      }
      setIsAlphabet(false);
      return;
    };
  
    const handelValidations = (e) => {
      if (searchOption === "VPA") {
        if (!isVpaUpiRegexIsValid(searchValue)) {
          setIsSearchValueError(true);
          setSearchValueError("Invalid Enter Valid VPA");
        }
        return;
      }
  
      if (searchOption === "custLocation" || searchOption === "bankLocation") {
        // console.log(e.key);
        const regex = new RegExp(/^[a-z]*$/);
        const isValidData = regex.test(searchValue);
        // console.log(searchValue,":",isValidVpa);
        if (!isValidData) {
          setIsSearchValueError(true);
          setSearchValueError(
            `Invalid Enter Valid ${
              searchOption === "bankLocation"
                ? "Bank Location"
                : "Customer Location"
            } `
          );
        }
        return;
      }
    };
  
    const handleFromToDateInputChange = (from, to) => {
      console.log(from, ":", to);
  
      const inputFromDateTime = DateTime.fromISO(from, { zone: "utc" });
      const inputToDateTime = DateTime.fromISO(to, { zone: "utc" });
      if (inputToDateTime >= inputFromDateTime) {
        return true;
      } else {
        return false;
      }
    };
  
    // Modal Popup filter conts
    // const [show, setShow] = useState(false);
  
    // const handleModalClose = () => setShow(false);
    // const handleModalShow = () => setShow(true);
    return (
      <div>
        <div className="d-flex flex-row">
          <Modal show={show2} size="lg" centered>
            <Modal.Header className="bg-dark ">
              <Modal.Title className="text-white">
                Chargeback Multiple Filter
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="filter-field-area">
                 <fieldset className="rounded-0 mb-3">
                  <legend className="fs-5 fw-bold">
                    Select Transaction Date
                  </legend>
                  <div className="field-set-form">
                    <Row className="g-2">
                      <Col lg={6} sm={12}>
                        <div>
                          <select
                            className="form-control"
                            value={searchOption2}
                            onChange={(e) => setSearchOption2(e.target.value)}
                          >
                            <option value="">Select</option>
                            <option value="date">Date</option>
                            <option value="dateInBetween">Date In Between</option>
                            <i class="bi bi-caret-down-fill"></i>
                          </select>
                        </div>
                      </Col>
  
                      {searchOption2 !== "dateInBetween" ? (
                        <Col lg={6} sm={12}>
                          <div className="form-group">
                            <input
                              className="advanced-input-box form-control"
                              type="date"
                              value={searchValue2}
                              onChange={(e) => {
                                if (handleDateInputChange(e)) {
                                  setSearchValue2("");
                                  console.log("true..........");
                                  document
                                    .getElementById("dateErr")
                                    .classList.remove("d-none");
                                } else {
                                  document
                                    .getElementById("dateErr")
                                    .classList.add("d-none");
                                  setSearchValue2(e.target.value);
                                  console.log("false..........");
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                              }}
                              placeholder={
                                searchOption === "Date" ? "Enter Date" : ""
                              }
                            />
                            <span id="dateErr1" className="text-danger d-none">
                              *Date must be lesser than current date.
                            </span>
                          </div>
                        </Col>
                      ) : (
                        <>
                          <Col lg={6} sm={12}>
                            <div className="form-group">
                              <input
                                className="advanced-input-box form-control"
                                type="date"
                                value={startDate}
                                onChange={(e) => {
                                  if (handleDateInputChange(e)) {
                                    setStartDate("");
                                    document
                                      .getElementById("startDateErr")
                                      .classList.remove("d-none");
                                  } else {
                                    document
                                      .getElementById("startDateErr")
                                      .classList.add("d-none");
                                    setStartDate(e.target.value);
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                }}
                                placeholder="Enter Start Date"
                              />
                              <span
                                id="startDateErr"
                                className=" text-danger d-none"
                              >
                                *Please Enter Valid Date!
                              </span>
                            </div>
                            <div className="form-group">
                              <input
                                className="advanced-input-box form-control"
                                type="date"
                                value={endDate}
                                onChange={(e) => {
                                  if (handleDateInputChange(e)) {
                                    setEndDate("");
                                    document
                                      .getElementById("endDateErr")
                                      .classList.remove("d-none");
                                  } else {
                                    if (
                                      handleFromToDateInputChange(
                                        startDate,
                                        e.target.value
                                      )
                                    ) {
                                      document
                                        .getElementById("endDateErr")
                                        .classList.add("d-none");
                                      document
                                        .getElementById("fromToDateErr")
                                        .classList.add("d-none");
                                      setEndDate(e.target.value);
                                    } else {
                                      document
                                        .getElementById("fromToDateErr")
                                        .classList.remove("d-none");
                                    }
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                }}
                                placeholder="End Date"
                              />
                              <span
                                id="endDateErr"
                                className=" text-danger d-none"
                              >
                                *Please Enter Valid Date!
                              </span>
                              <span
                                id="fromToDateErr"
                                className="text-danger d-none"
                              >
                                *TO Date can't be less than FROM Date!
                              </span>
                            </div>
                          </Col>
                        </>
                      )}
                    </Row>
                  </div>
                </fieldset>  
                 <fieldset className="rounded-0 mb-3">
                  <legend className="fs-5 fw-bold">
                    Select ChargeBack Upload Date
                  </legend>
                  <div className="field-set-form">
                    <Row className="g-2">
                      <Col lg={6} sm={12}>
                        <div>
                          <select
                            className="form-control"
                            value={searchOption3}
                            onChange={(e) => setSearchOption3(e.target.value)}
                          >
                            <option value="">Select</option>
                            <option value="date">ChargeBack Upload Date</option>
                            <option value="dateInBetween">ChargeBack Upload Date In Between</option>
                            <i class="bi bi-caret-down-fill"></i>
                          </select>
                        </div>
                      </Col>
  
                      {searchOption3 !== "dateInBetween" ? (
                        <Col lg={6} sm={12}>
                          <div className="form-group">
                            <input
                              className="advanced-input-box form-control"
                              type="date"
                              value={searchValue3}
                              onChange={(e) => {
                                if (handleDateInputChange(e)) {
                                  setSearchValue3("");
                                  console.log("true..........");
                                  document
                                    .getElementById("dateErr2")
                                    .classList.remove("d-none");
                                } else {
                                  document
                                    .getElementById("dateErr2")
                                    .classList.add("d-none");
                                  setSearchValue3(e.target.value);
                                  console.log("false..........");
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                              }}
                              placeholder={
                                searchOption3 === "Date" ? "Enter Date" : ""
                              }
                            />
                            <span id="dateErr2" className=" text-danger d-none">
                              *Date must be lesser than current date.
                            </span>
                          </div>
                        </Col>
                      ) : (
                        <>
                          <Col lg={6} sm={12}>
                            <div className="form-group">
                              <input
                                className="advanced-input-box form-control"
                                type="date"
                                value={startDate1}
                                onChange={(e) => {
                                  if (handleDateInputChange(e)) {
                                    setStartDate1("");
                                    document
                                      .getElementById("startDateErr2")
                                      .classList.remove("d-none");
                                  } else {
                                    document
                                      .getElementById("startDateErr2")
                                      .classList.add("d-none");
                                    setStartDate1(e.target.value);
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                }}
                                placeholder="Enter Start Date"
                              />
                              <span
                                id="startDateErr2"
                                className=" text-danger d-none"
                              >
                                *Please Enter Valid Date!
                              </span>
                            </div>
                            <div className="form-group">
                              <input
                                className="advanced-input-box form-control"
                                type="date"
                                value={endDate1}
                                onChange={(e) => {
                                  if (handleDateInputChange(e)) {
                                    setEndDate1("");
                                    document
                                      .getElementById("endDateErr2")
                                      .classList.remove("d-none");
                                  } else {
                                    if (
                                      handleFromToDateInputChange(
                                        startDate1,
                                        e.target.value
                                      )
                                    ) {
                                      document
                                        .getElementById("endDateErr2")
                                        .classList.add("d-none");
                                      document
                                        .getElementById("fromToDateErr2")
                                        .classList.add("d-none");
                                      setEndDate1(e.target.value);
                                    } else {
                                      document
                                        .getElementById("fromToDateErr2")
                                        .classList.remove("d-none");
                                    }
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                }}
                                placeholder="End Date"
                              />
                              <span
                                id="endDateErr2"
                                className=" text-danger d-none"
                              >
                                *Please Enter Valid Date!
                              </span>
                              <span
                                id="fromToDateErr2"
                                className=" text-danger d-none"
                              >
                                *TO Date can't be less than FROM Date!
                              </span>
                            </div>
                          </Col>
                        </>
                      )}
                    </Row>
                  </div>
                </fieldset>  
  
                <fieldset className="border-2 rounded-0 mt-3">
                  <legend className="fs-5 fw-bold">Select Parameter</legend>
                  <div className="field-set-form">
                    <Row className="g-2">
                      <Col lg={6}>
                        <div className="form-group">
                          <select
                            className="form-control"
                            value={searchOption}
                            onChange={(e) => setSearchOption(e.target.value)}
                          >
                            <option type="number" value="">
                              Select
                            </option>
                            <option type="number" value="amount">
                              Chargeback Amount
                            </option>
                            <option type="number" value="amountInBetween">
                              Chargeback Amount Range
                            </option>
                            {/* <option type="text" value="custLocation">
                              Payer Location
                            </option>
                            <option type="text" value="bankLocation">
                              Payer Bank Location
                            </option> */}
                          </select>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="form-group">
                          {searchOption === "amountInBetween" ? (
                            <>
                              <input
                                className="advanced-input-box form-control"
                                type="text"
                                min={0}
                                value={range}
                                onChange={(e) => handleAmountRange(e)}
                              />
                              <span className="ml-2">
                                {searchOption && exampleValues[searchOption]}
                              </span>
                            </>
                          ) : searchOption === "custLocation" ||
                            searchOption === "bankLocation" ? (
                            <div>
                              <input
                                className="advanced-input-box form-control"
                                type="text"
                                value={searchValue}
                                onKeyDown={getKeyCode}
                                onChange={
                                  isAlphabet
                                    ? (e) => setSearchValue(e.target.value)
                                    : () => {}
                                }
                              />
                              <span className="ml-2 mt-1">
                                {searchOption && exampleValues[searchOption]}
                              </span>
                            </div>
                          ) : searchOption === "amount" ? (
                            <div>
                              <input
                                className="advanced-input-box form-control"
                                type="number"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                              />
                              <span className="ml-2 mt-1">
                                {searchOption && exampleValues[searchOption]}
                              </span>
                            </div>
                          ) : (
                            <div>
                              <input
                                className="advanced-input-box form-control"
                                type="text"
                                value={searchValue}
                                onChange={handelSearchData}
                                onBlur={handelValidations}
                              />
                              {isSearchValueError ? (
                                <span id="searchValErr" className=" text-danger">
                                  {`${searchValueError}`}
                                </span>
                              ) : (
                                ""
                              )}
                              <div>
                                <span className="ml-2 mt-1">
                                  {searchOption && exampleValues[searchOption]}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </fieldset>

                <fieldset className="border-2 rounded-0 mt-3">
                <legend className="fs-5 fw-bold">Select Merchant</legend>
                <div className="field-set-form">
                  <Row className="g-2">
                    <Col lg={6}>
                      <div className="form-group">
                        <div className=" ">
                          <select
                            id="companyDropdown"
                            className="form-control"
                            onChange={handleCompanyChange}
                            value={selectedCompany}
                          >
                            <option value="">Select a Company</option>
                            {dropDownData.map((merchant) => (
                              <option
                                key={merchant.mid}
                                value={merchant.merchantName}
                              >
                                {merchant.merchantName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="">
                        <input
                          id="companyDropdown"
                          className="form-control"
                          value={formData.mid}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </fieldset>
  
                {/* <fieldset  className='border-2 rounded-0 mt-3'>
                                  <legend className='fs-5 fw-bold'>Enter VPA / UPI ID</legend>
                                  <div className="field-set-form">
                                      <Row className='g-2'>
                                          <Col lg={12} sm={12}>
                                              <div className='form-group'>
                                                  <input type="text" className='advanced-input-box form-control' value={payerVPA}
                                                      onChange={(e) => setPayerVPA(e.target.value)}
                                                  />
                                              </div>
                                          </Col>
                                      </Row>
                                  </div>
                              </fieldset> */}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="danger"
                size="sm"
                className="text-capitlize"
                onClick={handleModalClose}
              >
                Close
              </Button>
              <Button
                className="text-capitlize"
                size="sm"
                variant="primary"
                onClick={submitHandler}
              >
                <i class="bi bi-search mx-1"></i> Search
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
  
        {/* {multiApiResponse && multiApiResponse.length === 0 && (
      <div className="no-data-message">No data available.</div>
    )} */}
      </div>
    );
  };

export default LiveChargebackMultipleFilter