import React, { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { getMechantForPayoutApiTest, getMerchantInfoByIdPayin, testCallbackURLPayin } from "../../../utils/ApiServices";
import swal from "sweetalert";

const CallbackPayin = ({ setShowLoader }) => {
  const [merchant, setMerchant] = useState([]);
  const token = localStorage.getItem("token");
  const [parsedResponse, setParsedResponse] = useState(null);
  const [merchantData, setMerchantData] = useState({
    callBackUrl: "",
    mid:""
  });

  const [formData, setFormData] = useState({
    merchantMid: "",
    customCallbackURL: "",
  });

  const handelInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setMerchantData({ callbackURL: "" }); 
    };

  const getMerchantInfo = async (mid) => {
    if (mid === '') {
      swal({
        title: 'Alert',
        text: 'Please Select A Merchant',
        icon: 'error',
      });
      return;
    }
  try {
      setShowLoader(true);
      const res = await getMerchantInfoByIdPayin(token,mid)
      setShowLoader(false);
      console.log('API Response:', res.data);
      if (res.statusCode === 200) {
        const merchantDataResponse = res.data
        if (merchantDataResponse) {
          console.log(merchantDataResponse);
          setMerchantData({
            ...merchantData,
            callBackUrl: merchantDataResponse?.callBackUrl || 'Callback URL not found',
            
          });
        }
      } else {
        swal({
          title: 'Alert',
          text: "CallBack URL not found",
          icon: 'error',
        });
      }
    } catch (error) {
      setShowLoader(false);
      swal({
        title: 'Error',
        text: 'An error occurred while fetching merchant information',
        icon: 'error',
      });
      console.error('Error:', error);
    }
  };

  const getAllMerchantForApiTest = async () => {
    setShowLoader(true);
    let res = await getMechantForPayoutApiTest(token);
    setShowLoader(false);
    setMerchant(res?.data);
  };

  
const submitCallbackURL = async () => {
  const callbackURL = formData.customCallbackURL || merchantData.callbackURL;

    if (formData.merchantMid === '') {
      swal({
        title: 'Alert',
        text: 'Please select a merchant',
        icon: 'error',
      });
      return;
    }
    try {
      setShowLoader(true);

      const res = await testCallbackURLPayin(token,callbackURL,formData.merchantMid)

      setShowLoader(false);

      if (res.status === "success") {
        const parsedData = {
          body: res.data.body,
          statusCode: res.data.statusCode,
          statusCodeValue: res.data.statusCodeValue,
        };
        setParsedResponse(parsedData);
        swal({
          title: "Success",
          text: "Callback URL Test Successful",
          icon: "success",
        })
         
        setMerchantData({
          callBackUrl: '',
          mid: '',
        });
        setFormData({
          merchantMid: '',
        });
      
        
      } else {
        swal({
          title: "Error",
          text: "Failed to test Callback URL",
          icon: "error",
        });
      }
    } catch (error) {
      setShowLoader(false);
      swal({
        title: "Error",
        text: "An error occurred while testing Callback URL",
        icon: "error",
      });
      console.error("Error:", error);
    }
  };
  useState(() => {
    getAllMerchantForApiTest();
  }, []);
  return (
   <Container>
      <Card className="shadow border-0 mb-4">
      <Card.Body>
        <div className="merchant-report-view">
          <div className="report-table-view">
            <div className="containerflag">
              <div className="row mt-4">
                <div className="col-md-6 offset">
                  <div className="col-sm-12 col-md-6  offset-md-3">
                          <label className="form-label">
                            Select A Merchant
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <select
                              name="merchantMid"
                              value={formData.mid}
                              onChange={(e) => {
                                handelInput(e);
                                getMerchantInfo(e.target.value);
                              }}
                              className="form-control"
                            >
                              <option>Select A Merchant</option>
                              {merchant?.map((elem) => (
                                <option key={elem.mid} value={elem.mid}>
                                  {elem.merchantName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                    </div>
                    
                    
                    <div className="col-md-6">
                  <div className="col-sm-12 col-md-6  offset-md-3 mt-3">
                          <label className="form-label">
                            Callback URL
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              type="text"
                               className="form-control"
                               value={merchantData.callbackURL || formData.customCallbackURL}
                               onChange={(e) =>
                                setFormData({ ...formData, customCallbackURL: e.target.value })
                              }
                             
                            />
                          </div>
                        </div>
                   </div>
                   {parsedResponse && (
            <div className="mt-4">
              <h5>API Testing Response:</h5>
              <pre>{JSON.stringify(parsedResponse, null, 2)}</pre>
            </div>
          )}
        <div className="d-flex justify-content-center mt-4">
          <button className="btn btn-primary" onClick={submitCallbackURL}>
            Submit
          </button>
        </div>
        </div>
                    </div>
            </div>
          </div>
        
      </Card.Body>
    </Card>
    </Container>
  
  );
};


export default CallbackPayin;