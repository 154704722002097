import React, { useEffect, useState } from "react";
import SubTabManage from "./SubTabManage";
import { Card, Row, Container, Col } from "react-bootstrap";
import InactiveServices from "../../InactiveServices";
import MyLoader from "../../../common/MyLoader";
import PayoutApiTest from "../PayoutApiTest/PayoutApiTest";
import CallbackSubTab from "./CallbackSubTab";

const PayoutRequest = () => {
  const [activePhase, setActivePhase] = useState("Phase1");
  const [activeSubtab, setActiveSubtab] = useState("MerchantManagement");
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setActivePhase("Phase1");
    setActiveSubtab("MerchantManagement");
  }, []);

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  return (
    <>
    
     {showLoader ? <MyLoader/> : ""}
      <section className="dashboard-header-sec">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    {/* <Card.Title className="h3">Reports</Card.Title>  */}
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn">
                        <li
                          className={activePhase === "Phase1" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase1")}
                        >
                          Merchant Management
                        </li>
                    
                        <li
                          className={activePhase === "Phase3" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase3")}
                        >
                          RESELLER MANAGEMENT
                        </li>
                        <li
                          className={activePhase === "Phase4" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase4")}
                        >
                          AGGREGATOR MANAGEMENT
                        </li>
                        <li
                          className={activePhase === "Phase5" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase5")}
                        >
                          INSTRUMENT MANAGEMENT
                        </li>

                        <li
                          className={activePhase === "Phase6" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase6")}
                        >
                          PAYOUT API TEST
                        </li>
                        <li
                          className={activePhase === "Phase7" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase7")}
                        >
                          CALLBACK TEST
                        </li>
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
                
              </Col>
              
            </Row>
          </div>
        </Container>
      </section>

      <div>
        {activePhase === "Phase1" ? (
          <SubTabManage
          setShowLoader = {setShowLoader} />
        ) : activePhase === "Phase2" ? (
          <InactiveServices />
        ) : activePhase === "Phase3" ? (
          <InactiveServices />
        ) : activePhase === "Phase4" ? (
          <InactiveServices />
        ) : activePhase === "Phase5" ? (
          <InactiveServices />
        ) : activePhase === "Phase6" ? (
          <PayoutApiTest setShowLoader = {setShowLoader}/>
        ) : activePhase === "Phase7" ? (
          <CallbackSubTab setShowLoader = {setShowLoader}/>
        ) : (
          <InactiveServices  />
        )}
      </div>
    </>
  );
};

export default PayoutRequest;