import React from "react";
import { useNavigate } from "react-router-dom";
const Popup = ({ open, close, message }) => {
  const navigate = useNavigate();

  const handleClose = () => {
    close(false); 
    if (message === "Application Approved") {
      navigate("/Applications"); 
    }
  };

  return (
    <div style={{ position: 'absolute', top: 0, width: '100%', height: '100vh', background: '', display: 'flex', alignItems: 'center', justifyContent: 'center' }} className='popup'>
      {open && <div style={{ zIndex: 1000, background: '#fff', position: 'absolute', height: '300px', width: '400px', border: '1px solid #000', display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
        <p style={{ color: 'green', fontSize: '1.3rem' }}>{message}</p>
        <button onClick={handleClose} style={{ background: 'red', color: 'white' }}>Close</button>
      </div>}
    </div>
  );
};
export default Popup;