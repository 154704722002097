import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { SWIPELINC_API } from "../utils/constant";

const ViewApprove = ({setShowLoader}) => {
  const [applications, setApplications] = useState([]);
  const [openServices, setOpenServices] = useState({});
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    const axiosInstance = axios.create({
      baseURL: `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard`,
      headers: {
        Authentication: `Bearer ${token}`,
      },
    });
    
  setShowLoader(true)
    axiosInstance
      .get("/getallmerchant/Approved",{
        params:{
          applicationStatus:"Approved"
        }
      })
      .then((response) => {
        if (response.data.statusCode===200){
          setApplications(response.data.data);
        }
        else if (response.data.statusCode === 304)
        setApplications(response.data.data);
        setShowLoader(false)
      })
   
      .catch((error) => {
        console.error("Error fetching applications:", error.message);
        setShowLoader(false)
      });
  }, []);

  const handleViewDetails = (registrationId) => {
   
    navigate(`/ViewFile1/${registrationId}`);
  };
  const togglePaymentServices = (registrationId) => {
    setOpenServices((prevOpenServices) => ({
      ...prevOpenServices,
      [registrationId]: !prevOpenServices[registrationId],
    }));
  };

  return (
    <>
      {/* <Header /> */}

      <div className="report-grid-view">
      <h2>Approved Applications</h2>
      <div className="table-responsive">
      <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                    <th>Company Name</th>
                    <th>Authorized Person Name</th>
                    <th>e-Mail ID</th>
                    <th>Mobile Number</th>
                    <th>Company PAN Number</th>
                    <th>GST Number</th>
                    <th>Services</th>
                    <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {applications ? (applications.map((application) => (
                      <tr key={application.registrationId}>
                         <td>{application.companyName}</td>
                        <td>{application.authorizedPersonName}</td>
                        <td>{application.email}</td>
                        <td>{application.mobile}</td>
                       <td>{application.companyPanNumber}</td>
                        <td>{application.gstNumber}</td>
                         <td>
                            <button
                              className="btn btn-secondary btn-sm"
                              onClick={() => togglePaymentServices(application.registrationId)}
                            >
                              {openServices[application.registrationId]
                                ? "View Services"
                                : "View Services"}
                            </button>
                            {openServices[application.registrationId] && (
                              <div>
                                {application.paymentService.map((service) => (
                                  <div key={service.paymentTypeId}>
                                    {service.paymentService}
                                  </div>
                                ))}
                              </div>
                            )}
                          </td>
                        <td>
                          <button className="btn btn-primary btn-sm"
                            type="button"
                            onClick={() => handleViewDetails(application.registrationId)}
                          >
                            View Details
                          </button>
                        </td>
                      </tr>
                    ))
                  ):(
                    <tr>
                      <td colspan = "8">NO DATA FOUND</td>
                    </tr>
                  )}
                  </tbody>
                 </table>
      </div>
      </div> 
      
    </>
  );
};
export default ViewApprove;