import React, { useEffect, useState } from "react";
import MerchantDetails from "../../../Merchant/MerchantDetails";
import FlagManagement from "../../../Merchant/MerchantManagement/FlagManagement";
import AllPayoutReport from "./AllPayoutReport";
import Withdraw from "../../../reports/Withdraw";
import InactiveServices from "../../InactiveServices";
import WithdrawAmount from "../../../Merchant/MerchantManagement/WithdrawAmount2";
import Withdrawl from "./Withdrawl";
import ServiceCharge from "../../../Merchant/MerchantManagement/ServiceCharge";
import AllVendor from "../ALL VENDOR REGISTRATION REQUEST/AllVendor";
import ChargebackUpload from "../../../reports/ChargebackUpload";
import ManageAmount from "../../../Merchant/MerchantManagement/ManageAmount";
import PayOutSection from "./PayOutSection";
import VendorSection from "./VendorSection";
import WithdrawSection from "./WithdrawSection";
import HoldandDepositSection from "../../../Merchant/MerchantManagement/Hold&DepositSection";
import { Row, Col, Card, Container } from "react-bootstrap";
import Routing from "../../../Merchant/MerchantManagement/PayInRouting";
import Reconciliation from "./Reconcilliation";
import MyLoader from "../../../common/MyLoader";
import RoutingSubtab from "./RoutingSubtab";
import WalletBalance from "./WalletBalance";
import MDRPercentageEdit from "./MDRPercentageEdit";

const SubTabManage = ({setShowLoader}) => {
  const [activePhase, setActivePhase] = useState("Phase1");
  // const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setActivePhase("Phase1");
   
  }, []);

  const handlePhaseClick = (phase) => {
    console.log(phase)
    setActivePhase(phase);
  };

  return (
    <>
    {/* {showLoader ? <MyLoader/> : ""} */}
      <section className="dashboard-header-sec subtab-card">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-3  ds-inner-card">
                  <Card.Body>
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn submenu-tab-admin">
                        <li
                          className={activePhase === "Phase1" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase1")}
                        >
                          Details{activePhase === "Phase1" }
                        </li>
                        <li
                          className={activePhase === "Phase2" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase2")}
                        >
                          Flag{activePhase === "Phase2" }
                        </li>
                        <li
                          className={activePhase === "Phase3" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase3")}
                        >
                          Hold & Deposit
                          {activePhase === "Phase3"}
                        </li>
                        <li
                          className={activePhase === "Phase4" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase4")}
                        >
                          Payout{activePhase === "Phase4" }
                        </li>
                        <li
                          className={activePhase === "Phase5" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase5")}
                        >
                          Withdraw{activePhase === "Phase5" }
                        </li>
                        
                        <li
                          className={activePhase === "Phase6" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase6")}
                        >
                          Routing {activePhase === "Phase6"}
                        </li>
                        {/* <li
                          className={activePhase === "Phase7" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase7")}
                        >
                          Service Charge
                          {activePhase === "Phase7" }
                        </li> */}
                        <li
                          className={activePhase === "Phase8" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase8")}
                        >
                          Vendor{activePhase === "Phase8" }
                        </li>
                        <li
                          className={activePhase === "Phase9" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase9")}
                        >
                          Upload Chargeback
                          {activePhase === "Phase9" }
                        </li>
                        <li
                          className={activePhase === "Phase10" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase10")}
                        >
                          Reconciliation{" "}
                          {activePhase === "Phase10" }
                        </li>
                        <li
                          className={activePhase === "Phase11" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase11")}
                        >
                          Wallet Balance{" "}
                          {activePhase === "Phase11" }
                        </li>
                        <li
                          className={activePhase === "Phase12" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase12")}
                        >
                          MDR   {activePhase === "Phase12"}
                        </li>
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <div className="admin-tab-content mat-bt-table">
        {activePhase === "Phase1" ? (
          <MerchantDetails
          setShowLoader = {setShowLoader} />
        ) : activePhase === "Phase2" ? (
          <FlagManagement 
          setShowLoader = {setShowLoader}/>
        ) : activePhase === "Phase3" ? (
          <HoldandDepositSection 
          setShowLoader = {setShowLoader}/>
        ) : activePhase === "Phase4" ? (
          <PayOutSection 
          setShowLoader = {setShowLoader}/>
        ) : activePhase === "Phase5" ? (
          <WithdrawSection 
          setShowLoader = {setShowLoader}/>
        ) : activePhase === "Phase6" ? (
          
          <RoutingSubtab 
          setShowLoader = {setShowLoader}/>
         
        // ) : activePhase === "Phase7" ? (
        //   <ServiceCharge
        //   setShowLoader = {setShowLoader} />
        ) : activePhase === "Phase8" ? (
          <VendorSection 
          setShowLoader = {setShowLoader}/>
        ) : activePhase === "Phase9" ? (
          <ChargebackUpload 
          setShowLoader = {setShowLoader}/>
        ) : activePhase === "Phase10" ? (
          <Reconciliation
          setShowLoader = {setShowLoader} />
        ) : activePhase === "Phase11" ? (
          <WalletBalance
          setShowLoader = {setShowLoader} />
        ) : activePhase === "Phase12" ? (
        
          <MDRPercentageEdit 
          setShowLoader = {setShowLoader} />
        ) : (
          <InactiveServices />
        )}
      </div>
    </>
  );
};

export default SubTabManage;
