import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { SWIPELINC_API } from "../../utils/constant";

function FlagEditForm({ mid, closeModal, showModal, data, onCloseModal }) {
  const [formData, setFormData] = useState({});
  const [token] = useState(localStorage.getItem("token"));
  const [isWithdrawalAuto, setIsWithdrawalAuto] = useState(false)
  const [withdrawalPeriod ,setWithdrawalPeriod] = useState("")

  useEffect(() => {
    const merchantData = data.find((item) => item.mid === mid);
    if (merchantData) {
      setFormData({
        isActive: merchantData.isActive === true ? "Enabled" : "Disabled",
        isAmountHold: merchantData.isAmountHold === true ? "Auto" : "Manual",
        isPayoutAutoApprovel:
          merchantData.isPayoutAutoApprovel === true ? "Auto" : "Manual",
        isProfileEdit:
          merchantData.isProfileEdit === true ? "Enabled" : "Disabled",
        isTransactionAllow:
          merchantData.isTransactionAllow === true ? "Enabled" : "Disabled",
        isVendorAutoApproved:
          merchantData.isVendorAutoApproved === true ? "Auto" : "Manual",
        isWithdrawlAuto:
          merchantData.isWithdrawlAuto === true ? "Auto" : "Manual",
        mid,
      });
      if(merchantData.isWithdrawlAuto === true) {
        setIsWithdrawalAuto(true)
        setWithdrawalPeriod(merchantData.withdrawalPeriod)
      }
    }
  }, [data, mid]);

  const flagData = [
    {
      name: "Login",
      key: "isActive",
      enabled: "Enabled",
      disabled: "Disabled",
    },
    { name: "Hold", key: "isAmountHold", enabled: "Auto", disabled: "Manual" },
    {
      name: "Profile Edit",
      key: "isProfileEdit",
      enabled: "Enabled",
      disabled: "Disabled",
    },
    {
      name: "Transaction",
      key: "isTransactionAllow",
      enabled: "Enabled",
      disabled: "Disabled",
    },
    {
      name: "Vendor",
      key: "isVendorAutoApproved",
      enabled: "Auto",
      disabled: "Manual",
    },
    {
      name: "Payout",
      key: "isPayoutAutoApprovel",
      enabled: "Auto",
      disabled: "Manual",
    },
    {
      name: "Withdraw",
      key: "isWithdrawlAuto",
      enabled: "Auto",
      disabled: "Manual",
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedData = { ...formData };

      updatedData.isActive = updatedData.isActive === "Enabled";
      updatedData.isAmountHold = updatedData.isAmountHold === "Auto";
      updatedData.isPayoutAutoApprovel =
        updatedData.isPayoutAutoApprovel === "Auto";
      updatedData.isProfileEdit = updatedData.isProfileEdit === "Enabled";
      updatedData.isTransactionAllow =
        updatedData.isTransactionAllow === "Enabled";
      updatedData.isVendorAutoApproved =
        updatedData.isVendorAutoApproved === "Auto";
      updatedData.isWithdrawlAuto = updatedData.isWithdrawlAuto === "Auto";
      updatedData.withdrawalPeriod = withdrawalPeriod

      const response = await axios.put(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/updateMerchantsByMid`,
        updatedData,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      );

      if (response.data.statusCode === 200) {
        swal({
          title: "Success",
          text: "Data saved successfully",
          icon: "success",
        });
        onCloseModal();
      } else if (response.data.statusCode === 304) {
        swal({
          title: "error",
          text: "No Data Found",
          icon: "error",
        });
        onCloseModal();
      }
    } catch (error) {
      console.error("Error updating flags:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if(name === "isWithdrawlAuto" && value === "Auto") {
      setIsWithdrawalAuto(true)
    }
    if(name === "isWithdrawlAuto" && value === "Manual") {
      setIsWithdrawalAuto(false)
    }
  };

  return (
    <div className={`flag-edit-form ${showModal ? "show" : ""}`}>
      <form onSubmit={handleSubmit} style={{ width: "80%", margin: "0 auto" }}>
        <div className="form-row  border border-1">
          {flagData.map((flag) => (

            

            <div className=" border border-1 align-content-center p-3">
              <div
                className="form-group w-100 d-flex justify-content-between   "
                key={flag.key}
              >
                <label className="w-50 " htmlFor={flag.key}>
                  {flag.name}
                </label>
                <div className="d-flex w-75 justify-content-between ">
                  <div className="form-check w-50 form-check-inline d-flex  flex-start gap-4">
                    <input
                      type="radio"
                      name={flag.key}
                      value={flag.enabled}
                      checked={formData[flag.key] === flag.enabled}
                      onChange={handleChange}
                      className="form-check-input"
                    />
                    <label
                      className="form-check-label"
                      htmlFor={flag.key + "Enabled"}
                    >
                      {flag.enabled}
                    </label>
                  </div>

                  <div className="form-check w-50 form-check-inline d-flex flex-start gap-4">
                    <input
                      type="radio"
                      name={flag.key}
                      value={flag.disabled}
                      checked={formData[flag.key] === flag.disabled}
                      onChange={handleChange}
                      className="form-check-input"
                    />
                    <label
                      className="form-check-label"
                      htmlFor={flag.key + "Disabled"}
                    >
                      {flag.disabled}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {
            isWithdrawalAuto ? <div className="mt-2 d-flex pb-3">
            <div className="col-5 d-flex align-items-center px-3 ">
              <label htmlFor="email">Update Withdrawal Period</label>
            </div>
            <div className="col-7">
              <select
                id=""
                className="form-control mt-1"
                onChange={(e) => setWithdrawalPeriod(e.target.value)}
                value={withdrawalPeriod}
                name="withdrawalPeriod"
              >
                <option value="">Select Withdrawal Period</option>
                <option value="3hours">3 Hours</option>
                <option value="12hours">12 Hours</option>
                <option value="24hours">24 Hours</option>
                <option value="t+1">T + 1</option>
                <option value="t+7">T + 7</option>
                <option value="t+15">T + 15</option>
                <option value="instant">Instant Withdrawal</option>
                <option value="hourly">Hourly Withdrawal</option>
              </select>
            </div>
          </div> :""
          }
        </div>
        <div className="button-container mt-3 d-flex gap-3">
          <button className="btn btn-primary" type="submit">
            Submit
          </button>
          <button
            className="btn btn-secondary"
            type="button"
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </form>
    </div>
  );
}

export default FlagEditForm;