import React, { useState } from "react";
import Ledger from "./Ledger";
import EKyc from "./EKyc";
import MerchantTransaction from "../Merchant/MerchantTransaction";
import { Container, Card, Row, Col } from "react-bootstrap";
import MyLoader from "../common/MyLoader";
import InactiveServices from "../components/InactiveServices";
import LiveCummulativeSubTab from "./LiveCummulativeSubTab";

import TemporaryAutoRequest from "./TemporaryAutoWithdrawlRequest";
import MerchantTxnReport from "./MerchantTxnReport";

function Reports() {
  const [reportType, setReportType] = useState("MerchantTransaction");
  const [activeButton, setActiveButton] = useState(1);
  const [showLoader, setShowLoader] = useState(false);

  const HandleClick = (reportType, btn) => {
    setReportType(reportType);
    setActiveButton(btn);
  };

  return (
    <>
      {showLoader ? <MyLoader /> : ""}
      <section className="dashboard-header-sec">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                  
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn">
                        <li
                          className={activeButton === 1 ? "active" : ""}
                          onClick={() => HandleClick("MerchantTransaction", 1)}
                        >
                          Merchant Transaction
                        </li>
                      
                        <li
                          className={activeButton === 2 ? "active" : ""}
                          onClick={() => HandleClick("LiveCummulaticeSubTab", 2)}
                        >
                          Live Cummulative Report
                        </li>
                        <li
                          className={activeButton === 3 ? "active" : ""}
                          onClick={() => HandleClick("TemporaryAutoRequest", 3)}
                        >
                          Auto-Withdrawl Report
                        </li>
                        <li
                          className={activeButton === 4 ? "active" : ""}
                          onClick={() => HandleClick("merchantTxnReport", 4)}
                        >
                          Merchant Transaction Report
                        </li>
                        <li
                          className={activeButton === 5 ? "active" : ""}
                          onClick={() => HandleClick("EkycReport", 5)}
                        >
                          E-KYC
                        </li>
                        <li
                          className={activeButton === 6 ? "active" : ""}
                          onClick={() => HandleClick("LedgerReport", 6)}
                        >
                          Ledger
                        </li>
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>
              <Col xs={12} lg={12} md={12}>
                
                    <div className="merchant-report-view">
                      <div className="report-table-view">
                        {reportType === "EkycReport" ? (
                          <EKyc setShowLoader={setShowLoader} />
                        ) : reportType === "LedgerReport" ? (
                          <Ledger setShowLoader={setShowLoader} />
                        ) : reportType === "MerchantTransaction" ? (
                          <MerchantTransaction setShowLoader={setShowLoader} />
                        ) : reportType === "LiveCummulaticeSubTab" ? (
                          <LiveCummulativeSubTab setShowLoader={setShowLoader} />
                          
                        ) :reportType === "TemporaryAutoRequest" ? (
                         <TemporaryAutoRequest setShowLoader={setShowLoader}/>

                        ):reportType === "merchantTxnReport" ? (
                          <MerchantTxnReport setShowLoader={setShowLoader} />
                        ):
                        // <InactiveServices />

                        null}
                      </div>
                    </div>
                  
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Reports;