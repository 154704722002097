import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { SWIPELINC_API } from "../../utils/constant";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Card, Row, Col } from "react-bootstrap";
import DownloadCsv from "../../common/DownloadCsv";
import { headerLabelsForVendor } from "../../utils/DownloadHeaders";
import CompanyNameAndMid from "../../common/CompanyNameAndMid";

function SingleVendor() {
  const [data, setData] = useState();
  const token = localStorage.getItem("token");
  const [showTable, setShowTable] = useState(false);
  const[showLoader,setShowLoader] = useState()

  const { mid } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (mid) {
      setShowLoader(true)
      axios
        .get(
          `${SWIPELINC_API}/MerchantAdminPanel/merchant/vendor/getAllVendorsbyMid/${mid}`,
          {
            headers: {
              Authentication: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            setData(response.data.data);
          setShowTable(true);
          }else{
            setData([])
          }
          setShowLoader(false)
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setShowTable(false);
        });
    }
  }, [mid]);

  const columns = [
    // { label: 'Vendor ID', field: 'vendorId', width:'auto' },
    { label: "Name", field: "fullName", width: "auto" },
    { label: "e-Mail", field: "email", width: "auto" },
    { label: "Category", field: "category", width: "auto" },
    { label: "VPA / UPI ID", field: "upi", width: "auto" },
    { label: "Account Number", field: "accountNumber", width: "auto" },
    { label: "IFSC Code", field: "ifscCode", width: "auto" },
    { label: "Bank Name", field: "bankName", width: "auto" },
    { label: "Branch Name", field: "branchName", width: "auto" },
    { label: "Vendor Status", field: "vendorStatus", width: "auto" },
    { label: "Mobile", field: "mobile", width: "auto" },
    { label: "Create Date", field: "createDate", width: "auto" },
    { label: "Create Time", field: "createTime", width: "auto" },
  ];
  const mappedData = data?.map((item) => {
    const mappedItem = {};
    columns.forEach((column) => {
      mappedItem[column.field] = item[column.field];
    });
    return mappedItem;
  });
  const handleBackButtonClick = () => {
    navigate(-1);
  };
  return (
    <>
      <Container>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <Card className="shadow border-0 my-4">
            <Card.Header>
           
              <CompanyNameAndMid mid={mid} reportType={"Vendor Report"} />
              </Card.Header>
              <Card.Body>
                <div className="d-flex justify-content-between my-2">
                  <button
                    className="btn btn-primary mt-2"
                    onClick={handleBackButtonClick}
                  >
                    Go Back
                  </button>
                </div>

                <div className="report-grid-view">
                  <div className="material-table-records mb-3">
                    <MDBDataTable
                      className="dataTable"
                      striped
                      bordered
                      small
                      data={{
                        columns: columns,
                        rows: mappedData,
                      }}
                      searching={true}
                      sorting={true}
                      responsive
                      hover
                      footer="none"
                    />
                  </div>
                  <DownloadCsv DownLoadData={data}  Header={headerLabelsForVendor} Name={`${mid}-Vendor`}/>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SingleVendor;
