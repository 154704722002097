import React, { useEffect, useState } from "react";
import Stage2Applications from "./Stage2Applications";
import Stage2Approved from "./Stage2Approved";
import Stage2Rejected from "./Stage2Rejected";
import Stage2Pending from "./Stage2Pending";
import InactiveServices from "../components/InactiveServices";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

const Stage2AppSections = ({ setShowLoader }) => {
  const [activePhase, setActivePhase] = useState("Phase1");

  useEffect(() => {
    setActivePhase("Phase1");
  }, []);

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  return (
    <>
      <div className="subtab-sec-admin">
        <Row>
          <Col xs={12} lg={12} md={12}>
            <Card className="shadow border-0 mb-4">
              <div className="merchant-tab-btn">
                <ul className="report-link nav-report-btn">
                  <li
                    className={activePhase === "Phase1" ? "active" : ""}
                    onClick={() => handlePhaseClick("Phase1")}
                  >
                    Submitted
                  </li>
                  <li
                    className={activePhase === "Phase2" ? "active" : ""}
                    onClick={() => handlePhaseClick("Phase2")}
                  >
                    Approved
                  </li>
                  <li
                    className={activePhase === "Phase3" ? "active" : ""}
                    onClick={() => handlePhaseClick("Phase3")}
                  >
                    Rejected
                  </li>
                  <li
                    className={activePhase === "Phase4" ? "active" : ""}
                    onClick={() => handlePhaseClick("Phase4")}
                  >
                    Pending
                  </li>
                </ul>
              </div>
              <Card.Body>
                <div className="admin-tab-content">
                  {activePhase === "Phase1" ? (
                    <Stage2Applications
                      setActivePhase={setActivePhase}
                      setShowLoader={setShowLoader}
                    />
                  ) : activePhase === "Phase2" ? (
                    <Stage2Approved
                      setActivePhase={setActivePhase}
                      setShowLoader={setShowLoader}
                    />
                  ) : activePhase === "Phase3" ? (
                    <Stage2Rejected
                      setActivePhase={setActivePhase}
                      setShowLoader={setShowLoader}
                    />
                  ) : activePhase === "Phase4" ? (
                    <Stage2Pending
                      setActivePhase={setActivePhase}
                      setShowLoader={setShowLoader}
                    />
                  ) : (
                    <InactiveServices />
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Stage2AppSections;
