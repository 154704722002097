import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import BankForm from "./BankForm";
import InactiveServices from "../components/InactiveServices";
import SingleSubmittedBankForm from "./SingleSubmittedBankForm";
import SubmittedBankForm from "./SubmittedBankForm";
import Routing from "./Routing";
import MyLoader from "../common/MyLoader";

const SubTabForRouting = ({setShowLoader}) => {
  const [activePhase, setActivePhase] = useState("Phase1");
  const [show, setShow] = useState(true);
  // const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setActivePhase("Phase1");
  }, []);

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  return (
    <>
    {/* {showLoader ? <MyLoader/> : ""} */}
      <section className="dashboard-header-sec subtab-card">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-3  ds-inner-card">
                  <Card.Body>
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn submenu-tab-admin">
                        <li
                          className={activePhase === "Phase1" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase1")}
                        >
                         Routing Form {activePhase === "Phase1"}
                        </li>

                        <li
                          className={activePhase === "Phase2" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase2")}
                        >
                          Submitted {activePhase === "Phase2"}
                        </li>
                        {/* <li
                              className={activePhase === "Phase2" ? "active" : ""}
                              onClick={() => handlePhaseClick("Phase2")}
                            >
                              Account phase 2 {activePhase === "Phase2"}
                            </li>
                            <li
                              className={activePhase === "Phase3" ? "active" : ""}
                              onClick={() => handlePhaseClick("Phase3")}
                            >
                              Account phase 3 {activePhase === "Phase3"}
                            </li> */}
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <div className="admin-tab-content">
              {activePhase === "Phase1" ? <Routing
              setShowLoader = {setShowLoader} /> 
              :activePhase === "Phase2" ? <SubmittedBankForm  
              setShowLoader={setShowLoader}
              show={show}/>:
              <InactiveServices />}
              
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default SubTabForRouting;