import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MDBDataTable } from 'mdbreact';
import "../assets/css/style.css";
import { SWIPELINC_API } from '../utils/constant';

const Withdraw = () => {
  const [withdrawData, setWithdrawData] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
 
    axios
      .get(`${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal/getAllWithdrawal`, {
        headers: {
          Authentication: `Bearer ${token}`
        }
      })
      .then((response) => {
        if (response.data.statusCode === 200 && Array.isArray(response.data.data)) {
          setWithdrawData(response.data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching withdrawal data:', error);
      });
  }, []);

 
  const columns = [
    { label: 'Merchant Name', field: 'fullName', width: 'auto' },
    { label: 'Merchant Id', field: 'mid', width: 'auto' },
    { label: 'Amount', field: 'amount', width: 'auto' },
    { label: 'created Time', field: 'createTime', width: 'auto' },
    { label: 'created Date', field: 'createDate', width: 'auto' },
    { label: 'Email Id', field: 'emailId', width: 'auto' },
    { label: 'Transfer Type', field: 'transferType', width: 'auto' },
    { label: 'Virtual Account Type', field: 'virtualAccountType', width: 'auto' },
    
];

  const mappedData = withdrawData.map((item) => {
    const mappedItem = {};
    columns.forEach((column) => {
      mappedItem[column.field] = item[column.field];
    });
    <div>
    <button
      className="toolbutton"
      
    >
    
    </button>
    </div>
    return mappedItem;
  });

  return (
    <>
      <main className="">
        <div className="reportSection">
          <div className="container">
            <div className="tabs-content active" id="tab1">
              <div className="card py-3">
                <div id="Withdraw-datatable-container">
                  <MDBDataTable
                    className='dataTable overflow-x-scroll'
                    hover
                    striped
                    small
                    bordered
                    responsive
                    sortable={true}
                    searching={true}
                    entries={10}
                    data={{
                      columns: columns,
                      rows: mappedData
                    }}
                    footer="none"
                    theadColor='#333'
                    paginationLabel={['Previous', 'Next']}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Withdraw;
