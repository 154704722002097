import axios from "axios";
import {
  CHECK_FILE_IS_PDF,
  GET_UPLOADED_PDF_FILES,
  GET_ALL_MERCHANT_PAYOUT_API_TEST,
  FETCH_ACCOUNT_DETAILS_BY_MID,
  FETCH_ALL_ACCOUNT_DETAILS,
  FETCH_ALL_BANK_FORM_SUBMITTED,
  FETCH_ALL_FRM_SUBMITTED_FORM,
  FETCH_ALL_MERCHANT_VALIDATIONS,
  FETCH_BANK_FORM_SUBMITTED_BY_MID,
  FETCH_DOCUMENTS_BY_MID_FOR_ACCOUNT,
  FETCH_FRM_SUBMITTED_FORM_BY_MID,
  GET_RECONCILIATION_DATA,
  SWIPELINC_API,
  UPLOAD_NODAL_FILE,
  UPLOAD_NODAL_TRUE_OR_FALSE,
  UPLOAD_SWITCH_FILE,
  GET_TXN_REPORT_ADVANCE_FILTER,
  PAYIN_FILTER_API,
  SETTLEMENT_MULTIPLE_FILTER_API,
  CHARGEBACK_MULTIPLE_FILTER_API,
  PAYOUT_MULTIPLE_FILTER_API,
  WITHDRAW_MULTIPLE_FILTER_API,
  GURANTEE_MULTIPLE_FILTER_API,
  DISABLED_DATA_PAYOUT_MASTER,
  SAVE_MERCHANT_PAYOUT_MASTER,
  GET_ALL_MECHANT_PAYOUT_MASTERS,
  GET_ALL_ROUTING_VIEW,
  GET_WALLET_BALANCE_BY_MID,
  SID_DETAILS,
  GET_ALL_SUBMITTED_PAYOUT_ROUTING,
  GET_ALL_LIVE_TRACKING_DATA_BOX,
  GET_ALL_LIVE_TRACKING_DATA_TABLE,
  GET_ALL_DAILY_TRANSACTION_DATA_BOX,
  GET_ALL_DAILY_TRANSACTION_DATA_TABLE,
  GET_ALL_DAILY_TRANSACTION_DATA_TABLE_BY_MID,
  GET_ALL_DAILY_TRANSACTION_DATA_BOX_BY_MID,
  GET_ALL_RECON_DATA_BY_MULTIPLE_CONDITION,
  UPDATE_MERCHANT_BANK_DETAILS,
  ACC_NUM_IFSC_VERIFY_API,
  IFSC_VERIFY_API,
  VERIFY_MERCHANT_ONBOARD_MOBILE,
  VERIFY_MERCHANT_ONBOARD_EMAIL,
  GET_ALL_ORGANISATIONAL_TYPE,
  SAVE_ORGANISATIONAL_TYPE,
  GET_ALL_ORGANISATIONAL_TYPE_WITH_ACTIVE,
  GET_ALL_VEHICLE_COMMERCIAL_TYPE_WITH_ACTIVE,
  GET_ALL_VEHICLE_SETTLEMENT_TYPE_WITH_ACTIVE,
  GET_ALL_VEHICLE_COMMERCIAL_WITH_ACTIVE,
  GET_ALL_VEHICLE_TYPE_WITH_ACTIVE,
  SUBMITTED_VEHICLE_COMMERCIAL,
  SUBMITTED_VEHICLE_TYPE,
  SUBMITTED_VEHICLE_SUBTYPE,
  GET_ALL_VEHICLE_SUB_TYPE_WITH_ACTIVE,
  GET_ALL_VEHICAL_SETTLEMENT_TYPE,
  GET_ALL_VEHICAL_COMMERCIAL_TYPE,
  SAVE_VEHICAL_SETTLEMENT_TYPE,
  SAVE_VEHICAL_COMMERCIAL_TYPE,
  GET_ALL_VEHICAL_MASTER_FORMS,
  SAVE_VEHICLE_MASTER_FORM,
  SAVE_VEHICLE_MASTER_UPDATE_FORM,
  SAVE_ORGANISATIONAL_TYPE_UPDATE_FORM,
  GET_ALL_MERCHANT_PHASE_2_APPROVED,
  GET_LIVE_TXN_REPORT_ADVANCE_FILTER,
  LIVE_PAYIN_MORE_FILTER_API,
  GET_ALL_TEMPORARY_REQUEST_DATA,
  GET_ALL_DAILY_TRANSACTION_DATA_RECONCILIATION,
  GET_ALL_DAILY_TRANSACTION_DATA_WITHDRAWL,
  GET_ALL_DAILY_TRANSACTION_DATA_CHARGEBACK,
  GET_ALL_DAILY_TRANSACTION_DATA_PAYOUT,
  GET_ALL_DAILY_TRANSACTION_DATA_PAYOUT_BY_MID,
  GET_ALL_DAILY_TRANSACTION_CHARGEBACK_,
  GET_ALL_DAILY_TRANSACTION_CHARGEBACK_BY_MID,
  GET_ALL_DAILY_TRANSACTION_WITHDRAWL_BY_MID,
  GET_ALL_DAILY_TRANSACTION_RECONCILIATION_BY_MID,
  LIVE_WITHDRAWL_MULTIPLE_FILTER_API,
  LIVE_TRANSACTION_PAYOUT_WITHOUT_MID,
  CHECK_CAKKBACK_PAYIN,
  CALLBACK_PAYIN_BY_MID,
  CHECK_CALLBACK_FOR_PAYOUT,
  CALLBACK_PAYOUT_BY_MID,
  GET_ALL_MERCHANT_MASTER,
  GET_ALL_AGGREGATOR_DATA,
  GET_ALL_SWITCH_DATA,
  GET_ALL_BANK,
  GET_ALL_SCHEME,
  GET_TXN_REPORT,
  CHECK_CALLBACK_PAYIN,
  DISABLE_VEHICLE_MASTER,
  ENABLE_VEHICLE_MASTER,
  GET_ALL_ACTIVE_DISABLE_VEHICAL_MASTER,
} from "./constant";
export const FETCH_ALL_SID_MASTER_DETAILS = `${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/getAllSid`;
export const checkIsFileIsPDF = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(`${CHECK_FILE_IS_PDF}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    // console.log(response?.data?.statusCode);
    return response?.data?.statusCode;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const WithdrawType = async (token) => {
  try {
    const response = await axios.get(
      `${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal-type/getAllwithdrawal-type`,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const MDRTypeFetch = async (mid, token) => {
  try {
    console.log(mid);
    const response = await axios.get(
      `${SWIPELINC_API}MerchantAdminPanel/merchant/Account/getAllServices/${mid}`,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const MDRPercentageFetchByMid = async (mid, token) => {
  try {
    console.log(mid);
    const response = await axios.get(
      `${SWIPELINC_API}MerchantAdminPanel/merchant/mdr/getAllMdrByMid/${mid}`,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const getAllMerchantPhase2Approved = async (token) => {
  try {
    const response = await axios.get(FETCH_ALL_MERCHANT_VALIDATIONS, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const fetchAllAccountdetails = async (token) => {
  try {
    const response = await axios.get(FETCH_ALL_ACCOUNT_DETAILS, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(response?.data );
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const fetchAccountDetailsByMid = async (mid, token) => {
  try {
    const response = await axios.get(`${FETCH_ACCOUNT_DETAILS_BY_MID}/${mid}`, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const fetchDocsByMidForaccounts = async (mid, token) => {
  try {
    const response = await axios.get(
      `${FETCH_DOCUMENTS_BY_MID_FOR_ACCOUNT}${mid}`,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const fetchAllFrmsubmittedForm = async (token) => {
  try {
    const response = await axios.get(`${FETCH_ALL_FRM_SUBMITTED_FORM}`, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const fetchAllBankSubmittedForm = async (token) => {
  try {
    const response = await axios.get(`${FETCH_ALL_BANK_FORM_SUBMITTED}`, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const fetchFrmsubmittedFormByMid = async (mid, token) => {
  try {
    const response = await axios.get(
      `${FETCH_FRM_SUBMITTED_FORM_BY_MID}${mid}`,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const fetchBankFormByMid = async (mid, token) => {
  try {
    console.log(mid);
    const response = await axios.get(
      `${FETCH_BANK_FORM_SUBMITTED_BY_MID}${mid}`,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const fetchAllMerchantValidations = async (token) => {
  try {
    const response = await axios.get(FETCH_ALL_MERCHANT_VALIDATIONS, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const uploadMerchantDocs = async (mid, docsName, docsFile, token) => {
  try {
    const formData = new FormData();
    formData.append("mid", mid);

    docsFile.forEach((file) => {
      formData.append(`pdfFiles`, file);
    });
    docsName.forEach((name) => {
      formData.append(`fileName`, name);
    });

    const response = await axios.post(
      `${SWIPELINC_API}MerchantAdminPanel/merchant/Account/savemerchantdocuments`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authentication: "Bearer " + token,
        },
      }
    );
    // console.log(response);
    return response?.data?.statusCode;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const fetchAllSIDMasterSubmittedApplications = async (token) => {
  try {
    const response = await axios.get(FETCH_ALL_SID_MASTER_DETAILS, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(response?.data );
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const FetchAllReconcialiation = async (token) => {
  try {
    const response = await axios.get(GET_RECONCILIATION_DATA, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const uploadNodalTrueOrFalse = async (token) => {
  try {
    const response = await axios.get(UPLOAD_NODAL_TRUE_OR_FALSE, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const uploadSwitchFile = async (File, token) => {
  // alert("called");

  let formData = new FormData();
  formData.append("file", File);

  try {
    const response = await axios.post(UPLOAD_SWITCH_FILE, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authentication: "Bearer " + token,
      },
    });
    console.log(response);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const uploadNodalFile = async (File, token) => {
  // alert("called");

  let formData = new FormData();
  formData.append("file", File);

  try {
    const response = await axios.post(UPLOAD_NODAL_FILE, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authentication: "Bearer " + token,
      },
    });
    console.log(response);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const getUploadedPdfFiles = async (token) => {
  try {
    const response = await axios.get(GET_UPLOADED_PDF_FILES, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const USE_PAYIN_REPORT_FILTER_API = async (
  amountSearchValue,
  bankLocationSearchValue,
  custLocationSearchValue,
  dateSearchValue,
  endAmountSearchValue,
  endDateSearchValue,
  greaterAmountSearchValue,
  ipAddress,
  lessAmountSearchValue,
  mid,
  payerVPASearchValue,
  paymentTypeSearchvalue,
  startAmountSearchValue,
  startDateSearchValue,
  token,
  transectionStatusSearchValue,
  setTransactionReportData,
  setShowLoader
) => {
  try {
    setShowLoader(true);
    const payload = {
      amount: amountSearchValue ? parseInt(amountSearchValue) : 0,
      bankLocation: bankLocationSearchValue ? bankLocationSearchValue : "",
      customerLocation: custLocationSearchValue ? custLocationSearchValue : "",
      date: dateSearchValue ? dateSearchValue : "",
      endAmount: endAmountSearchValue ? endAmountSearchValue : 0,
      endDate: endDateSearchValue ? endDateSearchValue : "",
      greterAmount: greaterAmountSearchValue ? greaterAmountSearchValue : 0,
      ipAddress: ipAddress,
      lessAmount: lessAmountSearchValue ? lessAmountSearchValue : 0, // Include mobile value
      mid: mid,
      payerVpa: payerVPASearchValue ? payerVPASearchValue.trim(" ") : "",
      paymentType: paymentTypeSearchvalue
        ? paymentTypeSearchvalue.trim(" ")
        : "",
      startAmount: startAmountSearchValue ? startAmountSearchValue : 0,
      startDate: startDateSearchValue ? startDateSearchValue : "",
      transactionStatus: transectionStatusSearchValue
        ? transectionStatusSearchValue
        : "",
    };

    const response = await axios.post(PAYIN_FILTER_API, payload, {
      headers: {
        Authentication: `Bearer ${token}`,
      },
    });

    console.log(response);
    setShowLoader(false);

    if (response.data.statusCode === 200)
      setTransactionReportData(response?.data?.responseData);
    else setTransactionReportData([]);
    // console.log(response)
    // setCustomMultiApiResponse(response?.data?.responseData);
  } catch (error) {
    console.log(error);
  }
};

export const USE_LIVE_PAYIN_MORE_FILTER_API = async (
  withOrWithoutMid,
  amountSearchValue,
  bankLocationSearchValue,
  custLocationSearchValue,
  dateSearchValue,
  endAmountSearchValue,
  endDateSearchValue,
  greaterAmountSearchValue,
  ipAddress,
  lessAmountSearchValue,
  mid,
  payerVPASearchValue,
  paymentTypeSearchvalue,
  startAmountSearchValue,
  startDateSearchValue,
  token,
  transectionStatusSearchValue,
  setTransactionReportData,
  setShowLoader
) => {
  try {
    console.log(withOrWithoutMid);

    setShowLoader(true);
    const payload = {
      amount: amountSearchValue ? parseInt(amountSearchValue) : 0,
      bankLocation: bankLocationSearchValue ? bankLocationSearchValue : "",
      customerLocation: custLocationSearchValue ? custLocationSearchValue : "",
      date: dateSearchValue ? dateSearchValue : "",
      endAmount: endAmountSearchValue ? endAmountSearchValue : 0,
      endDate: endDateSearchValue ? endDateSearchValue : "",
      greterAmount: greaterAmountSearchValue ? greaterAmountSearchValue : 0,
      ipAddress: ipAddress,
      lessAmount: lessAmountSearchValue ? lessAmountSearchValue : 0, // Include mobile value
      mid: mid,
      payerVpa: payerVPASearchValue ? payerVPASearchValue.trim(" ") : "",
      paymentType: paymentTypeSearchvalue
        ? paymentTypeSearchvalue.trim(" ")
        : "",
      startAmount: startAmountSearchValue ? startAmountSearchValue : 0,
      startDate: startDateSearchValue ? startDateSearchValue : "",
      transactionStatus: transectionStatusSearchValue
        ? transectionStatusSearchValue
        : "",
    };

    const response = await axios.post(
      withOrWithoutMid ? LIVE_PAYIN_MORE_FILTER_API : PAYIN_FILTER_API,
      payload,
      {
        headers: {
          Authentication: `Bearer ${token}`,
        },
      }
    );

    console.log(response);
    setShowLoader(false);

    if (response.data.statusCode === 200)
      setTransactionReportData(response?.data?.responseData);
    else setTransactionReportData([]);
    // console.log(response)
    // setCustomMultiApiResponse(response?.data?.responseData);
  } catch (error) {
    console.log(error);
  }
};

export const txnReportAdvanceFilter = async (
  transactionIdSearchValue,
  orderNumberSearchValue,
  emailSearchValue,
  mobileSearchValue,
  payerVPASearchValue,
  custRefNoSearchValue,
  mid,
  token,
  setTransactionReportData,
  setShowLoader
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      GET_TXN_REPORT_ADVANCE_FILTER,
      {
        amount: "",
        bankLocation: "",
        custIpAddress: "",
        custLocation: "",
        custRefNo: custRefNoSearchValue ? custRefNoSearchValue.trim(" ") : "",
        email: emailSearchValue ? emailSearchValue.trim(" ") : "",
        endDate: "",
        ifsc: "",
        mid: mid,
        mobile: mobileSearchValue ? mobileSearchValue.trim(" ") : "",
        orderNo: orderNumberSearchValue ? orderNumberSearchValue.trim(" ") : "",
        paymentType: "",
        startDate: "",
        terminalId: "",

        txnId: transactionIdSearchValue
          ? transactionIdSearchValue.trim(" ")
          : "",
        userVpa: payerVPASearchValue ? payerVPASearchValue.trim(" ") : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    setShowLoader(false);
    // console.log(response?.data?.responseData);
    if (response.data.statusCode === 200)
      setTransactionReportData(response?.data?.responseData);
    else setTransactionReportData([]);

    // setCustomApiResponse(response?.data?.responseData);
  } catch (error) {
    console.log(error);
  }
};

export const liveTxnReportAdvanceFilter = async (
  date,
  transactionIdSearchValue,
  orderNumberSearchValue,
  emailSearchValue,
  mobileSearchValue,
  payerVPASearchValue,
  custRefNoSearchValue,
  mid,
  token,
  setTransactionReportData,
  setShowLoader
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      GET_LIVE_TXN_REPORT_ADVANCE_FILTER,
      {
        amount: "",
        bankLocation: "",
        custIpAddress: "",
        custLocation: "",
        custRefNo: custRefNoSearchValue ? custRefNoSearchValue.trim(" ") : "",
        email: emailSearchValue ? emailSearchValue.trim(" ") : "",
        endDate: "",
        ifsc: "",
        mid: mid,
        mobile: mobileSearchValue ? mobileSearchValue.trim(" ") : "",
        orderNo: orderNumberSearchValue ? orderNumberSearchValue.trim(" ") : "",
        paymentType: "",
        startDate: "",
        terminalId: "",
        txnDate: date,
        txnId: transactionIdSearchValue
          ? transactionIdSearchValue.trim(" ")
          : "",
        userVpa: payerVPASearchValue ? payerVPASearchValue.trim(" ") : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    setShowLoader(false);
    // console.log(response?.data?.responseData);
    if (response.data.statusCode === 200)
      setTransactionReportData(response?.data?.responseData);
    else setTransactionReportData([]);

    // setCustomApiResponse(response?.data?.responseData);
  } catch (error) {
    console.log(error);
  }
};

export const USE_SETTLEMENT_MULTIPLE_FILTER_API = async (
  endAmount,
  startAmount,
  vpa,
  orderNo,
  txnId,
  endPayinAmount,
  startPayinAmount,
  payinAmount,
  startDate,
  endDate,
  mid,
  token,
  setShowLoader,
  setSettlementReportData
  // startDate,
  // endDate,
) => {
  try {
    // alert(startDate)
    setShowLoader(true);
    const response = await axios.post(
      SETTLEMENT_MULTIPLE_FILTER_API,
      {
        endAmount: endAmount ? parseInt(endAmount) : 0,
        endDate: endDate,
        endPayinAmount: endPayinAmount ? parseInt(endPayinAmount) : 0,
        mid: mid,
        orderNo: orderNo ? orderNo : "",
        payinAmount: payinAmount ? parseInt(payinAmount) : 0,
        startAmount: startAmount ? parseInt(startAmount) : 0,
        startDate: startDate,
        startPayinAmount: startPayinAmount ? parseInt(startPayinAmount) : 0,
        txnId: txnId ? txnId : "",
        vpa: vpa ? vpa : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    setShowLoader(false);
    console.log(response?.data?.data, "jhgjgjhfhgcfgh");
    console.log(setSettlementReportData);
    setSettlementReportData(response?.data?.data ? response?.data?.data : []);
  } catch (error) {
    console.log(error);
  }
};

export const USE_CHARGEBACK_MULTIPLE_FILTER_API = async (
  amount,
  startAmount,
  endAmount,
  transactionDate,
  startDate,
  endDate,
  chargeBackUploadDate,
  startDate1,
  endDate1,
  email,
  mobile,
  payerVpa,
  rrn,
  transactionUid,
  mid,
  token,
  setCustomMultiApiResponse,
  setShowLoader
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      CHARGEBACK_MULTIPLE_FILTER_API,
      {
        chargeBackAmount: amount ? amount : "",
        startAmount: startAmount ? startAmount : "",
        endAmount: endAmount ? endAmount : "",
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
        transactionDate: transactionDate ? transactionDate : "",
        chargeBackUploadStartDate: startDate1 ? startDate1 : "",
        chargeBackUploadEndDate: endDate1 ? endDate1 : "",
        chargeBackUploadDate: chargeBackUploadDate ? chargeBackUploadDate : "",
        mid: mid,
        email: email ? email : "",
        mobile: mobile ? mobile : "",
        payerVpa: payerVpa ? payerVpa : "",
        rrn: rrn ? rrn : "",
        transactionUid: transactionUid ? transactionUid : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    setShowLoader(false);
    console.log(response?.data?.data, "chargeback - jhgjgjhfhgcfgh");
    setCustomMultiApiResponse(response?.data?.data ? response?.data?.data : []);
  } catch (error) {
    console.log(error);
  }
};

export const USE_LIVE_CHARGEBACK_MULTIPLE_FILTER_API = async (
  withOrWithoutMid,
  amount,
  startAmount,
  endAmount,
  transactionDate,
  startDate,
  endDate,
  chargeBackUploadDate,
  chargeBackUploadStartDate,
  chargeBackUploadEndDate,
  email,
  mobile,
  payerVpa,
  rrn,
  transactionUid,
  mid,
  token,
  setCustomMultiApiResponse,
  setShowLoader
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      withOrWithoutMid
        ? GET_ALL_DAILY_TRANSACTION_CHARGEBACK_
        : CHARGEBACK_MULTIPLE_FILTER_API,
      {
        chargeBackAmount: amount ? amount : "",
        startAmount: startAmount ? startAmount : "",
        endAmount: endAmount ? endAmount : "",
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
        transactionDate: transactionDate ? transactionDate : "",
        chargeBackUploadStartDate: chargeBackUploadStartDate ? chargeBackUploadStartDate : "",
        chargeBackUploadEndDate: chargeBackUploadEndDate ? chargeBackUploadEndDate : "",
        chargeBackUploadDate: chargeBackUploadDate ? chargeBackUploadDate : "",
        mid: mid,
        email: email ? email : "",
        mobile: mobile ? mobile : "",
        payerVpa: payerVpa ? payerVpa : "",
        rrn: rrn ? rrn : "",
        transactionUid: transactionUid ? transactionUid : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    setShowLoader(false);
    console.log(response?.data?.data, "chargeback - jhgjgjhfhgcfgh");
    setCustomMultiApiResponse(response?.data?.data ? response?.data?.data : []);
  } catch (error) {
    console.log(error);
  }
};

export const USE_PAYOUT_MULTIPLE_FILTER_API = async (
  amount,
  startAmount,
  endAmount,
  createDate,
  startDate,
  endDate,
  email,
  mobile,
  txnId,
  refId,
  rrn,
  txnStatus,
  transferType,
  upi,
  mid,
  token,
  setPayoutReportData,
  setShowLoader
  // startDate,
  // endDate,
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      PAYOUT_MULTIPLE_FILTER_API,
      {
        amount: amount ? amount : "",
        startAmount: startAmount ? startAmount : "",
        endAmount: endAmount ? endAmount : "",
        createDate: createDate ? createDate : "",
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
        email: email ? email : "",
        mobile: mobile ? mobile : "",
        txnId: txnId ? txnId : "",
        reference: refId ? refId : "",
        rrn: rrn ? rrn : "",
        txnStatus: txnStatus ? txnStatus : "",
        txnType: transferType,
        upi: upi ? upi : "",
        mid: mid,
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    setShowLoader(false);
    console.log(response?.data?.data, "chargeback - jhgjgjhfhgcfgh");
    setPayoutReportData(response?.data?.data ? response?.data?.data : []);
  } catch (error) {
    console.log(error);
  }
};

export const USE_LIVE_PAYOUT_MULTIPLE_FILTER_API = async (
  withOrWithoutMid,
  amount,
  startAmount,
  endAmount,
  createDate,
  startDate,
  endDate,
  email,
  mobile,
  txnId,
  refId,
  rrn,
  txnStatus,
  transferType,
  upi,
  mid,
  token,
  setPayoutReportData,
  setShowLoader
  // startDate,
  // endDate,
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
       !withOrWithoutMid?PAYOUT_MULTIPLE_FILTER_API:LIVE_TRANSACTION_PAYOUT_WITHOUT_MID ,
      {
        amount: amount ? amount : "",
        startAmount: startAmount ? startAmount : "",
        endAmount: endAmount ? endAmount : "",
        createDate: createDate ? createDate : "",
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
        email: email ? email : "",
        mobile: mobile ? mobile : "",
        txnId: txnId ? txnId : "",
        reference: refId ? refId : "",
        rrn: rrn ? rrn : "",
        txnStatus: txnStatus ? txnStatus : "",
        txnType: transferType,
        upi: upi ? upi : "",
        mid: mid,
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    setShowLoader(false);
    
    setPayoutReportData(response?.data?.data ? response?.data?.data : []);
  } catch (error) {
    console.log(error);
  }
};
export const USE_RECON_MULTIPLE_FILTER_API = async (
  amount,
  endAmount,
  endDate,
  mid,
  upi,
  rrn,
  startAmount,
  startDate,

  txnId,
  token,
  setReconReportData,
  setShowLoader
) => {
  console.log("JHKJHKJH---------");
  try {
    setShowLoader(true);
    const response = await axios.post(
      GET_ALL_RECON_DATA_BY_MULTIPLE_CONDITION,
      {
        amount: amount ? parseInt(amount) : 0,
        endAmount: endAmount ? parseInt(endAmount) : 0,
        endDate: endDate ? endDate : "",
        mid: mid,
        payerVpa: upi ? upi : "",
        rrn: rrn ? rrn : "",
        startAmount: startAmount ? parseInt(startAmount) : 0,

        startDate: startDate ? startDate : "",
        txnId: txnId ? txnId : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    setShowLoader(false);
    console.log(response?.data?.statusCode, "recon - jhgjgjhfhgcfgh");
    setReconReportData(
      response?.data?.statusCode === 200 ? response?.data?.data : []
    );
  } catch (error) {
    console.log(error);
  }
};

export const USE_WITHDRAW_MULTIPLE_FILTER_API = async (
  amount,
  startAmount,
  endAmount,
  startDate,
  endDate,
  createStartDate,
  createEndDate,

  txnDate,
  createDate,
  utr,
  status,
  mid,
  token,
  setShowLoader,
  setWithdrawlReportData
  // startDate,
  // endDate,
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      WITHDRAW_MULTIPLE_FILTER_API,
      {
        amount: amount ? parseInt(amount) : 0,
        startAmount: startAmount ? parseInt(startAmount) : 0,
        endAmount: endAmount ? parseInt(endAmount) : 0,
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
        createStartDate: createStartDate ? createStartDate : "",
        createEndDate: createEndDate ? createEndDate : "",
        mid: mid,
        txnDate: txnDate ? txnDate : "",
        createDate: createDate ? createDate : "",
        utr: utr ? utr : "",
        status: status ? status : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    setShowLoader(false);
    console.log(response, "jhgjgjhfhgcfgh");
    if (response?.data?.statusCode === 304) {
      setWithdrawlReportData([]);
      return;
    }
    setWithdrawlReportData(
      response?.data?.statusCode === 200 ? response?.data?.data : []
    );
  } catch (error) {
    console.log(error);
  }
};

export const USE_LIVE_WITHDRAW_MULTIPLE_FILTER_API = async (
  withOrWithoutMid,
  amount,
  startAmount,
  endAmount,
  startDate,
  endDate,
  createStartDate,
  createEndDate,

  txnDate,
  createDate,
  utr,
  status,
  mid,
  token,
  setShowLoader,
  setWithdrawlReportData
  // startDate,
  // endDate,
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      withOrWithoutMid
        ? LIVE_WITHDRAWL_MULTIPLE_FILTER_API
        : WITHDRAW_MULTIPLE_FILTER_API,
      {
        amount: amount ? parseInt(amount) : 0,
        startAmount: startAmount ? parseInt(startAmount) : 0,
        endAmount: endAmount ? parseInt(endAmount) : 0,
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
        createStartDate: createStartDate ? createStartDate : "",
        createEndDate: createEndDate ? createEndDate : "",
        mid: mid,
        txnDate: txnDate ? txnDate : "",
        createDate: createDate ? createDate : "",
        utr: utr ? utr : "",
        status: status ? status : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    setShowLoader(false);
    console.log(response, "jhgjgjhfhgcfgh");
    if (response?.data?.statusCode === 304) {
      setWithdrawlReportData([]);
      return;
    }
    setWithdrawlReportData(
      response?.data?.statusCode === 200 ? response?.data?.data : []
    );
  } catch (error) {
    console.log(error);
  }
};

export const USE_GURANTEE_MULTIPLE_FILTER_API = async (
  startGuarantyAmount,
  endGuarantyAmount,
  guarantyAmount,
  netAmount,
  startDate,
  endDate,
  transactionDate,
  utrNumber,
  mid,
  token,
  setGuranteeReportData,
  setShowLoader
  // startDate,
  // endDate,
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      GURANTEE_MULTIPLE_FILTER_API,
      {
        startGuarantyAmount: startGuarantyAmount ? startGuarantyAmount : "",
        endGuarantyAmount: endGuarantyAmount ? endGuarantyAmount : "",
        guarantyAmount: guarantyAmount ? guarantyAmount : "",
        netAmount: netAmount ? netAmount : "",
        startDate: startDate ? startDate : "",
        enddate: endDate ? endDate : "",
        transactionDate: transactionDate ? transactionDate : "",
        utrNumber: utrNumber ? utrNumber : "",
        mid: mid,
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    setShowLoader(false);
    console.log(response?.data?.data, "chargeback - jhgjgjhfhgcfgh");
    setGuranteeReportData(response?.data?.data ? response?.data?.data : []);
  } catch (error) {
    console.log(error);
  }
};

export const getAllmerchantPayoutmasters = async (token) => {
  try {
    const response = await axios.get(GET_ALL_MECHANT_PAYOUT_MASTERS, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const savePayoutMaster = async (token) => {
  try {
    const response = await axios.get(SAVE_MERCHANT_PAYOUT_MASTER, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const getAllRoutingView = async (token) => {
  try {
    const response = await axios.get(GET_ALL_ROUTING_VIEW, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const getWalletBalnceByMid = async (mid, token) => {
  try {
    const response = await axios.get(GET_WALLET_BALANCE_BY_MID + mid, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const disabledDataPayoutMaster = async (token) => {
  try {
    const response = await axios.get(DISABLED_DATA_PAYOUT_MASTER, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const fetchSIDDetails = async (token) => {
  try {
    const response = await axios.get(SID_DETAILS, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const getAllSubmittedPayoutRouting = async (token) => {
  try {
    const response = await axios.get(GET_ALL_SUBMITTED_PAYOUT_ROUTING, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const getAllDailyTransactionData = async (token) => {
  try {
    const response = await axios.get(GET_ALL_DAILY_TRANSACTION_DATA_BOX, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const getAllDailyTransactionDataTable = async (token) => {
  try {
    const response = await axios.get(GET_ALL_DAILY_TRANSACTION_DATA_TABLE, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const getAllDailyTransactionDataByMid = async (mid, token) => {
  try {
    const response = await axios.get(
      GET_ALL_DAILY_TRANSACTION_DATA_BOX_BY_MID + "/" + mid,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const getAllDailyTransactionDataTableByMid = async (mid, token) => {
  try {
    const response = await axios.get(
      GET_ALL_DAILY_TRANSACTION_DATA_TABLE_BY_MID + "/" + mid,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

// UPDATE MERCHANT BANK DETAILS
export const updateMerchantBankDetails = async (payload, token) => {
  try {
    const response = await axios.put(
      `${UPDATE_MERCHANT_BANK_DETAILS}`,
      payload,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

// verify account number and ifsc code
export const accountNumberIfscCodeVerifier = async (
  ifscCode,
  accountNumber
) => {
  try {
    const res = await axios.post(ACC_NUM_IFSC_VERIFY_API, {
      ifsc: ifscCode,
      accountNumber,
    });
    return res?.data;
  } catch (error) {
    throw new Error("Error occurred while fetching data");
  }
};

// get bank details by ifsc code
export const ifscCodeVerifier = async (ifscCode) => {
  try {
    const res = await axios.post(IFSC_VERIFY_API, { ifscCode });
    return { data: res?.data?.data, status: res?.data?.statusCode };
  } catch (error) {
    throw new Error("Error occurred while fetching data");
  }
};

export const FirstStageApproveAndSecondNotSubmitted = async (token) => {
  try {
    const response = await axios.get(
      `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/FirstStageApproveAndSecondNotSubmitted`,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    // console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const fetchAllOrganisationalType = async (token) => {
  try {
    const res = await axios.get(GET_ALL_ORGANISATIONAL_TYPE, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });

    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllOrganisationalTypeWithActive = async (token) => {
  try {
    const res = await axios.get(GET_ALL_ORGANISATIONAL_TYPE_WITH_ACTIVE, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllVehiclecommercialTypeWithActive = async (token) => {
  try {
    const res = await axios.get(GET_ALL_VEHICLE_COMMERCIAL_TYPE_WITH_ACTIVE, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllVehicleSettlementTypeWithActive = async (token) => {
  try {
    const res = await axios.get(GET_ALL_VEHICLE_SETTLEMENT_TYPE_WITH_ACTIVE, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllVehicleCommercialWithActive = async (token) => {
  try {
    const res = await axios.get(GET_ALL_VEHICLE_COMMERCIAL_WITH_ACTIVE, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllVehicleTypelWithActive = async (token) => {
  try {
    const res = await axios.get(GET_ALL_VEHICLE_TYPE_WITH_ACTIVE, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllVehicleSubTypelWithActive = async (token) => {
  try {
    const res = await axios.get(GET_ALL_VEHICLE_SUB_TYPE_WITH_ACTIVE, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const saveOrganisationalType = async (payload, token) => {
  try {
    const res = await axios.post(SAVE_ORGANISATIONAL_TYPE, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};
// verify merchant onboard email is pre verified or not
export const verifyMerchantEmailIsVerifiedOrNot = async (email) => {
  try {
    const response = await axios.post(`${VERIFY_MERCHANT_ONBOARD_EMAIL}`, {
      email,
    });
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

// verify merchant onboard mobile is pre verified or not
export const verifyMerchantMobileIsVerifiedOrNot = async (mobile) => {
  try {
    const response = await axios.post(`${VERIFY_MERCHANT_ONBOARD_MOBILE}`, {
      mobile,
    });
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const fetchSubmittedVehicleSubType = async (token) => {
  try {
    const response = await axios.get(SUBMITTED_VEHICLE_SUBTYPE, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const fetchSubmittedVehicleType = async (token) => {
  try {
    const response = await axios.get(SUBMITTED_VEHICLE_TYPE, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const fetchSubmittedVehicleCommercial = async (token) => {
  try {
    const response = await axios.get(SUBMITTED_VEHICLE_COMMERCIAL, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const saveVehicalCommercialType = async (payload, token) => {
  try {
    const res = await axios.post(SAVE_VEHICAL_COMMERCIAL_TYPE, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const saveVehicalSettlementType = async (payload, token) => {
  try {
    const res = await axios.post(SAVE_VEHICAL_SETTLEMENT_TYPE, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const fetchAllVehicalCommercialType = async (token) => {
  try {
    const res = await axios.get(GET_ALL_VEHICAL_COMMERCIAL_TYPE, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllVehicalSettlementType = async (token) => {
  try {
    const res = await axios.get(GET_ALL_VEHICAL_SETTLEMENT_TYPE, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllVehicleMasterForm = async (token) => {
  try {
    const res = await axios.get(GET_ALL_VEHICAL_MASTER_FORMS, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllActiveAndDisableVehicleMaster = async (token) => {
  try {
    const res = await axios.get(GET_ALL_ACTIVE_DISABLE_VEHICAL_MASTER, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const SaveVehicleMasterForm = async (payload, token) => {
  console.log(token);
  console.log(payload);
  try {
    const res = await axios.post(SAVE_VEHICLE_MASTER_FORM, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const SaveVehicleMasterUpdateForm = async (payload, id, token) => {
  console.log(token);
  console.log(payload);
  try {
    const res = await axios.put(
      `${SAVE_VEHICLE_MASTER_UPDATE_FORM}/${id}`,
      payload,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const SaveOrganisationalTypeUpdateForm = async (payload, id, token) => {
  console.log(token);
  console.log(payload);
  try {
    const res = await axios.put(
      `${SAVE_ORGANISATIONAL_TYPE_UPDATE_FORM}/${id}`,
      payload,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const getMechantForPayoutApiTest = async (token) => {
  try {
    const response = await axios.get(FETCH_ALL_MERCHANT_VALIDATIONS, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const FetchTemporaryRequest = async (token) => {
  try {
    const response = await axios.get(GET_ALL_TEMPORARY_REQUEST_DATA, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const getAllDailyTransactionDataPayout = async (token) => {
  try {
    const response = await axios.get(GET_ALL_DAILY_TRANSACTION_DATA_PAYOUT, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const getAllDailyTransactionDataPayoutByMid = async (mid, token) => {
  try {
    const response = await axios.get(
      GET_ALL_DAILY_TRANSACTION_DATA_PAYOUT_BY_MID + mid,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const getAllDailyTransactionDataChargebackByMid = async (mid, token) => {
  try {
    const response = await axios.get(
      GET_ALL_DAILY_TRANSACTION_CHARGEBACK_BY_MID + mid,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const getAllDailyTransactionDataWithdrawByMid = async (mid, token) => {
  try {
    const response = await axios.get(
      GET_ALL_DAILY_TRANSACTION_WITHDRAWL_BY_MID + mid,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const getAllDailyTransactionDataReconciliationByMid = async (
  mid,
  token
) => {
  try {
    const response = await axios.get(
      GET_ALL_DAILY_TRANSACTION_RECONCILIATION_BY_MID + mid,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const getAllDailyTransactionDataChargeback = async (token) => {
  try {
    const response = await axios.get(
      GET_ALL_DAILY_TRANSACTION_DATA_CHARGEBACK,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const getAllDailyTransactionDataWithdrawl = async (token) => {
  try {
    const response = await axios.get(GET_ALL_DAILY_TRANSACTION_DATA_WITHDRAWL, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const getAllDailyTransactionDataReconciliation = async (token) => {
  try {
    const response = await axios.get(
      GET_ALL_DAILY_TRANSACTION_DATA_RECONCILIATION,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const getMerchantInfoByIdPayout = async (token, mid) => {
  try {
    const response = await axios.get(
      CALLBACK_PAYOUT_BY_MID + mid,
      {
        headers: {
          Authentication: 'Bearer ' + token,
        },
      }
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const testCallbackURLPayout = async (token, callbackURL, jsonString) => {
  try {
    
    const response = await axios.post(
      CHECK_CALLBACK_FOR_PAYOUT,
      {
        callBackUrl: callbackURL,
        jsonString: jsonString,
      },
      {
        headers: {
          Authentication: 'Bearer ' + token,
        },
      }
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getMerchantInfoByIdPayin = async (token, mid) => {
  try {
    const response = await axios.get(
      CALLBACK_PAYIN_BY_MID + mid,
      {
        headers: {
          Authentication: 'Bearer ' + token,
        },
      }
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const testCallbackURLPayin = async (token, callBackUrl,merchantMid) => {
  try {
    const response = await axios.post(
      CHECK_CALLBACK_PAYIN,
      {
        callBackUrl: callBackUrl,
        mid:merchantMid
        },
      {
        headers: {
          Authentication: 'Bearer ' + token,
        },
      }
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllMerchantMaster = async (token) => {
  try {
    const res = await axios.get(GET_ALL_MERCHANT_MASTER, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllScheme = async (token) => {
  try {
    const res = await axios.get(GET_ALL_SCHEME, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllBank = async (token) => {
  try {
    const res = await axios.get(GET_ALL_BANK, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};


export const fetchAllSwitchData = async (token) => {
  try {
    const res = await axios.get(GET_ALL_SWITCH_DATA, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllAggeregatorData = async (token) => {
  try {
    const res = await axios.get(GET_ALL_AGGREGATOR_DATA, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const FetchTemporaryTxnReport = async (token) => {
  try {
    const response = await axios.get(GET_TXN_REPORT, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const disableVehicleMaster = async (token, regId) => {
  try {
    const res = await axios.put(`${DISABLE_VEHICLE_MASTER}/${regId}`,"" ,{
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};


export const enableVehicleMaster = async (token, regId) => {
  try {
    const res = await axios.put(`${ENABLE_VEHICLE_MASTER}/${regId}`,"" ,{
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};