import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { fetchFrmsubmittedFormByMid } from "../utils/ApiServices";
import swal from "sweetalert";

const ViewSingleFrmForm = ({ mid, setOpenView, openView }) => {
  const [responseData, setResponseData] = useState({});
  const [token, setToken] = useState(localStorage.getItem("token"));

  const [pdfFilenames, setPdfFilenames] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [viewDataClicked, setViewDataClicked] = useState(false);

  const FetchDataBymid = async (mid, token) => {
    try {
      const apiResponse = await fetchFrmsubmittedFormByMid(mid, token);
      if (apiResponse.statusCode === 200) {
        setResponseData(apiResponse?.data[0]);

        // setFinancialData(initializeFinancialData(responseData));
        console.log(apiResponse?.data[0]);
      } else {
        swal({
          title: "ALERT!",
          text: "Something went wrong",
          icon: "warning",
        });
        setOpenView(false);
      }
    } catch (error) {
      swal({
        title: "ALERT!",
        text: "Something went wrong",
        icon: "warning",
      });
      setOpenView(false);
    }
  };

  useEffect(() => {
    FetchDataBymid(mid, token);
  }, []);

  // useEffect(() => {
  //   // FetchDataBymid(mid, token);

  //   setFinancialData(initializeFinancialData(responseData));
  // }, [responseData]);

  // const initializeFinancialData = (responseData) => ({
  //   netSales: {
  //     firstYear: responseData.netSalesInFirstYear,
  //     secondYear: responseData.netSalesInSecondYear,
  //     thirdYear: responseData.netSalesInThirdYear,
  //   },
  //   netPurchase: {
  //     firstYear: responseData.netPurchaseInFirstYear,
  //     secondYear: responseData.netPurchaseInSecondYear,
  //     thirdYear: responseData.netPurchaseInThirdYear,
  //   },
  //   netProfit: {
  //     firstYear: responseData.netProfitInFirstYear,
  //     secondYear: responseData.netProfitInSecondYear,
  //     thirdYear: responseData.netProfitInthirdyear,
  //   },
  //   netExpenses: {
  //     firstYear: responseData.netExpensesInFirstyear,
  //     secondYear: responseData.netExpensesInSeconYear,
  //     thirdYear: responseData.netExpensesInThirdYear,
  //   },
  // });

  // const [financialData, setFinancialData] = useState(
  //   initializeFinancialData(responseData)
  // );

  //   useEffect(() => {
  //     FetchDataBymid(mid, token);
  //     setFinancialData(initializeFinancialData(responseData));
  //   }, []);

  //   const FetchDataBymid=async (mid,token)=>{
  //     try {
  //         const apiResponse =await  fetchAccountDetailsByMid(mid,token)
  //         if (apiResponse.statusCode === 200) {
  //               setResponseData(apiResponse?.data);
  //             console.log(apiResponse?.data);
  //         } else {
  //         }
  //     } catch (error) {
  //         // console.error(error);
  //        console.log("Something went wrong. Please try again.");
  //     }
  //   }

  // const handleViewData =async (mid,prev) => {
  //   try {
  //       const apiResponse = await fetchDocsByMidForaccounts(mid, token);
  //       if (apiResponse.statusCode === 200) {
  //           setPdfFilenames(apiResponse.data);
  //           setViewDataClicked((prev) => !prev);

  //       } else {
  //       }
  //     } catch (error) {
  //       // console.error(error);
  //       console.log("Something went wrong. Please try again.");
  //     }
  // };

  // const handleViewPDF = (filename) => {
  //   const encodedFilename = encodeURIComponent(filename);
  //   const headers = {
  //     Authentication: `Bearer ${token}`,
  //   };

  //   axios
  //     .get(
  //       `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/view-pdf/${encodedFilename}`,
  //       {
  //         responseType: "arraybuffer",
  //         headers,
  //       }
  //     )
  //     .then((response) => {
  //       const pdfBlob = new Blob([response.data], { type: "application/pdf" });
  //       const pdfUrl = URL.createObjectURL(pdfBlob);
  //       window.open(pdfUrl, "_blank");
  //       // setSelectedFile(pdfUrl);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching PDF:", error.message);
  //     });
  // };

  return (
    <>
      <section className="admin-view-details">
        <Container>
          <Card className="shadow border-0 mb-4 ds-header-card">
            <Card.Body>
              <Row>
                <Col xs={12} xl={12} lg={12} sm={12}>
                  <div className="two-column-layout">
                    <div className="vertical-table mb-4">
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Merchant Name
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.companyName}
                        </div>
                      </div>

                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">MID</div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.mid}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          e-Mail ID
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.email}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Location
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.location}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Type Of Bussiness
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.typeOfBusiness}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Website Analysis
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.websiteAnalysis}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          App Analysis
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.appAnalysis}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Director Profile
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.directorProfile}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Company Profile
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.companyProfile}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Bussiness Profile
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.businessProfile}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Chargeback Probability
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.chargebackProbability}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Bank Approval Probability
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.bankApprovalProbability}
                        </div>
                      </div>

                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Ticket Size
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.ticketSize}
                        </div>
                      </div>

                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Volume
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.volume}
                        </div>
                      </div>

                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Number Of Ticket
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.numberOfTickets}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="d-flex flex-end">
                <Col>
                  <div className="button-toolbar groups-btn text-end">
                    <button
                      className="btn btn-primary"
                      onClick={() => setOpenView(false)}
                    >
                      Go Back
                    </button>
                  </div>
                </Col>
              </Row>
              <Row></Row>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
};

export default ViewSingleFrmForm;
