import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Files, PencilSquare, Trash } from 'react-bootstrap-icons';
import DeleteModal from '.././DeleteModal';
import { MDBDataTable } from 'mdbreact';
import '.././assets/css/style.css';
import Papa from 'papaparse';
import { FiDownload } from "react-icons/fi";
import { Calendar } from 'react-calendar';
import { SWIPELINC_API } from '../utils/constant';

const SettlementFile = () => {
  const [files, setFiles] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  // const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
 
//  const [isAdvancedFilterOpen, setIsAdvancedFilterOpen] = useState(false);
 
  const [token, setToken] = useState(localStorage.getItem("token"));

  

  useEffect(() => {
    fetchSettlementFiles();
  }, []);

  const fetchSettlementFiles = async () => {
    
    try {
      const response = await axios.get(`${SWIPELINC_API}MerchantAdminPanel/admin/settlement`, {
        headers: {
          Authentication: `Bearer ${token}`,
        }
      })
      console.log(response.data);
      if (Array.isArray(response.data.data)) {
        setFiles(response.data.data);
       
      } else {
        console.error('Response data is not an array:', response.data);
      }
    } catch (error) {
      console.error('Error fetching settlement files:', error);
    }
  };

  const handleUpdate = async (editedFile) => {
    try {
 
      const response = await axios.put(
        `${SWIPELINC_API}MerchantAdminPanel/settlement/update/${editedFile.id}`,
        editedFile, {
        headers: {
          Authentication: `Bearer ${token}`

        }
      }
      )
      console.log('File updated:', response.data);

      // Update the corresponding file in the state
      const updatedFiles = files.map((f) => {
        if (f.id === editedFile.id) {
          return response.data;
        }
        return f;
      });

      setFiles(updatedFiles);
    } catch (error) {
      console.error('Error updating file:', error);
    }
  };


  const handleDelete = async (file) => {
    if (openModal) {
      try {
    
        const response = await axios.delete(
          `${SWIPELINC_API}MerchantAdminPanel/settlement/delete/${file.id}`, {
          headers: {
            Authentication: `Bearer ${token}`
          }
        }
        );
        console.log('File deleted:', response.data);
        setDeleteSuccess(true);
      } catch (error) {
        console.error('Error deleting file:', error);
      }
    }
    else {
      setOpenModal(true)
    }
    
  };
  // TO ADD SEARCH BOX:
  // const handleSearch = (searchQuery) => {
  //   const trimmedSearchTerm = searchQuery.trim(); 

  //   const filtered = files.filter((item) =>
  //     Object.values(item).some((value) => {
  //       if (value === null) {
  //         return false; // Skip null values
  //       }
  //       return value.toString().toLowerCase().includes(trimmedSearchTerm.toLowerCase());
  //     })
  //   );
  //   setfilteredData(filtered);
  //   setCurrentPage(1);
  // }

 
  // ADD ADVANCED FILTER:
  // const fetchDateReport = async () => {
  //   function formatDateToYYYYMMDD(inputDate) {
  //     const date = new Date(inputDate);
      
  //     const year = date.getFullYear();
  //     const month = String(date.getMonth() + 1).padStart(2, '0');
  //     const day = String(date.getDate()).padStart(2, '0');
      
  //     return `${year}-${month}-${day}`; 
  //   }
  //   const sdate=formatDateToYYYYMMDD(startDate);
  
  //   const ldate=formatDateToYYYYMMDD(endDate);
  //   console.log(sdate+"date is")
  //   try {
      
  //     const payload = {
        
  //       "endDate": "",
  //       "settledAmount": 0,
  //       "settlementDate": "",
  //       "startDate": "",
  //       "utrNumber": 0

  //     };

  //     const response = await axios.post(
  //       `${SWIPELINC_API}MerchantAdminPanel/settlement/ByCondition`,
  //       payload,
  //       {
  //         headers: {
  //           Authentication: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     if (response.data.statusCode === 200 && Array.isArray(response.data.data)) {
  //       setFiles(response.data.data)
  //       setfilteredData(response.data.responsedata);
  //     } else {
  //       console.error('No data found:', response.data.data);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching chargeback data:', error);
  //   }
  // };

  // const handleAdvancedFilter = (filterType,filterValue) => {
    
  //   const trimmedFilterValue = filterValue.trim();
   

  //   const filtered = files.filter((item) => {
  //     if (!trimmedFilterValue) {
  //       return true;
  //     }

  //     if (
  //       filterType === 'settledAmount' || filterType === 'utrNumberOfSettlement' || filterType === 'settlementDate'
      
  //      )
  //        {
  //       const value = String(item[filterType]);
  //       return value && value.toLowerCase().includes(trimmedFilterValue.toLowerCase());
  //     }

  //     return true;
  //   });

  //   setfilteredData(filtered);
  //   setCurrentPage(1);
  // };

  // const handleResetFilters = () => {
  //  setResetFilters(true)
  //  setfilteredData(files);
  //   setCurrentPage(1);
  //   setSelectedFilter(null);
  //   setFilterValue('');
  // };

  // const toggleAdvancedFilterModal = () => {
  //   const buttonRect = document.getElementById('advanced-filter-button').getBoundingClientRect();
  //   setFilterButtonPosition({ top: buttonRect.bottom + window.scrollY, left: buttonRect.left + window.scrollX });
  //   setIsAdvancedFilterModalOpen(!isAdvancedFilterModalOpen);
  // };

// HANDLE DOWNLOAD:
const handleDownloadCSV = () => {
  const table = document.getElementById('settlement-datatable-container');
  const visibleRows = table.querySelectorAll('.dataTable tbody tr');

  const visibleData = [];

  visibleRows.forEach(row => {
      const rowData = [];
      row.querySelectorAll('td').forEach(cell => {
          rowData.push(cell.textContent.trim());
      });
      visibleData.push(rowData);
  });

  const headerLabels = columns.map(column => column.label);
  const dataWithHeader = [headerLabels, ...visibleData];
  
  const csv = Papa.unparse(dataWithHeader);

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'settlement_report.csv';
  link.style.display = 'none';

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
};

  const handleEdit = (file) => {
    setSelectedFile(file);
    setEditFormOpen(true);
  };
  // mdb data table:
  const columns = [
    {label: 'Merchant ID',field: 'mid', width:'auto'},
    {label: 'Net Amount',field: 'netAmount', width:'auto'},
    {label: 'Order Number',field: 'orderNo', width:'auto'},
    {label: 'Transaction ID',field: 'txnId', width:'auto'},
    {label: 'VPA',field: 'vpa', width:'auto'},
    {label: 'GST',field: 'gst', width:'auto'},
    {label: 'Service Charge',field: 'serviceChanrge',  width:'auto'},
    {label: 'Service Charge Amount',field: 'serviceChargeAmount', width:'auto'},
    {label: 'GST Amount',field: 'gstAmount', width:'auto'},
    {label: 'Remark',field: 'remark', width:'auto'},
    {label: 'Payin Amount',field: 'payinAmount', width:'auto'},
    {label: 'Transaction Status',field: 'txnStatus', width:'auto'},
    {label: 'Collection Method',field: 'collectionMethod', width:'auto'},
    {label: 'Terminal Id',field: 'terminalId', width:'auto'},
   
    // {label: 'Edit',field: 'edit', width:'auto'},
    // {label: 'Delete',field: 'delete', width:'auto'},
  ];

  const mappedData = files?.map((item) => {
    const mappedItem = {};
    columns.forEach((column)=>{
      mappedItem[column.field]=item[column.field]
    })
    return mappedItem;
  })
      

  return (
    <>
      <main className="dashboardPage">
        <section className="reportSection">
          <div className="container">

      <div className="tabs-content active " id="tab1">
      <div className="card py-3">
    
        {deleteSuccess && ( // Display success message if deleteSuccess is true
          <div className="alert alert-success" role="alert">
            Record deleted successfully!
          </div>
        )}
       {openModal && <DeleteModal closeModal={setOpenModal} />}
       <div className="search-container">
       {/* <div className="search-box">
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      handleSearch(e.target.value);
                    }}
                  />
                  </div> */}
   {/* DATE FILTER: */}
   <div className="filter-container">
                  {/* <div className="date-filter">
                    <button
                      className={`date-filter-button ${isDateFilterOpen ? 'active' : ''}`}
                      onClick={() => setIsDateFilterOpen(!isDateFilterOpen)}
                    >
                      Date Filter
                    </button>
                    {isDateFilterOpen && (
                      <div className="date-filter-modal">

      <div className="date-filter-content">
        <Calendar
          onChange={date => setStartDate(date)}
          value={startDate}
          selectRange={false}
        />
        <Calendar
          onChange={date => setEndDate(date)}
          value={endDate}
          selectRange={false}
        />
        <button className='advanced-filterbutton' onClick={() => {fetchDateReport(); setIsDateFilterOpen(false); }}>OK</button>
      </div>
    </div>
  )}
</div> */}
                {/* ADVANCED FILTER */}
                {/* <div className="advanced-filters">
                <button id="advanced-filter-button" className='advanced-filterbutton' onClick={toggleAdvancedFilterModal}> Advanced Filter</button>

            {isAdvancedFilterModalOpen && (
            <div className="advanced-filter-modal"
           style={{ top: filterButtonPosition.top, left: filterButtonPosition.left }}>

                <select value={selectedFilter} 
                onChange={(e) => setSelectedFilter(e.target.value)}
                >
                      <option value="">Select</option>
                      <option value="settledAmount">Settled Amount</option>
                      <option value="utrNumberOfSettlement">UTR Number</option>
                      <option value="settlementDate">Settlement Date</option>
                     
               </select>

                    {selectedFilter && (
                    <input
                      type="text"
                      placeholder={`Enter ${selectedFilter}`}
                      value={filterValue}
                      onChange={(e) => setFilterValue(e.target.value)}
                    />)}
      <div className='advreset'>
     <button className='advanced-filterbutton' onClick={handleResetFilters}>Reset </button> 
     <button className='advanced-filterbutton' onClick={() => handleAdvancedFilter(selectedFilter, filterValue)}>Apply</button>
   </div>
    </div>
                )}
              </div> */}
              </div>
              </div>
              {/* MDB DATA TABLE: */}
              <div id = "settlement-datatable-container">
              <div className="text-left">
 <MDBDataTable className=' dataTable overflow-x-scroll' 
                  striped
                  bordered
                  hover
                  responsive
                  data={{
                    columns: columns,
                    rows: mappedData,
                  }}
                  searching={true}
                  entries={50}
                  paginationLabel={['Previous', 'Next']}
                  sortable={true}
                  footer = "none"
                  exportToCSV
                  theadColor='#333'
                />
                </div>
          <button className="advanced-btn btn btn-primary text-capitlize" onClick={handleDownloadCSV}>
              <FiDownload className="d-none" /><i className="bi bi-arrow-down-circle"></i> Download CSV
           </button>
             </div>
               
              </div>
            </div>
          </div>
        </section>
      </main>


      {editFormOpen && (
        <EditForm
          file={selectedFile}
          onSubmit={(editedFile) => {
            handleUpdate(editedFile);
            setEditFormOpen(false);
          }}
          onClose={() => setEditFormOpen(false)}
        />
      )}
    </>
  );
}

function EditForm({ file, onSubmit, onClose }) {
  const [editedFile, setEditedFile] = useState({ ...file });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(editedFile);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedFile((prevFile) => ({ ...prevFile, [name]: value }));
  };

  return (
    <div className="edit-form">
      <h2>Settlement Edit Report</h2>
      <form onSubmit={handleSubmit} className="form">

        <div className="form-row">
          <label>Collection Date:</label>
          <input type="text" name="collectionDate" value={editedFile.collectionDate} onChange={handleChange} />
        </div>
        <div className="form-row">
          <label>CollectionMethod:</label>
          <input type="text" name="collectionMethod" value={editedFile.collectionMethod} onChange={handleChange} />
        </div>
        <div className="form-row">
          <label>TerminalID:</label>
          <input type="text" name="terminalId" value={editedFile.terminalId} onChange={handleChange} />
        </div>
        <div className="form-row">
          <label>Total Count:</label>
          <input type="text" name="totalCount" value={editedFile.totalCount} onChange={handleChange} />
        </div>
        <div className="form-row">
          <label>Total Collection:</label>
          <input type="text" name="totalCollection" value={editedFile.totalCollection} onChange={handleChange} />
        </div>
        <div className="form-row">
          <label>Service Charge:</label>
          <input type="text" name="serviceCharge" value={editedFile.serviceCharge} onChange={handleChange} />
        </div>
        <div className="form-row">
          <label>GSTOnCharge:</label>
          <input type="text" name="gstOnCharge" value={editedFile.gstOnCharge} onChange={handleChange} />
        </div>
        <div className="form-row">
          <label>ChargebackAmount:</label>
          <input type="text" name="chargeBackAmount" value={editedFile.chargeBackAmount} onChange={handleChange} />
        </div>
        <div className="form-row">
          <label>PayableAmount:</label>
          <input type="text" name="payableAmount" value={editedFile.payableAmount} onChange={handleChange} />
        </div>
        <div className="form-row">
          <label>SecurityDeposit:</label>
          <input type="text" name="securityDeposit" value={editedFile.securityDeposit} onChange={handleChange} />
        </div>
        <div className="form-row">
          <label>AmountOnHold:</label>
          <input type="text" name="amountOnHold" value={editedFile.amountOnHold} onChange={handleChange} />
        </div>
        <div className="form-row">
          <label>SettlementDate:</label>
          <input type="text" name="settlementDate" value={editedFile.settlementDate} onChange={handleChange} />
        </div>
        <div className="form-row">
          <label>UTRNumberOf Settlement:</label>
          <input type="text" name="utrNumberOfSettlement" value={editedFile.utrNumberOfSettlement} onChange={handleChange} />
        </div>
        <div className="form-row">
          <label>Remaining Balance:</label>
          <input type="text" name="totalRemainingBalance" value={editedFile.totalRemainingBalance} onChange={handleChange} />
        </div>
        {/* <div className="form-row">
          <label>IsDelete:</label>
          <input type="text" name="isDelete" value={editedFile.isDelete} onChange={handleChange} />
        </div> */}
        <div className="form-row">
          <label>MID:</label>
          <input type="text" name="mid" value={editedFile.mid} onChange={handleChange} />
        </div>
        <div className="form-row">
          <label>SettledAmount:</label>
          <input type="text" name="settledAmount" value={editedFile.settledAmount} onChange={handleChange} />
        </div>
        <div>
          <button type="submit" className='rprtbtn1'>Save</button>
          <button type="button" onClick={onClose} className='rprtbtn2'> Cancel </button>
        </div>
      </form>
      {/* <form onSubmit={handleSubmit}>
    
    <div className="form-row">
        <label>Collection Date:</label>
       <input type="text" name="collectionDate" value={editedFile.collectionDate} onChange={handleChange} />

       <label>CollectionMethod:</label>
       <input type="text" name="collectionMethod" value={editedFile.collectionMethod} onChange={handleChange} />
      
       <label>TerminalID:</label>
       <input type="text" name="terminalId" value={editedFile.terminalId} onChange={handleChange} />
     
       <label>Total Count:</label>
       <input type="text" name="totalCount" value={editedFile.totalCount} onChange={handleChange} />
     
       <label>Total Collection:</label>
       <input type="text" name="totalCollection" value={editedFile.totalCollection} onChange={handleChange} />
     
       <label>Service Charge:</label>
       <input type="text" name="serviceCharge" value={editedFile.serviceCharge} onChange={handleChange} />
      
       <label>GSTOnCharge:</label>
       <input type="text" name="gstOnCharge" value={editedFile.gstOnCharge} onChange={handleChange} />
     
       <label>ChargebackAmount:</label>
       <input type="text" name="chargeBackAmount" value={editedFile.chargeBackAmount} onChange={handleChange} />
      
       <label>PayableAmount:</label>
       <input type="text" name="payableAmount" value={editedFile.payableAmount} onChange={handleChange} />
    
       <label>SecurityDeposit:</label>
      <input type="text" name="securityDeposit" value={editedFile.securityDeposit} onChange={handleChange} />
     
      <label>AmountOnHold:</label>
      <input type="text" name="amountOnHold" value={editedFile.amountOnHold} onChange={handleChange} />
      
      <label>SettlementDate:</label>
      <input type="text" name="settlementDate" value={editedFile.settlementDate} onChange={handleChange} />
      
      <label>UTRNumberOf Settlement:</label>
       <input type="text" name="utrNumberOfSettlement" value={editedFile.utrNumberOfSettlement} onChange={handleChange} />
     
       <label>Remaining Balance:</label>
       <input type="text" name="totalRemainingBalance" value={editedFile.totalRemainingBalance} onChange={handleChange} />
     
       <label>IsDelete:</label>
       <input type="text" name="isDelete" value={editedFile.isDelete} onChange={handleChange} />
     
       <label>MID:</label>
       <input type="text" name="mid" value={editedFile.mid} onChange={handleChange} />
     
       <label>SettledAmount:</label>
       <input type="text" name="settledAmount" value={editedFile.settledAmount} onChange={handleChange} />
     
       <button type="submit">Save</button>
      <button type="button" onClick={onClose}> Cancel </button>
      </div>
    </form> */}
    </div>
  );
}
export default SettlementFile;
