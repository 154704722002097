import React, { useEffect, useState } from "react";
import axios from "axios"; 
import { useNavigate,useLocation } from "react-router-dom"; 
import { SWIPELINC_API } from "../utils/constant";

const ViewRejected = ({setShowLoader}) => {
  const [applications, setApplications] = useState([]);
  const [rejectionRemark, setRejectionRemark] = useState("");
  const token = localStorage.getItem("token");
  const location = useLocation
  const navigate = useNavigate();

  useEffect(() => {

    const params = new URLSearchParams(location.search);
    const remark = params.get("rejectionRemark");
    setRejectionRemark(remark || ""); // Set the state with the rejection remark if available

    

    // Create Axios instance with the default header
    const axiosInstance = axios.create({
      baseURL:  `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard`,
      headers: {
        Authentication: `Bearer ${token}`,
      },
    });
    
  setShowLoader(true)
    axiosInstance
      .get("/getallmerchant/rejected",{
        params:{
          applicationStatus:"rejected"
        }
      })
      .then((response) => {
        if(response.data.statusCode === 200){
          console.log("Applications:", response.data.data);
          setApplications(response.data.data);
        }
        else if (response.data.statusCode === 304){
          console.log("NO APPLICATIONS FOUND:", response.data.data);
          setApplications (response.data.data);
        }
        setShowLoader(false)
       })
      .catch((error) => {
        console.error("Error fetching applications:", error.message);
        setShowLoader(false)
      });
  }, [location.search]);

  
  const handleViewDetails = (registrationId) => {
   
    navigate(`/ViewFile1/${registrationId}`)
  };

  return (
    <>
      {/* <Header /> */}

      <div className="report-grid-view">
      <h2>Rejected Applications</h2>
      <div className="table-responsive">
      <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                    <th>Company Name</th>
                    <th>Authorized Person Name</th>
                    <th>e-Mail ID</th>
                    <th>Mobile Number</th>
                    <th>Company PAN Number</th>
                    <th>GST Number</th>
                    <th>Rejection Remark</th>
                    <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {applications? (applications.map((application) => (
                      <tr key={application.registrationId}>
                         <td>{application.companyName}</td>
                        <td>{application.authorizedPersonName}</td>
                        <td>{application.email}</td>
                        <td>{application.mobile}</td>
                       <td>{application.companyPanNumber}</td>
                        <td>{application.gstNumber}</td>
                        <td>{application.rejectionRemark}</td>
                      <td>
                          <button className="btn btn-primary btn-sm"
                            type="button"
                            onClick={() => handleViewDetails(application.registrationId)}
                          >
                            View Details
                          </button>
                        </td>
                      </tr>
                    ))
                  ):(
                    <tr>
                      <td colSpan= "8">NO DATA FOUND</td>
                    </tr>
                  )}
                  </tbody>
                </table>
      </div>
      </div>
      
    </>
  );
};
export default ViewRejected;