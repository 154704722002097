import React, { useEffect, useState } from "react";
import PayoutRouting from "../../../Merchant/MerchantManagement/PayoutRouting";
import InactiveServices from "../../InactiveServices";
import { Row, Col, Card, Container } from "react-bootstrap";
import PayInRouting from "../../../Merchant/MerchantManagement/PayInRouting";
import RoutingSubTabForPayin from "./RoutingSubTabForPayin";
import RoutingSubTabForPayout from "./RoutingSubTabForPayout";

const RoutingSubtab = ({setShowLoader}) => {
    const [activePhase, setActivePhase] = useState("Phase1");
   
  
    useEffect(() => {
      setActivePhase("Phase1");
     
    }, []);
  
    const handlePhaseClick = (phase) => {
      setActivePhase(phase);
    };
    return(
        <>
        <div className="subtab-sec-admin">
      
      <Container>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <Card className="shadow border-0 mb-4">
              <div className="merchant-tab-btn">
                <ul className="report-link nav-report-btn">
                  <li
                    className={activePhase === "Phase1" ? "active" : ""}
                    onClick={() => handlePhaseClick("Phase1")}
                  >
                     Payin Routing
                  </li>
                  <li
                    className={activePhase === "Phase2" ? "active" : ""}
                    onClick={() => handlePhaseClick("Phase2")}
                  >
                   Payout Routing
                  </li>
                
                  <li
                    className={activePhase === "Phase3" ? "active" : ""}
                    onClick={() => handlePhaseClick("Phase3")}
                  >
                   MATM
                  </li>
                  <li
                    className={activePhase === "Phase4" ? "active" : ""}
                    onClick={() => handlePhaseClick("Phase4")}
                  >
                   DCP
                  </li>
                  <li
                    className={activePhase === "Phase5" ? "active" : ""}
                    onClick={() => handlePhaseClick("Phase5")}
                  >
                   DMT
                  </li>
                  <li
                    className={activePhase === "Phase6" ? "active" : ""}
                    onClick={() => handlePhaseClick("Phase6")}
                  >
                   AEPS
                  </li>
                  <li
                    className={activePhase === "Phase7" ? "active" : ""}
                    onClick={() => handlePhaseClick("Phase7")}
                  >
                   IPG
                  </li>
                  <li
                    className={activePhase === "Phase8" ? "active" : ""}
                    onClick={() => handlePhaseClick("Phase8")}
                  >
                   NET BANKING
                  </li>
                  <li
                    className={activePhase === "Phase9" ? "active" : ""}
                    onClick={() => handlePhaseClick("Phase9")}
                  >
                   REMMITTANCE
                  </li>
                  <li
                    className={activePhase === "Phase10" ? "active" : ""}
                    onClick={() => handlePhaseClick("Phase10")}
                  >
                   SOFTPOS
                  </li>
                  <li
                    className={activePhase === "Phase11" ? "active" : ""}
                    onClick={() => handlePhaseClick("Phase11")}
                  >
                   Virtual Terminal
                  </li>
                 
                  <li
                    className={activePhase === "Phase12" ? "active" : ""}
                    onClick={() => handlePhaseClick("Phase12")}
                  >
                   e-KYC
                  </li>
                  <li
                    className={activePhase === "Phase13" ? "active" : ""}
                    onClick={() => handlePhaseClick("Phase13")}
                  >
                   POS
                  </li>
                </ul>
              </div>
              <Card.Body>
                <div className="admin-tab-content">
                  {activePhase === "Phase1" ? (
                    <RoutingSubTabForPayin
                    setShowLoader = {setShowLoader}/>
                  //  <InactiveServices />
                  ) : activePhase === "Phase2" ? (
                    // <InactiveServices />
                    <RoutingSubTabForPayout
                    setShowLoader={setShowLoader}/>
                  // <InactiveServices />

                  ) : (
                    <InactiveServices />
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  </>
    
    )
}
export default RoutingSubtab;