import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MDBDataTable } from 'mdbreact';
import "../assets/css/style.css";
import { SWIPELINC_API } from '../utils/constant';

const Ledger = ({setShowLoader}) => {
  const [ledgerData, setLedgerData] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));

  // useEffect(() => {
   
  //   axios
  //     .get(`${SWIPELINC_API}`, {
  //       headers: {
  //         Authentication: `Bearer ${token}`
  //       }
  //     })
  //     .then((response) => {
  //       if (response.data.statusCode === 200 && Array.isArray(response.data.data)) {
  //         setLedgerData(response.data.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching ledger data:', error);
  //     });
  // }, []);

  // Define the columns for the MDBDataTable for Ledger
  const columns = [
    { label: 'Date', field: 'Date', width: 'auto' },
    { label: 'Time', field: 'Time', width: 'auto' },
    { label: 'Transaction Id', field: 'To Customer/Vendor Name', width: 'auto' },
    { label: 'Credit', field: 'Particular(A/C or VPA)', width: 'auto' },
    { label: 'Debit', field: 'Amount', width: 'auto' },
    { label: 'RRN', field: 'RRN', width: 'auto' },
 
  
  ];

  const mappedData = ledgerData.map((item) => {
    const mappedItem = {};
    columns.forEach((column) => {
      mappedItem[column.field] = item[column.field];
    });
    return mappedItem;
  });

  return (
    <>
      <main className="">
        <div className="reportSection">
          <div className="">
            <div className="tabs-content active" id="tab1">
              <div className="card py-3">
                <div id="Ledger-datatable-container">
                <div className="text-left">
                  <MDBDataTable
                    className='dataTable overflow-x-scroll p-5'
                    hover
                    striped
                    small
                    bordered
                    responsive
                    sortable={true}
                    searching={true}
                    entries={10}
                    data={{
                      columns: columns,
                      rows: mappedData
                    }}
                    footer="none"
                    theadColor='#333'
                    paginationLabel={['Previous', 'Next']}
                  />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Ledger;
