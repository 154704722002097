import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { SWIPELINC_API } from "../utils/constant";
import swal from "sweetalert";


const VehicleTypeForm = ({setShowLoader}) => {
  const [data, setData] = useState({
    mdrPercentage:'',
    paymentService: '',
   
  });

  const [token, setToken] = useState(localStorage.getItem('token'));

  const handleSubmit = (e) => {
    e.preventDefault();

    if (data.paymentService === '' || data.mdrPercentage === '') {
      swal({
        title: 'Alert!',
        text: 'Please fill in both fields',
        icon: 'error',
      });
      return;
    }

    setShowLoader(true);
    axios
      .post(`${SWIPELINC_API}MerchantAdminPanel/merchant/paymentType/savePaymentType`, data, {
        headers: {
          Authentication: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response);

        if (response.data.statusCode === 200) {
          swal({
            title: 'Success!',
            text: response.data.message,
            icon: 'success',
          });
          setData({
            mdrPercentage:'',
            paymentService: '',
           
        }); 
        }
        else if(response.statusCode=== 309) {
          swal({
            title: "Alert",
            text: "Service already exists",
            icon: "alert"
          })
        } else {
          swal({
            title: 'Alert!',
            text: response.data.message,
            icon: 'error',
          });
        }
        setShowLoader(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setShowLoader(false);
      });
  };

  const handleInputChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <section className="lincpay-dashboard-sec merchant-admin-info-form company-add-form">
      <div className="cart-view-sec mt-4">
        <Row>
          <Col xs={12} lg={12} md={12}>
            <Card className="shadow-cst border-0 mb-3 chart-boxes merchant-info-form-sec">
              <Card.Body>
                <div className="merchant-form-info">
                  <form className="form-section-form-group">
                    <Row className="d-flex flex-column gap-4">
                  
                      <Col xs={12} lg={6} md={6}>
                        <div className="form-group">
                          <label htmlFor="paymentService">Payment Service</label>
                          <input
                            type="text"
                            className="form-control"
                            id="paymentService"
                            name="paymentService"
                            placeholder="Enter Payment Service"
                            value={data.paymentService}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col xs={12} lg={6} md={6}>
                        <div className="form-group">
                          <label htmlFor="mdrPercentage">MDR Percentage  <span style={{ color: 'red', fontSize: 'small' }}>(minimum MDR percentage for service)</span></label>
                          <input
                            type="number"
                            className="form-control"
                            id="mdrPercentage"
                            name="mdrPercentage"
                            placeholder="Enter MDR Percentage"
                            value={data.mdrPercentage}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                   
                    </Row>
                    <Col xs={12} lg={6} md={6}>
                      <div className="d-flex justify-content-end">
                        <div className="form-group tool-bar-btn mb-0 mt-3">
                          <button
                            className="submit-button submit_link_info btn btn-success"
                            onClick={handleSubmit}
                            id="submitDetailsBtn"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Col>
                  </form>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default VehicleTypeForm