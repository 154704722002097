import React, {useState } from "react";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { SaveOrganisationalTypeUpdateForm } from "../utils/ApiServices";

const OrganisationalTypeEdit = ({
  handleCloseModal,
  isModalOpen,
  data,
  setData,
  id,
}) => {
  console.log(data, id);
  
  const [token, setToken] = useState(localStorage.getItem("token"));

  

  const UpdateOrganisationalType = async () => {
    if (!data) {
      swal({
        title: "Alert!",
        text: "Please Enter Organisational Type.",
        icon: "error",
      });
      return;
    }

    swal({
      title: "Alert!",
      text: "Organisational Type Updated SuccessFully.",
      icon: "error",
    });

   

    try {
      const response = await SaveOrganisationalTypeUpdateForm({ type:data },id, token);

      if (response.statusCode === 200) {
        swal({
          title: "Success",
          text: "Saved Successfully",
          icon: "success",
        });
        setData("");
        handleCloseModal()
      } else if (response.statusCode === 309) {
        swal({
          title: "Alert",
          text: "Service already exists",
          icon: "alert",
        });
      } else {
        swal({
          title: "Alert",
          text: "Failed to save organisational type.",
          icon: "warning",
        });
      }
    } catch (error) {
      console.error("Error saving organisational type:", error);
    }
  };

  return (
    <Modal show={isModalOpen} onHide={handleCloseModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Organisational Type:</Modal.Title>
      </Modal.Header>
      <Modal.Body className="w-100  ">
        <div className="containerflag">
          <div className=" ">
            <div className=" ">
              <div className="col-sm-12 col-md-6 offset-md-3">
                <label htmlFor="email">Organisational Type</label>
                <input
                  id="vehicleName"
                  className="form-control"
                  value={data}
                  onChange={(e) => setData(e.target.value)}
                />
              </div>
            </div>

            <div className="d-flex justify-content-center mt-4">
              <button
                className="btn btn-primary"
                onClick={UpdateOrganisationalType}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default OrganisationalTypeEdit;