import { MDBDataTable } from 'mdbreact';
import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import { SWIPELINC_API } from '../utils/constant';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import DownloadCsv from '../common/DownloadCsv';
import { headerLabelsForSettlementAdjustment } from '../utils/DownloadHeaders';
import CompanyNameAndMid from "../common/CompanyNameAndMid";
import MyLoader from '../common/MyLoader';

const SingleSettlementAdjustmentAmount = () => {
    const [data, setData] = useState();
    const token = localStorage.getItem("token");
    const [showTable, setShowTable] = useState(false);
    const [isMultipleFilterOpen, setIsMultipleFilterOpen] = useState(false);
    const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [filterRecords, setFilterRecords] = useState([]);
  
    const { mid } = useParams();
    const navigate = useNavigate();
  
    useEffect(() => {
      if (mid) {
        setShowLoader(true)
        axios
          .get(
            `${SWIPELINC_API}/MerchantAdminPanel/admin/get-by-mid-and-remark/${mid}`,
            {
              headers: {
                Authentication: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            if (response.data.statusCode === 200)
            {
               setData(response.data.data);
            setShowTable(true);
            }
            else{
           setData([])
            }
            setShowLoader(false)
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            setShowTable(false);
          });
      }
    }, [mid]);
  
    const columns = [
      // { label: 'ID', field: 'id', width:'auto' },
       
      
     
    
      { label: "Merchant ID", field: "mid", width: "auto" },
      { label: "Transaction Status", field: "txnStatus", width: "auto" },
      //   { label: "Transaction ID", field: "txnId", width: "auto" },
      { label: "Amount", field: "netAmount", width: "auto" },
    //   { label: "Transaction Status", field: "txnStatus", width: "auto" },
    //   { label: "Company Name", field: "companyName", width: "auto" },
    //   { label: "RRN", field: "rrn", width: "auto" },
      { label: "Create Date", field: "settlmentDate", width: "auto" },
      // { label: "Create Time", field: "settlmentTime", width: "auto" },
      { label: " Remark    ", field: "remark", width: "auto" },
    ];
    const mappedData = data?.map((item) => {
      const mappedItem = {};
      columns.forEach((column) => {
        mappedItem[column.field] = item[column.field];
      });
      return mappedItem;
    });
  
    const handleBackButtonClick = () => {
      navigate(-1);
    };
  
    
    const handleMultipleFilter = () => {
      setIsMultipleFilterOpen(!isMultipleFilterOpen);
      setIsAdvancedSearchOpen(false);
      // setIsDateFilterOpen(false);
      // setShow2(!show2);
    };
  
    const handleAdvanceSearch = () => {
      setIsAdvancedSearchOpen(!isAdvancedSearchOpen);
      // setIsDateFilterOpen(false);
      setIsMultipleFilterOpen(false);
      // setShow(!show);
    };
  
    const handleReset = () => {
      window.location.reload();
    };
  
    return (
      <>
          {showLoader ? <MyLoader /> : ""}
        <Container>
          <Row>
            <Col xs={12} lg={12} md={12}>
              <Card className="shadow border-0 my-4">
              <Card.Header>
           
              <CompanyNameAndMid mid={mid} reportType={"Settlement Adjustment Report"} />
              </Card.Header>
                <Card.Body>
                  <div className="d-flex justify-content-between mt-2">
                    <button
                      className="btn btn-primary"
                      onClick={handleBackButtonClick}
                    >
                      Go Back
                    </button>
                    
                  </div>
  
                  <div className="report-grid-view mt-3">
                    <div className="material-table-records mb-3">
                      <MDBDataTable
                        className="dataTable"
                        striped
                        bordered
                        small
                        data={{
                          columns: columns,
                          rows: mappedData,
                        }}
                        searching={true}
                        sorting={true}
                        responsive
                        hover
                        footer="none"
                      />
                    </div>
                    <DownloadCsv DownLoadData={data} Header={headerLabelsForSettlementAdjustment}  Name={`${mid}-SettlementAdjustment`}/>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          
        </Container>
      </>
    );
  }
  

export default SingleSettlementAdjustmentAmount
