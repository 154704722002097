import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import InactiveServices from "../components/InactiveServices";
import LivePayinReport from "./LivePayinReport";
import LivePayoutReport from "./LivePayoutReport";
import LiveChargebackReport from "./LiveChargebackReport";
import LiveWithdrawlReport from "./LiveWithdrawlReport";
import LiveReconciliationReport from "./LiveReconciliationReport";
import { getAllMerchantPhase2Approved } from "../utils/ApiServices";

const LiveCummulativeSubTab = ({ setShowLoader }) => {
  const [activePhase, setActivePhase] = useState("Phase1");
  const token = localStorage.getItem("token");
  const [dropDownData, setDropDownData] = useState([]);
 
 

  const getMerchantData = async (token) => {
    try {
      setShowLoader(true)
      const response = await getAllMerchantPhase2Approved(token);
      if (response?.statusCode === 200) {
        setDropDownData(response?.data);
        setShowLoader(false)
        console.log(response?.data);
      } else {
        setDropDownData([]);
        setShowLoader(false)
      }
    } catch (error) {
      setDropDownData([]);
      setShowLoader(false)
    }
  };
  
 
  useEffect(() => {
    setActivePhase("Phase1");
    getMerchantData(token)
  }, []);

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  return (
    <>
      <Card className="shadow border-0 mb-3  ds-inner-card">
        <Card.Body>
          <div className="merchant-tab-btn">
            <ul className="report-link nav-report-btn submenu-tab-admin">
              <li
                className={activePhase === "Phase1" ? "active" : ""}
                onClick={() => handlePhaseClick("Phase1")}
              >
                Payin {activePhase === "Phase1"}
              </li>
              <li
                className={activePhase === "Phase2" ? "active" : ""}
                onClick={() => handlePhaseClick("Phase2")}
              >
                Payout {activePhase === "Phase2"}
              </li>
              <li
                className={activePhase === "Phase3" ? "active" : ""}
                onClick={() => handlePhaseClick("Phase3")}
              >
                Chargeback {activePhase === "Phase3"}
              </li>
              <li
                className={activePhase === "Phase4" ? "active" : ""}
                onClick={() => handlePhaseClick("Phase4")}
              >
                Withdraw {activePhase === "Phase4"}
              </li>
              <li
                className={activePhase === "Phase5" ? "active" : ""}
                onClick={() => handlePhaseClick("Phase5")}
              >
                Reconciliation {activePhase === "Phase5"}
              </li>
            </ul>
          </div>
        </Card.Body>
      </Card>
      <Card className="shadow border-0 mb-3  ds-inner-card">
        <Card.Body>
          <div className="admin-tab-content mat-bt-table">
            {activePhase === "Phase1" ? (
              // <InactiveServices />
              <LivePayinReport reportType={activePhase} setShowLoader={setShowLoader} dropDownData={dropDownData}/>
            ) : activePhase === "Phase2" ? (
              <LivePayoutReport reportType={activePhase} setShowLoader={setShowLoader} dropDownData={dropDownData}/>
            ) : //  <InactiveServices />
            activePhase === "Phase3" ? (
              <LiveChargebackReport reportType={activePhase} setShowLoader={setShowLoader} dropDownData={dropDownData}/>
            ) : activePhase === "Phase4" ? (
              <LiveWithdrawlReport reportType={activePhase} setShowLoader={setShowLoader} dropDownData={dropDownData}/>
            ) : activePhase === "Phase5" ? (
              <LiveReconciliationReport reportType={activePhase} setShowLoader={setShowLoader} dropDownData={dropDownData}/>
            ) : (
              <InactiveServices />
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default LiveCummulativeSubTab;
