import React from "react";
// import MainLogo from "../assets/images/Logo.png";
import SwipelincLogo from '../assets/images/SwipelincLogo.png'
import LincpayLogo from '../assets/images/LincpayLogo.jpeg'
import user from "../assets/images/user.png";
import { config } from "../utils/helper";
const InactiveServices = () => {

  
  
  const {logoPath,Name}=config( process.env.REACT_APP_SERVER_ENVIRONMENT)
 
  return (
    <>
      {/* <Header /> */}
      <div className="noservice">
        <div className="card text-center">
          <div className="card-header">Not Available</div>
          <div className="card-body">
            <img src={logoPath} className="inactive-header" alt="logo" />
            <h5 className="card-title">Service not activated yet</h5>
            <p className="card-text"> </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default InactiveServices;