import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { SWIPELINC_API } from "../utils/constant";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Card, Row, Col, Modal, Button } from "react-bootstrap";
import ChargebackMultipleFilter from "../Merchant/MultipleFilter/ChargebackMultipleFilter";
import ChargebackAdvanceFilter from "../Merchant/AdvanceFilter/ChargebackAdvanceFilter";
import { BiEdit } from "react-icons/bi";
import swal from "sweetalert";
import { DateTime } from "luxon";
import DownloadCsv from "../common/DownloadCsv";
import { headerLabelsForChargeback } from "../utils/DownloadHeaders";
import CompanyNameAndMid from "../common/CompanyNameAndMid";
import MyLoader from "../common/MyLoader";

function SingleChargeback() {
  const [data, setData] = useState();

  const token = localStorage.getItem("token");
  const [showTable, setShowTable] = useState(false);
  const [isMultipleFilterOpen, setIsMultipleFilterOpen] = useState(false);
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [RRNNum, setRRNNum] = useState("");
  const [chargeBackAdjDate, setChargeBackAdjDate] = useState("");
  const [editItem, setEditItem] = useState(null);

  const { mid } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (mid) {
      axios
        .get(
          `${SWIPELINC_API}/MerchantAdminPanel/chargeBack/findBymid/${mid}`,
          {
            headers: {
              Authentication: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setData(response.data.data);
          setShowTable(true);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setShowTable(false);
        });
    }
  }, [mid]);

  const columns = [
    { label: "ID", field: "id", width: "auto" },
    { label: "RRN", field: "rrn", width: "auto" },
    {
      label: "Chargeback Adjustment Date",
      field: "chargeBackAdjDate",
      width: "auto",
    },
    // { label: "UPI-Transaction-ID", field: "upiTransactionId", width: "auto" },
    { label: "Chargeback Amount", field: "chargeBackAmount", width: "auto" },
    {
      label: "Compensation Penalty",
      field: "compensationPenalty",
      width: "auto",
    },
    { label: "Transaction Date", field: "transactionDate", width: "auto" },
    { label: "Transaction Amount", field: "transactionAmount", width: "auto" },
    { label: "Payer-VPA", field: "payerVpa", width: "auto" },
    { label: "Payee Bank", field: "payeeBank", width: "auto" },
    // { label: "Transaction UID", field: "transactionUid", width: "auto" },
    { label: "Mobile Number", field: "mobileNumber", width: "auto" },
    { label: "e-Mail ID", field: "emailId", width: "auto" },
    { label: "IFSC Code", field: "ifscCode", width: "auto" },
    { label: "Bank Location", field: "bankLocation", width: "auto" },
    { label: "Payee IP Address", field: "payeeIpAddress", width: "auto" },
    { label: "Payee Location", field: "payeeLocation", width: "auto" },
    { label: "Chargeback Upload Date", field: "createDate", width: "auto" },
    { label: "Chargeback Upload Time", field: "createTime", width: "auto" },
    { label: "Action", field: "action", width: "auto" },
  ];
  const mappedData = data?.map((item) => {
    const mappedItem = {};
    columns.forEach((column) => {
      mappedItem[column.field] = item[column.field];
      item.action = (
        <div className="button-container">
          <button
            className="btn btn-warning"
            onClick={() => {
              console.log(item);
              setEditItem(item);
              setRRNNum(item.rrn);
              setChargeBackAdjDate(item.chargeBackAdjDate);
              setIsModalOpen(true);
            }}
          >
            <BiEdit />
          </button>
        </div>
      );
    });
    return mappedItem;
  });
  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const handleMultipleFilter = () => {
    setIsMultipleFilterOpen(!isMultipleFilterOpen);
    setIsAdvancedSearchOpen(false);
    // setIsDateFilterOpen(false);
    // setShow2(!show2);
  };

  const handleAdvanceSearch = () => {
    setIsAdvancedSearchOpen(!isAdvancedSearchOpen);
    // setIsDateFilterOpen(false);
    setIsMultipleFilterOpen(false);
    // setShow(!show);
  };

  const handleReset = () => {
    window.location.reload();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDateInputChange = (event) => {
    const newDate = event.target.value;
    const inputDateTime = DateTime.fromISO(newDate, { zone: "UTC" });

    if (!inputDateTime.isValid) {
      swal({
        title: "Invalid Date",
        text: "Please enter a valid date.",
        icon: "warning",
      });
      return;
    }

    const today = DateTime.now().startOf("day");
    console.log(today, inputDateTime);

    if (inputDateTime.toISODate() > today.toISODate()) {
      swal({
        title: "Invalid Date",
        text: "Please select a date that is not in the future.",
        icon: "warning",
      });
    } else {
      setChargeBackAdjDate(inputDateTime.toISODate());
    }
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    const payload = {
      chargeBackAdjDate: chargeBackAdjDate,
      id: editItem.id,
      rrn: RRNNum,
    };

    axios
      .put(`${SWIPELINC_API}MerchantAdminPanel/chargeBack/update`, payload, {
        headers: {
          Authentication: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          swal({
            title: "Success",
            text: response.data.message,
            icon: "success",
          });
          setIsModalOpen(false);
        } else {
          swal({
            title: "Alert",
            text: response.data.message,
            icon: "error",
          });
          setIsModalOpen(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
       {showLoader ? <MyLoader /> : ""}
      <Container>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <Card className="shadow border-0 my-4">
            <Card.Header>
         
              <CompanyNameAndMid mid={mid} reportType={"Chargeback Report"}/>
              </Card.Header>
              <Card.Body>
                <div className="d-flex justify-content-between mt-2">
                  <button
                    className="btn btn-primary"
                    onClick={handleBackButtonClick}
                  >
                    Go Back
                  </button>
                  <div className=" d-flex justify-content-end">
                    {/* multiple filter starts */}
                    <div className="Advanced-btn d-flex justify-content-end mx-1">
                      <button
                        className={`advanced-btn btn btn-primary   text-capitlize ${
                          isMultipleFilterOpen ? "active" : ""
                        }`}
                        onClick={handleMultipleFilter}
                      >
                        <i className="bi bi-funnel-fill mx-1"></i>More Filter
                      </button>
                    </div>
                    {/* multiple filter ends */}

                    {/* advance filter starts */}
                    <div className="Advanced-btn  d-flex justify-content-end mx-1">
                      <button
                        className={`advanced-btn btn btn-primary text-capitlize ${
                          isAdvancedSearchOpen ? "active" : ""
                        }`}
                        onClick={handleAdvanceSearch}
                      >
                        <i className="bi bi-funnel-fill mx-1"></i>
                        Advanced Filter
                      </button>
                    </div>
                    {/* advance filter ends */}

                    {/* reset btn code starts */}
                    <div className="Advanced-btn  d-flex justify-content-end mx-1">
                      <button
                        className={`advanced-btn btn btn-primary   text-capitlize`}
                        onClick={handleReset}
                      >
                        <i class="bi bi-arrow-clockwise mx-1"> </i>Reset
                      </button>
                    </div>
                    {/* reset btn coden ends  */}

                    {isMultipleFilterOpen ? (
                      <ChargebackMultipleFilter
                        setChargeBackReportData={setData}
                        setIsMultipleFilterOpen={setIsMultipleFilterOpen}
                        mid={mid}
                        setShowLoader={setShowLoader}
                      />
                    ) : (
                      ""
                    )}

                    {isAdvancedSearchOpen ? (
                      <div className="date-report">
                        {/* <AdvancedSearchPopup2 onClose={handleAdvancedSearchClose} setCustomApiResponse={handleCustomApiResponse} /> */}
                        <ChargebackAdvanceFilter
                          setShowLoader={setShowLoader}
                          setChargebackReportData={setData}
                          setIsAdvanceFilterOpen={setIsAdvancedSearchOpen}
                          mid={mid}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="report-grid-view mt-3">
                  <div className="material-table-records mb-3">
                    <MDBDataTable
                      className="dataTable"
                      striped
                      bordered
                      small
                      data={{
                        columns: columns,
                        rows: mappedData,
                      }}
                      searching={true}
                      sorting={true}
                      responsive
                      hover
                      footer="none"
                    />
                  </div>
                  <DownloadCsv
                    DownLoadData={data}
                    Header={headerLabelsForChargeback}
                    Name={`${mid}-Chargeback`}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* // EDIT FORM MODEL STARTS */}
      <Modal show={isModalOpen} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Chargeback:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="w-100">
            <div className="form-group">
              <div>
                <label>Reference Number *</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter Reference Number"
                  value={RRNNum}
                  onChange={(e) => setRRNNum(e.target.value)}
                />
                <label className="mt-3">Transaction Date *</label>
                <input
                  className="form-control"
                  type="date"
                  placeholder="Enter Transaction Date"
                  value={chargeBackAdjDate}
                  onChange={handleDateInputChange}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={(e) => handleEditSubmit(e)}>
            Edit
          </Button>
          <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SingleChargeback;
