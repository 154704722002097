import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Modal, Button, Form } from "react-bootstrap";
import { SWIPELINC_API } from "../utils/constant";
import { Container, Card, Row, Col } from "react-bootstrap";
import TransactionReportMultipleFilter from "./MultipleFilter/TransactionReportMultipleFilter";
import TxnReportAdvancedSearchPopup from "./AdvanceFilter/TxnReportAdvancedSearchPopup";
import ViewResponse from "./MerchantManagement/ViewResponse";
import ViewTxnStatus from "./MerchantManagement/ViewTxnStatus";
import DownloadCsv from "../common/DownloadCsv";
import { headerLabelsForPayin } from "../utils/DownloadHeaders";
import CompanyNameAndMid from "../common/CompanyNameAndMid";
import MyLoader from "../common/MyLoader";
import DailyTransactionBox from "../reports/DailyTransactionBox";

function TransactionReport() {
  const [transactionReportData, setTransactionReportData] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isMultipleFilterOpen, setIsMultipleFilterOpen] = useState(false);
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [filterRecords, setFilterRecords] = useState([]);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [showTxnStatusModal, setShowTxnStatusModal] = useState(false);
  const [modalOrderNo, setModalOrderNo] = useState(null);
  const [modalMid, setModalMid] = useState(null);
  const [countData, setCountData] = useState([]);

  const navigate = useNavigate();
  const { mid, orderNo } = useParams();

  useEffect(() => {
    fetchTransactionReport();
  }, []);

  const fetchTransactionReport = async () => {
    try {
      const payload = {
        mid: mid,
      };
      console.log("mid is:", mid);
      const response = await axios.post(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getreport`,
        payload,
        {
          headers: {
            Authentication:` Bearer ${token}`,
          },
        }
      );

      if (response.data.statusCode === 200) {
        setTransactionReportData(response.data.responseData);
      } else {
        console.error("Api response error:", response.data);
        setTransactionReportData([]);
      }
    } catch (error) {
      console.error("Error fetching transaction report:", error);
    }
  };

  const mappedData2 = transactionReportData?.map((item) => {
    if (item.txnStatus === "failed" && item.orderNo !== "static QR") {
      const mappedItem2 = {
        ...item,
        evokTxn :(item.evokTxnCode === "null" || item.evokTxnCode === null ) ? "0" : item.evokTxnCode,
        view: (
          <button
            className="p-2 border   bg-primary rounded-2"
            onClick={() => handleViewButtonClick(item.orderNo, item.mid)}
          >
            View Response
          </button>
        ),
        txnStatusBtn: (
          <button
            className="p-2 border   bg-primary rounded-2"
            onClick={() => handelViewTxnBtn(item.orderNo)}
          >
            View TXN Status
          </button>
        ),
      } 

      return mappedItem2;
    } else {
      const mappedItem2 = {
        ...item,
        evokTxn :(item.evokTxnCode === "null" || item.evokTxnCode === null ) ? "0" : item.evokTxnCode,
        txnStatus:
          item.txnStatus === null || item.txnStatus === ""
            ? ""
            : item.txnStatus.toUpperCase(),
        txnStatusBtn: (
          <button
            className="p-2 border   bg-primary rounded-2"
            onClick={() => handelViewTxnBtn(item.orderNo)}
          >
            View TXN Status
          </button>
        ),
      };

      return mappedItem2;
    }
  });

  const columns = [
    { label: "Payment Type", field: "paymentType", width: "auto" },
    { label: "View Response", field: "view", width: "auto" },
    { label: "View Transaction Status", field: "txnStatusBtn", width: "auto" },
    { label: "Transaction ID", field: "txn_id", width: "auto" },
    { label: "Transaction Date", field: "txnDate", width: "auto" },
    { label: "Transaction Time", field: "txnTime", width: "auto" },
    { label: "Terminal ID", field: "terminalId", width: "auto" },
    { label: "Amount", field: "amount", width: "auto" },
    { label: "Currency", field: "currency", width: "auto" },
    { label: "Transaction Status", field: "txnStatus", width: "auto" },
    { label: "Bank Response Code ", field: "evokTxn", width: "auto" },
    { label: "Response Date", field: "responseDate", width: "auto" },
    { label: "UTR Number", field: "custRefNo", width: "auto" },
    { label: "Order No", field: "orderNo", width: "auto" },
    { label: "Payer VPA", field: "userVpa", width: "auto" },
    { label: "Mobile Number", field: "mobileNo", width: "auto" },
    { label: "Email", field: "email", width: "auto" },
    { label: "Customer IP Address", field: "customerIp", width: "auto" },
    { label: "Customer Location", field: "customerLocation", width: "auto" },
    { label: "Remark", field: "remark", width: "auto" },
    { label: " Customer IFSC", field: "customerIfsc", width: "auto" },
    { label: "Bank Location", field: "bankLocation", width: "auto" },
  ];

  function handleViewButtonClick(orderNo, mid) {
    setModalOrderNo(orderNo);
    setModalMid(mid);
    setShowResponseModal(true);
  }

  function handelViewTxnBtn(orderNo) {
    setModalOrderNo(orderNo);
    setShowTxnStatusModal(true);
  }
  const handleCloseResponseModal = () => {
    setShowResponseModal(false);
  };
  const onCloseModal = () => {
    setShowResponseModal(false);
  };
  const handleCloseTxnStatusModal = () => {
    setShowTxnStatusModal(false);
  };
  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const handleMultipleFilter = () => {
    setIsMultipleFilterOpen(!isMultipleFilterOpen);
    setIsAdvancedSearchOpen(false);
  };

  const handleAdvanceSearch = () => {
    setIsAdvancedSearchOpen(!isAdvancedSearchOpen);
    // setIsDateFilterOpen(false);
    setIsMultipleFilterOpen(false);
    // setShow(!show);
  };

  const handleReset = () => {
    window.location.reload();
  };

  const value = Array.isArray(transactionReportData)
    ? transactionReportData
    : [];

  const calculateTotalAmountForSuccess = (data) => {
    // Filter the data to include only "success" transactions
    const successTransactions = transactionReportData.filter(
      (item) => item.txnStatus === "success"
    );
    return successTransactions.reduce(
      (total, item) => total + parseFloat(item.amount),
      0
    );
    // Calculate the total sum of amounts for success transactions
  };

  const totalAmountForSuccess = calculateTotalAmountForSuccess(
    !value.length ? filterRecords : value
  );

  const boxComponentsUp = countData?.map((entry, index) => (
    <Col xs={2} lg={1} md={1} key={index}>
      <DailyTransactionBox data={entry} />
    </Col>
  ));

  return (
    <>
    {showLoader ? <MyLoader /> : ""}
      <Container>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <Card className="shadow border-0 my-4">
              <Card.Header>
             
              <CompanyNameAndMid mid={mid} reportType={"Payin Report"}/>
              </Card.Header>
              <Card.Body>
                <div className="d-flex justify-content-between mt-2">
                  <button
                    className="btn btn-primary"
                    onClick={handleBackButtonClick}
                  >
                    Go Back
                  </button>
                  <div className=" d-flex justify-content-end">
                    {/* multiple filter starts */}
                    <div className="Advanced-btn d-flex justify-content-end mx-1">
                      <button
                        className={`advanced-btn btn btn-primary   text-capitlize ${
                          isMultipleFilterOpen ? "active" : ""
                        }`}
                        onClick={handleMultipleFilter}
                      >
                        <i className="bi bi-funnel-fill mx-1"></i>More Filter
                      </button>
                    </div>
                    {/* multiple filter ends */}

                    {/* advance filter starts */}
                    <div className="Advanced-btn  d-flex justify-content-end mx-1">
                      <button
                        className={`advanced-btn btn btn-primary text-capitlize ${
                          isAdvancedSearchOpen ? "active" : ""
                        }`}
                        onClick={handleAdvanceSearch}
                      >
                        <i className="bi bi-funnel-fill mx-1"></i>
                        Advanced Filter
                      </button>
                    </div>
                    {/* advance filter ends */}

                    {/* reset btn code starts */}
                    <div className="Advanced-btn  d-flex justify-content-end mx-1">
                      <button
                        className={`advanced btn btn btn-primary   text-capitlize`}
                        onClick={handleReset}
                      >
                        <i class="bi bi-arrow-clockwise mx-1"> </i>Reset
                      </button>
                    </div>
                    {/* reset btn coden ends  */}

                    {isMultipleFilterOpen ? (
                      <TransactionReportMultipleFilter
                        setTransactionReportData={setTransactionReportData}
                        setIsMultipleFilterOpen={setIsMultipleFilterOpen}
                        mid={mid}
                        setShowLoader={setShowLoader}
                        setCountData={setCountData}
                      />
                    ) : (
                      ""
                    )}

                    {isAdvancedSearchOpen ? (
                      <div className="date-report">
                        {/* <AdvancedSearchPopup2 onClose={handleAdvancedSearchClose} setCustomApiResponse={handleCustomApiResponse} /> */}
                        <TxnReportAdvancedSearchPopup
                          setShowLoader={setShowLoader}
                          setTransactionReportData={setTransactionReportData}
                          setIsAdvanceFilterOpen={setIsAdvancedSearchOpen}
                          mid={mid}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="dasboard-view-count-boxes">
                  <Container>
                    <Card.Body>
                      <Row>{boxComponentsUp}</Row>
                    </Card.Body>
                  </Container>
                </div>
                <div className="mt-5 text-danger d-flex justify-content-evenly">
                  <div>
                    <span>U03 - Above Allowed Limit</span>
                    <br />
                    <span>U16 - Daily Limit Of Exceeded</span>
                    <br />
                    <span>U28 - Unable To Connect Sender Application </span>
                    <br />
                  </div>
                  <div>
                    <span>U30 - Unable To Debit</span>
                    <br />
                    <span>U31 - Denied By Reciever</span>
                    <br />
                    <span>U67 - Debit Timeout</span>
                    <br />
                  </div>
                  <div>
                    <span>U90 - Txn Deemed</span>
                    <br />
                    <span>U91 - Sender Bank Rejected</span>
                    <br />
                    <span>E05 - User Left Txn Incomplete</span>
                    <br />
                  </div>
                </div>
                <div className="report-grid-view mt-3">
                  <div className="material-table-records mb-3">
                    <MDBDataTable
                      className="dataTable"
                      striped
                      small
                      bordered
                      hover
                      responsive
                      data={{
                        columns: columns,
                        rows: mappedData2,
                      }}
                      searching={true}
                      entries={50}
                      paginationLabel={["Previous", "Next"]}
                      sortable={true}
                      footer="none"
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center justify-content-between w-25  ">
                    {transactionReportData.length > 0 && (
                      <DownloadCsv DownLoadData={transactionReportData} Header={headerLabelsForPayin} Name={`${mid}-PayIn`} />
                    )}
                  </div>
                    <div className="d-flex align-items-center justify-content-between w-25 gap-4 border boeder-1 p-2 ">
                      <lable className="fs-4 w-50">Total Amount</lable>
                      <span className="fs-4 fw-bold w-50 border boeder-2 rounded-2 p-1">
                        {totalAmountForSuccess}
                      </span>
                    </div>
                     
                  </div>

                  
                </div>
                <Modal
                  show={showResponseModal}
                  onHide={handleCloseResponseModal}
                  size="lg"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>View Response</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <ViewResponse
                      mid={modalMid}
                      orderNo={modalOrderNo}
                      showModal={showResponseModal}
                      closeModal={handleCloseResponseModal}
                      onCloseModal={onCloseModal}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={handleCloseResponseModal}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={showTxnStatusModal}
                  onHide={handleCloseTxnStatusModal}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>View TXN Status</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <ViewTxnStatus
                      mid={modalMid}
                      orderNo={modalOrderNo}
                      showModal={showTxnStatusModal}
                      closeModal={handleCloseTxnStatusModal}
                      onCloseModal={onCloseModal}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={handleCloseTxnStatusModal}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TransactionReport;