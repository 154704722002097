import React from "react";
import { Card } from "react-bootstrap";

const DailyTransactionBox = ({ data }) => {
  console.log(data);
  return (
    <Card className="shadow-cst p-2 border-0 mb-3 dashboard-boxs">
      <Card.Body>
       
          <div className="fw-bold ds-card-dt d-flex justify-content-center align-items-center">
            {data.evokTxnCode}
          </div>
          <div className="fw-bold ds-card-dt d-flex justify-content-center align-items-center">
            {data.count}
          </div>
         
      </Card.Body>
    </Card>
  );
};

export default DailyTransactionBox;
