import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { SWIPELINC_API } from "../../utils/constant";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Card, Row, Col, Modal, Button } from "react-bootstrap";
import { BiEdit } from "react-icons/bi";
import swal from "sweetalert";
import { DateTime } from "luxon";
import WithdrawMultipleFilter from "../MultipleFilter/WithdrawMultipleFilter";
import WithdrawAdvanceFilter from "../MultipleFilter/WithdrawAdvanceFilter";
import DownloadCsv from "../../common/DownloadCsv";
import CompanyNameAndMid from "../../common/CompanyNameAndMid";
import MyLoader from "../../common/MyLoader";

function SingleWithdrawl() {
  const [data, setData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const token = localStorage.getItem("token");
  const [showTable, setShowTable] = useState(false);
  const [utrNum, setUtrNum] = useState("");
  const [txnDate, setTxnDate] = useState("");
  const [isWithdrawalManual, setIswithdrawlmanual] = useState(Boolean);
  const [editItem, setEditItem] = useState(null);
  const [isMultipleFilterOpen, setIsMultipleFilterOpen] = useState(false);
  const [isAdvanceFilterOpen, setIsAdvanceFilterOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(Boolean);
  // const [showLoader, setShowLoader] = useState(false);

  const { mid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (mid) {
      setShowLoader(true)
      axios
        .get(
          `${SWIPELINC_API}/MerchantAdminPanel/merchant/withdrawal/getAllSuccessAndReject/${mid}`,
          {
            headers: {
              Authentication: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setData(response.data.data);
          setShowTable(true);
          setShowLoader(false)
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setShowTable(false);
          setShowLoader(false);
        });
    }
  }, [isModalOpen,mid]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDeleteRecord = (withdrawalId) => {
    if (data) {
      const recordToDelete = data.find(
        (item) => item.withdrawalId === withdrawalId
      );
      if (recordToDelete && recordToDelete.isWithdrawalManual) {
        swal({
          title: "Are you sure?",
          text: "Once deleted, you will not be able to recover this record!",
          icon: "warning",
          buttons: ["Cancel", "Yes, delete it"],
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            axios
              .get(
                `${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal/deleteBy/${withdrawalId}`,
                {
                  headers: {
                    Authentication: `Bearer ${token}`,
                  },
                }
              )
              .then((response) => {
                if (response.data.statusCode === 200) {
                  swal({
                    title: "Success",
                    text: response.data.message,
                    icon: "success",
                  });

                  const updatedData = data.filter(
                    (item) => item.withdrawalId !== withdrawalId
                  );
                  setData(updatedData);
                  
                } else {
                  swal({
                    title: "Alert",
                    text: response.data.message,
                    icon: "error",
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      }
    }
  };
  const columns = [
    { label: "Merchant ID", field: "mid", width: "auto" },
    { label: "Amount", field: "amount", width: "auto" },
    { label: "UTR Number", field: "utr", width: "auto" },
    { label: "Transfer Type", field: "transferType", width: "auto" },
    {
      label: "Virtual Account Type",
      field: "virtualAccountType",
      width: "auto",
    },
    { label: "Status", field: "status", width: "auto" },
    { label: "Full Name", field: "fullName", width: "auto" },
    { label: "e-Mail ID", field: "emailId", width: "auto" },
    { label: "Transaction Date", field: "transactionDate", width: "auto" },
    { label: "Create Time", field: "createTime", width: "auto" },
    { label: "Create Date", field: "createDate", width: "auto" },
    // { label: 'Withdrawal Manual', field: 'isWithdrawalManual', width: 'auto' },
    { label: "Action", field: "action", width: "auto" },
  ];
  const mappedData = data?.map((item) => {
    console.log(item.isWithdrawalManual)
    const mappedItem = {};
    columns.forEach((column) => {
      mappedItem[column.field] = item[column.field];

      if(item.virtualAccountType === null) {
        item.virtualAccountType = "Virtual Account"
      }

      if (item.status === "success") {
        item.action = (
          <div className="d-flex gap-2">
            <button
              className="btn btn-warning"
              onClick={() => {
                setEditItem(item);
                setUtrNum(item.utr);
                setTxnDate(item.transactionDate);
                setIswithdrawlmanual(item.isWithdrawalManual);
                setIsModalOpen(true);
                console.log(item.isWithdrawalManual)
              }}
            >
              <BiEdit />
            </button>
            {item.isWithdrawalManual && (
              <button
                className="btn btn-danger ml-2"
                onClick={() => handleDeleteRecord(item.withdrawalId)}
              >
                Delete
              </button>
            )}
          </div>
        );
      }
    });

    return mappedItem;
  });

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const handleDateInputChange = (event) => {
    const newDate = event.target.value;
    const inputDateTime = DateTime.fromISO(newDate, { zone: "UTC" });

    if (!inputDateTime.isValid) {
      swal({
        title: "Invalid Date",
        text: "Please enter a valid date.",
        icon: "warning",
      });
      return;
    }

    const today = DateTime.now().startOf("day");
    console.log(today, inputDateTime);

    if (inputDateTime.toISODate() > today.toISODate()) {
      swal({
        title: "Invalid Date",
        text: "Please select a date that is not in the future.",
        icon: "warning",
      });
    } else {
      setTxnDate(inputDateTime.toISODate());
    }
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    const payload = {
      remark: "",
      status: editItem.status,
      transactionDate: txnDate,
      isWithdrawalManual:isWithdrawalManual,
      utr: utrNum,
      withdrawalId: editItem.withdrawalId,
    };
   

    axios
      .put(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal/updateWithdrawal`,
        payload,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          swal({
            title: "Success",
            text: response.data.message,
            icon: "success",
          });
          setIsModalOpen(false);
        } else {
          swal({
            title: "Alert",
            text: response.data.message,
            icon: "error",
          });
          setIsModalOpen(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMultipleFilter = () => {
    setIsMultipleFilterOpen(!isMultipleFilterOpen);
    // setIsDateFilterOpen(false);
    // setShow2(!show2);
  };
  const handleAdvanceFilter = () => {
    setIsAdvanceFilterOpen(!isAdvanceFilterOpen);
    // setIsDateFilterOpen(false);
    // setShow2(!show2);
  };

  const handleReset = () => {
    window.location.reload();
  };

  return (
    <>
        {showLoader ? <MyLoader /> : ""}
      <Container>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <Card className="shadow border-0 my-4">
            <Card.Header>
           
              <CompanyNameAndMid mid={mid} reportType={"Withdrawl Report"} />
              </Card.Header>
              <Card.Body>
                <div className="d-flex justify-content-between mt-2">
                  <button
                    className="btn btn-primary"
                    onClick={handleBackButtonClick}
                  >
                    Go Back
                  </button>
                  <div className=" d-flex justify-content-end">
                    {/* multiple filter starts */}
                    <div className="Advanced-btn d-flex justify-content-end mx-1">
                      <button
                        className={`advanced-btn btn btn-primary   text-capitlize ${
                          isMultipleFilterOpen ? "active" : ""
                        }`}
                        onClick={handleMultipleFilter}
                      >
                        <i className="bi bi-funnel-fill mx-1"></i>More Filter
                      </button>
                    </div>
                    <div className="Advanced-btn d-flex justify-content-end mx-1">
                      <button
                        className={`advanced-btn btn btn-primary   text-capitlize ${
                          isAdvanceFilterOpen ? "active" : ""
                        }`}
                        onClick={handleAdvanceFilter}
                      >
                        <i className="bi bi-funnel-fill mx-1"></i>Advance search
                      </button>
                    </div>
                    {/* multiple filter ends */}

                    {/* reset btn code starts */}
                    <div className="Advanced-btn  d-flex justify-content-end mx-1">
                      <button
                        className={`advanced-btn btn btn-primary   text-capitlize`}
                        onClick={handleReset}
                      >
                        <i class="bi bi-arrow-clockwise mx-1"> </i>Reset
                      </button>
                    </div>
                    {/* reset btn coden ends  */}

                    {isMultipleFilterOpen ? (
                      <WithdrawMultipleFilter
                        setWithdrawlReportData={setData}
                        setIsMultipleFilterOpen={setIsMultipleFilterOpen}
                        mid={mid}
                        setShowLoader={setShowLoader}
                      />
                    ) : isAdvanceFilterOpen ? (
                      <WithdrawAdvanceFilter
                      setWithdrawlReportData={setData}
                      setIsAdvanceFilterOpen={setIsAdvanceFilterOpen}
                      mid={mid}
                      setShowLoader={setShowLoader}
                    />
                    ):''}
                  </div>
                </div>

                <div className="report-grid-view mt-3">
                  <div className="material-table-records mb-3">
                    <MDBDataTable
                      className="dataTable"
                      striped
                      bordered
                      small
                      data={{
                        columns: columns,
                        rows: mappedData,
                      }}
                      searching={true}
                      sorting={true}
                      responsive
                      hover
                      footer="none"
                    />
                  </div>
                  <DownloadCsv DownLoadData={data} Name={`${mid}-Withdrawl`} />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* // EDIT FORM MODEL STARTS */}
      <Modal show={isModalOpen} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Withdrawal:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="w-100">
            <div className="form-group">
              <div>
                <label>Reference Number *</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter Reference Number"
                  value={utrNum}
                  onChange={(e) => setUtrNum(e.target.value)}
                />
                <label className="mt-3">Transaction Date *</label>
                <input
                  className="form-control"
                  type="date"
                  placeholder="Enter Transaction Date"
                  value={txnDate}
                  onChange={handleDateInputChange}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={(e) => handleEditSubmit(e)}>
            Edit
          </Button>
          <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SingleWithdrawl;