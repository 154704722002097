import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { SWIPELINC_API } from "../../utils/constant";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Card, Row, Col } from "react-bootstrap";
import PayoutMultipleFilter from "../MultipleFilter/PayoutMultipleFilter";
import PayoutAdvanceFilter from "../AdvanceFilter/PayoutAdvanceFilter";
import DownloadCsv from "../../common/DownloadCsv";
import { headerLabelsForPayout } from "../../utils/DownloadHeaders";
import CompanyNameAndMid from "../../common/CompanyNameAndMid";
import MyLoader from "../../common/MyLoader";

function SinglePayout() {
  const [data, setData] = useState();
  const token = localStorage.getItem("token");
  const [showTable, setShowTable] = useState(false);
  const [isMultipleFilterOpen, setIsMultipleFilterOpen] = useState(false);
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [filterRecords, setFilterRecords] = useState([]);

  const { mid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (mid) {
      setShowLoader(true)
      axios
        .get(
          `${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/getAllPayoutByMid/${mid}`,
          {
            headers: {
              Authentication: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200){
             setData(response.data.data);
          setShowTable(true);
          }
          else{
           setData([])
          }
          setShowLoader(false)
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setShowTable(false);
        });
    }
  }, [mid]);

  const columns = [
    // { label: 'ID', field: 'id', width:'auto' },
    { label: "Full Name", field: "fullName", width: "auto" },
    { label: "e-Mail ID", field: "email", width: "auto" },
    { label: "Category", field: "category", width: "auto" },
    { label: "Mobile Number", field: "mobile", width: "auto" },
    { label: "VPA/UPI", field: "upi", width: "auto" },
    { label: "VPA/UPI", field: "upi", width: "auto" },
    { label: "UTR Number", field: "rrn", width: "auto" },
    { label: "Amount", field: "amount", width: "auto" },
    { label: "Transfer type", field: "transferType", width: "auto" },
    { label: "Reference ID", field: "reference", width: "auto" },
    { label: "Payout Status", field: "payoutStatus", width: "auto" },
    { label: "Payment By", field: "paymentBy", width: "auto" },
    { label: "Callback Recieved", field: "Green", width: "auto" },
    { label: "Transaction ID", field: "txnId", width: "auto" },
    { label: "Transaction Status", field: "txnStatus", width: "auto" },
    // { label: "Company Name", field: "companyName", width: "auto" },
    { label: "RRN", field: "rrn", width: "auto" },
    { label: "Create Date", field: "createDate", width: "auto" },
    { label: "Create Time", field: "createTime", width: "auto" },
  ];
    const mappedData = data?.map((item) => {
    const mappedItem = { ...item };
    
    mappedItem.Green = (
      item.merchantCallbackRecived == 1 ? (
        <span className="text-green">
        Callback Recieved
      </span>):( <span   >
          Not Received
      </span>)
    )
       
    
    return mappedItem;
  });
  

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  
  const handleMultipleFilter = () => {
    setIsMultipleFilterOpen(!isMultipleFilterOpen);
    setIsAdvancedSearchOpen(false);
    // setIsDateFilterOpen(false);
    // setShow2(!show2);
  };

  const handleAdvanceSearch = () => {
    setIsAdvancedSearchOpen(!isAdvancedSearchOpen);
    // setIsDateFilterOpen(false);
    setIsMultipleFilterOpen(false);
    // setShow(!show);
  };

  const handleReset = () => {
    window.location.reload();
  };

  return (
    <>
        {showLoader ? <MyLoader /> : ""}
      <Container>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <Card className="shadow border-0 my-4">
            <Card.Header>
            
              <CompanyNameAndMid mid={mid} reportType={"Payout Report"}/>
              </Card.Header>
              <Card.Body>
                <div className="d-flex justify-content-between mt-2">
                  <button
                    className="btn btn-primary"
                    onClick={handleBackButtonClick}
                  >
                    Go Back
                  </button>
                  <div className=" d-flex justify-content-end">
                    {/* multiple filter starts */}
                    <div className="Advanced-btn d-flex justify-content-end mx-1">
                      <button
                        className={`advanced-btn btn btn-primary   text-capitlize ${
                          isMultipleFilterOpen ? "active" : ""
                        }`}
                        onClick={handleMultipleFilter}
                      >
                        <i className="bi bi-funnel-fill mx-1"></i>More Filter
                      </button>
                    </div>
                    {/* multiple filter ends */}

                    {/* advance filter starts */}
                    <div className="Advanced-btn  d-flex justify-content-end mx-1">
                      <button
                        className={`advanced-btn btn btn-primary text-capitlize ${
                          isAdvancedSearchOpen ? "active" : ""
                        }`}
                        onClick={handleAdvanceSearch}
                      >
                        <i className="bi bi-funnel-fill mx-1"></i>
                        Advanced Filter
                      </button>
                    </div>
                    {/* advance filter ends */}

                    {/* reset btn code starts */}
                    <div className="Advanced-btn  d-flex justify-content-end mx-1">
                      <button
                        className={`advanced-btn btn btn-primary   text-capitlize`}
                        onClick={handleReset}
                      >
                        <i class="bi bi-arrow-clockwise mx-1"> </i>Reset
                      </button>
                    </div>
                    {/* reset btn coden ends  */}

                    {isMultipleFilterOpen ? (
                      <PayoutMultipleFilter
                        setPayoutReportData={setData}
                        setIsMultipleFilterOpen={setIsMultipleFilterOpen}
                        mid={mid}
                        setShowLoader={setShowLoader}
                      />
                      
                    ) : (
                      ""
                    )}

                    {isAdvancedSearchOpen ? (
                      <div className="date-report">
                        {/* <AdvancedSearchPopup2 onClose={handleAdvancedSearchClose} setCustomApiResponse={handleCustomApiResponse} /> */}
                        <PayoutAdvanceFilter
                          setShowLoader={setShowLoader}
                          setPayoutReportData={setData}
                          setIsAdvanceFilterOpen={setIsAdvancedSearchOpen}
                          mid={mid}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="report-grid-view mt-3">
                  <div className="material-table-records mb-3">
                    <MDBDataTable
                      className="dataTable"
                      striped
                      bordered
                      small
                      data={{
                        columns: columns,
                        rows: mappedData,
                      }}
                      searching={true}
                      sorting={true}
                      responsive
                      hover
                      footer="none"
                    />
                  </div>
                  <DownloadCsv DownLoadData={data} Header={headerLabelsForPayout}  Name={`${mid}-Payout`}/>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SinglePayout;