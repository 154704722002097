import React, { useEffect, useState } from "react";
import SubTabManage from "./SubTabManage";
import InactiveServices from "../components/InactiveServices";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import "../stylesheet/Reports.css";
import SubTabForAccounts from "./SubTabForaccounts";
import SubTabForbank from "./SubTabForbank";
import SubTabForFRM from "./SubTabForFRM";
import SubTabForRouting from "./SubTabForRouting";
import MyLoader from "../common/MyLoader";
import SubTabForCreateMerchant from "./SubTabForCreateMerchant";

const Applications = () => {
  const [activePhase, setActivePhase] = useState("Phase1");
  const [activeSubtab, setActiveSubtab] = useState("Applications");
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setActivePhase("Phase1");
    setActiveSubtab("Applications");
  }, []);

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  return (
    <>
    {showLoader ? <MyLoader/> : ""}
      <section className="dashboard-header-sec">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    {/* <Card.Title className="h3">Reports</Card.Title>  */}
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn">
                        <li
                          className={activePhase === "Phase1" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase1")}
                        >
                          Applications
                        </li>

                        <li
                          className={activePhase === "Phase2" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase2")}
                        >
                          Accounts
                        </li>
                        <li
                          className={activePhase === "Phase3" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase3")}
                        >
                          Fraud And Risk Management
                        </li>
                        <li
                          className={activePhase === "Phase4" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase4")}
                        >
                          Bank
                        </li>

                        {/* <li
                          className={activePhase === "Phase5" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase5")}
                        >
                          Routing
                        </li> */}
                        <li
                          className={activePhase === "Phase6" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase6")}
                        >
                          Create Merchant
                        </li>
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <div className="admin-tab-content">
        {activePhase === "Phase1" ? (
          <SubTabManage 
          setShowLoader = {setShowLoader}/>
        ) : activePhase === "Phase2" ? (
          <SubTabForAccounts
          setShowLoader = {setShowLoader} />
        ) : activePhase === "Phase3" ? (
          <SubTabForFRM 
          setShowLoader = {setShowLoader}/>
        ) : activePhase === "Phase4" ? (
          <SubTabForbank 
          setShowLoader = {setShowLoader}/>
        ) : activePhase === "Phase5" ? (
          <SubTabForRouting
          setShowLoader = {setShowLoader} />
        ) : activePhase === "Phase6" ? (
          <SubTabForCreateMerchant
          
          setShowLoader = {setShowLoader} />
        ) : (
          <InactiveServices />
        )}
      </div>
    </>
  );
};
export default Applications;