import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { SWIPELINC_API } from "../utils/constant";
import { Container, Row, Col, Card } from "react-bootstrap";
import "../stylesheet/ViewDetails.css";
import swal from "sweetalert";
import MyLoader from "../common/MyLoader";

const MoaDetails = () => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const selectedServicesFromUrl = JSON.parse(
    queryParams.selectedServices || "[]"
  );

  const navigate = useNavigate();

  const [merchantData, setMerchantData] = useState(null);
  const [showLoader, setShowLoader] = useState(Boolean);
  const [open, setOpen] = useState(false);
  const [paymentservice, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState(
    selectedServicesFromUrl
  );
  const [showPaymentServicesList, setShowPaymentServicesList] = useState(false);
  const [rejectionRemark, setRejectionRemark] = useState("");
  const [viewDataClicked, setViewDataClicked] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [pdfFilenames, setPdfFilenames] = useState([]);
  const token = localStorage.getItem("token");

  const { id } = useParams();
  console.log(id);

  const handleClosePDF = () => {
    window.close();
  };

  const handleApprove = () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to approve this vendor?",
      icon: "warning",
      buttons: ["Cancel", "Yes, approve it!"],
      dangerMode: true,
    }).then((isConfirmed) => {
      if (isConfirmed) {
        const axiosInstance = axios.create({
          baseURL: `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard`,
          headers: {
            Authentication: `Bearer ${token}`,
          },
        });

        const payload = {
          applicationStatus: "Approved",
          payoutAutoApprovel: true,
          registrationId: id,
          rejectionRemark: "NA",
          showReferralTransaction: true,
          vendorAutoApproved: true,
        };
        setShowLoader(true);
        axiosInstance
          .put("/updateStage2", payload)
          .then((response) => {
            swal("Success", "Application approved!", "success").then(() => {
              setOpen(false);
              navigate("/Applications");
            });
			setShowLoader(false)
          })
          .catch((error) => {});
      } else {
        setOpen(false);
      }
    });
  };
  // handle rejection:

  const handleReject = () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to reject this vendor?",
      icon: "warning",
      buttons: ["Cancel", "Yes, reject it!"],
      dangerMode: true,
      content: {
        element: "input",
        attributes: {
          type: "text",
          placeholder: "Enter rejection remark",
        },
      },
    }).then((remark) => {
      if (remark) {
        const axiosInstance = axios.create({
          baseURL: `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard`,
          headers: {
            Authentication: `Bearer ${token}`,
          },
        });

        const payload = {
          applicationStatus: "rejected",
          payoutAutoApprovel: false,
          registrationId: id,
          rejectionRemark: remark,
          showReferralTransaction: false,
          vendorAutoApproved: false,
        };
setShowLoader(true)
        axiosInstance
          .put("/updateStage2", payload)
          .then((response) => {
            swal("Success", "Application Rejected!", "success").then(() => {
              setOpen(false);
              navigate("/Applications");
            });
			setShowLoader(false)

          })
          .catch((error) => {});
      } else {
        setOpen(false);
      }
    });
  };

  // GET MERCHANT DETAILS BY ID:

  useEffect(() => {
    const token = localStorage.getItem("token");

    // Create Axios instance with the default header
    const axiosInstance = axios.create({
      baseURL: `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard`,
      headers: {
        Authentication: `Bearer ${token}`,
      },
    });
setShowLoader(true)
    axiosInstance
      .get(`/getmerchantById/${id}`)
      .then((response) => {
        setMerchantData(response.data.data);
        setSelectedServices(
          response.data.data.paymentService.map(
            (service) => service.paymentService
          )
        );
		setShowLoader(false)
      })
      .catch((error) => {
        console.error("Error fetching merchant details:", error.message);
      });
    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/paymentType/getALLPaymentType`
      )
      .then((response) => {
        setServices(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching payment services:", error.message);
      });
  }, [id]);

  const handleViewData = (mid) => {
    const axiosInstance = axios.create({
      baseURL: `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard`,
      headers: {
        Authentication: `Bearer ${token}`,
      },
    });

    axiosInstance
      .get(`/viewData/${mid}`)

      .then((response) => {
        if (response.data.statusCode === 200) {
          setPdfFilenames(response.data.data);
          setViewDataClicked(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error.message);
      });
  };

  const handleViewPDF = (filename) => {
    const encodedFilename = encodeURIComponent(filename);
    const headers = {
      Authentication: `Bearer ${token}`,
    };

    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/view-pdf/${encodedFilename}`,
        {
          responseType: "arraybuffer",
          headers,
        }
      )
      .then((response) => {
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
        // setSelectedFile(pdfUrl);
      })
      .catch((error) => {
        console.error("Error fetching PDF:", error.message);
      });
  };

  useEffect(() => {
    handleViewData();
  }, []);

  if (!merchantData) {
    return <div><MyLoader/></div>;
  }
  return (
    <>
	{showLoader?<MyLoader/>:""}
      <section className="admin-view-details">
        <Container>
          <Card className="shadow border-0 mb-4 ds-header-card">
            <Card.Body>
              <Row>
                <Col xs={12} xl={12} lg={12} sm={12}>
                  <div className="two-column-layout">
                    <div className="vertical-table">
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Company Name
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData.companyName}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Authorized Person Name
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData.authorizedPersonName}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Services
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() =>
                              setShowPaymentServicesList(
                                !showPaymentServicesList
                              )
                            }
                          >
                            View Services
                          </button>
                          {/* Render the selected payment services as text */}
                          {showPaymentServicesList && (
                            <div className="payment-services-list">
                              {paymentservice.map(
                                (service) =>
                                  selectedServices.includes(
                                    service.paymentService
                                  ) && (
                                    <span
                                      key={service.paymentTypeId}
                                      className="selected-service"
                                    >
                                      {service.paymentService}
                                    </span>
                                  )
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Merchant Bank Name
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData.merchantBankName}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Merchant Account Number
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData.merchantAccountNumber}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Merchant IFSC Code
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData.merchantIfscCode}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Website
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData.websiteUrl}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          CallBack URL
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData.callBackUrl}
                        </div>
                      </div>

                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Referred By
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData.referredBy}
                        </div>
                      </div>
                    </div>
                    {viewDataClicked && (
                      <div className="pdf-list-container report-grid-view">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>File Name</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {pdfFilenames.map((fileObject, index) => (
                              <tr key={index}>
                                {/* <td>{fileObject["Orignal name"]}</td> */}
                                <td>{fileObject["File name"]}</td>
                                <td>
                                  <button
                                    className="btn btn-secondary"
                                    onClick={() =>
                                      handleViewPDF(fileObject["Orignal name"])
                                    }
                                  >
                                    View PDF
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                    <div className="button-toolbar groups-btn text-end">
                      <button
                        className="btn btn-warning"
                        onClick={() => handleViewData(merchantData.mid)}
                      >
                        View Data
                      </button>
                      <button
                        className="btn btn-success"
                        onClick={() => handleApprove()}
                      >
                        Approve
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleReject()}
                      >
                        Reject
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => navigate(-1)}
                      >
                        Go Back
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </section>

      {selectedFile && (
        <div className="pdf-viewer">
          <iframe src={selectedFile} title="PDF Viewer" />

          <div className="button-toolbar groups-btn text-center">
            <button className="btn btn-danger" onClick={handleClosePDF}>
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default MoaDetails;
