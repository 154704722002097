import React, { useEffect, useState } from "react";
import { fetchAllOrganisationalType } from "../utils/ApiServices";
import { Card, Col, Container, Row } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import swal from "sweetalert";
import { SWIPELINC_API } from "../utils/constant";
import axios from "axios";
import OrganisationalTypeEdit from "./OrganisationalTypeEdit";

const SubmittedOrganisationalType = ({setShowLoader}) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [organisationTypeData, setOrganisationTypeData] = useState([]);
  const [openView, setOpenView] = useState("");
  const [callAgain, setCallAgain] = useState(false);
  const [Id, setId] = useState("");
  const [data, setData] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    GetOrganisationalType(token);
  }, [callAgain,isModalOpen]);

  const GetOrganisationalType = async (token) => {
    try {
      const response = await fetchAllOrganisationalType(token); 

      if (response.statusCode === 200) {
        setOrganisationTypeData(response?.data);
      } else {
        setOrganisationTypeData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

 

  const columns = [
    { label: "Organisational Type", field: "type", width: "auto" },
    {label: "Organisational Type ID", field: "organisationalTypeId",width: "auto",},
    { label: "Status", field: "Active", width: "auto" },
    { label: "Action", field: "action", width: "150px" },
  ];

  const mappedData = organisationTypeData
    ? organisationTypeData?.map((item) => {
        const mappedItem = { ...item };
        mappedItem.Active = item.active ? "Active" : "Not Active";
        mappedItem.action = (
          <div className="d-flex gap-2 justify-content-evenly">
            <button
              className={`btn btn-primary ${item.active?"bg-danger":"bg-success"} border border-none`}
              onClick={() =>
                EnableDisable(
                  item.organisationalTypeId,
                  item.active ? "Disable" : "Enable"
                )
              }
            >
              {item.active ? "Disable" : "Enable"}
            </button>
            <button
             className="btn btn-warning btn-sm"
              onClick={() =>
                Update(
                  item.type, 
                  item.organisationalTypeId,
                )
              }
            >
              Edit
            </button>
          </div>
        );
        return mappedItem;
      })
    : [];

  const EnableDisable = (id, ActionType) => {
    swal({
      title: "Alert",
      text: "Are you sure, do you want to disable?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDisable) => {
      if (willDisable) {
        setShowLoader(true);
        axios
          .post(
            `${SWIPELINC_API}MerchantAdminPanel/api/organisational-types/${ActionType.toLowerCase()}/${id}`,
            "",
            {
              headers: {
                Authentication: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log(response);

            if (response.data.statusCode === 200) {
              swal({
                title: "Success!",
                text: `Organisational Type ${ActionType}d Successfully`,
                icon: "success",
              });

              //   FetchData(token);
            } else {
              swal({
                title: "Alert!",
                text: response.data.message,
                icon: "error",
              });
            }
            setCallAgain(!callAgain);
            setShowLoader(false);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            setShowLoader(false);
          });
      }
    });
  };

  const Update=(type,id)=>{
    setData(type)
    setId(id)
    setIsModalOpen(true)

  }

  const handleCloseModal=( )=>{
    
    setIsModalOpen(false)

  }
  return (
    <>
      
        <Row>
          <Col xs={12} lg={12} md={12}>
            
            <Card className="shadow border-0 mb-4">
              <Card.Body>
                <div className="report-grid-view">
                  <div className="material-table-records mb-3">
                    <MDBDataTable
                      className="dataTable"
                      striped
                      small
                      bordered
                      hover
                      responsive
                      data={{
                        columns: columns,
                        rows: mappedData,
                      }}
                      searching={true}
                      entries={50}
                      exportToCSV
                      theadColor="#333"
                      paginationLabel={["Previous", "Next"]}
                      sortable={true}
                      footer="none"
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
            <OrganisationalTypeEdit handleCloseModal={handleCloseModal} isModalOpen={isModalOpen} data={data } setData={setData} id={Id} />
          </Col>
        </Row>
       
    </>
  );
};

export default SubmittedOrganisationalType;
