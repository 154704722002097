import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import swal from "sweetalert";
import { fetchBankFormByMid } from "../utils/ApiServices";
// import { fetchBankFormByMid } from '../utils/ApiServices';

const SingleSubmittedBankForm = ({
  setOpenView,
  mid,
  openView,
  name,
  show,
}) => {
  const [responseData, setResponseData] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));

 
  const FetchDataBymid = async (mid, token) => {
    try {
      const apiResponse = await fetchBankFormByMid(mid, token);
      if (apiResponse.statusCode === 200) {
        setResponseData(apiResponse?.data);
        console.log(apiResponse?.data);
      } else {
      }
    } catch (error) {
      // console.error(error);
      console.log("Something went wrong. Please try again.");
    }
  };
  useEffect(() => {
    FetchDataBymid(mid, token);
  }, []);

  return (
    <>
      <section className="admin-view-details">
        <Container>
          <Card className="shadow border-0 mb-4 ds-header-card">
            <Card.Body>
              <Row>
                <Col>
                  <div className="two-column-layout">
                    <div className="vertical-table mb-4 px-4">
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Merchant Name
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {name}
                        </div>
                      </div>

                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">MID</div>
                        <div className="vertical-table-cell-value text-left">
                          {mid}
                        </div>
                      </div>

                      {responseData?.map((item, index) => (
                        <div
                          className="w-50 d-flex flex-column gap-2 mt-5"
                          key={index}
                        >
                          <div className="d-flex align-items-center   w-100">
                            <div className="text-left w-50">Service Name</div>
                            <div className=" border border-1 px-5 p-2 w-25 text-left">
                              {item.service}
                            </div>
                          </div>
                          <div className="d-flex align-items-center w-100">
                            <div className="text-left w-50">Service Provider Name</div>
                            <div className=" border border-1 px-5 p-2 w-25 text-left">
                              {item.providerName || "-"}
                            </div>
                          </div>
                          {!show ? (
                            ""
                          ) : (
                            <div className="d-flex align-items-center w-100">
                              <div className="text-left w-50">SID</div>
                              <div className=" border border-1 px-5 p-2 w-25 text-left">
                                {item.psid || "-"}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="d-flex flex-end">
                <Col>
                  <div className="button-toolbar groups-btn text-end">
                    <button
                      className="btn btn-primary"
                      onClick={() => setOpenView(false)}
                    >
                      Go Back
                    </button>
                  </div>
                </Col>
              </Row>
              <Row></Row>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
};

export default SingleSubmittedBankForm;
