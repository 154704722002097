import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import { SWIPELINC_API } from "../../../utils/constant";
import ViewVendor from "./ViewVendor";
import { Container, Card, Row, Col } from "react-bootstrap";

function SubmittedVendor({setShowLoader}) {
  const [vendor, setSubmittedVendor] = useState([]);
  const [openView, setOpenView] = useState(false);
  const [id, setId] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const navigate = useNavigate();

 

  const View = (vendorId) => {
    setId(vendorId);
    setOpenView(true);
  };

  const columns = [
    // { label: ' ID', field: 'vendorId', width: 'auto' },
    { label: "Vendor Name", field: "fullName", width: "auto" },
    { label: "Merchant Name", field: "companyName", width: "auto" },
    { label: "e-Mail", field: "email", width: "auto" },
    { label: "Category", field: "category", width: "auto" },
    { label: "Mobile", field: "mobile", width: "auto" },
    // { label: 'Vendor Status', field: 'vendorStatus', width: 'auto' },
    { label: "Company Name", field: "companyName", width: "auto" },
    { label: "Merchant ID", field: "parentMid", width: "auto" },
    { label: "Action", field: "action", width: "150px" },
  ];

  const mappedData = vendor
    ? vendor.map((item) => {
        const mappedItem = { ...item };
        mappedItem.action = (
          <div>
            <button
              className="btn btn-primary"
              onClick={() => View(item.vendorId)}
            >
              View Details
            </button>
          </div>
        );
        return mappedItem;
      })
    : [];
    useEffect(() => {
      const axiosInstance = axios.create({
        baseURL: `${SWIPELINC_API}MerchantAdminPanel/merchant/vendor`,
        headers: {
          Authentication: `Bearer ${token}`,
        },
      });
      setShowLoader(true)
      axiosInstance
        .get("/getAllVendors/Submitted", {
          params: {
            vendorStatus: "Submitted",
          },
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            setSubmittedVendor(response.data.data);
      
          }else{
            setSubmittedVendor([])
            console.error("Error fetching data:", response.data.message);
          }
          setShowLoader(false)
        })
        .catch((error) => {
          console.error("Error fetching vendor:", error.message);
          setShowLoader(false)
        });
        console.log(mappedData)
    }, [openView]);

  return (
    <Container>
      <Row>
        <Col xs={12} lg={12} md={12}>
          <Card className="shadow border-0 mb-4">
            <Card.Body>
              <div className="report-grid-view">
                <div className="material-table-records mb-3">
                  {!openView ? (
                    <MDBDataTable
                      className="dataTable"
                      striped
                      small
                      bordered
                      hover
                      responsive
                      data={{
                        columns: columns,
                        rows: mappedData,
                      }}
                      searching={true}
                      entries={50}
                      exportToCSV
                      theadColor="#333"
                      paginationLabel={["Previous", "Next"]}
                      sortable={true}
                      footer="none"
                    />
                  ) : (
                    <ViewVendor id={id} setOpenView={setOpenView} />
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default SubmittedVendor;
