import React, { useState } from "react";
import {  Col, Card,  Container } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { useEffect } from "react";
import { FIND_BY_ORDER_NO, } from "../../utils/constant";
import { useParams } from "react-router-dom";
import MyLoader from "../../common/MyLoader";
import swal from 'sweetalert';

function ViewTxnStatus({mid,orderNo, onCloseModal}) {
 
  const [searchValue, setSearchValue] = useState("");
  const [data1, setData1] = useState([]);
  const [error1, setError1] = useState(null);

  const [orderNumber, setOrderNumber] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const token = localStorage.getItem("token");

 
  const getData1 = async (orderNo) => {
   const Payload = {
      mid: mid,
      orderNumber: orderNo,
      rrn: "",
    };

    console.log(Payload);
    try {
        // setShowLoader(true);
        const response = await axios.post(FIND_BY_ORDER_NO, Payload, {
          headers: {
            Authentication: "Bearer " + token,
          },
        });
        // setShowLoader(false);
        onCloseModal()
        setData1(response?.data);
        console.log(" data is", response?.data);
      } catch (error) {
        setError1(true);
        console.error(error);
        swal({
          title: "Alert!",
          text: "Something Went Wrong.",
          icon: "error",
        });
        // setShowLoader(false);
        onCloseModal()
        setData1([]); 
      }
    }
  var data2 = [];

  const handleSearch = (ordNo) => {
    getData1(ordNo);
    data2 = [data1];
  };

  useEffect(() => {
    setOrderNumber(orderNo);
  }, []);

  useEffect(() => {
    handleSearch(orderNo);
  }, [orderNo]);

  const column = [
    {
      label: "Customer Name",
      field: "customerName",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Transaction ID",
      field: "extTransactionId",
      sort: "asc",
      width: "850px",
    },
    {
      label: "Status",
      field: "primaryStatus",
      sort: "asc",
      width: "850px",
    },
    {
      label: "Actual Status",
      field: "actualStatus",
      sort: "asc",
      width: "850px",
    },

    {
      label: "Transaction Time",
      field: "txnTime",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Amount",
      field: "amount",
      sort: "asc",
      width: "auto",
    },
    {
      label: "UPI ID",
      field: "upiId",
      sort: "asc",
      width: "auto",
    },
    {
      label: " UTR No",
      field: "custRefNo",
      sort: "asc",
      width: "auto",
    },

    {
      label: "Remark",
      field: "remark",
      sort: "asc",
      width: "auto",
    },
  ];

  const datas = {
    columns: column,
    rows: data1.responseData,
   
  };

  return (
   
    <>
       <section className="lincpay-dashboard-sec merchant-admin-info-form aadharform-sec">
        <Container>
          <div className="cart-view-sec mt-4">
            <Col xs={12} lg={12} md={12}>
              <Card className="shadow-cst border-0 mb-3 chart-boxes merchant-info-form-sec">
                <Card.Body>
                  <div className="merchant-form-info">
                    <Col xs={12} lg={8} md={8}>
                      <div className="form-group">
                        <label htmlFor="companyPanNumber" className="form-label">
                          Enter Order No
                          <sup className="text-danger">*</sup>
                        </label>
                        <div className="input-field-validate">
                          <input
                            type="text"
                            value={orderNumber}
                            onChange={(e) => setOrderNumber(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} lg={8} md={8}>
                      <div className="form-group text-lg-end">
                        <button
                          id="companyPanVerifyBtn"
                          type="button"
                          className="btn btn-primary"
                          onClick={handleSearch}
                        >
                          <i className="bi bi-search mx-1"></i>Search
                        </button>
                      </div>
                    </Col>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </div>
        </Container>
      </section>

      <section className="lincpay-dashboard-sec merchant-admin-info-form aadharform-sec">
        <Container>
          <div className="cart-view-sec mt-4">
            <Col xs={12} lg={12} md={12}>
              {/* Tab content start here */}
              <Card className="shadow-cst border-0 mb-3 chart-boxes merchant-info-form-sec">
                <Card.Body>
                  <div className="merchant-form-info d-flex justify-content-center">
                    <Col >
                      {data1.statusCode === 200 ? (
                        <MDBDataTable
                          className=" dataTable overflow-x-scroll"
                          data={datas}
                          footer="none"
                          striped
                          bordered
                          exportToCSV
                          responsive
                          theadColor="#333"

                          
                        />
                      ) : (
                        <div className="no-data-message fs-5 w-100 bg-secondary fw-semibold rounded-3 px-5 pl-5 p-2 d-flex align-items-center justify-content-center">
                          {" "}
                          No data available.{" "}
                        </div>
                      )}
                    </Col>
                  </div>
                </Card.Body>
              </Card>
              {/* Tab content end here */}
            </Col>
            {/* ./col */}
          </div>
        </Container>
      </section>
    </>
  );
}
export default ViewTxnStatus;