import axios from 'axios';
import React, { useState } from 'react'
import { SWIPELINC_API } from '../../utils/constant';
import swal from 'sweetalert';
import { Button, Form, Modal } from 'react-bootstrap';
import { DateTime } from 'luxon';

const MyModalGuarenteeAmount = ({mid,showModal,closeModal,transactionType}) => {
    const [data, setData] = useState('');
    const [date, setDate] = useState('');
    const [amount, setAmount] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
  
  
     const token = localStorage.getItem("token")
    
   console.log(showModal,mid)
   
const  APIURL=(transactionType)=>{
  if(transactionType==="credit" || transactionType==="debit"){
    return "MerchantAdminPanel/admin/guarantAmount/save"
  }else if(transactionType==="hold" || transactionType==="deposit" || transactionType==="guarentee" || transactionType==="settlement"){
    return "MerchantAdminPanel/merchant/withdrawal/save"
  }
}

const  payload=(transactionType)=>{
  if(transactionType==="credit" || transactionType==="debit"){
    return {

      guarantyAmount:amount,
      mid: mid, 
      transactionType: transactionType,
      transactionDate:date,
      utrNumber: data, 
  }
  }else if(transactionType==="hold" || transactionType==="deposit" || transactionType==="guarentee" || transactionType==="settlement"){
    return {

       amount:amount,
      mid: mid, 
      transactionType: transactionType,
      date:date,
      utr: data, 
  }
  }
}
const handleAmountChange = (event) => {
  const value = event.target.value;

 
  if (/^(?!0\d)(\d*(\.\d*)?)$/.test(value)) {
    setAmount(value);
  }
};
    


      const handleDateInputChange = (event) => {
        const newDate = event.target.value;
      
        // Create a DateTime object from the input value
        const inputDateTime = DateTime.fromISO(newDate, { zone: "UTC" });
      
        // Check if the input date is valid (i.e., it was successfully parsed)
        if (!inputDateTime.isValid) {
          swal({
            title: "Invalid Date",
            text: "Please enter a valid date.",
            icon: "warning",
          });
          return;
        }
      
        // Check if the input date is greater than today's date
        const today = DateTime.now().startOf("day");
        console.log(today,inputDateTime)
      
        if (inputDateTime.toISODate()    > today.toISODate()) {
          swal({
            title: "Invalid Date",
            text: "Please select a date that is not in the future.",
            icon: "warning",
          });
        } else {
        
          setDate(inputDateTime.toISODate()); // Assuming you want to store the date as a string (ISO format)
        }
      };
   
      const handleSubmit = () => {
 
    
        if (isSubmitting||
          !data.length ||
          !mid.length ||
          !amount.length ||
          !date.length
        ) {
          swal({
            title: "Warning",
            text: "Please Fill the Mandatory fields.",
            icon: "warning",
          });
          return;
        }
        
        setIsSubmitting(true);
    
        try {
          axios
            .post(
              `${SWIPELINC_API}${APIURL(transactionType)}`,
              payload(transactionType),
              {
                headers: {
                  Authentication: `Bearer ${token}`,
                },
              }
            ).then((response) => {
              if (response.data.statusCode === 200){
                swal({
                  title: "Saved",
                  text: "Data Saved Successfully.",
                  icon: "success",
                 
                }) 
                setData('')
                setAmount('')
                setDate('')
                setIsSubmitting(false)
                closeModal()
              }else{
                swal({
                  title: "Failed",
                  text: `${response.data.message}`,
                  icon: "failed",
                 
                }) 
              }
            })
            
        } catch (error) {
          console.log(error);
          swal({
            title: "Failed",
            text: `Something went wrong.`,
            icon: "failed",
           
          }) 
          setData('')
    
        
          setIsSubmitting(false);
        }
  }      
   
     
      
    return (<>
       {/* <div className="popup-overlay">
       <div className="popup-content"> */}
      <Modal show={showModal} onHide={closeModal} size='lg'>
       
        <Modal.Header closeButton>
          <Modal.Title>Enter Details</Modal.Title>
         
        </Modal.Header>
        <Modal.Body  >
          <Form className='w-100'>
             
            <Form.Group>
              <label>Amount *</label>
              <Form.Control
                type="text"
                placeholder="Enter Amount"
                value={amount}
                onChange={handleAmountChange}
              />
              <label>Reference Number *</label>
              <Form.Control
                type="text"
                placeholder="Enter Reference Number"
                value={data}
                onChange={(e) => setData(e.target.value)}
              />
               <label className='mt-3'>Transaction Date *</label>
              <Form.Control
                type="date"
                placeholder="Enter Transaction Date"
                value={date}
                onChange={handleDateInputChange}
              />
              
            </Form.Group>
            
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={()=>handleSubmit()}>
            Submit
          </Button>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      
   </> );
  };
  

export default MyModalGuarenteeAmount