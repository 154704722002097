import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import InactiveServices from '../components/InactiveServices';

import SubmittedPayoutSIDApplications from './SubmittedPayoutSIDApplications';
import PayoutMaster from './PayoutMaster';
import Disabled from './Disabled';
import VehicleMasterFormSubTab from './VehicleMasterFormSubtab';
import OrganisationalTypeSubTab from './OrganisationalTypeSubTab';
import VehicleCommercialTypeSubTab from './VehicleCommercialTypeSubTab';
import VehicleSettlementSubTab from './VehicleSettlementSubTab';
import VehicleCommercialSubTab from './VehicleCommercialSubTab';
import VehicleTypeSubTab from './VehicleTypeSubTab';
import VehicleSubTab from './VehicleSubTypeSubTab';
import VehicleSubTypeSubTab from './VehicleSubTypeSubTab';

const SubTabForVehicleMaster = ({setShowLoader}) => {
    const [activePhase, setActivePhase] = useState("Phase1");

    useEffect(() => {
      setActivePhase("Phase1");
    }, []);
  
    const handlePhaseClick = (phase) => {
      setActivePhase(phase);
    };
  

  return (
    <>
    <section className="dashboard-header-sec subtab-card">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-3  ds-inner-card">
                  <Card.Body>
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn submenu-tab-admin">
                        <li
                          className={activePhase === "Phase1" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase1")}
                        >
                          Vehicle Master Form {activePhase === "Phase1"}
                        </li>
                        <li
                          className={activePhase === "Phase2" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase2")}
                        >
                         Organisational Type {activePhase === "Phase2"}
                        </li>    
                        <li
                          className={activePhase === "Phase3" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase3")}
                        >
                        Vehical Commercial Type {activePhase === "Phase3"}
                        </li>       
                        <li
                          className={activePhase === "Phase4" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase4")}
                        >
                        Vehicle Settlement Type {activePhase === "Phase4"}
                        </li>       
                        <li
                          className={activePhase === "Phase5" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase5")}
                        >
                       Vehicle Commercial {activePhase === "Phase5"}
                        </li>       
                        <li
                          className={activePhase === "Phase6" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase6")}
                        >
                       Vehicle Type {activePhase === "Phase6"}
                        </li>       
                        <li
                          className={activePhase === "Phase7" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase7")}
                        >
                       Vehicle Sub-Type {activePhase === "Phase7"}
                        </li>                          
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>
            </Row>
            <div className="admin-tab-content">
              {activePhase === "Phase1" ? (
              <VehicleMasterFormSubTab
              setShowLoader={setShowLoader}
              />
                
              ) : activePhase === "Phase2" ? (
                <OrganisationalTypeSubTab
                setShowLoader={setShowLoader}
                />
                // <InactiveServices />
              ) :activePhase === "Phase3" ? (
               <VehicleCommercialTypeSubTab
               setShowLoader={setShowLoader}
               /> 
               
              ) :activePhase === "Phase4" ? (
                <VehicleSettlementSubTab
                setShowLoader={setShowLoader}
                />
              ):activePhase === "Phase5" ? (
                <VehicleCommercialSubTab
                setShowLoader={setShowLoader}/>
              ):activePhase === "Phase6" ? (
                <VehicleTypeSubTab
                setShowLoader={setShowLoader}
                />
              ):activePhase === "Phase7" ?(
               <VehicleSubTypeSubTab
               setShowLoader={setShowLoader}
               />
              ):
               
              (
                <InactiveServices />
              )}
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}


export default SubTabForVehicleMaster;