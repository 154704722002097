import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { PencilSquare, Trash } from 'react-bootstrap-icons';
import ".././assets/css/style.css";
import DeleteModal from '.././DeleteModal';
import { MDBDataTable } from 'mdbreact';
import Papa from 'papaparse';
import { FiDownload } from "react-icons/fi";
import { SWIPELINC_API } from '../utils/constant';
// import { Calendar } from 'react-calendar';

function ChargebackFile() {
  const [files, setFiles] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [filterValue, setFilterValue] = useState('');
  const [resetFilters, setResetFilters] = useState(false);
//  const [isAdvancedFilterOpen, setIsAdvancedFilterOpen] = useState(false);
 const [filteredData,setfilteredData]= useState ([]);
 const [isAdvancedFilterModalOpen, setIsAdvancedFilterModalOpen] = useState(false);
 const [filterButtonPosition, setFilterButtonPosition] = useState({ top: 0, left: 0 });
  const [token, setToken] = useState(localStorage.getItem("token"));
  
    useEffect(() => {
    fetchChargebackFile();
  }, []);
 
  const fetchChargebackFile = async () => {
    try {
     
      const response = await axios.get(`${SWIPELINC_API}MerchantAdminPanel/chargeBack`,{
        headers:{
          Authentication:`Bearer ${token}`,
        },
      })

      if (Array.isArray(response.data.data)) {
        setFiles(response.data.data);
        setfilteredData(response.data.data);
      } else {
        console.error('Response data is not an array:', response.data);
      }
    } catch (error) {
      console.error('Error fetching chargeback files:', error);
    }
  };
  const handleUpdate = async (editedFile) => {
    try {
     
      const response = await axios.put(
        `${SWIPELINC_API}MerchantAdminPanel/chargeBack/update/${editedFile.id}`,
        editedFile,
        {
          headers: {
            Authentication: `Bearer ${token}`, 
          },
        }
      );
  
      console.log('File updated:', response.data);
  
      const updatedFiles = files.map((f) => {
        if (f.id === editedFile.id) {
          return response.data; 
        }
        return f;
      });
  
      setFiles(updatedFiles);
    } catch (error) {
      console.error('Error updating file:', error);
    }
  };
  
  const handleDelete = async (file) => {
    if (openModal) {
      try {
      
        const response = await axios.delete(
          `${SWIPELINC_API}MerchantAdminPanel/chargeBack/delete/${file.id}`,
          {
            headers: {
              Authentication: `Bearer ${token}`,
            },
          }
        );
  
        console.log('File deleted:', response.data);
        setDeleteSuccess(true); // Set delete success state to true
      } catch (error) {
        console.error('Error deleting file:', error);
      }
    } else {
      setOpenModal(true);
    }
  };
  const handleEdit = (file) => {
    setSelectedFile(file);
    setEditFormOpen(true);
  };
  // SEARCH BOX:
  
  const handleSearch = (searchQuery) => {
    const trimmedSearchTerm = searchQuery.trim(); 

    const filtered = files.filter((item) =>
      Object.values(item).some((value) => {
        if (value === null) {
          return false; // Skip null values
        }
        return value.toString().toLowerCase().includes(trimmedSearchTerm.toLowerCase());
      })
    );
    setfilteredData(filtered);
    setCurrentPage(1);
  }
  
  // ADD DATE AND ADVANCED FILTER:

  const fetchDateReport = async () => {
    function formatDateToYYYYMMDD(inputDate) {
      const date = new Date(inputDate);
      
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      
      return `${year}-${month}-${day}`; 
    }
    const sdate=formatDateToYYYYMMDD(startDate);
  
    const ldate=formatDateToYYYYMMDD(endDate);
    console.log(sdate+"date is")
    try {
      
      const payload = {
        "chargeBackAdjDate": "",
        "emailId": '',
        "endDate": "",
        "mobileNumber": 0,
        "payeeIpAddress": '',
        "payerVpa": '',
        "rrn": '',
        "startDate": "",
        "transactionAmount": 0,
        "transactionUid": '',
        "upiTransactionId": '',
      };

      const response = await axios.post(
        `${SWIPELINC_API}MerchantAdminPanel/chargeBack/ByCondition`,
        payload,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      );

      if (response.data.statusCode === 200 && Array.isArray(response.data.data)) {
        setFiles(response.data.data)
        setfilteredData(response.data.responsedata);
      } else {
        console.error('No data found:', response.data.data);
      }
    } catch (error) {
      console.error('Error fetching chargeback data:', error);
    }
  };

  const handleAdvancedFilter = (filterType,filterValue) => {
    console.log('handleAdvancedFilter: filterType', filterType);
    console.log('handleAdvancedFilter: filterValue', filterValue);

    const trimmedFilterValue = filterValue.trim();
    console.log('handleAdvancedFilter: trimmedFilterValue', trimmedFilterValue);

    const filtered = files.filter((item) => {
      if (!trimmedFilterValue) {
        return true;
      }

      if (
       
        filterType === 'emailId' ||
        filterType === 'mobileNumber' ||
        filterType === 'payerVpa' ||
        filterType === 'rrn'

    )
         {
        const value = item[filterType];
        return value && value.toLowerCase().includes(trimmedFilterValue.toLowerCase());
      }

      return true;
    });

    setfilteredData(filtered);
    setCurrentPage(1);
  };

  const handleResetFilters = () => {
   setResetFilters(true)
   setfilteredData(files);
    setCurrentPage(1);
    setSelectedFilter(null);
    setFilterValue('');
  };

  const toggleAdvancedFilterModal = () => {
    const buttonRect = document.getElementById('advanced-filter-button').getBoundingClientRect();
    setFilterButtonPosition({ top: buttonRect.bottom + window.scrollY, left: buttonRect.left + window.scrollX });
    setIsAdvancedFilterModalOpen(!isAdvancedFilterModalOpen);
  };
  
//  MDBDATA TABLE:
  const columns = [
    { label: 'ID', field: 'id', width:'auto' },
    { label: 'RRN', field: 'rrn', width:'auto' },
    { label: 'Chargeback Adjustment Date', field: 'chargeBackAdjDate', width:'auto' },
    { label: 'UPI-Transaction-ID', field: 'upiTransactionId' , width:'auto'},
    { label: 'Chargeback Amount', field: 'chargeBackAmount' , width:'auto'},
    { label: 'Compensation Penalty', field: 'compensationPenalty', width:'auto' },
    { label: 'Transaction Date', field: 'transactionDate' , width:'auto'},
    { label: 'Transaction Amount', field: 'transactionAmount' , width:'auto'},
    { label: 'Payer-VPA', field: 'payerVpa' , width:'auto'},
    { label: 'Payee Bank', field: 'payeeBank' , width:'auto'},
    { label: 'Transaction UID', field: 'transactionUid' , width:'auto'},
    { label: 'Mobile Number', field: 'mobileNumber', width:'auto' },
    { label: 'e-Mail ID', field: 'emailId', width:'auto'  },
    { label: 'IFSC Code', field: 'ifscCode', width:'auto'  },
    { label: 'Bank Location', field: 'bankLocation' , width:'auto' },
    { label: 'Payee IP Address', field: 'payeeIpAddress', width:'auto'  },
    { label: 'Payee Location', field: 'payeeLocation', width:'auto'  },
    { label: 'MID', field: 'mid' , width:'auto' },
    { label: 'MID', field: 'mid' , width:'auto' },
    { label: 'MID', field: 'mid' , width:'auto' },
  ];
  
const mappedData = filteredData.map((item)=>{
  const mappedItem = {};
  columns.forEach ((column)=>{
    mappedItem[column.field]=item[column.field]
  })
  return mappedItem;
})
// DOWNLOAD CSV BUTTON:
const handleDownloadCSV = () => {
  const table = document.getElementById('chargeback-datatable-container');
  const visibleRows = table.querySelectorAll('.dataTable tbody tr');

  const visibleData = [];

  visibleRows.forEach(row => {
      const rowData = [];
      row.querySelectorAll('td').forEach(cell => {
          rowData.push(cell.textContent.trim());
      });
      visibleData.push(rowData);
  });

  const headerLabels = columns.map(column => column.label);
  const dataWithHeader = [headerLabels, ...visibleData];
  
  const csv = Papa.unparse(dataWithHeader);

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'chargeback_report.csv';
  link.style.display = 'none';

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
};

  return (
    <>
      
      <main className="dashboardPage">
        <section className="reportSection">
          <div className="container">
          <div className="tabs-content active" id="tab1">
            {deleteSuccess && ( // Display success message if deleteSuccess is true
                <div className="alert alert-success" role="alert">
                  Record deleted successfully!
                </div>
              )}
              <div className="card py-3">
              { openModal && <DeleteModal closeModal={setOpenModal}/>}
              <div className="search-container">
            
                {/* DATE FILTER: */}
                <div className="filter-container">
                  {/* <div className="date-filter">
                    <button
                      className={`date-filter-button ${isDateFilterOpen ? 'active' : ''}`}
                      onClick={() => setIsDateFilterOpen(!isDateFilterOpen)}
                    >
                      Date Filter
                    </button>
                    {isDateFilterOpen && (
                      <div className="date-filter-modal">

      <div className="date-filter-content">
        <Calendar
          onChange={date => setStartDate(date)}
          value={startDate}
          selectRange={false}
        />
        <Calendar
          onChange={date => setEndDate(date)}
          value={endDate}
          selectRange={false}
        />
        <button className='advanced-filterbutton' onClick={() => {fetchDateReport(); setIsDateFilterOpen(false); }}>OK</button>
      </div>
    </div>
  )}
</div> */}
                {/* ADVANCED FILTER */}
                <div className="advanced-filter">
                <button id="advanced-filter-button" className='advanced-filterbutton' onClick={toggleAdvancedFilterModal}> Advanced Filter</button>

                {isAdvancedFilterModalOpen && (
               <div className="advanced-filter-modal"
               style={{ top: filterButtonPosition.top, left: filterButtonPosition.left }}>

                    <select 
                    value={selectedFilter} 
                    onChange={(e) => setSelectedFilter(e.target.value)}
                    >
                      <option value="">Select </option>
                     <option value="emailId">e-Mail ID</option>
                      <option value="mobileNumber">Mobile Number</option>
                      <option value="payerVpa">Payer VPA</option>
                      <option value="rrn">RRN Number</option>
                     
                 
                    </select>

                    {selectedFilter && (
                    <input
                      type="text"
                      placeholder={`Enter ${selectedFilter}`}
                      value={filterValue}
                      onChange={(e) => setFilterValue(e.target.value)}
                    />
                    )}
      <div className='advreset'>
     <button className='advanced-filterbutton' onClick={handleResetFilters}>Reset </button> 
     <button className='advanced-filterbutton' onClick={() => handleAdvancedFilter(selectedFilter, filterValue)}>Apply</button>
   </div>
    </div>
      )}
              </div>
              </div>
              </div>
              
               {/* SORTING TABLE: */}
                <div id = "chargeback-datatable-container">
                <div className="text-left">
      <MDBDataTable className=' dataTable overflow-x-scroll'
                  striped
                  bordered
                  hover
                  responsive
                  data={{
                    columns: columns,
                    rows: mappedData,
                  }}
                  searching={true}
                  entries={50}
                  paginationLabel={['Previous', 'Next']}
                  sortable={true}
                  footer = "none"
                  theadColor='#333'
                  exportToCSV
                 
                />
            </div>
            <button className="advanced-btn btn btn-primary text-capitlize" onClick={handleDownloadCSV}>
              <FiDownload className="d-none" /><i className="bi bi-arrow-down-circle"></i> Download CSV
           </button>
                </div>
                
              </div>
            </div>
          </div>
        </section>
      </main>

  {editFormOpen && (
        <EditForm
          file={selectedFile}
          onSubmit={(editedFile) => {
            handleUpdate(editedFile);
            setEditFormOpen(false);
          }}
          onClose={() => setEditFormOpen(false)}
        />
  )}
     </>
  );
}

function EditForm({ file, onSubmit, onClose }) {
  const [editedFile, setEditedFile] = useState({ ...file });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(editedFile);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedFile((prevFile) => ({ ...prevFile, [name]: value }));
  };

  return (
    <div className="edit-form">
      <h2> Chargeback Edit Report</h2>
      <form onSubmit={handleSubmit} className="form">
          <div className="form-row">
          <label>RRN:</label>
          <input type="text" name="rrn" value={editedFile.rrn} onChange={handleChange} className="input"/>
        </div>
        <div className="form-row">
          <label>ChargeBack Adjustment Date:</label>
         <input type="text" name="chargeBackAdjDate" value={editedFile.chargeBackAdjDate} onChange={handleChange} className="input" />
         </div>
         <div className="form-row">
          <label>UPI Transaction ID:</label>
         <input type="text" name="upiTransactionId" value={editedFile.upiTransactionId} onChange={handleChange} className="input" />
         </div>
         <div className="form-row">
          <label>Chargeback Amount:</label>
         <input type="text" name="chargeBackAmount" value={editedFile.chargeBackAmount} onChange={handleChange} className="input"/>
         </div>
         <div className="form-row">
          <label>Compensation Penalty:</label>   
         <input type="text" name="compensationPanalty" value={editedFile.compensationPanalty} onChange={handleChange} className="input" />
         </div>
         <div className="form-row">
          <label>Transaction Date:</label>  
         <input type="text" name="transactionDate" value={editedFile.transactionDate} onChange={handleChange} className="input" />
         </div>
         <div className="form-row">
        
          <label>Transaction Amount:</label>
         <input type="text" name="transactionAmount" value={editedFile.transactionAmount} onChange={handleChange} className="input" />
         </div>
         <div className="form-row">
          <label>Payer VPA:</label>
         <input type="text" name="payerVpa" value={editedFile.payerVpa} onChange={handleChange} className="input"/>
         </div>
         <div className="form-row">
          <label>Payee Bank:</label>
         <input type="text" name="payeeBank" value={editedFile.payeeBank} onChange={handleChange} className="input" />
        
         </div>
         <div className="form-row">
          <label>Transaction UID:</label>
        <input type="text" name="transactionUid" value={editedFile.transactionUid} onChange={handleChange} className="input" />
        </div>
        <div className="form-row">
        
          <label>Mobile Number:</label>
        <input type="text" name="mobileNumber" value={editedFile.mobileNumber} onChange={handleChange} className="input" />
        </div>
        <div className="form-row">
        
          <label>Email Id:</label>
        <input type="text" name="emailId" value={editedFile.emailId} onChange={handleChange} className="input" />
        </div>
        <div className="form-row">
        
          <label>IFSC Code:</label>
         <input type="text" name="ifscCode" value={editedFile.ifscCode} onChange={handleChange} className="input" />
         </div>
         <div className="form-row">
         <label>bank Location:</label>
         <input type="text" name="bankLocation" value={editedFile.bankLocation} onChange={handleChange} className="input" />
         </div>
         <div className="form-row">
          <label>Is Delete:</label>
         <input type="text" name="isDelete" value={editedFile.isDelete} onChange={handleChange} className="input" />
         </div>
         <div className="form-row">
          <label>Payee IPAddress:</label>
         <input type="text" name="payeeIpAddress" value={editedFile.payeeIpAddress} onChange={handleChange} className="input" />
         </div>
         <div className="form-row">
          <label>payee Location:</label>
         <input type="text" name="payeelLocation" value={editedFile.payeelLocation} onChange={handleChange} className="input" />
         </div>
         <div className="form-row">
          <label>MID:</label>
         <input type="text" name="mid" value={editedFile.mid} onChange={handleChange} className="input" />
         </div>
         <div>
          <div>
        <button type="submit" className='rprtbtn1'>Save</button>
        <button type="button" onClick={onClose} className='rprtbtn2'> Cancel </button>
     </div>
     </div>
   </form>
   </div>
    
  );
}
export default ChargebackFile;
