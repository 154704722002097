import React, { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import {
  getMechantForPayoutApiTest,
  getMerchantInfoByIdPayout,
  testCallbackURLPayout,
} from "../../../utils/ApiServices";
import swal from "sweetalert";

const CallbackPayout = ({ setShowLoader }) => {
  const [merchant, setMerchant] = useState([]);
  const token = localStorage.getItem("token");

  const [parsedResponse, setParsedResponse] = useState(null);

  const [merchantData, setMerchantData] = useState({
    callbackURL: "",
    jsonString: `{
      "attempt": 0,
      "bankReferenceNumber": "",
      "beneficiaryName": "",
      "callbackTxnId": "",
      "decentroTxnId": "",
      "errorKey": "",
      "originalCallbackTxnId": "",
      "providerMessage": "",
      "referenceId": "",
      "timestamp": "",
      "transactionAmount": 0,
      "transactionMessage": "",
      "transactionStatus": "",
      "transferType": ""
    }`,
  });

  const [formData, setFormData] = useState({
    merchantMid: "",
    customCallbackURL: "",
    jsonString:""
  });

  const handelInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setMerchantData({ callbackURL: "" });
  };

  const getMerchantInfo = async (mid) => {
    if (mid === "") {
      swal({
        title: "Alert",
        text: "Please Select A Merchant",
        icon: "error",
      });
      return;
    }

    try {
      setShowLoader(true);
      const res = await getMerchantInfoByIdPayout(token, mid);
      setShowLoader(false);

      if (res.statusCode === 200) {
        setMerchantData({
          callbackURL: res.data[0]?.callbackUrl || "Callback URL not found",
        });
      } else {
        swal({
          title: "Alert",
          text: "callback URL not found",
          icon: "error",
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const getAllMerchantForApiTest = async () => {
    setShowLoader(true);
    let res = await getMechantForPayoutApiTest(token);
    setShowLoader(false);
    setMerchant(res?.data);
  };

  useState(() => {
    getAllMerchantForApiTest();
  }, []);

  const submitCallbackURL = async () => {
    const callbackURL = formData.customCallbackURL || merchantData.callbackURL;

    if (formData.merchantMid === "") {
      swal({
        title: "Alert",
        text: "Please select a merchant",
        icon: "error",
      });
      return;
    }
    
    try {
      
      setShowLoader(true);
      const jsonStringToSend =
      formData.customCallbackURL && formData.jsonString !== ""
        ? formData.jsonString
        : merchantData.jsonString;


      const res = await testCallbackURLPayout(
        token,
        callbackURL,
        jsonStringToSend || "{}" 
      );
      setShowLoader(false);

      if (res.status === "success") {
       
        const parsedData = {
          body: res.data.body,
          statusCode: res.data.statusCode,
          statusCodeValue: res.data.statusCodeValue,
        };
        setParsedResponse(parsedData);
        swal({
          title: "Success",
          text: "Callback URL Test Successful",
          icon: "success",
        });

      setMerchantData({
        callbackURL: "",
        jsonString: merchantData.jsonString,
      });
      setFormData({
        merchantMid: "",
        customCallbackURL: "",
        jsonString: merchantData.jsonString,
      });
      }
      else {
        swal({
          title: "Error",
          text: "Failed to test Callback URL",
          icon: "error",
        });
      }
    } catch (error) {
      setShowLoader(false);
      console.error("API Error:", error);
      swal({
        title: "Error",
        text: "An error occurred while testing Callback URL",
        icon: "error",
      });
      console.error("Error:", error);
    }
  };
  const handleChange = (e) => {
    setMerchantData({ ...merchantData, jsonString: e.target.value });
  };
  return (
    <Container>
      <Card className="shadow border-0 mb-4">
        <Card.Body>
          <div className="merchant-report-view">
            <div className="report-table-view">
              <div className="containerflag">
                <div className="row mt-4">
                  <div className="col-md-6 offset">
                    <div className="col-sm-12 col-md-6  offset-md-3">
                      <label className="form-label">
                        Select A Merchant
                        <sup className="text-danger">*</sup>
                      </label>
                      <div className="d-flex gap-2">
                        <select
                          name="merchantMid"
                          value={formData.mid}
                          onChange={(e) => {
                            handelInput(e);
                            getMerchantInfo(e.target.value);
                          }}
                          className="form-control"
                        >
                          <option>Select A Merchant</option>
                          {merchant?.map((elem) => (
                            <option key={elem.mid} value={elem.mid}>
                              {elem.merchantName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="col-sm-12 col-md-6  offset-md-3 mt-3">
                      <label className="form-label">Callback URL</label>
                      <div className="d-flex gap-2">
                      <input
                type="text"
                className="form-control"
                value={merchantData.callbackURL || formData.customCallbackURL}
                onChange={(e) =>
                  setFormData({ ...formData, customCallbackURL: e.target.value })
                }
              />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label className="form-label">JSON Data</label>
                    <textarea
                      className="form-control"
                      value={merchantData.jsonString}
                      onChange={handleChange}
                      rows={15}
                      cols={30}
                    />
                  </div>
                  {parsedResponse && (
            <div className="mt-4">
              <h5>API Testing Response:</h5>
              <pre>{JSON.stringify(parsedResponse, null, 2)}</pre>
            </div>
          )}

                  <div className="d-flex justify-content-center mt-4">
                    <button
                      className="btn btn-primary"
                      onClick={submitCallbackURL}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CallbackPayout;