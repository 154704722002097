
import React from 'react';

const DeleteModal = ({closeModal}) => {

    const handleContinue = ()=>{
        closeModal(false);
    }

  return (
    <div className='modalBackground'>
        <div className='modalContainer'>
            <div className='titleCloseBtn'>
            <button onClick={()=> closeModal(false)}> X </button>
            </div>
            <div>
                <h1 className='title'>Are You Sure You Want To Delete The Record?</h1>
            </div>
            <div className='footer'>
                <button  id='cancelBtn' onClick={()=> closeModal(false)}>Cancel</button>
                <button onClick={handleContinue}>Continue</button>
            </div>
        </div>
      
    </div>
  )
}

export default DeleteModal
