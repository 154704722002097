import React from 'react'
import InactiveServices from './components/InactiveServices'
function Payment() {
  return (
    <>
      <InactiveServices/> 
    </>
  )
}

export default Payment
