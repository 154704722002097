import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { SWIPELINC_API } from "../utils/constant";
import swal from "sweetalert";
import { fetchAllMerchantValidations } from "../utils/ApiServices";

const BankForm = ({setShowLoader}) => {
  const [services, setServices] = useState([]);
  const [merchantData, setMerchantData] = useState([]);
  const [selectedMerchantMid, setSelectedMerchantMid] = useState(null);
  const [companyData, setCompanyData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [bankData, setBankData] = useState([]);
  const [data, setData] = useState([]);
  const token = localStorage.getItem("token");

  const getAllInitailServices = (mid) => {
    setShowLoader(true)
      axios
        .get(`${SWIPELINC_API}MerchantAdminPanel/merchant/mdr/getbymid/${mid}`
        ,{
          headers:{
          Authentication:`Bearer ${token}`
        }
      })
        .then((response) => {
          if (response.data.statusCode === 200) {
            setServices(response?.data.data);
          } else {
            setServices([]);
          }
          setShowLoader(false)
        })
        .catch((error) => {
          console.error("Error fetching applications:", error.message);
          setShowLoader(false)
        });
    };
    const getAllMerchants = () => {
      setShowLoader(true)
       axios
         .get(`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getallforBank`, {
           headers:{
             Authentication:`Bearer ${token}`
           }
          })
      .then((response) => {
        if (response.data.statusCode === 200) {
          console.log("Approved applications:", response.data.data);
          setMerchantData(response.data.data);
        } else if (response.data.statusCode === 304) {
          setMerchantData(response.data.data);
        } else {
          console.error("NO APPLICATIONS FOUND:", response.data.data);
        }
        setShowLoader(false)
      })
      .catch((error) => {
        console.error("Error fetching applications:", error.message);
        setShowLoader(false)
      });
  };

  const handelMerchantChange = (e) => {
    setSelectedMerchantMid(e.target.value);
    getAllInitailServices(e.target.value);
  };
 
  const handelServiceProvider = (elemId, e) => {
    const data = e.target.value.split(",");
    setData(data);
  

    let d = bankData?.filter((elem) => {
      return elem.id !== elemId;
    });

    // console.log(d);
    if (data[1] === " ") {
      setBankData(d);
      return;
    }

 

    setBankData([
      ...d,
      {
        id: elemId,
        providerId: parseInt(data[0]),
        providerType: data[1],
        
        
      },
    ]);
  };

  const handelBankSubmit = (e) => {
    e.preventDefault();
    const showConfirmationAlert = () => {
      swal({
        title: "Are you sure?",
        text: "Do you want to submit this application ?",
        icon: "warning",
        buttons: true,
        
      }).then((willSubmit) => {
        if (willSubmit) {
    setShowLoader(true)
    axios
      .post(`${SWIPELINC_API}MerchantAdminPanel/merchant/mdr/saveprovider`,bankData,
      {headers:
      {
        Authentication:`Bearer ${token}`
      }
      })
      .then((response) => {
        console.log(response);
        if (response.data.statusCode === 200) {
          swal({
            title: "Success!",
            text: response.data.message,
            icon: "success",
          });
          setBankData([]);
          //   setMerchantData([])
          setSelectedMerchantMid(null);
          setServices([]);
          //   setCompanyData([])
          setSelectedCompany("");
        } else {
          swal({
            title: "Alert!",
            text: response.data.message,
            icon: "error",
          });
        }
        setShowLoader(false)
      })
      .catch((error) => {
        console.error("Error fetching applications:", error.message);
        setShowLoader(false)
      });
    }
   else {
    swal("Submission Cancelled!", {
      icon: "info",
    });
  }
});
};

showConfirmationAlert();
};

  useEffect(() => {
    getAllMerchants();
    
  }, []);

 
  const FetchDataBymid = async ( ) => {
   setShowLoader(true)
    try {
      const apiResponse = await fetchAllMerchantValidations( token);
      if (apiResponse.statusCode === 200) {
        setCompanyData(apiResponse?.data );

        // setFinancialData(initializeFinancialData(responseData));
        console.log(apiResponse?.data );
      } else {
        }
        setShowLoader(false)
    } catch (error) {
        setShowLoader(false)
    }
  };

  useEffect(() => {
    FetchDataBymid( )
  }, [])
  

  return (
    <>
      <section className="lincpay-dashboard-sec merchant-admin-info-form company-add-form">
        <Container>
          <div className="cart-view-sec mt-4">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow-cst border-0 mb-3 chart-boxes merchant-info-form-sec">
                  <Card.Body>
                    <div className="merchant-form-info">
                      <form
                        className="form-section-form-group"
                        // onSubmit={handleSubmit}
                      >
                        <Row className="d-flex flex-column gap-4">
                          <Col xs={12} lg={6} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                Please Select Company
                                <sup className="text-danger">*</sup>
                              </label>
                              <select
                                className="form-control"
                                name="mid"
                                // value={FRMData.mid}
                                onChange={handelMerchantChange}
                              >
                                <option value="">
                                  Select
                                  <span className="star">*</span>
                                </option>
                                {merchantData?.map((merchant) => (
                                  <option
                                    key={merchant.mid}
                                    value={merchant.mid}
                                  >
                                    {merchant.companyName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>

                          {services?.map((elem) => {
                            // console.log(elem.data);

                            return (
                              <Col className="mt-3">
                                <Row>
                                  <Col xs={12} lg={6} md={6}>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="fullName"
                                        placeholder="Full Name"
                                        value={elem.service}
                                        readOnly
                                      />
                                    </div>
                                  </Col>
                                  <Col  >
                                    <div className="form-group">
                                      <select
                                        className="form-control"
                                        name={elem.id}
                                        // value={FRMData.mid}
                                        onChange={(e) =>
                                          handelServiceProvider(elem.id, e)
                                        }
                                      >
                                        <option value=" , ">
                                          Select
                                          <span className="star">*</span>
                                        </option>
                                        {elem?.data?.map((service) => (
                                          <option
                                            name={service.service}
                                            key={service.id}
                                            value={`${service.id},${service.masterType}`}
                                          >
                                            {service.serviceName}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  
                                  
                                </Row>
                              </Col>
                            );
                          })}
                        </Row>
                      </form>
                      <Col xs={12} lg={6} md={6}>
                        <div className="d-flex justify-content-end">
                          <div className="form-group  tool-bar-btn mb-0 mt-3">
                            <button
                              className="submit-button submit_link_info btn btn-primary"
                              onClick={handelBankSubmit}
                              id="submitDetailsBtn"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </Card.Body>
                </Card>
                {/* Tab content end here */}
              </Col>
              {/* ./col */}
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default BankForm;
