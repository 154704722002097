import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import DropDowns from "./DropDowns";
import {
  SaveVehicleMasterForm,
  fetchAllAggeregatorData,
  fetchAllBank,
  fetchAllScheme,
  fetchAllSwitchData,
  fetchAllVehicleCommercialWithActive,
  fetchAllVehicleSettlementTypeWithActive,
  fetchAllVehicleSubTypelWithActive,
  fetchAllVehicleTypelWithActive,
  fetchAllVehiclecommercialTypeWithActive,
} from "../utils/ApiServices";
import swal from "sweetalert";
import { getKeyPressed } from "../utils/helper";

const inputBoxStyle = { fontSize: "16px", borderRadius: "0px" };

const VehicleMasterForm = ({setShowLoader}) => {
  const [schemeData, setSchemeData] = useState([]);
  const [bankData, setBankData] = useState([]);
  const [aggeregatorData, setAggeregatorData] = useState([]);
  const [switchData, setSwitchData] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [paymentSubTypes, setPaymentSubTypes] = useState([]);
  const [selectedPaymentTypes, setSelectedPaymentTypes] = useState([]);
  const [selectedPaymentSubTypes, setSelectedPaymentSubTypes] = useState([]);
  const [pressedKeyName, setPressedKeyName] = useState("");
  const [selectedType, setSelectedType] = useState({
    schemeId: "",
    aggeregatorId: "",
    switchId: "",
    vehicleName: "",
    bankId: "",
    remark: "",
    interchargeFix: "",
    interchargePercentage: "",
    nodelFeeFix: "",
    nodelFeePercentage: "",
    processingFeeFix: "",
    processingFeePercentage: "",
    platformFeeFix: "",
    platformFeePercentage: 0,
  });
  const [token, setToken] = useState(localStorage.getItem("token"));

  const [formData, setFormData] = useState({
    mid: "",
    merchantName: "",
  });

  useEffect(() => {
    GetScheme(token);
    GetAllBankData(token);
    GetAllAggregatorData(token);
    GetAllSwitchData(token);
    GetVehicleType(token);
    GetVehicleSubType(token);
  }, []);

  const GetScheme = async (token) => {
    try {
       setShowLoader(true);
      const response = await fetchAllScheme(token);
   if (response.statusCode === 200) {
        setSchemeData(
          response?.data?.map((item) => {
            return {
              type: item.name,
              Id: item.schemeId,
            };
          })
        );
      } else {
        setSchemeData([]);
      }
    } catch (error) {
      console.log(error);
    }
     setShowLoader(false);
  };

  const GetAllBankData = async (token) => {
    setShowLoader(true);
    try {
      const response = await fetchAllBank(token); // Add the await keyword here

      if (response.statusCode === 200) {
        setBankData(
          response?.data?.map((item) => {
            return {
              type: item.name,
              Id: item.bankId,
            };
          })
        );
      } else {
        setBankData([]);
      }
    } catch (error) {
      console.log(error);
    }
    setShowLoader(false);
  };

  const GetAllSwitchData = async (token) => {
    try {
      const response = await fetchAllSwitchData(token); // Add the await keyword here

      if (response.statusCode === 200) {
        setSwitchData(
          response?.data?.map((item) => {
            return {
              type: item.name,
              Id: item.switchId,
            };
          })
        );
      } else {
        setSwitchData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllAggregatorData = async (token) => {
    try {
      const response = await fetchAllAggeregatorData(token); 

      if (response.statusCode === 200) {
        setAggeregatorData(
          response?.data?.map((item) => {
            return {
              type: item.name,
              Id: item.aggregatorId,
            };
          })
        );
      } else {
        setAggeregatorData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetVehicleType = async (token) => {
    try {
      const response = await fetchAllVehicleTypelWithActive(token); // Add the await keyword here

      if (response.statusCode === 200) {
        setPaymentTypes(response?.data);
      } else {
        setPaymentTypes([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetVehicleSubType = async (token) => {
    try {
      const response = await fetchAllVehicleSubTypelWithActive(token); // Add the await keyword here

      if (response.statusCode === 200) {
        setPaymentSubTypes(response?.data);
      } else {
        setPaymentSubTypes([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTypeChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    // console.log(name, value);

    if (pressedKeyName === "Backspace" && name === "remark") {
      setSelectedType({ ...selectedType, [name]: value });
    }

    if (name === "remark" && selectedType.remark.length >= 200) {
      return;
    }

    if (
      name === "platformFeePercentage" ||
      name === "processingFeePercentage" ||
      name === "nodelFeePercentage" ||
      name === "interchargePercentage"
    ) {
      if (value >= 0 && value <= 100) {
        setSelectedType({ ...selectedType, [name]: value });
      }
      return;
    }
       setSelectedType({ ...selectedType, [name]: value?.toUpperCase()});
   };

  const handelPasteEvent = (event, name) => {
    let pastedText = (event.clipboardData || window.clipboardData).getData(
      "text"
    );

    const remarkLength = 200;

    if (
      name === "remark" &&
      selectedType.remark?.length + pastedText?.length > remarkLength
    ) {
      swal({
        title: "Alert!",
        text: `Remark must be lesser than ${remarkLength} characters`,
        icon: "error",
      });
      return;
    }
  };

  const handleTypeCheckBox = (e) => {
    const found = selectedPaymentTypes?.find(
      (item) => item === parseInt(e.target.value)
    );
   if (found) {
      setSelectedPaymentTypes(
        selectedPaymentTypes.filter((item) => item !== found)
      );
    } else {
      setSelectedPaymentTypes([
        ...selectedPaymentTypes,
        parseInt(e.target.value),
      ]);
    }
  };

  const handleSubTypeCheckBox = (e) => {
    const paymentSubTypeId = parseInt(e.target.value);
    const correspondingPaymentType = paymentTypes.find((type) =>
      type.paymentSubTypedtos.some(
        (subType) => subType.paymentSubTypeId === paymentSubTypeId
      )
    );

    if (!correspondingPaymentType) {
      swal({
        title: "Alert!",
        text: "Invalid payment subtype selected.",
        icon: "error",
      });
      return;
    }

    const foundPaymentType = selectedPaymentTypes.includes(
      correspondingPaymentType.paymentTypeId
    );

    if (!foundPaymentType) {
     
      setSelectedPaymentSubTypes([]);
      swal({
        title: "Alert!",
        text: "Please select the corresponding Vehicle Type first.",
        icon: "error",
      });
      return;
    }

    const found = selectedPaymentSubTypes.includes(paymentSubTypeId);

    if (found) {
      // Deselecting payment subtype
      setSelectedPaymentSubTypes(
        selectedPaymentSubTypes.filter((item) => item !== paymentSubTypeId)
      );
    } else {
      // Selecting payment subtype
      setSelectedPaymentSubTypes([
        ...selectedPaymentSubTypes,
        paymentSubTypeId,
      ]);
    }
  };

  const generatePayload = () => {
    return {
      aggregatorId: selectedType.aggeregatorId,
      bankId: selectedType.bankId,
      paymentSubTypes: selectedPaymentSubTypes,
      paymentTypes: selectedPaymentTypes,
      schemeId: selectedType.schemeId,
      switchId: selectedType.switchId,
      vehicleName: selectedType.vehicleName,
      remark: selectedType.remark,
      interchangeFeeFix: selectedType.interchargeFix,
      interchangeFeePercentage: selectedType.interchargePercentage,
      nodelFeeFix: selectedType.nodelFeeFix,
      nodelFeePercentage: selectedType.nodelFeePercentage,
      platformFeeFix: selectedType.platformFeeFix,
      platformFeePercentage: selectedType.platformFeePercentage,
      processingFeeFix: selectedType.processingFeeFix,
      processingFeePercentage: selectedType.processingFeePercentage,
      
    };
  };

  const handleSubmit = async () => {
    const payload = generatePayload();

    if (selectedType.vehicleName === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Vehicle Name.",
        icon: "error",
      });
      return;
    }

    if (selectedType.schemeId === "") {
      swal({
        title: "Alert!",
        text: "Please Select Scheme.",
        icon: "error",
      });
      return;
    }

    if (selectedType.bankId === "") {
      swal({
        title: "Alert!",
        text: "Please Select Bank.",
        icon: "error",
      });
      return;
    }

    if (selectedType.switchId === "") {
      swal({
        title: "Alert!",
        text: "Please Select Switch.",
        icon: "error",
      });
      return;
    }

    if (selectedType.aggeregatorId === "") {
      swal({
        title: "Alert!",
        text: "Please Select Aggeregator.",
        icon: "error",
      });
      return;
    }

    if (
      selectedType.schemeId !=="5" &&
      selectedType.interchargeFix === "" &&
      selectedType.interchargePercentage === ""
    ) {
      swal({
        title: "Alert!",
        text: "Please Enter Intercharge Fix or Intercharge Percentage.",
        icon: "error",
      });
      return;
    }

    if (
      selectedType.bankId !== "3" &&
      selectedType.nodelFeeFix === "" &&
      selectedType.nodelFeePercentage === ""
    ) {
      swal({
        title: "Alert!",
        text: "Please Enter Nodel Fee Fix or Nodel Fee Percentage.",
        icon: "error",
      });
      return;
    }

    if (
      selectedType.switchId !== "3" &&
      selectedType.processingFeeFix === "" &&
      selectedType.processingFeePercentage === ""
    ) {
      swal({
        title: "Alert!",
        text: "Please Enter Processing Fee Fix or Processing Fee Percentage.",
        icon: "error",
      });
      return;
    }

    if (
      selectedType.aggeregatorId !== "3" &&
      selectedType.platformFeeFix === "" &&
      selectedType.platformFeeFix === ""
    ) {
      swal({
        title: "Alert!",
        text: "Please Enter Platform Fee Fix or Platform Fee Percentage.",
        icon: "error",
      });
      return;
    }

    if (!selectedPaymentTypes.length) {
      swal({
        title: "Alert!",
        text: "Please Select Vehicle Types.",
        icon: "error",
      });
      return;
    }
    const paymentTypesWithSubtypes = paymentTypes.filter(
      (type) => type.paymentSubTypedtos.length > 0
    );

    for (const paymentType of paymentTypesWithSubtypes) {
      if (
        selectedPaymentTypes.includes(paymentType.paymentTypeId) &&
        !selectedPaymentSubTypes.some((subType) =>
          paymentType.paymentSubTypedtos.some(
            (subtype) => subtype.paymentSubTypeId === subType
          )
        )
      ) {
        swal({
          title: "Alert!",
          text: `Please select all subtypes for '${paymentType.paymentService}'`,
          icon: "error",
        });
        return;
      }
    }
    try {
      setShowLoader(true);
      const response = await SaveVehicleMasterForm(payload, token);

      if (response.statusCode === 200) {
        swal({
          title: "Success",
          text: "Saved Successfully",
          icon: "success",
        });

        setSelectedType({
          vehicleName: "",
          schemeId: "",
          bankId: "",
          switchId: "",
          aggeregatorId: "",
          remark: "",
        });
        setSelectedPaymentTypes([]);
        setSelectedPaymentSubTypes([]);
      } else if (response.statusCode === 309) {
        swal({
          title: "Alert",
          text: "Service already exists",
          icon: "alert",
        });
      } else {
        swal({
          title: "Failed",
          text: "Vehicle Master Form Not Saved",
          icon: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
    setShowLoader(false);
  };

  return (
    <Card className="shadow border-0 mb-4">
      <Card.Body>
        <div className="merchant-report-view">
          <div className="report-table-view">
            <div className="containerflag">
              <Container>
                <Row className="">
                  <Col xs={11} xl={4} lg={4} md={6} sm={11} className="mb-3  ">
                    <label htmlFor="email">
                      Vehicle Name<sup className="text-danger">*</sup>
                    </label>
                    <input
                      name="vehicleName"
                      id="vehicleName"
                      className="form-control"
                      value={selectedType.vehicleName}
                      onChange={handleTypeChange}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={11} xl={4} lg={4} md={6} sm={11} className="mb-3 ">
                    <DropDowns
                      Name={"schemeId"}
                      HandleChange={handleTypeChange}
                      SelectedType={selectedType.schemeId}
                      AllTypes={schemeData}
                    />
                  </Col>

                  {selectedType.schemeId === "5" ||
                  selectedType.schemeId === "" ? (
                    ""
                  ) : (
                    <>
                      <Col
                        xs={11}
                        xl={4}
                        lg={4}
                        md={6}
                        sm={11}
                        className="mb-3 "
                      >
                        <label htmlFor="email">Intercharge Fix</label>
                        <input
                          type="number"
                          name="interchargeFix"
                          style={inputBoxStyle}
                          placeholder="0"
                          id="interchargeFix"
                          className="form-control"
                          value={selectedType.interchargeFix}
                          onChange={handleTypeChange}
                        />
                      </Col>

                      <Col
                        xs={11}
                        xl={4}
                        lg={4}
                        md={6}
                        sm={11}
                        className="mb-3 "
                      >
                        <label htmlFor="email">Intercharge Percentage</label>
                        <input
                          name="interchargePercentage"
                          type="number"
                          style={inputBoxStyle}
                          placeholder="0"
                          id="interchargePercentage"
                          className="form-control"
                          value={selectedType.interchargePercentage}
                          onChange={handleTypeChange}
                        />
                      </Col>
                    </>
                  )}
                </Row>

                <Row>
                  <Col xs={11} xl={4} lg={4} md={6} sm={11} className="mb-3 ">
                    <DropDowns
                      Name={"bankId"}
                      HandleChange={handleTypeChange}
                      SelectedType={selectedType.bankId}
                      AllTypes={bankData}
                    />
                  </Col>

                  {selectedType.bankId === "3" || selectedType.bankId === "" ? (
                    ""
                  ) : (
                    <>
                      <Col
                        xs={11}
                        xl={4}
                        lg={4}
                        md={6}
                        sm={11}
                        className="mb-3 "
                      >
                        <label htmlFor="email">Nodel Fee Fix</label>
                        <input
                          type="number"
                          style={inputBoxStyle}
                          placeholder="0"
                          name="nodelFeeFix"
                          id="nodelFeeFix"
                          className="form-control"
                          value={selectedType.nodelFeeFix}
                          onChange={handleTypeChange}
                        />
                      </Col>

                      <Col
                        xs={11}
                        xl={4}
                        lg={4}
                        md={6}
                        sm={11}
                        className="mb-3 "
                      >
                        <label htmlFor="email">Nodel Fee Percentage</label>
                        <input
                          name="nodelFeePercentage"
                          id="nodelFeePercentage"
                          type="number"
                          style={inputBoxStyle}
                          placeholder="0"
                          className="form-control"
                          value={selectedType.nodelFeePercentage}
                          onChange={handleTypeChange}
                        />
                      </Col>
                    </>
                  )}
                </Row>

                <Row>
                  <Col xs={11} xl={4} lg={4} md={6} sm={11} className="mb-3 ">
                    <DropDowns
                      Name={"switchId"}
                      HandleChange={handleTypeChange}
                      SelectedType={selectedType.switchId}
                      AllTypes={switchData}
                    />
                  </Col>

                  {selectedType.switchId === "3" ||
                  selectedType.switchId === "" ? (
                    ""
                  ) : (
                    <>
                      <Col
                        xs={11}
                        xl={4}
                        lg={4}
                        md={6}
                        sm={11}
                        className="mb-3 "
                      >
                        <label htmlFor="email">Processing Fee Fix</label>
                        <input
                          name="processingFeeFix"
                          id="processingFeeFix"
                          type="number"
                          style={inputBoxStyle}
                          placeholder="0"
                          className="form-control"
                          value={selectedType.processingFeeFix}
                          onChange={handleTypeChange}
                        />
                      </Col>

                      <Col
                        xs={11}
                        xl={4}
                        lg={4}
                        md={6}
                        sm={11}
                        className="mb-3 "
                      >
                        <label htmlFor="email">Processing Fee Percentage</label>
                        <input
                          name="processingFeePercentage"
                          id="processingFeePercentage"
                          type="number"
                          style={inputBoxStyle}
                          placeholder="0"
                          className="form-control"
                          value={selectedType.processingFeePercentage}
                          onChange={handleTypeChange}
                        />
                      </Col>
                    </>
                  )}
                </Row>

                <Row>
                  <Col xs={11} xl={4} lg={4} md={6} sm={11} className="mb-3 ">
                    <DropDowns
                      Name={"aggeregatorId"}
                      HandleChange={handleTypeChange}
                      SelectedType={selectedType.aggeregatorId}
                      AllTypes={aggeregatorData}
                    />
                  </Col>

                  {selectedType.aggeregatorId === "3" ||
                  selectedType.aggeregatorId === "" ? (
                    ""
                  ) : (
                    <>
                      <Col
                        xs={11}
                        xl={4}
                        lg={4}
                        md={6}
                        sm={11}
                        className="mb-3 "
                      >
                        <label htmlFor="email">Platform Fee Fix</label>
                        <input
                          name="platformFeeFix"
                          id="platformFeeFix"
                          style={inputBoxStyle}
                          placeholder="0"
                          type="number"
                          className="form-control"
                          value={selectedType.platformFeeFix}
                          onChange={handleTypeChange}
                        />
                      </Col>

                      <Col
                        xs={11}
                        xl={4}
                        lg={4}
                        md={6}
                        sm={11}
                        className="mb-3 "
                      >
                        <label htmlFor="email">Platform Fee Percentage</label>
                        <input
                          name="platformFeePercentage"
                          id="platformFeePercentage"
                          type="number"
                          style={inputBoxStyle}
                          placeholder="0"
                          className="form-control"
                          value={selectedType.platformFeePercentage}
                          onChange={handleTypeChange}
                        />
                      </Col>
                    </>
                  )}
                </Row>

                <Row className="">
                  <Col
                    xs={11}
                    xl={4}
                    lg={4}
                    md={6}
                    sm={11}
                    className="mb-3     "
                  >
                    <label htmlFor="email">Remark</label>
                    <input
                      name="remark"
                      id="remark"
                      className="form-control"
                      value={selectedType.remark}
                      onPaste={(e) => handelPasteEvent(e, "remark")}
                      onKeyDown={(e) => {
                        setPressedKeyName(getKeyPressed(e));
                      }}
                      onChange={handleTypeChange}
                    />
                    <div className="d-flex justify-content-end">
                      <span className="text-danger">
                        {`Remaining Characters ${
                          200 - selectedType.remark?.length
                        } / 200`}{" "}
                      </span>
                    </div>
                  </Col>
                </Row>
              </Container>

              {/* ------------------------------------------------------------------------------------------ */}

              {/* <Row>
                <Col xs={12} xl={12} lg={12} sm={12} className="mb-5">
                  <div className="row mt-4">
                    <div className="col-md-6 offset mt-2">
                      <div className="col-sm-12 col-md-6  offset-md-3">
                        <label htmlFor="email">
                          Vehicle Name<sup className="text-danger">*</sup>
                        </label>
                        <input
                          name="vehicleName"
                          id="vehicleName"
                          className="form-control"
                          value={selectedType.vehicleName}
                          onChange={handleTypeChange}
                        />
                      </div>
                    </div>

                    <DropDowns
                      Name={"schemeId"}
                      HandleChange={handleTypeChange}
                      SelectedType={selectedType.schemeId}
                      AllTypes={schemeData}
                    />
                    <DropDowns
                      Name={"bankId"}
                      HandleChange={handleTypeChange}
                      SelectedType={selectedType.bankId}
                      AllTypes={bankData}
                    />

                    <DropDowns
                      Name={"switchId"}
                      HandleChange={handleTypeChange}
                      SelectedType={selectedType.switchId}
                      AllTypes={switchData}
                    />

                    <DropDowns
                      Name={"aggeregatorId"}
                      HandleChange={handleTypeChange}
                      SelectedType={selectedType.aggeregatorId}
                      AllTypes={aggeregatorData}
                    />

                    <div className="col-md-6 offset mt-2">
                      <div className="col-sm-12 col-md-6  offset-md-3">
                        <label htmlFor="email">Remark</label>
                        <input
                          name="remark"
                          id="remark"
                          className="form-control"
                          value={selectedType.remark}
                          onPaste={(e) => handelPasteEvent(e, "remark")}
                          onKeyDown={(e) => {
                            setPressedKeyName(getKeyPressed(e));
                          }}
                          onChange={handleTypeChange}
                        />
                        <div className="d-flex justify-content-end">
                          <span className="text-danger">
                            {`Remaining Characters ${
                              200 - selectedType.remark?.length
                            } / 200`}{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row> */}

              <div className="row mt-4">
                <div className="col-9 mx-auto ">
                  <div className="d-flex  ">
                    <label htmlFor="email" className="w-50 d-flex flex-center">
                      Vehicle Types
                    </label>
                    <label htmlFor="email" className="w-50 d-flex flex-start">
                      Vehicle Sub-Types
                    </label>
                  </div>
                  {paymentTypes?.map((paymentType, i) => (
                    <div
                      style={{ minHeight: "4vw" }}
                      className="row  border border-1  "
                      key={i}
                    >
                      <div className=" col-4 d-flex justify-content-center align-items-center border border-right-1  ">
                        <div className="w-75 d-flex gap-4 align-items-center">
                          <input
                            type="checkbox"
                            style={{ border: "1px solid grey", scale: "2" }}
                            name="typeOfServices"
                            className="form-check-inpu larger"
                            value={paymentType.paymentTypeId}
                            checked={
                              selectedPaymentTypes.includes(
                                paymentType.paymentTypeId
                              )
                                ? true
                                : false
                            }
                            onChange={handleTypeCheckBox}
                          />
                          <label
                            className="form-label mx-2 mt-2"
                            style={{ scale: "1" }}
                          >
                            {paymentType.paymentService.toUpperCase()}
                          </label>{" "}
                        </div>
                      </div>
                      <div className="col-8 d-flex flex-wrap py-2">
                        {paymentType?.paymentSubTypedtos?.map(
                          (paymentSubType, i) => (
                            <div
                              className="d-flex align-items-center border border-1 mt-1 py-1 px-4 rounded-5 bg-light"
                              key={i}
                            >
                              <input
                                type="checkbox"
                                style={{ border: "1px solid grey" }}
                                name="typeOfServices"
                                className="form-check-inpu"
                                value={paymentSubType.paymentSubTypeId}
                                checked={
                                  selectedPaymentSubTypes.includes(
                                    paymentSubType.paymentSubTypeId
                                  )
                                    ? true
                                    : false
                                }
                                onChange={handleSubTypeCheckBox}
                              />
                              <label
                                className="fw-semibold mx-1 mt-1"
                                style={{ fontSize: "0.8vw" }}
                              >
                                {paymentSubType.paymentSubService}
                              </label>{" "}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <button className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default VehicleMasterForm;