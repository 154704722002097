import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { SWIPELINC_API } from "../utils/constant";
import MyLoader from "../common/MyLoader";

const Stage2Applications = ({ setShowLoader }) => {
  const [applications, setApplications] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [showPaymentServices, setShowPaymentServices] = useState(false);
  const [message, setMessage] = useState(true);
  const [token, setToken] = useState(localStorage.getItem("token"));

  const handleSelect = () => {
    setShowPaymentServices(
      (prevShowPaymentServices) => !prevShowPaymentServices
    );
  };

  const navigate = useNavigate();

  useEffect(() => {
    const axiosInstance = axios.create({
      baseURL: `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard`,
      headers: {
        Authentication: `Bearer ${token}`,
      },
    });
    setShowLoader(true);
    axiosInstance
      .get("findbystage2/Submitted")
      .then((response) => {
        if (response.data.statusCode === 200) {
          console.log("application:", response.data.data);
          setApplications(response.data.data);
        } else if (response.data.statusCode === 304) {
          console.log("NO APPLICATIONS FOUND:", response.data.data);
          setApplications(response.data.data);
          setMessage("No Data Found", response.data.data);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching applications:", error.message);
        setShowLoader(false);
      });
  }, []);

  const handleViewDetails = (registrationId) => {
    const queryParams = queryString.stringify({
      selectedServices: JSON.stringify(selectedServices),
    });
    navigate(`/MoaDetails/${registrationId}?${queryParams}`);
  };

  return (
    <>
     
      <div className="report-grid-view">
        <h2>Submitted Applications</h2>
        <div className="table-responsive">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Authorized Person Name</th>
                <th>e-Mail ID</th>
                <th>Mobile Number</th>
                <th>Company PAN</th>
                <th>GST Number</th>
                {/* <th>MID</th> */}
                {/* <th>Payment Services</th> */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {applications ? (
                applications.map((application) => (
                  <tr key={application.registrationId}>
                    <td>{application.companyName}</td>
                    <td>{application.authorizedPersonName}</td>
                    <td>{application.email}</td>
                    <td>{application.mobile}</td>
                    <td>{application.companyPanNumber}</td>
                    <td>{application.gstNumber}</td>

                    <td>
                      <button
                        className="btn btn-primary btn-sm"
                        type="button"
                        onClick={() =>
                          handleViewDetails(application.registrationId)
                        }
                      >
                        View Details
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7">NO DATA FOUND</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default Stage2Applications;
