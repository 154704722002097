import React, { useState } from "react";
import { saveVehicalCommercialType } from "../utils/ApiServices";
import swal from "sweetalert";
import { Card } from "react-bootstrap";

const VehicalCommercialType = ({setShowLoader}) => {
  const [type, setType] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));

  const SaveVehicalCommercialType = async () => { 

    if(!type ){
      swal({
        title: "Alert!",
        text: "Please Enter Vehicle Commercial Type.",
        icon: "error",
      })
      return
    }

    try {
      const response = await saveVehicalCommercialType({ type }, token); 

      if (response.statusCode === 200) {
        swal({
          title: "Success",
          text: "Saved Successfully",
          icon: "success",
        });
        setType("")
      }else if(response.statusCode=== 309) {
        swal({
          title: "Alert",
          text: "Service already exists",
          icon: "alert"
        })
      } 
      else {
        swal({
            title: "ALERT",
            text: response.message,
            icon: "error",
          });
       
      }
    } catch (error) {
      console.error("Error saving organisational type:", error);
    }
  };

  return (
    <Card className="shadow border-0 mb-4">
      <Card.Body>
        <div className="merchant-report-view">
          <div className="report-table-view">
            <div className="containerflag">
              <div className="row mt-4">
                <div className="col-md-6 offset mt-2">
                  <div className="col-sm-12 col-md-6 offset-md-3">
                    <label htmlFor="email">Vehical Commercial Type</label>
                    <input
                      id="vehicleName"
                      className="form-control"
                      onChange={(e) => setType(e.target.value)}
                      value={type}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-center mt-4">
                  <button
                    className="btn btn-primary"
                    onClick={SaveVehicalCommercialType}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default VehicalCommercialType;