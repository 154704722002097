import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../../assets/css/style.css";
import { MDBDataTable } from "mdbreact";
import { useNavigate } from "react-router-dom";
import { SWIPELINC_API } from "../../../utils/constant";
import ViewDetails1 from "./ViewDetails1";
import { Container, Card, Row, Col } from "react-bootstrap";

function AllPayoutReport({setActivePhase}) {
  const [payoutReportdata, setPayoutReportdata] = useState([]);
  const [open, setOpen] = useState(false);
  const [id, setID] = useState('');
  const [token, setToken] = useState(localStorage.getItem("token"));

  const navigate = useNavigate();

  useEffect(() => {
    fetchAllPayoutReport();
  }, []);

  const fetchAllPayoutReport = async () => {
    try {
      const response = await axios.get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/getAllPayout`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data.data);

      if (Array.isArray(response.data.data)) {
        setPayoutReportdata(response.data.data);
      } else {
        console.error("Response data is not an array:", response.data);
      }
    } catch (error) {
      console.error("Error fetching pay-in report:", error);
    }
  };
  const ViewDetails = (id) => {
    setOpen(true)
    setID(id)
  };
  const columns = [
   
    { label: "Vendor Name", field: "fullName", width: "auto" },
    { label: "e-Mail", field: "email", width: "auto" },
  
    { label: "Mobile", field: "mobile", width: "auto" },
   
    { label: "Merchant ID", field: "mid", width: "auto" },
   
    { label: "Payout Staus", field: "payoutStatus", width: "auto" },
    { label: "Merchant Name", field: "companyName", width: "auto" },
    { label: "Action", field: "action", width: "150px" },
  ];

  const mappedData = payoutReportdata?.map((item) => {
    const mappedItem = { ...item };
    mappedItem.action = (
      <button
          className="btn btn-primary" onClick={() => ViewDetails(item.id)}>
          View Details
        </button>
    
    );
    return mappedItem;
  });
  return (
    <>
      <Container>
    <Row>
      <Col xs={12} lg={12} md={12}>
      <Card className="shadow border-0 mb-4">
        <Card.Body>
        <div className="report-grid-view">
      <div className="material-table-records mb-3">
                  {!open?(  <MDBDataTable
                  className='dataTable'
                striped
                small
                bordered
                hover
                responsive
                data={{
                  columns:columns,
                  rows: mappedData,
                }}
                searching={true}
                entries={50}
                exportToCSV
                theadColor="#333"
                paginationLabel={['Previous', 'Next']}
                sortable={true}
                footer="none"
              />):(<ViewDetails1 id={id} setActivePhase={setActivePhase} setOpen={setOpen}/>)}
                  </div>
                </div>
                </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container> 
            
     
    </>
  );
}

export default AllPayoutReport;
