import React from "react";

const DropDowns = ({ Name, HandleChange, SelectedType, AllTypes }) => {
  return (
    <>
      <label htmlFor="email">
        {" "}
        {Name === "schemeId"
          ? "Scheme"
          : Name === "bankId"
          ? "Bank"
          : Name === "switchId"
          ? "Switch"
          : Name === "aggeregatorId"
          ? "Aggregator"
          : ""}
        <sup className="text-danger">*</sup>
      </label>
      <select
        id={Name}
        name={Name}
        className="form-control"
        onChange={HandleChange}
        value={SelectedType}
      >
        <option value="">Select</option>
        {AllTypes.map((item, i) => (
          <option key={i} value={item.Id}>
            {item.type}
          </option>
        ))}
      </select>
    </>
  );
};

export default DropDowns;