import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { SWIPELINC_API } from "../../utils/constant";
import MyLoader from "../../common/MyLoader";
 
function Hold({setShowLoader,data}) {
  const [merchantData, setMerchantData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [formData, setFormData] = useState({
    mid: "",
  });
  const [holdAmount, setHoldAmount] = useState("");
  const [holdAmountpercentage, setHoldAmountpercentage] = useState("");
  const [holdAmountError, setHoldAmountError] = useState("");
  const [holdAmountpercentageError, setHoldAmountpercentageError] = useState("");
  const [changedAmount, setChangedAmount] = useState("");

  const Fetch_Hold_Amount = (mid) => {
    setShowLoader(true)
    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/hold/getholddata/${mid}`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          setHoldAmount(
            response.data.data === "null" ? "0" : response.data.data
          );
        }else{
          console.error("Error fetching data:", response.data.message);
        }
        setShowLoader(false)
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const Fetch_Hold_Amount_Per = (mid) => {
    setShowLoader(true)
    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/holdAmountpercentage/getMerchantHoldPercentageByMid/${mid}`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data.data[0].holdPercentage)
        if (response.data.statusCode === 200) {
          setHoldAmountpercentage(
            response.data.data[0].holdPercentage === "null" ? "0" : response.data.data[0].holdPercentage
          );
          console.log(response,holdAmountpercentage)
          setShowLoader(false)
        }else if
         (response.data.statusCode === 304) {
          setHoldAmountpercentage(
             "0"  
          );
          console.log(response,holdAmountpercentage)
        }else{
          console.error("Error fetching data:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  
  useEffect(() => {
    setMerchantData(data)
  }, []);

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;

    if(selectedCompany === ''){
        setFormData({
            // email: "",
            mid: "",
            // mobile: "",
            // fullName: "",
          });
        setHoldAmount("")
        setHoldAmountpercentage("")
      setSelectedCompany('')

        return 
    }
    setSelectedCompany(selectedCompany);

    const selectedMerchant = merchantData.find(
      (merchant) => merchant.merchantName === selectedCompany
    );

    if (selectedMerchant) {
      setFormData({
        mid: selectedMerchant.mid || "",
        // email: selectedMerchant.emailId || "",
        // mobile: selectedMerchant.mobileNo || "",
        // fullName: selectedMerchant.merchantName || "",
      });
      Fetch_Hold_Amount(selectedMerchant.mid);
      Fetch_Hold_Amount_Per(selectedMerchant.mid)
      setChangedAmount("");
    }  
  };
  const handleHoldAmountChange = (event) => {
    const value = event.target.value;

    if (!isNaN(value) && value >= 0) {
      setHoldAmount(value);
      setHoldAmountError("");
     
    } else {
      setHoldAmount("");
      setHoldAmountError("Hold Amount must be a positive number.");
    }
  };
  const handleHoldAmountperChange = (event) => {
    const value = event.target.value;

    if (!isNaN(value) && value >= 0) {
      setHoldAmountpercentage(value);
      setHoldAmountpercentageError("");
      
    } else {
        setHoldAmountpercentage("");
      setHoldAmountpercentageError("Hold Amount Percentage must be a positive .");
    }
  };
  useEffect(()=>{

  },[changedAmount])

  const handleSubmit = (changedAmount) => {
    if (holdAmountError || holdAmountpercentageError) {
      return;
    }

    const ApiPayload = (changedAmount) => {

      
      console.log(changedAmount);
      if (changedAmount === "hold") {
        return {
          // email: formData.email,
          mid: formData.mid,
          // mobile: formData.mobile,
          // fullName: formData.fullName,
          amount: holdAmount,
        };
      } else if (changedAmount === "holdAmountpercentage") {
        return {
          // email: formData.email,
          mid: formData.mid,
          // mobile: formData.mobile,
          // fullName: formData.fullName,
          holdPercentage: holdAmountpercentage,
          // isActive : true
        };
      } else {
        console.log("not chnage");
      }
    };

    const newData = ApiPayload(changedAmount);
    console.log(newData);

    if(!formData.mid.length)
    {
      swal({
        title: "Warning",
        text: "Please Fill the Mandatory fields.",
        icon: "warning",
       
      })
      return;
    };
    setShowLoader(true)
    axios
      .post(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/${changedAmount}/save`,
        newData,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if(response.data.statusCode === 200){
          swal({
            title: "Success",
            text: "Data Saved Successfully.",
            icon: "success",
           
          })
          setChangedAmount("");
          setFormData({
             mid: "",
          })
          setHoldAmount("")
          setHoldAmountpercentage("");
          setSelectedCompany("")
        
        }
        setShowLoader(false)
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };
 
  return (
    <div className="containerflag">
    
      {/* <h1 className="mt-3">Select</h1> */}
      <div className="row mt-4">
        <div className="col-sm-12 col-md-6 offset-md-3">
          <select
            id="companyDropdown"
            className="form-control"
            onChange={handleCompanyChange}
            value={selectedCompany}
          >
            <option value="">Select a Company</option>
            {merchantData.map((merchant) => (
              <option key={merchant.mid} value={merchant.merchantName}>
                {merchant.merchantName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-sm-12 col-md-6 offset-md-3">
          <div className="form-group">
            <label htmlFor="email">Merchant ID</label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Merchant ID"
              value={formData.mid}
              readOnly
            />
          </div>
        
          
          <div className="form-group">
            <label htmlFor="holdAmount">Hold Amount (in Rupees)</label>
            <div className="d-flex gap-4">
              <input
                type="text"
                className="form-control"
                id="holdAmount"
                placeholder="Hold Amount"
                value={holdAmount}
                onChange={handleHoldAmountChange}
                readOnly={changedAmount === "hold" ? false : true}
              />
              {changedAmount === "hold" ? (
                <button
                  className="toolbutton"
                  onClick={() => handleSubmit(changedAmount)}
                >
                  Submit
                </button>
              ) : (
                <button
                className="bg-secondary"
                  onClick={() => setChangedAmount("hold")}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
  <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
</svg>
                </button>
              )}
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="holdamountpercentage">Hold Amount In Percentage (in Percentage)</label>
            <div  className="d-flex gap-4">
              <input
                type="text"
                className="form-control"
                id="holdamountpercentage"
                placeholder="Hold Amount In Percentage"
                value={holdAmountpercentage}
                onChange={handleHoldAmountperChange}
                readOnly={changedAmount === "holdAmountpercentage" ? false : true}
              />
              {changedAmount === "holdAmountpercentage" ? (
                <button
                  className="toolbutton"
                  onClick={() => handleSubmit(changedAmount)}
                >
                  Submit
                </button>
              ) : (
                <button
                className="bg-secondary"
                  onClick={() => setChangedAmount("holdAmountpercentage")}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
  <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
</svg>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hold