import axios from "axios";
import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import swal from "sweetalert";
import { SWIPELINC_API } from "../../../utils/constant";
import { getAllRoutingView } from "../../../utils/ApiServices";
import NumberInWords from "../../../masters/NumberInWords";

const SubmittedRoutingPayin = ({ setShowLoader }) => {
  const [applications, setApplications] = useState([]);
  const [mappedMerchant, setMappedMerchant] = useState([]);
  const [openView, setOpenView] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [SidData, setSidData] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [checkTxnLimit, setCheckTxnLimit] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editFormData, setEditFormData] = useState([]);
  const [midToSearch, setMidToSearch] = useState([]);
  const [selectedEditData, setSelectedEditData] = useState(null);
  const [priority, setPriority] = useState([]);

  const handleCloseModal2 = () => {
    setIsModal2Open(false);
  };

  const showRoutingType = () => {
    if (selectedEditData?.routingType === "RR") return false;
    else return true;
  };

  const columns = [
    { label: "Merchant ID", field: "mid", width: "auto" },
    { label: "Merchant Name", field: "companyName", width: "auto" },
    { label: "Action", field: "action", width: "auto" },
  ];

  const columnsForEditDetails = showRoutingType()
    ? [
        { label: "Merchant ID", field: "mid", width: "auto" },
        { label: "Merchant Name", field: "companyName", width: "auto" },
        { label: "SID", field: "sid", width: "auto" },
        { label: "SID Company Name", field: "sidCompanyName", width: "auto" },
        {label: "Remaining SID Limit",field: "sidRemainigLimit",width: "auto", },
        {label: "Priority",field: "prority",width: "auto",},
        {label: "Maximum Transaction Amount",field: "sidLimit",width: "auto",},
        { label: "Action", field: "action", width: "auto" },
      ]
    : [
        { label: "Merchant ID", field: "mid", width: "auto" },
        { label: "Merchant Name", field: "companyName", width: "auto" },
        { label: "SID", field: "sid", width: "auto" },
        { label: "SID Company Name", field: "sidCompanyName", width: "auto" },
        {label: "Remaining SID Limit",field: "sidRemainigLimit",width: "auto",},
        {label: "Maximum Transaction Amount",field: "sidLimit",width: "auto",},
        { label: "Action", field: "action", width: "auto" },
      ];

  const columnsForDetails = [
    { label: "Merchant ID", field: "mid", width: "auto" },
    { label: "Merchant Name", field: "companyName", width: "auto" },
    { label: "SID", field: "sid", width: "auto" },
    { label: "SID Company Name", field: "sidCompanyName", width: "auto" },
    {label: "Maximum Transaction Amount",field: "sidLimit",width: "auto"},
   
  ];

  const mappedData = applications?.map((item) => {
    const mappedItem = { ...item };
    mappedItem.maxTxnAmount = (
      <span>
        {" "}
        <b>&#8377;</b> {`${mappedItem.maxTxnAmount}`}
      </span>
    );

    if (mappedItem.status === true) {
      mappedItem.action = (
        <div className="d-flex gap-2 justify-content-evenly">
          <button
            className="btn btn-warning btn-sm"
            onClick={() => {
              openDetails(
                mappedItem.mid,
                mappedItem.companyName,
                mappedItem.sid,
                item.sidLimit,
                item.id,
                true,
                item.routingType,
                item.prority
              );
              setSelectedEditData(mappedItem);
              Details(item.mid, "edit");
            }}
          >
            Edit
          </button>
          
          <button
            className="btn btn-primary btn-sm"
            onClick={() => {
              Details(item.mid, "details");
            }}
          >
            View Details
          </button>
        </div>
      );
    }

    if (mappedItem.status === false) {
      mappedItem.action = (
        <div className="d-flex gap-2 justify-content-evenly">
          <button
            className="btn btn-warning btn-sm"
            onClick={() => {
              openDetails(
                mappedItem.mid,
                mappedItem.companyName,
                mappedItem.sid,
                item.sidLimit,
                item.id,
                false,
                item.routingType,
                item.prority
              );
              Details(item.mid, "edit");
            }}
          >
            Edit
          </button>
          {/* <button
            className="btn btn-success btn-sm"
            onClick={() => enableSID(item.id)}
          >
            Enable
          </button> */}
          <button
            className="btn btn-primary btn-sm"
            onClick={() => Details(item.mid, "details")}
          >
            View Details
          </button>
        </div>
      );
    }

    return mappedItem;
  });

  const handelEditMaxTxnAmtInput = (id, e) => {
    let typedData = editFormData.filter((elem) => {
      return elem.id === id;
    });
    typedData[0].sidLimit = e.target.value;

    let remainingData = editFormData.filter((elem) => {
      return elem.id !== id;
    });
    setEditFormData([...remainingData, typedData[0]]);
  };

  const handelEditPrority = (id, e) => {
    let typedData = editFormData.filter((elem) => {
      return elem.id === id;
    });

    typedData[0].prority = parseInt(e.target.value);

    let remainingData = editFormData.filter((elem) => {
      return elem.id !== id;
    });
    setEditFormData([...remainingData, typedData[0]]);
  };

  const handelEditRoutingType = (id, e) => {
    console.log("kjhk--------------", id, e.target.value);
    let typedData = editFormData.filter((elem) => {
      return elem.id === id;
    });

    typedData[0].routingType = e.target.value;

    let remainingData = editFormData.filter((elem) => {
      return elem.id !== id;
    });
    setEditFormData([...remainingData, typedData[0]]);
  };

  const editMappedData = mappedMerchant?.map((item, index) => {
    const mappedItem = { ...item };
    mappedItem.sidLimit = (
      <input
        // readOnly={true}
        className="form-control"
        name={item.id}
        type="number"
        onChange={(e) => handelEditMaxTxnAmtInput(item.id, e)}
        value={mappedItem.sidLimit}
      />
    );

    mappedItem.prority = (
      <div>
        <select
          className="form-control"
          value={mappedItem.prority}
          onChange={(e) => handelEditPrority(item.id, e)}
        >
          <option value="0">Select</option>

          {priority.map((item, index) => {
            return <option>{index + 1}</option>;
          })}

          {/* <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option> */}
        </select>
      </div>
    );

    // mappedItem.routingType = (
    //   <select
    //   className="form-control"
    //     value={item.routingType }
    //     onChange={(e) => handelEditRoutingType(item.id, e)}
    //   >
    //     <option value="">Select</option>
    //     <option value="RR">Round Robin</option>
    //     <option value="SQ">Sequencial</option>
    //   </select>
    // );

    if (mappedItem.status === true) {
      mappedItem.action = (
        <div className="d-flex gap-2 justify-content-evenly">
          {/* <button
            className="btn btn-warning btn-sm"
            onClick={() =>{
              openDetails(
                mappedItem.mid,
                mappedItem.companyName,
                mappedItem.sid,
                item.sidLimit,
                item.id,
                true,
                item.routingType
              );
              Details(item.mid, "edit")
            }
            }
          >
            Edit
          </button> */}
          <button
            className="btn btn-danger btn-sm"
            onClick={() => disableSID(item.id)}
          >
            Disable
          </button>
        </div>
      );
    }

    if (mappedItem.status === false) {
      mappedItem.action = (
        <div className="d-flex gap-2 justify-content-evenly">
          {/* <button
            className="btn btn-warning btn-sm"
            onClick={() => {
              openDetails(
                mappedItem.mid,
                mappedItem.companyName,
                mappedItem.sid,
                item.sidLimit,
                item.id,
                false,
                item.routingType
              )
              Details(item.mid, "edit")
            }
            }
          >
            Edit
          </button> */}
          <button
            className="btn btn-success btn-sm"
            onClick={() => enableSID(item.id)}
          >
            Enable
          </button>
        </div>
      );
    }

    return mappedItem;
  });

  const getAllInitialSID = () => {
    setShowLoader(true);
    axios
      .get(`${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/getAllSid`, {
        headers: {
          Authentication: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          setSidData(response.data.data);
          let res = response.data.data.map((elem) => {
            return { ...elem, maxTxnAmtInputVal: "" };
          });

          let selectedData = response.data.data.filter(
            (elem) => editFormData.sid === elem.sid
          );
          setCheckTxnLimit(selectedData[0]);
          setSidData(res);
        } else {
          console.error("Error fetching data:", response.data.message);
        }

        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const Details = (mid, openModel) => {
    setMidToSearch(mid);
    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/admin/MerchantRouting/get-mapped-sid/byMid/${mid}`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);

        if (response.data.statusCode === 200) {
          setMappedMerchant(response.data.data);
          setEditFormData(response.data.data);
          setPriority(response.data.data);
        } else {
          swal({
            title: "Alert",
            text: "No data found",
            icon: "warning",
          });
          setIsModal2Open(false);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setShowLoader(false);
      });

    if (openModel === "edit") setIsModalOpen(true);
    else setIsModal2Open(true);
    console.log("id---------------", mid);
  };

  const openDetails = (
    mid,
    companyName,
    sid,
    sidLimit,
    id,
    status,
    routingType,
    prority
  ) => {
    setEditFormData({
      sidLimit: sidLimit,
      mid,
      merchantName: companyName,
      sid,
      routingType: routingType,
      id,
      status,
      prority: parseInt(prority),
    });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const disableSID = (id) => {
    // Show swal confirmation before proceeding
    swal({
      title: "Alert",
      text: "Are you sure, you want to disable?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDisable) => {
      if (willDisable) {
        const formData = new FormData();
        formData.append("id", id);
        setShowLoader(true);

        axios
          .put(
            `${SWIPELINC_API}/MerchantAdminPanel/admin/MerchantRouting/disable/${id}`, // Fix the template string
            formData,
            {
              headers: {
                Authentication: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log(response);

            if (response.data.statusCode === 200) {
              swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
              });

              // handleCloseModal();

              Details(midToSearch, "edit");

              // FetchData(token);
            } else {
              swal({
                title: "Alert!",
                text: response.data.message,
                icon: "error",
              });
            }
            setShowLoader(false);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            setShowLoader(false);
          });
      } else {
        // User clicked cancel in the swal confirmation
        setShowLoader(false);
      }
    });
  };

  const enableSID = (id) => {
    // Show swal confirmation before proceeding
    swal({
      title: "Alert",
      text: "Are you sure,You want to enable?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDisable) => {
      if (willDisable) {
        const formData = new FormData();
        formData.append("id", id);
        setShowLoader(true);

        axios
          .put(
            `${SWIPELINC_API}/MerchantAdminPanel/admin/MerchantRouting/enable/${id}`, // Fix the template string
            formData,
            {
              headers: {
                Authentication: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log(response);

            if (response.data.statusCode === 200) {
              swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
              });
              // handleCloseModal();
              Details(midToSearch, "edit");
              // FetchData(token);
            } else {
              swal({
                title: "Alert!",
                text: response.data.message,
                icon: "error",
              });
            }
            setShowLoader(false);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            setShowLoader(false);
          });
      } else {
        // User clicked cancel in the swal confirmation
        setShowLoader(false);
      }
    });
  };

  // const handelDataChange = (e) => {
  //   // console.log("name", e.target.name);
  //   setEditFormData({ ...editFormData, [e.target.name]: e.target.value });
  // };

  function areArrayValuesUnique(editFormData) {
    console.log(editFormData);
    const PriorityArr = editFormData?.map((item) => {
      return item?.prority;
    });
    console.log(PriorityArr);
    // Use a Set to track unique values
    const uniqueSet = new Set(PriorityArr);

    // If the size of the Set is equal to the length of the array, all values are unique
    return uniqueSet.size === PriorityArr?.length;
  }

  function IsAllPrioritiesSelect(editFormData) {
    console.log(editFormData);
    const PriorityArr = editFormData?.map((item) => {
      return item?.prority;
    });

    console.log(PriorityArr);
    // If the size of the Set is equal to the length of the array, all values are unique
    return PriorityArr.includes(0);
  }

  function IsRoutingPatternSelect(editFormData) {
    console.log(editFormData);
    const RoutingArr = editFormData?.map((item) => {
      return item?.routingType;
    });

    console.log(RoutingArr);

    return RoutingArr.includes("");
  }

  const handleUpdateSubmit = (e) => {
    e.preventDefault();

    const IsPrioritiesUnique = areArrayValuesUnique(editFormData);
    const IsPrioritiesSelected = IsAllPrioritiesSelect(editFormData);
    const IsRoutingPatternSelected = IsRoutingPatternSelect(editFormData);

    if (!IsPrioritiesUnique) {
      swal({
        title: "ALERT!",
        text: "Priorities Cannot Be Same.",
        icon: "error",
      });
      return;
    }
    if (IsPrioritiesSelected) {
      swal({
        title: "ALERT!",
        text: "Please Select Priorities.",
        icon: "error",
      });
      return;
    }
    if (IsRoutingPatternSelected) {
      swal({
        title: "ALERT!",
        text: "Please Select Routing Pattern.",
        icon: "error",
      });
      return;
    }
    // const payload = {
    //   sidLimit: editFormData.sidLimit,
    //   mid: editFormData.mid,
    //   sid: editFormData.sid,
    //   routingType: editFormData.routingType,
    //   processor: "",
    //   prority: editFormData.prority,
    //   id: editFormData.id,
    //   status: editFormData.status,
    // };

    const payload = editFormData.map((elem) => {
      return {
        sidLimit: elem.sidLimit,
        mid: elem.mid,
        sid: elem.sid,
        routingType: selectedEditData.routingType,
        processor: "",
        prority: elem.prority,
        id: elem.id,
        status: elem.status,
        flag : elem.flag
      };
    });

    setShowLoader(true);
    axios
      .put(
        `${SWIPELINC_API}MerchantAdminPanel/admin/MerchantRouting/updateAllData`,
        payload,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);

        if (response.data.statusCode === 200) {
          swal({
            title: "Success!",
            text: response.data.message,
            icon: "success",
          });
          // setEditFormData({
          //   sid: "",
          //   txnAmt: "",
          //   mid: "",
          //   merchantName: "",
          //   id: "",
          // });
          handleCloseModal();
          FetchData(token);
        } else {
          swal({
            title: "Alert!",
            text: response.data.message,
            icon: "error",
          });
        }
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setShowLoader(false);
      });
  };

  const FetchData = async (token) => {
    setShowLoader(true);
    try {
      const apiResponse = await getAllRoutingView(token);
      if (apiResponse.statusCode === 200) {
        setApplications(apiResponse?.data);
        console.log(apiResponse.data);
      } else {
      }
      setShowLoader(false);
    } catch (error) {
      // console.error(error);
      console.log(error);
      setShowLoader(false);
    }
  };

  const handelRoutingChange = (e) => {
    setSelectedEditData({ ...selectedEditData, routingType: e.target.value });
  };

  useEffect(() => {
    FetchData(token);
  }, []);

  useEffect(() => {
    getAllInitialSID(token);
  }, [editFormData.id]);

  return (
    <>
      <div className="card p-4">
        <MDBDataTable
          className="dataTable"
          striped
          small
          bordered
          hover
          responsive
          data={{
            columns: columns,
            rows: mappedData,
          }}
          searching={true}
          entries={50}
          exportToCSV
          theadColor="#333"
          paginationLabel={["Previous", "Next"]}
          sortable={true}
          footer="none"
        />
      </div>

      {/* EDIT FORM MODEL STARTS */}
      <Modal show={isModalOpen} onHide={handleCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Edit :</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="shadow border-0 mb-4">
            <Card.Body>
              {/* <div className="d-flex justify-content-end">
                <span className="text-danger">
                  Available Remaining Limit : {checkTxnLimit?.remainingLimit}
                </span>
              </div>
              <div className="merchant-report-view">
                <div className="report-table-view">
                  <div className="containerflag ">
                    <div className="row mt-4 ">
                      <div className="col-sm-12 col-md-8  offset-md-2 ">
                        <label htmlFor="email">Merchant Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          placeholder="Merchant Name"
                          value={editFormData.merchantName}
                          readOnly
                        />
                      </div>

                      <div className="col-sm-12 col-md-8 offset-md-2">
                        <div className="form-group mt-3">
                          <label htmlFor="email">Merchant MID</label>
                          <input
                            type="text"
                            className="form-control"
                            id="email"
                            placeholder="Merchant MID"
                            value={editFormData.mid}
                            readOnly
                          />
                        </div>
                      </div>

                   

                      <div className="col-sm-12 col-md-8 offset-md-2 mt-2">
                        <label htmlFor="email">Routing Pattern</label>
                        <select
                          id=""
                          className="form-control"
                          onChange={handelDataChange}
                          value={editFormData.routingType}
                          name="routingType"
                        >
                          <option value="">Select a Pattern</option>
                          <option value="RR">Round Robin</option>
                          <option value="SQ">Sequential</option>
                        </select>
                      </div>
                      <div className="col-sm-12 col-md-8 offset-md-2">
                        <div className="form-group mt-3">
                          <label htmlFor="email">
                            Enter Maximum Transaction Amount
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="txnAmt"
                            name="sidLimit"
                            placeholder="Enter Maximum Txn Amount"
                            value={editFormData.sidLimit}
                            onChange={handelDataChange}
                          />
                           <span>
                            <NumberInWords number={editFormData.sidLimit} />
                          </span>
                          <div className="">
                            <span className="text-danger">
                              Note: Maximum transaction amount should be lesser
                              than available remaining limit{" "}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center mt-4">
                        <button
                          className="btn btn-primary"
                          onClick={handleUpdateSubmit}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="col-sm-12 col-md-6 mt-2">
                <label htmlFor="email">Routing Pattern</label>
                <select
                  id=""
                  className="form-control"
                  onChange={handelRoutingChange}
                  value={selectedEditData?.routingType}
                  name="routingPattern"
                >
                  <option value="">Select a Pattern</option>
                  <option value="RR">Round Robin</option>
                  <option value="SQ">Sequential</option>
                </select>
              </div>

              <div className="merchant-report-view">
                <div className="report-table-view">
                  <div className="containerflag ">
                    <div className="row mt-4 ">
                      <MDBDataTable
                        className="dataTable"
                        striped
                        small
                        bordered
                        hover
                        responsive
                        data={{
                          columns: columnsForEditDetails,
                          // rows: mappedMerchant,
                          rows: editMappedData,
                        }}
                        searching={true}
                        entries={50}
                        exportToCSV
                        theadColor="#333"
                        paginationLabel={["Previous", "Next"]}
                        sortable={true}
                        footer="none"
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-success"
                        onClick={handleUpdateSubmit}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
      <Modal show={isModal2Open} onHide={handleCloseModal2} size="lg">
        <Modal.Header closeButton>
          <Modal.Title> Details:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="shadow border-0 mb-4">
            <Card.Body>
              {/* <div className="text-danger mb-2   fw-bold">
                Following merchant is mapped with this SID
              </div> */}
              <div className="merchant-report-view">
                <div className="report-table-view">
                  <div className="containerflag ">
                    <div className="row mt-4 ">
                      <MDBDataTable
                        className="dataTable"
                        striped
                        small
                        bordered
                        hover
                        responsive
                        data={{
                          columns: columnsForDetails,
                          rows: mappedMerchant,
                        }}
                        searching={true}
                        entries={50}
                        exportToCSV
                        theadColor="#333"
                        paginationLabel={["Previous", "Next"]}
                        sortable={true}
                        footer="none"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex gap-3">
                {/* <Button
                  className="border border-none bg-danger mm-3"
                  onClick={disableSID}
                >
                  Close
                </Button> */}
                <Button onClick={handleCloseModal2}>Close</Button>
              </div>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubmittedRoutingPayin;