import {
  Badge,
  Button,
  ButtonGroup,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  FormControl,
  FormLabel,
  Row,
} from "react-bootstrap";
import { SWIPELINC_API } from "../utils/constant";
import { useParams } from "react-router-dom";
//AccountPhase3Imports
import swal from "sweetalert";
import {
  MDRTypeFetch,
  WithdrawType,
  checkIsFileIsPDF,
  uploadMerchantDocs,
} from "../utils/ApiServices";
import { stopCharacterInput, stopSpecialCharacterInput } from "../utils/helper";

// import RemoveIcon from '@mui/icons-material/RemoveIcon';

const AccountPhase1 = ({setShowLoader}) => {
  const [merchantData, setMerchantData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [paymentService, setPaymentServices] = useState([]);
  const [formData, setFormData] = useState({
    email: "",
    mid: "",
    fullName: "",
  });
  const [MDRTypes, setMDRType] = useState([]);
  const [MDRData, setMDRData] = useState([]);
  const [withdrawType, setWithdrawType] = useState([]);
  const [option, setOptions] = useState(0);
  const [accountData, setAccountData] = useState({
    bussinessYear: 0,
    Chargeback: 0,
    fillingYears: 0,
    ticketSize: 0,
    monthlyVolume: 0,
    monthlyTicket: 0,
    withdraw: Number,
    withdrawType: false,
    holdPercentage: 0,
    depositeLumpsum: 0,
    depositePercentage: 0,
    guarantyAmount: 0,
    withdrawType: 0,
    withdrawalFlag: false,
  });
 
  const [token, setToken] = useState(localStorage.getItem("token"));

  const [financialData, setFinancialData] = useState({
    netSales: {
      firstYear: 0,
      secondYear: 0,
      thirdYear: 0,
    },
    netPurchase: {
      firstYear: 0,
      secondYear: 0,
      thirdYear: 0,
    },
    netProfit: {
      firstYear: 0,
      secondYear: 0,
      thirdYear: 0,
    },
    netExpenses: {
      firstYear: 0,
      secondYear: 0,
      thirdYear: 0,
    },
  });



  const fetchtheTypeOfoption = async () => {
    setMDRData([])
    setMDRType([])
    const MDRType = await MDRTypeFetch(formData?.mid, token);
    setMDRType(MDRType?.data);
 
     
   
    const withdrawType = await WithdrawType(token);
    setWithdrawType(withdrawType?.data);
    console.log(withdrawType);
  };

  useEffect(() => {

     
    fetchtheTypeOfoption(formData.mid, token);
    
  }, [formData.mid]);
 


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    console.log(name, value, type, checked);

    setAccountData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleOptions = (e) => {
    console.log(e.target.value);
    setOptions(e.target.value);

    // setShowModal(true);
  };

  useEffect(() => {
    setShowLoader(true)
    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getallforAccount`,

        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setMerchantData(response.data.data);
        }
        setShowLoader(false)
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setShowLoader(false)
      });
  }, []);

  const handleCompanyChange = (event) => {
    console.log(event.target.value);
    const selectedCompany = event.target.value;
    if (selectedCompany === "Blank") {
      setFormData({
        email: "",
        mid: "",
        paymentService: "",
        fullName: "",
      });
      setSelectedCompany("");
      return;
    }

    setSelectedCompany(selectedCompany);

    const selectedMerchant = merchantData.find(
      (merchant) => merchant.mid === selectedCompany
    );

    if (selectedMerchant) {
      setPaymentServices(selectedMerchant.paymentService);
      setFormData({
        mid: selectedMerchant.mid || "",
        email: selectedMerchant.email || "",
        paymentService: selectedCompany.paymentService,
        fullName: selectedMerchant.authorizedPersonName || "",
      });
    } else {
      setFormData({
        email: "",
        mid: "",
        paymentService: "",
        fullName: "",
      });
    }
  };

  const handleInputChange = (metric, year, value) => {
    if (stopCharacterInput(value, false) || stopSpecialCharacterInput(value)) {
      return;
    }

    setFinancialData((prevData) => ({
      ...prevData,
      [metric]: {
        ...prevData[metric],
        [year]: value === "" ? 0 : parseInt(value),
      },
    }));
  };

  const handleCountChange = (e, name) => {
    if (
      stopCharacterInput(e.target.value, false) ||
      stopSpecialCharacterInput(e.target.value)
    ) {
      return;
    }

    console.log(e.target.value, e.target.name);
    setAccountData({
      ...accountData,
      [e.target.name]: e.target.value === "" ? 0 : parseInt(e.target.value),
    });
  };

  const handleAccoundPhase3data = (e, name) => {
    const value = e.target.value;
    if (
      stopCharacterInput(e.target.value, true) ||
      stopSpecialCharacterInput(e.target.value)
    ) {
      return;
    }
    if (value.includes("-") || value.includes("+")) {
      return;
    }

    setAccountData({
      ...accountData,
      [e.target.name]: parseFloat(e.target.value),
    });
  };

  // AccountPhase2

  // Function to update the form data when an input changes
  const handleMDRChange = (fieldName, value, mdrId) => {
    if (stopCharacterInput(value, true) || stopSpecialCharacterInput(value)) {
      return;
    }
 
    setMDRData({ ...MDRData, [mdrId]: parseFloat(value) });
    console.log(MDRData)
  };

  const handleMDRsubmit = async () => {
    let data = Object.entries(MDRData);

    let payload = data.map((elem) => {
      return {
        paymentTypeId: elem[0],
        mdrPercentage: elem[1],
        mid: formData.mid,
      };
    });

    console.log("Payload-------------------------",payload);
    // handleFormSubmit()
    setShowLoader(true)
    try {
      const response = await axios.post(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/mdr/save`,
        payload,
        {
          headers: {
            Authentication: "Bearer " + token,
          },
        }
      );
      console.log(response);
      // return response?.data;

      if (response.data.statusCode === 200) {
        handleFormSubmit();
        setMDRData([]);
      } else {
        swal({
          title: "Alert!",
          text: response.data.message,
          icon: "error",
        });
      }
      setShowLoader(false)
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };



  // ActivePhase3 Function here

  const [supportingFileList, setSupportingFileList] = useState([]);

  const [docsFile, setDocsFile] = useState([]);
  const [docsName, setDocsName] = useState([]);

  const addSupportingDocumentField = () => {
    let newEntry = {
      id: new Date().getTime().toLocaleString(),
      name: "",
      file: "",
    };
    setSupportingFileList([...supportingFileList, newEntry]);
  };

  const removeSupportingDocumentField = (id) => {
    let newData = supportingFileList.filter((elem) => {
      return elem.id !== id;
    });

    let nameArray = newData.map((elem) => {
      return elem.name;
    });

    let fileArray = newData.map((elem) => {
      return elem.file;
    });

    setDocsName(nameArray);
    setDocsFile(fileArray);

    setSupportingFileList(newData);
  };

  const setFilesToState = () => {
    let nameArr = [];
    let docsArr = [];
   
    supportingFileList.forEach((element, indx) => {
      let name = element.name;
      let file = element.file;
      nameArr.push(name);
      docsArr.push(file);
    });

    setDocsName(nameArr);
    setDocsFile(docsArr);
  };

  const handelDocumentsFileName = (event, id) => {
    let newArr = supportingFileList.map((elem) => {
      if (elem.id === id) {
        elem.name = event.target.value;
        return elem;
      }
      return elem;
    });

    setSupportingFileList(newArr);
    setFilesToState();
  };

  const handelDocumentsFileData = async (file, id) => {
    let newArr = supportingFileList.map((elem) => {
      if (elem.id === id) {
        elem.file = file;
        return elem;
      }
      return elem;
    });
    setSupportingFileList(newArr);
    setFilesToState();
  };

  const isPdfFile = async (event, id) => {
    // clear state of pdf seleted
    let newArr = supportingFileList.map((elem) => {
      if (elem.id === id) {
        elem.file = "";
        return elem;
      }
      return elem;
    });
    setSupportingFileList(newArr);

    let file = event.target.files[0];
    // setShowLoader(true)
    let res = await checkIsFileIsPDF(file);
    // setShowLoader(false)
    if (res === 200) {
      handelDocumentsFileData(file, id);
      return;
    } else {
      swal({
        title: "Alert!",
        text: "Kindly upload PDF files only",
        icon: "error",
      });

      document.getElementById(id).value = "";
      return false;
    }
  };

  const uploadDocs = async () => {
    if (!docsFile.length && !docsName.length) {
      handleMDRsubmit();
      console.log("DOCS IS EMPTY")
      return;
    }
    if(formData.mid === "") {
      swal({
        title: "Alert!",
        text: "Please select merchant.",
        icon: "warning",
      });
      return;
    }

    console.log("Docs_________",docsFile,docsName)

    let merchantDocsRes = await uploadMerchantDocs(
      formData.mid,
      docsName,
      docsFile,
      token
    );
    if (merchantDocsRes === 200) {
      handleMDRsubmit();
      setSupportingFileList([]);
      setDocsFile([])
      setDocsName([])
    } else {
      swal({
        title: "Alert!",
        text: "Error in uploading documents",
        icon: "error",
      });
    }
  };

  const handleFormSubmit = async (e) => {
 

    const payload = {
      avgValueMonthly: accountData.monthlyVolume || 0,
      chargebackDeclarationPercentage: accountData.Chargeback || 0,
      depositeLumpsum: accountData.depositeLumpsum || 0,
      depositePercentage: accountData.depositePercentage || 0,
      guarantyAmount: accountData.guarantyAmount || 0,
      holdPercentage: accountData.holdPercentage || 0,
      mid: formData.mid || 0,
      netExpensesInFirstyear: financialData.netExpenses.firstYear || 0,
      netExpensesInSeconYear: financialData.netExpenses.secondYear || 0,
      netExpensesInThirdYear: financialData.netExpenses.thirdYear || 0,
      netProfitInFirstYear: financialData.netProfit.firstYear || 0,
      netProfitInSecondYear: financialData.netProfit.secondYear || 0,
      netProfitInthirdyear: financialData.netProfit.thirdYear || 0,
      netPurchaseInFirstYear: financialData.netPurchase.firstYear || 0,
      netPurchaseInSecondYear: financialData.netPurchase.secondYear || 0,
      netPurchaseInThirdYear: financialData.netPurchase.thirdYear || 0,
      netSalesInFirstYear: financialData.netSales.firstYear || 0,
      netSalesInSecondYear: financialData.netSales.secondYear || 0,
      netSalesInThirdYear: financialData.netSales.thirdYear || 0,
      numberOfBusinessYear: accountData.bussinessYear || 0,
      numberOfTicketMonthly: accountData.monthlyTicket || 0,
      numberOfYearFillingCompliance: accountData.fillingYears || 0,
      ticketSize: accountData.ticketSize || 0,
      withdrawType: option || 0,
      withdrawalFlag: accountData.withdrawalFlag || 0,
    };

    console.log("payload", JSON.stringify(payload));


    
if( !accountData.monthlyVolume || !accountData.ticketSize || !accountData.bussinessYear || !accountData.fillingYears || !accountData.monthlyTicket){
  swal({
    title: "Alert!",
    text: "Please Fill All Mendotory fields.",
    icon: "error",
  })
  return
}
if(accountData.withdrawalFlag?(!option):false){
  swal({
    title: "Alert!",
    text: "Please Fill The Auto Withdrawl Type ",
    icon: "error",
  })
  return
}
setShowLoader(true)
    try {
      const response = await axios.post(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/Account/details/upload`,
        payload,
        {
          headers: {
            Authentication: "Bearer " + token,
          },
        }
      );

      // console.log(response);
      if (response.data.statusCode === 200) {
        swal({
          title: "Success!",
          text: "Your application is saved successfully",
          icon: "success",
        });
        setAccountData({
          bussinessYear: 0,
          Chargeback: 0,
          fillingYears: 0,
          ticketSize: 0,
          monthlyVolume: 0,
          monthlyTicket: 0,
          withdraw: Number,
          holdPercentage: 0,
          depositeLumpsum: 0,
          depositePercentage: 0,
          guarantyAmount: 0,
          withdrawType: 0,
          withdrawalFlag: false,
        });
        setFinancialData({
          netSales: {
            firstYear: 0,
            secondYear: 0,
            thirdYear: 0,
          },
          netPurchase: {
            firstYear: 0,
            secondYear: 0,
            thirdYear: 0,
          },
          netProfit: {
            firstYear: 0,
            secondYear: 0,
            thirdYear: 0,
          },
          netExpenses: {
            firstYear: 0,
            secondYear: 0,
            thirdYear: 0,
          },
        });
      }

      if (response.data.statusCode === 306) {
        // handleMDRsubmit()

        // let merchantDocsRes = await uploadMerchantDocs(formData.mid, docsName, docsFile, token);
        // if (merchantDocsRes === 200) {
        //   document.getElementById("docs").value = "";
        // } else {
        //   swal({
        //     title: "Alert!",
        //     text: "Error in Uploading Documents",
        //     icon: "error",
        //   })
        // }

        swal({
          title: "Alert!",
          text: response.data.message,
          icon: "error",
        });
      }
      setShowLoader(false)
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  //   const [monthlyTicket, setMonthlyTicket] = useState(0);
  return (
    <>
      <section className="admin-view-details card mb-2">
        <Container>
          <Card className=" border-0 mb-4 ds-header-card d-flex gap-5">
            <Card.Body>
              <Row>
                <Col xs={12} xl={12} lg={12} sm={12} className="mb-5">
                  <div className="row mt-4">
                    <div className="col-sm-12 col-md-6 offset-md-3">
                      <label className="form-label">
                        Select Merchant
                        <sup className="text-danger">*</sup>
                      </label>
                      <select
                        id="companyDropdown"
                        className="form-control"
                        onChange={handleCompanyChange}
                        value={selectedCompany}
                        style={{ border: "1px solid black" }}
                      >
                        <option value="Blank">Select a Company</option>
                        {merchantData?.map((merchant) => (
                          <option key={merchant.mid} value={merchant.mid}>
                            {merchant.companyName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label htmlFor="email">Merchant ID</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Merchant ID"
                      value={formData.mid}
                      readOnly
                    />
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label htmlFor="fullName">Full Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="fullName"
                      placeholder="Full Name"
                      value={formData.fullName}
                      readOnly
                    />
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label htmlFor="fullName">e-Mail ID</label>
                    <input
                      type="text"
                      className="form-control"
                      id="fullName"
                      placeholder="Full Name"
                      value={formData.email}
                      readOnly
                    />
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </section>
      <section className="lincpay-dashboard-sec merchant-admin-info-form company-add-form card">
        <Container>
          <div className="cart-view-sec mt-4">
            <Row>
              <Col xs={12} lg={12} md={12}>
                {/* Tab content start here */}
                <Card className="border-0 mb-3 chart-boxes merchant-info-form-sec">
                  <Card.Body>
                    {/* <Card.Title className="h3">
                        Company Address Details
                      </Card.Title> */}
                    <div className="merchant-form-info">
                      <form
                        className="form-section-form-group"
                        // onSubmit={handleSubmit}
                      >
                        <Row>
                          <Col xs={12} lg={4} md={6} className="mt-5">
                            <div className="form-group">
                              <label className="form-label">
                                Number Of Bussiness Years (Incubation)
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="d-flex gap-2">
                                <input
                                  // type="number"
                                  className="form-control"
                                  name="bussinessYear"
                                  value={accountData.bussinessYear}
                                  onChange={handleCountChange}
                                />

                                <ButtonGroup>
                                  <Button
                                    aria-label="reduce"
                                    onClick={() => {
                                      if (accountData.bussinessYear > 0) {
                                        setAccountData({
                                          ...accountData,
                                          bussinessYear:
                                            accountData.bussinessYear - 1,
                                        });
                                      }
                                    }}
                                  >
                                    <i class="bi bi-dash"></i>
                                  </Button>

                                  <Button
                                    aria-label="increase"
                                    onClick={() => {
                                      setAccountData({
                                        ...accountData,
                                        bussinessYear:
                                          accountData.bussinessYear + 1,
                                      });
                                    }}
                                  >
                                    <i class="bi bi-plus-lg"></i>
                                  </Button>
                                </ButtonGroup>
                              </div>
                              <span
                                id="regAddErr"
                                className=" text-danger d-none"
                              >
                                Please Enter Regisered Address*
                              </span>
                            </div>
                            {/* ./form-group */}
                          </Col>
                          <Col xs={12} lg={4} md={6} className="mt-5">
                            <div className="form-group">
                              <label className="form-label">
                                Number Of Years Filling Compliances
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="d-flex gap-2">
                                <input
                                  className="form-control"
                                  name="fillingYears"
                                  value={accountData.fillingYears}
                                  onChange={handleCountChange}
                                />

                                <ButtonGroup>
                                  <Button
                                    aria-label="reduce"
                                    onClick={() => {
                                      if (accountData.fillingYears > 0) {
                                        setAccountData({
                                          ...accountData,
                                          fillingYears:
                                            accountData.fillingYears - 1,
                                        });
                                      }
                                    }}
                                  >
                                    <i class="bi bi-dash"></i>
                                  </Button>

                                  <Button
                                    aria-label="increase"
                                    onClick={() => {
                                      setAccountData({
                                        ...accountData,
                                        fillingYears:
                                          accountData.fillingYears + 1,
                                      });
                                    }}
                                  >
                                    <i class="bi bi-plus-lg"></i>
                                  </Button>
                                </ButtonGroup>
                              </div>
                              <span
                                id="regAddErr"
                                className=" text-danger d-none"
                              >
                                Please Enter Regisered Address*
                              </span>
                            </div>
                            {/* ./form-group */}
                          </Col>
                          <Col xs={12} lg={4} md={6} className="mt-5">
                            <div className="form-group">
                              <label className="form-label">
                                Average Ticket Size
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="d-flex gap-2">
                                <input
                                  className="form-control"
                                  name="ticketSize"
                                  value={accountData.ticketSize}
                                  onChange={handleCountChange}
                                />

                                <ButtonGroup>
                                  <Button
                                    aria-label="reduce"
                                    onClick={() => {
                                      if (accountData.ticketSize > 0) {
                                        setAccountData({
                                          ...accountData,
                                          ticketSize:
                                            accountData.ticketSize - 1,
                                        });
                                      }
                                    }}
                                  >
                                    <i class="bi bi-dash"></i>
                                  </Button>

                                  <Button
                                    aria-label="increase"
                                    onClick={() => {
                                      setAccountData({
                                        ...accountData,
                                        ticketSize: accountData.ticketSize + 1,
                                      });
                                    }}
                                  >
                                    <i class="bi bi-plus-lg"></i>
                                  </Button>
                                </ButtonGroup>
                              </div>
                              <span
                                id="regAddErr"
                                className=" text-danger d-none"
                              >
                                Please Enter Regisered Address*
                              </span>
                            </div>
                            {/* ./form-group */}
                          </Col>
                          <Col xs={12} lg={4} md={6} className="mt-5">
                            <div className="form-group">
                              <label className="form-label">
                                Average Volume Monthly
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="d-flex gap-2">
                                <input
                                  className="form-control"
                                  name="monthlyVolume"
                                  value={accountData.monthlyVolume}
                                  onChange={handleCountChange}
                                />

                                <ButtonGroup>
                                  <Button
                                    aria-label="reduce"
                                    onClick={() => {
                                      if (accountData.monthlyVolume > 0) {
                                        setAccountData({
                                          ...accountData,
                                          monthlyVolume:
                                            accountData.monthlyVolume - 1,
                                        });
                                      }
                                    }}
                                  >
                                    <i class="bi bi-dash"></i>
                                  </Button>

                                  <Button
                                    aria-label="increase"
                                    onClick={() => {
                                      setAccountData({
                                        ...accountData,
                                        monthlyVolume:
                                          accountData.monthlyVolume + 1,
                                      });
                                    }}
                                  >
                                    <i class="bi bi-plus-lg"></i>
                                  </Button>
                                </ButtonGroup>
                              </div>
                              <span
                                id="regAddErr"
                                className=" text-danger d-none"
                              >
                                Please Enter Regisered Address*
                              </span>
                            </div>
                            {/* ./form-group */}
                          </Col>
                          <Col xs={12} lg={4} md={6} className="mt-5">
                            <div className="form-group">
                              <label className="form-label">
                                Number Of Ticket Monthly
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="d-flex gap-2">
                                <input
                                  className="form-control"
                                  name="monthlyTicket"
                                  value={accountData.monthlyTicket}
                                  onChange={handleCountChange}
                                />

                                <ButtonGroup>
                                  <Button
                                    aria-label="reduce"
                                    onClick={() => {
                                      if (accountData.monthlyTicket > 0) {
                                        setAccountData({
                                          ...accountData,
                                          monthlyTicket:
                                            accountData.monthlyTicket - 1,
                                        });
                                      }
                                    }}
                                  >
                                    <i class="bi bi-dash"></i>
                                  </Button>

                                  <Button
                                    aria-label="increase"
                                    onClick={() => {
                                      setAccountData({
                                        ...accountData,
                                        monthlyTicket:
                                          accountData.monthlyTicket + 1,
                                      });
                                    }}
                                  >
                                    <i class="bi bi-plus-lg"></i>
                                  </Button>
                                </ButtonGroup>
                              </div>
                              <span
                                id="regAddErr"
                                className=" text-danger d-none"
                              >
                                Please Enter Regisered Address*
                              </span>
                            </div>
                            {/* ./form-group */}
                          </Col>
                          <Col xs={12} lg={4} md={6} className="mt-5">
                            <div className="form-group mt-2">
                              <label className="form-lable">
                                Withdrawl Required
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="d-flex justify-content-around align-items-center form-control">
                                <div>
                                  <input
                                    type="radio"
                                    className="mx-2"
                                    // checked={accountData.withdrawalFlag?false:true }
                                    onChange={() => {
                                      setAccountData({
                                        ...accountData,
                                        withdrawalFlag: false,
                                      });
                                      setOptions(0);
                                    }}
                                    name="withdrawalFlag"
                                    value={accountData.withdrawalFlag}
                                    inputProps={{ "aria-label": "A" }}
                                  />
                                  <lable>Manual</lable>
                                </div>
                                <div>
                                  <input
                                    type="radio"
                                    className="mx-2"
                                    // checked={accountData.withdrawalFlag }
                                    onChange={() => {
                                      setAccountData({
                                        ...accountData,
                                        withdrawalFlag: true,
                                      });
                                      setOptions(0);
                                    }}
                                    value={accountData.withdrawalFlag}
                                    name="withdrawalFlag"
                                    inputProps={{ "aria-label": "B" }}
                                  />
                                  <lable>Auto</lable>
                                </div>
                              </div>
                              {accountData?.withdrawalFlag ? (
                                <div className="d-flex justify-content-end form-control">
                                  <div className="d-flex flex-column ">
                                    <div>
                                      {/* <lable>
                                        In Case Of Auto{" "}
                                        <sup className="text-danger">*</sup>
                                      </lable> */}
                                      <select
                                        id="companyDropdown"
                                        className="form-control"
                                        onChange={handleOptions}
                                        value={option}
                                      >
                                        <option key="" value="0">
                                          Select
                                        </option>
                                        {withdrawType?.map((withdrawType) => (
                                          <option
                                            key={withdrawType.withdrawTypeid}
                                            value={withdrawType.withdrawTypeid}
                                          >
                                            {withdrawType.withdrawType}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-5">
                          <div className="w-100 d-flex gap-2">
                            <label className="w-25 form-label">
                              Last 3 Years Balance Sheet
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="w-75 d-flex align-items-center justify-content-between w-100 border border-2 p-3 mb-5">
                              <table className="w-100">
                                <thead>
                                  <tr>
                                    <td scope="col"></td>
                                    <th scope="col">1st Year</th>
                                    <th scope="col">2nd Year</th>
                                    <th scope="col">3rd Year</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.keys(financialData).map((metric) => (
                                    <tr key={metric} className="mx-2">
                                      <td className="fw-bold">
                                        {metric === "netSales"
                                          ? "Net Sales"
                                          : metric === "netPurchase"
                                          ? "Net Purchase"
                                          : metric === "netExpenses"
                                          ? "Net Expences"
                                          : metric === "netProfit"
                                          ? "Net Profit"
                                          : ""}{" "}
                                      </td>
                                      <td>
                                        <input
                                          //  type="number"
                                          className="w-100 p-1"
                                          placeholder="Number"
                                          value={
                                            financialData[metric]["firstYear"]
                                          }
                                          onChange={(e) =>
                                            handleInputChange(
                                              metric,
                                              "firstYear",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          //  type="number"
                                          className="w-100 p-1"
                                          value={
                                            financialData[metric]["secondYear"]
                                          }
                                          onChange={(e) =>
                                            handleInputChange(
                                              metric,
                                              "secondYear",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          //  type="number"
                                          className="w-100 p-1"
                                          value={
                                            financialData[metric]["thirdYear"]
                                          }
                                          onChange={(e) =>
                                            handleInputChange(
                                              metric,
                                              "thirdYear",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Row>
                        <Row>
                          <Col xs={12} lg={4} md={6} className="mt-5">
                            <div className="form-group border border-1 p-3">
                              <label className="form-label">
                                Deposit
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="d-flex gap-2 flex-column  justify-content-evenly p-2">
                                <div className="form-group">
                                  <label className="form-label">
                                    In Lumsum
                                    <sup className="text-danger">*</sup>
                                  </label>
                                  <div className="d-flex gap-2">
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="depositeLumpsum"
                                      value={accountData.depositeLumpsum}
                                      onChange={handleAccoundPhase3data}
                                    />
                                  </div>
                                  <span
                                    id="regAddErr"
                                    className=" text-danger d-none"
                                  >
                                    Please Enter Regisered Address*
                                  </span>
                                </div>
                                <div className="form-group  ">
                                  <label className="form-label">
                                    In Percentage
                                    <sup className="text-danger">*</sup>
                                  </label>
                                  <div className="d-flex gap-2">
                                    <input
                                      className="form-control"
                                      type="number"
                                      name="depositePercentage"
                                      value={accountData.depositePercentage}
                                      onChange={handleAccoundPhase3data}
                                    />
                                  </div>
                                  <span
                                    id="regAddErr"
                                    className=" text-danger d-none"
                                  >
                                    Please Enter Regisered Address*
                                  </span>
                                </div>
                              </div>
                              <span
                                id="regAddErr"
                                className=" text-danger d-none"
                              >
                                Please Enter Regisered Address*
                              </span>
                            </div>
                            {/* ./form-group */}
                          </Col>

                          <Col xs={12} lg={4} md={6} className="mt-5">
                            <div className="form-group border border-1 p-3">
                              <label className="form-label">
                                Hold
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="d-flex gap-2 flex-column  justify-content-evenly p-2">
                                <div className="form-group">
                                  <label className="form-label">
                                    In Percentage
                                    <sup className="text-danger">*</sup>
                                  </label>
                                  <div className="d-flex gap-2">
                                    <input
                                      className="form-control"
                                      type="number"
                                      name="holdPercentage"
                                      value={accountData.holdPercentage}
                                      onChange={handleAccoundPhase3data}
                                    />
                                  </div>
                                  <span
                                    id="regAddErr"
                                    className=" text-danger d-none"
                                  >
                                    Please Enter Regisered Address*
                                  </span>
                                </div>
                              </div>
                              <span
                                id="regAddErr"
                                className=" text-danger d-none"
                              >
                                Please Enter Regisered Address*
                              </span>
                            </div>
                            {/* ./form-group */}
                          </Col>
                          <Col xs={12} lg={4} md={6} className="mt-5">
                            <div className="form-group border border-1 p-3">
                              <label className="form-label">
                                Guarantee
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="d-flex gap-2  flex-column justify-content-evenly p-2">
                                <div className="form-group">
                                  <label className="form-label">
                                    In Lumsum
                                    <sup className="text-danger">*</sup>
                                  </label>
                                  <div className="d-flex gap-2">
                                    <input
                                      className="form-control"
                                      type="number"
                                      name="guarantyAmount"
                                      value={accountData.guarantyAmount}
                                      onChange={handleAccoundPhase3data}
                                    />
                                  </div>
                                  <span
                                    id="regAddErr"
                                    className=" text-danger d-none"
                                  >
                                    Please Enter Regisered Address*
                                  </span>
                                </div>
                              </div>
                              <span
                                id="regAddErr"
                                className=" text-danger d-none"
                              >
                                Please Enter Regisered Address*
                              </span>
                            </div>
                            {/* ./form-group */}
                          </Col>
                          <Col xs={12} lg={4} md={6} className="mt-5">
                            <div className="form-group border border-1 p-3">
                              <label className="form-label">
                                Chargeback declaration
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="d-flex gap-2  flex-column justify-content-evenly p-2">
                                <div className="form-group">
                                  <label className="form-label">
                                    In Percentage
                                    <sup className="text-danger">*</sup>
                                  </label>
                                  <div className="d-flex gap-2">
                                    <input
                                      className="form-control"
                                      name="Chargeback"
                                      type="number"
                                      value={accountData.Chargeback}
                                      onChange={handleAccoundPhase3data}
                                    />
                                  </div>
                                  <span
                                    id="regAddErr"
                                    className=" text-danger d-none"
                                  >
                                    Please Enter Regisered Address*
                                  </span>
                                </div>
                              </div>
                              <span
                                id="regAddErr"
                                className=" text-danger d-none"
                              >
                                Please Enter Regisered Address*
                              </span>
                            </div>
                            {/* ./form-group */}
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </Card.Body>
                </Card>
                {/* Tab content end here */}
              </Col>
              {/* ./col */}
            </Row>
          </div>
        </Container>
      </section>
      <section className="lincpay-dashboard-sec merchant-admin-info-form company-add-form card  mt-2">
        <Container>
          <div className="p-5 ">
            <Row className="d-flex justify-content-start">
              <div className="form-group mt-3 ">
                <div className="mb-5 d-flex align-items-center fw-bold">
                  <label htmlFor="form-label mb-5">
                    ON GOING PAYMENT COLLECTION INFORMATION
                  </label>
                  <span
                    onClick={addSupportingDocumentField}
                    style={{ cursor: "pointer" }}
                    className="text-primary fs-4 mx-2"
                  >
                    <i className="bi bi-plus-circle-fill"></i>
                  </span>
                </div>
                <Row className="">
                  {supportingFileList?.map((elem) => {
                    return (
                      <Col xs={10} lg={4} md={6}>
                        <div
                          className="d-flex align-items-center"
                          key={elem.id}
                        >
                          <div className="w-100">
                            <div className="form-group">
                              <label
                                htmlFor="callBackUrl"
                                className="form-label"
                              >
                                Label Name
                                <sup className="text-danger">*</sup>
                              </label>
                              <input
                                type="text"
                                id="callBackUrl"
                                name="name"
                                value={elem.name}
                                required="true"
                                onChange={(event) =>
                                  handelDocumentsFileName(event, elem.id)
                                }
                                className="form-control"
                              />

                              {/* <span
                                          id="callBackUrlErr"
                                          className=" text-danger d-none"
                                        >
                                          Please Enter CallBack Url*
                                        </span> */}
                            </div>
                          </div>

                          <input
                            type="file"
                            accept=".pdf"
                            required="true"
                            onChange={(event) => isPdfFile(event, elem.id)}
                            id={elem.id}
                            className="form-control mx-3"
                            style={{ marginTop: "29px" }}
                          />
                        </div>
                        <div className="d-flex justify-content-end mt-1 mx-2">
                          <span
                            onClick={() =>
                              removeSupportingDocumentField(elem.id)
                            }
                            style={{ cursor: "pointer" }}
                            className="text-danger fs-4"
                          >
                            <i className="bi bi-trash-fill"></i>
                          </span>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
                {/* {supportingFileList?.length > 0 ? (
                  <Col xs={12} lg={12} md={12}>
                    <div className="d-flex justify-content-end mt-3">
                      <div className="form-group tool-bar-btn mb-0 mt-3">
                        <button
                          className="submit-button submit_link_info btn btn-success"
                          onClick={uploadDocs}
                          id="submitDetailsBtn"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </Col>
                ) : (
                  ""
                )} */}
              </div>
            </Row>
            <Row> </Row>
          </div>
        </Container>
      </section>
      <section className="lincpay-dashboard-sec merchant-admin-info-form company-add-form card  mt-2 mb-3">
        <Container>
          <div className="p-5 mt-2">
            <Row className="mt-5">
              <label className="form-label fw-bold mb-2">
                MERCHANT DISCOUNT RATE
                <sup className="text-danger">*</sup>
              </label>
              <label
                className="fw-semi-bold text-danger mb-5"
                style={{ fontSize: "13px" }}
              >
                (Please enter the MDR rate in Percentage.)
              </label>

              {MDRTypes?.map((service) => (
                <Col xs={12} lg={3} md={5} key={service.paymentTypeId}>
                  <div className="form-group mt-3">
                    <label className="form-label">
                      {service.paymentservice}
                      <sup className="text-danger">*</sup>
                    </label>
                    <input
                      type="number"
                      name={service.paymentTypeId}
                      className="form-control"
                      value={MDRData[service.paymentTypeId]  } // Set value correctly
                      onChange={(e) =>
                        handleMDRChange(
                          service.paymentservice,
                          e.target.value,
                          service.paymentTypeId
                        )
                      }
                      required
                    />
                    <span id="regAddErr" className="text-danger d-none">
                      Please Enter Registered Address*
                    </span>
                  </div>
                </Col>
              ))}

              <Col xs={12} lg={12} md={12}>
                <div className="d-flex justify-content-end mt-3">
                  <div className="form-group tool-bar-btn mb-0 mt-3">
                    <button
                      className="submit-button submit_link_info btn btn-primary"
                      onClick={uploadDocs}
                      id="submitDetailsBtn"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Col>
              {/* {MDRTypes?.length === 0 ? "" : (
                <Col xs={12} lg={12} md={12}>
                  <div className="d-flex justify-content-end mt-3">
                    <div className="form-group tool-bar-btn mb-0 mt-3">
                      <button
                        className="submit-button submit_link_info btn btn-success"
                        onClick={handleMDRsubmit}
                        id="mdrSave"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Col>
              )} */}
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default AccountPhase1;
