import React from "react";
import { useState, useEffect } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { DateTime } from "luxon";
import { isVpaUpiRegexIsValid } from "../../utils/helper";
import { USE_PAYIN_REPORT_FILTER_API as getApiData } from "../../utils/ApiServices";
import axios from "axios";
import { SWIPELINC_API } from "../../utils/constant";

const TransactionReportMultipleFilter = ({
  setTransactionReportData,
  setIsMultipleFilterOpen,
  setShowLoader,
  mid,
  setCountData,
}) => {
  const token = localStorage.getItem("token");
  
  const [lessAmount, setLessAmount] = useState("");
  const [greaterAmount, setGreaterAmount] = useState("");
  const [startAmount, setStartAmount] = useState(Number);
  const [range, setRange] = useState("");
  const [endAmount, setEndAmount] = useState(Number);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchOption, setSearchOption] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [searchValue, setSearchValue] = useState("test");
  const [transectionStatus, setTransectionStatus] = useState("");
  const [searchOption2, setSearchOption2] = useState("");
  const [searchValue2, setSearchValue2] = useState("");
  const [show2, setShow2] = useState(true);

  const exampleValues = {
    amount: "Ex- 5500",
    amountInBetween: "Ex- 100-500",
    VPA: "Ex- abcde@xyz / 98XXXXXXX1@xyz",
    paymentType: "Ex-  QR / SQR / DQR / UPI",
    custLocation: "Ex- Madhya Pradesh",
    bankLocation: "Ex- Bhopal",
    // Add more options and example values as needed
  };

  
  const [searchValueError, setSearchValueError] = useState("");
  const [isSearchValueError, setIsSearchValueError] = useState(false);
  const [isAlphabet, setIsAlphabet] = useState(false);

  const handleAmountRange = (e) => {
    setRange(e.target.value);
    function splitString(inputString) {
      const parts = inputString.split("-").map((part) => part.trim());
      return parts;
    }

    const [startAmount, endAmount] = splitString(e.target.value);
    setStartAmount(startAmount);
    setEndAmount(endAmount);

    console.log(startAmount, endAmount);
  };

  useEffect(() => {
    setSearchValue("");
  }, [searchOption]);
  useEffect(() => {
    setSearchValue2("");
  }, [searchOption2]);

  const getAllTxnStatus = async () => {
    const payload = {
      date: searchOption2 === "date" ? searchValue2 : "",
      endDate: searchOption2 === "dateInBetween" ? endDate : "",
      mid: mid,
      status: transectionStatus,
      startDate: searchOption2 === "dateInBetween" ? startDate : "",
    };

    try {
      const response = await axios.post(
      `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getReportBuMultipalConditionWithCountTxnStatusCode`,
        payload,
        {
          headers: {
            Authentication: "Bearer " + token,
          },
        }
      );
      if (response.data.statusCode === 200) {
        setCountData(response?.data?.responseData);
      } else {
        setCountData([]);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const submitHandler = () => {
    // console.log(searchOption2, searchValue2);
    getApiData(
      searchOption === "amount" ? searchValue : 0,
      searchOption === "bankLocation" ? searchValue : "",
      searchOption === "custLocation" ? searchValue : "",
      searchOption2 === "date" ? searchValue2 : "",
      searchOption === "amountInBetween" ? endAmount : 0,
      searchOption2 === "dateInBetween" ? endDate : "",
      greaterAmount,
      ipAddress,
      lessAmount,
      mid,
      searchOption === "VPA" ? searchValue : "",
      searchOption === "paymentType" ? searchValue.toUpperCase() : "",
      searchOption === "amountInBetween" ? startAmount : 0,
      searchOption2 === "dateInBetween" ? startDate : "",
      token,
      transectionStatus,
      setTransactionReportData,
      setShowLoader
    );

    // getAllTxnStatus();
    handleModalClose();
  };

  const handleModalClose = () => {
    setShow2(false);
    setIsMultipleFilterOpen(false);
  };

  const handleDateInputChange = (event) => {
    const newDate = event.target.value;
    // Check if the input date is greater than today's date
    const inputDateTime = DateTime.fromISO(newDate, { zone: "UTC" });
    const today = DateTime.now().startOf("day");

    if (inputDateTime.toISODate() > today.toISODate()) {
      return true;
    }
    return false;
  };

  const handelSearchData = (e) => {
    setSearchValue(e.target.value);
    setIsSearchValueError(false);
    setSearchValueError("");
  };

  const getKeyCode = (e) => {
    // get alphabet
    if (
      (e.keyCode >= 65 && e.keyCode <= 90) ||
      e.key === "Backspace" ||
      e.keyCode === 32
    ) {
      setSearchValue(e.target.value);
      setIsAlphabet(true);
      return;
    }
    setIsAlphabet(false);
    return;
  };

  const handelValidations = (e) => {
    if (searchOption === "VPA") {
      if (!isVpaUpiRegexIsValid(searchValue)) {
        setIsSearchValueError(true);
        setSearchValueError("Invalid Enter Valid VPA");
      }
      return;
    }

    if (searchOption === "custLocation" || searchOption === "bankLocation") {
      // console.log(e.key);
      const regex = new RegExp(/^[a-z]*$/);
      const isValidData = regex.test(searchValue);
      // console.log(searchValue,":",isValidVpa);
      if (!isValidData) {
        setIsSearchValueError(true);
        setSearchValueError(
          `Invalid Enter Valid ${
            searchOption === "bankLocation"
              ? "Bank Location"
              : "Customer Location"
          } `
        );
      }
      return;
    }
  };

  const handleFromToDateInputChange = (from, to) => {
    console.log(from, ":", to);

    const inputFromDateTime = DateTime.fromISO(from, { zone: "utc" });
    const inputToDateTime = DateTime.fromISO(to, { zone: "utc" });
    if (inputToDateTime >= inputFromDateTime) {
      return true;
    } else {
      return false;
    }
  };

  // Modal Popup filter conts
  // const [show, setShow] = useState(false);

  // const handleModalClose = () => setShow(false);
  // const handleModalShow = () => setShow(true);
  return (
    <div>
      <div className="d-flex flex-row">
        <Modal show={show2} size="lg" centered>
          <Modal.Header className="bg-dark ">
            <Modal.Title className="text-white">Multiple Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="filter-field-area">
              <fieldset className="rounded-0 mb-3">
                <legend className="fs-5 fw-bold">Select Date</legend>
                <div className="field-set-form">
                  <Row className="g-2">
                    <Col lg={6} sm={12}>
                      <div>
                        <select
                          className="form-control"
                          value={searchOption2}
                          onChange={(e) => setSearchOption2(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="date">Date</option>
                          <option value="dateInBetween">Date In Between</option>
                          <i class="bi bi-caret-down-fill"></i>
                        </select>
                      </div>
                    </Col>

                    {searchOption2 !== "dateInBetween" ? (
                      <Col lg={6} sm={12}>
                        <div className="form-group">
                          <input
                            className="advanced-input-box form-control"
                            type="date"
                            value={searchValue2}
                            onChange={(e) => {
                              if (handleDateInputChange(e)) {
                                setSearchValue2("");
                                console.log("true..........");
                                document
                                  .getElementById("dateErr")
                                  .classList.remove("d-none");
                              } else {
                                document
                                  .getElementById("dateErr")
                                  .classList.add("d-none");
                                setSearchValue2(e.target.value);
                                console.log("false..........");
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === " ") {
                                e.preventDefault();
                              }
                            }}
                            placeholder={
                              searchOption === "Date" ? "Enter Date" : ""
                            }
                          />
                          <span id="dateErr" className=" text-danger d-none">
                            *Date must be lesser than current date.
                          </span>
                        </div>
                      </Col>
                    ) : (
                      <>
                        <Col lg={6} sm={12}>
                          <div className="form-group">
                            <input
                              className="advanced-input-box form-control"
                              type="date"
                              value={startDate}
                              onChange={(e) => {
                                if (handleDateInputChange(e)) {
                                  setStartDate("");
                                  document
                                    .getElementById("startDateErr")
                                    .classList.remove("d-none");
                                } else {
                                  document
                                    .getElementById("startDateErr")
                                    .classList.add("d-none");
                                  setStartDate(e.target.value);
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                              }}
                              placeholder="Enter Start Date"
                            />
                            <span
                              id="startDateErr"
                              className=" text-danger d-none"
                            >
                              *Please Enter Valid Date!
                            </span>
                          </div>
                          <div className="form-group">
                            <input
                              className="advanced-input-box form-control"
                              type="date"
                              value={endDate}
                              onChange={(e) => {
                                if (handleDateInputChange(e)) {
                                  setEndDate("");
                                  document
                                    .getElementById("endDateErr")
                                    .classList.remove("d-none");
                                } else {
                                  if (
                                    handleFromToDateInputChange(
                                      startDate,
                                      e.target.value
                                    )
                                  ) {
                                    document
                                      .getElementById("endDateErr")
                                      .classList.add("d-none");
                                    document
                                      .getElementById("fromToDateErr")
                                      .classList.add("d-none");
                                    setEndDate(e.target.value);
                                  } else {
                                    document
                                      .getElementById("fromToDateErr")
                                      .classList.remove("d-none");
                                  }
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                              }}
                              placeholder="End Date"
                            />
                            <span
                              id="endDateErr"
                              className=" text-danger d-none"
                            >
                              *Please Enter Valid Date!
                            </span>
                            <span
                              id="fromToDateErr"
                              className=" text-danger d-none"
                            >
                              *TO Date can't be less than FROM Date!
                            </span>
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
              </fieldset>

              <fieldset className="border-2 rounded-0 mt-3">
                <legend className="fs-5 fw-bold">Select Parameter</legend>
                <div className="field-set-form">
                  <Row className="g-2">
                    <Col lg={6}>
                      <div className="form-group">
                        <select
                          className="form-control"
                          value={searchOption}
                          onChange={(e) => setSearchOption(e.target.value)}
                        >
                          <option type="number" value="">
                            Select
                          </option>
                          <option type="number" value="amount">
                            Amount
                          </option>
                          <option type="number" value="amountInBetween">
                            Amount Range
                          </option>
                          <option type="text" value="VPA">
                            UPI ID / VPA
                          </option>
                          <option type="text" value="paymentType">
                            Type Of Payment
                          </option>
                          <option type="text" value="custLocation">
                            Customer Location
                          </option>
                          <option type="text" value="bankLocation">
                            Bank Location
                          </option>
                        </select>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="form-group">
                        {searchOption === "amountInBetween" ? (
                          <>
                            <input
                              className="advanced-input-box form-control"
                              type="text"
                              min={0}
                              value={range}
                              onChange={(e) => handleAmountRange(e)}
                            />
                            <span className="ml-2">
                              {searchOption && exampleValues[searchOption]}
                            </span>
                          </>
                        ) : searchOption === "custLocation" ||
                          searchOption === "bankLocation" ? (
                          <div>
                            <input
                              className="advanced-input-box form-control"
                              type="text"
                              value={searchValue}
                              onKeyDown={getKeyCode}
                              onChange={
                                isAlphabet
                                  ? (e) => setSearchValue(e.target.value)
                                  : () => {}
                              }
                            />
                            <span className="ml-2 mt-1">
                              {searchOption && exampleValues[searchOption]}
                            </span>
                          </div>
                        ) : searchOption === "amount" ? (
                          <div>
                            <input
                              className="advanced-input-box form-control"
                              type="number"
                              value={searchValue}
                              onChange={(e) => setSearchValue(e.target.value)}
                            />
                            <span className="ml-2 mt-1">
                              {searchOption && exampleValues[searchOption]}
                            </span>
                          </div>
                        ) : (
                          <div>
                            <input
                              className="advanced-input-box form-control"
                              type="text"
                              value={searchValue}
                              onChange={handelSearchData}
                              onBlur={handelValidations}
                            />
                            {isSearchValueError ? (
                              <span id="searchValErr" className=" text-danger">
                                {`${searchValueError}`}
                              </span>
                            ) : (
                              ""
                            )}
                            <div>
                              <span className="ml-2 mt-1">
                                {searchOption && exampleValues[searchOption]}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </fieldset>

            
              <fieldset className="border-2 rounded-0 mt-3">
                <legend className="fs-5 fw-bold">Transaction Status</legend>
                <div className="field-set-form">
                  <Row className="g-2">
                    <Col lg={12} sm={12}>
                      <div className="form-group">
                        <select
                          className="form-control"
                          value={transectionStatus}
                          onChange={(e) => setTransectionStatus(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="success">Success</option>
                          <option value="failed">Failure</option>
                          <option value="fraud">Fraud</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                </div>
              </fieldset>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              size="sm"
              className="text-capitlize"
              onClick={handleModalClose}
            >
              Close
            </Button>
            <Button
              className="text-capitlize"
              size="sm"
              variant="primary"
              onClick={submitHandler}
            >
              <i class="bi bi-search mx-1"></i> Search
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      {/* {multiApiResponse && multiApiResponse.length === 0 && (
    <div className="no-data-message">No data available.</div>
  )} */}
    </div>
  );
};

export default TransactionReportMultipleFilter;