import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { SWIPELINC_API } from "../../../utils/constant";
import { MDBDataTable } from "mdbreact";

const ViewMappedSID = ({ setShowLoader }) => {
  const [sidData, setSidData] = useState([]);
  const token = localStorage.getItem("token");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sidDetailsData, setSidDetailsData] = useState([]);
  const [name, setName] = useState("");

  const getAllInitialSID = () => {
    setShowLoader(true);
    axios
      .get(`${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/getAllSid`, {
        headers: {
          Authentication: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          setSidData(response.data.data);
          let res = response.data.data.map((elem) => {
            return { ...elem, maxTxnAmtInputVal: "" };
          });
          setSidData(res);
        }

        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handelSearchLimit = (e, sid,Name) => {
    setName(Name)
    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/admin/MerchantRouting/getall-mapped-sid/${sid}`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log(response);
        if (response.data.statusCode === 200) {
          setSidDetailsData(response.data.data);
        } else {
          setSidDetailsData([]);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    setIsModalOpen(true);
  };

  const sidDetailsColumn = [
    { label: "Merchant ID", field: "mid", width: "auto" },
    { label: "Merchant Name", field: "companyName", width: "auto" },
    { label: "SID", field: "sid", width: "auto" },
    { label: "SID Limit", field: "sidLimit", width: "auto" },
  ];

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getAllInitialSID();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-center align-items-center flex-column">
        {sidData.map((elem) => {
          // console.log("elem---", elem);
          return (
            <div
              key={elem.sid}
              className=" d-flex align-items-center justify-content-center mt-2 col-sm-12 col-md-8 py-2 border  "
            >
              <div className="mt-2 mx-5 col-5">
                <div className=" ">{elem.company}</div>
              </div>
              <div className="d-flex flex-column col-sm-12 col-md-4 mt-2  ">
                <span className="  text-danger">
                  Maximum Transaction Amount : {`${elem.maxTxnAmount}`}
                </span>
                <span className=" text-danger">
                  Remaining Limit : {`${elem.remainingLimit}`}
                </span>
              </div>
              <div className="col-sm-12 col-md-2 mx-3 my-auto ">
                <Button
                  className="bg-success"
                  size="sm"
                    onClick={(e) => handelSearchLimit(e, elem.sid,elem.company)}
                >
                  View Mapped SID's
                </Button>
              </div>
            </div>
          );
        })}
      </div>
      <Modal show={isModalOpen} onHide={handleCloseModal} size="lg">
        <Modal.Header className="d-flex flex-column  align-items-start justify-content-evenly" closeButton>
          <Modal.Title> {name}</Modal.Title>
          <Modal.Title>SID Details:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="shadow border-0 mb-4">
            <Card.Body>
              <div className="merchant-report-view">
                <div className="report-table-view">
                  <div className="containerflag ">
                    <div className="row mt-4 ">
                      <MDBDataTable
                        className="dataTable"
                        striped
                        small
                        bordered
                        hover
                        responsive
                        data={{
                          columns: sidDetailsColumn,
                          rows: sidDetailsData,
                        }}
                        searching={true}
                        entries={50}
                        exportToCSV
                        theadColor="#333"
                        paginationLabel={["Previous", "Next"]}
                        sortable={true}
                        footer="none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewMappedSID;