import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MDBDataTable } from 'mdbreact'; 
import { SWIPELINC_API } from '../utils/constant';

const PayoutReport = () => {
  const [payoutData, setPayoutData] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    // Make an Axios GET request to fetch the data from the API for Payouts
    axios
      .get(`${SWIPELINC_API}MerchantAdminPanel/admin/payout/getAllUsage`, {
        headers: {
          Authentication: `Bearer ${token}`
        }
      })
      .then((response) => {
        if (response.data.statusCode === 200 && Array.isArray(response.data.data)) {
          setPayoutData(response.data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching payout data:', error);
      });
  }, []);

  // Define the columns for the MDBDataTable for Payouts
  const columns = [
    { label: 'Date', field: 'Date', width: 'auto' },
    { label: 'Time', field: 'Time', width: 'auto' },
    { label: 'To Customer/Vendor Name', field: 'To Customer/Vendor Name', width: 'auto' },
    { label: 'Transfer Type', field: 'Transfer Type', width: 'auto' },
    { label: 'Particular(A/C or VPA)', field: 'Particular(A/C or VPA)', width: 'auto' },
    { label: 'Amount', field: 'Amount', width: 'auto' },
    { label: 'RRN', field: 'RRN', width: 'auto' },
    ];

  const mappedData = payoutData.map((item) => {
    const mappedItem = {};
    columns.forEach((column) => {
      mappedItem[column.field] = item[column.field];
    });
    return mappedItem;
  });

  return (
    <>
      <main className="">
        <div className="reportSection">
          <div className="container">
            <div className="tabs-content active" id="tab1">
              <div className="card py-3">
                <div id="Payout-datatable-container">
                  <MDBDataTable
                    className='dataTable overflow-x-scroll'
                    hover
                    striped
                    small
                    bordered
                    responsive
                    sortable={true}
                    searching={true}
                    entries={10}
                    data={{
                      columns: columns,
                      rows: mappedData
                    }}
                    footer="none"
                    theadColor='#333'
                    paginationLabel={['Previous', 'Next']}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default PayoutReport;
