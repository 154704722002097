import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import swal from "sweetalert";
import { SWIPELINC_API } from "../utils/constant";
import axios from "axios";
import { disableVehicleMaster, enableVehicleMaster, fetchAllActiveAndDisableVehicleMaster, fetchAllVehicleMasterForm } from "../utils/ApiServices";

import VehicleMasterEditForm from "./VehicleMasterEditForm";

const SubmittedVehicleMasterForms = ({ setShowLoader }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [vehicalMasterData, setVehicalMasterData] = useState([]);
  const [callAgain, setCallAgain] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [registrationId, setRegistrationId] = useState("");
  const [data, setData] = useState({});

  useEffect(() => {
    GetVehicalMasterData(token);
  }, [callAgain, openEdit]);

  const GetVehicalMasterData = async (token) => {
    setShowLoader(true);
    try {
      const response = await fetchAllActiveAndDisableVehicleMaster(token); 

      if (response.statusCode === 200) {
        setVehicalMasterData(response?.data);
      } else {
        setVehicalMasterData([]);
      }
    } catch (error) {
      console.log(error);
    }
    setShowLoader(false);
  };

  const columns = [
    { label: "Vehical Name", field: "vehicleName", width: "auto" },
    {label: "Scheme",field: "schemeName",width: "auto",},
    {label: "Intercharge Fix",field: "interchangeFeeFix",width: "auto",},
    {label: "Intercharge Percentage",field: "interchangeFeePercentage",width: "auto",},
    {label: "Bank",field: "bankName",width: "auto" },
    {label: "Nodel Fee Fix",field: "nodelFeeFix",width: "auto" },
    {label: "Nodel Fee Percentage",field: "nodelFeePercentage",width: "auto" },
    {label: "Switch",field: "switchName",width: "auto",},
    {label: "Processing Fee Fix",field: "processingFeeFix",width: "auto",},
    {label: "Processing Fee Percentage",field: "processingFeePercentage",width: "auto",},
    { label: "Aggeregator", field: "aggregatorName", width: "auto" },
    { label: "Platform Fee Fix", field: "platformFeeFix", width: "auto" },
    { label: "Platform Fee Percentage", field: "platformFeePercentage", width: "auto" },
    { label: "Remark", field: "remark", width: "auto" },
    { label: "Payment Services", field: "PaymentServices", width: "auto" },
    {label: "Payment Sub Services",field: "PaymentSubServices",width: "auto", },
    { label: "Action", field: "Edit", width: "auto" },
    // { label: "Status", field: "active", width: "auto" },
  ];

  const disableVehicle = async (regId) => {
    setShowLoader(true);
    let res = await disableVehicleMaster(token, regId)
    if(res.statusCode === 200) {
      swal({
        title: "Success!",
        text: "Vehicle Disable Successfully.",
        icon: "success",
      }).then(() => {
        GetVehicalMasterData(token)
      })
    } else {
      swal({
        title: "Alert!",
        text: res.message,
        icon: "error",
      })
    }
    setShowLoader(false);
  }

  const enableVehicle = async (regId) => {
    setShowLoader(true);
    let res = await enableVehicleMaster(token, regId)
    if(res.statusCode === 200) {
      swal({
        title: "Success!",
        text: "Vehicle Enable Successfully.",
        icon: "success",
      }).then(() => {
        GetVehicalMasterData(token)
      });
    } else {
      swal({
        title: "Alert!",
        text: res.message,
        icon: "error",
      });
    }
    setShowLoader(false);
  }

  const mappedData = vehicalMasterData
    ? vehicalMasterData?.map((item, i) => {
        const mappedItem = { ...item };
       
        console.log("active>>>>>>",mappedItem.active);

        if(mappedItem.active) {
           mappedItem.Edit = (
            <div className="d-flex gap-2 justify-content-evenly">
              <button
                className="btn btn-warning btn-sm"
                key={i}
                onClick={(e) => OpenEdit(item.registrationId, { ...item })}
              >
                Edit
              </button>
              <button
                className="btn btn-danger btn-sm"
                key={i}
                onClick={(e) => disableVehicle(item.registrationId)}
              >
                Disable
              </button>
            </div>
          );
        }

        if(!mappedItem.active) {
          mappedItem.Edit = (
           <div className="d-flex gap-2 justify-content-evenly">
             <button
               className="btn btn-warning btn-sm"
               key={i}
               onClick={(e) => OpenEdit(item.registrationId, { ...item })}
             >
               Edit
             </button>
             <button
               className="btn btn-success btn-sm"
               key={i}
               onClick={(e) => enableVehicle(item.registrationId)}
             >
               Enable
             </button>
           </div>
         );
       }
      mappedItem.PaymentServices = (
          <div className="d-flex gap-2 justify-content-evenly">
            {item.paymentTypes.map((item) => {
              return (
                <div className="border border-1 p-2 bg-light rounded-5 px-3 mt-1">
                  {item.paymentservice}
                </div>
              );
            })}
          </div>
        );
        mappedItem.PaymentSubServices = (
          <div className="d-flex gap-2 justify-content-evenly">
            {item.paymentSubTypes.map((item) => {
              return (
                <div className="border border-1 p-2 bg-light rounded-5 px-3 mt-1">
                  {item.type}
                </div>
              );
            })}
          </div>
        );

        return mappedItem;
      })
    : [];

  const OpenEdit = (id, data) => {
    setRegistrationId(id);
    setData(data);
    console.log(data);
    setOpenEdit(true);
  };
  const handleCloseModal = () => {
    setRegistrationId("");
    setData({});
    setOpenEdit(false);
  };

  return (
    <>
      <Row>
        <Col xs={12} lg={12} md={12}>
          <Card className="shadow border-0 mb-4">
            <Card.Body>
              {!openEdit ? (
                <div className="report-grid-view">
                  <div className="material-table-records mb-3">
                    <MDBDataTable
                      className="dataTable"
                      striped
                      small
                      bordered
                      hover
                      responsive
                      data={{
                        columns: columns,
                        rows: mappedData,
                      }}
                      searching={true}
                      entries={50}
                      exportToCSV
                      theadColor="#333"
                      paginationLabel={["Previous", "Next"]}
                      sortable={true}
                      footer="none"
                    />
                  </div>
                </div>
              ) : (
                <VehicleMasterEditForm
                  id={registrationId}
                  data={data}
                  isModalOpen={openEdit}
                  handleCloseModal={handleCloseModal}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SubmittedVehicleMasterForms;