import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { SWIPELINC_API } from '../../../utils/constant';
import swal from 'sweetalert';
import { Container, Row, Col, Card } from "react-bootstrap";
import "../../../stylesheet/ViewDetails.css";
import MyLoader
 from '../../../common/MyLoader';
function ViewVendor({setOpenView,id}) {

    const [vendorDetails, setvendorDetails] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [token , setToken] = useState (localStorage.getItem("token"));

    
 
  
    useEffect(() => {
      fetchViewVendorDetails();
    }, []);
  
    const fetchViewVendorDetails = async () => {
      try {
        setShowLoader(true)
        const response = await axios.get(
  
  `${SWIPELINC_API}MerchantAdminPanel/merchant/vendor/getVendorById/${id}`,
          {
            headers: {
              Authentication: `Bearer ${token}`, 
            },
          }
        );
  if (response.data.statusCode === 200) {
          setvendorDetails(response.data.data);
          setShowLoader(false)
        } else {
          console.error('Api response error:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching merchant details:', error);
      }
    };

    // HANDLE APPROVE:

    
    const handleApproveConfirm = (id) => {
    
      swal({
        title: "Are you sure?",
        text: "Do you want to approve this vendor?",
        icon: "warning",
        buttons: ["Cancel", "Yes, approve it!"],
        dangerMode: true,
      }).then((isConfirmed) => {
        if (isConfirmed) {
          
          const axiosInstance = axios.create({
            baseURL: `${SWIPELINC_API}MerchantAdminPanel/merchant/vendor`,
            headers: {
              Authentication: `Bearer ${token}`,
            },
          });
  
          const payload = {
            vendorStatus: "Approved",
            vendorId: id,
            remark: "NA",
          };
          setShowLoader(true)
          axiosInstance
            .post("/updateStatus", payload)
            .then((response) => {
              if(response.data.statusCode === 200){
                swal("Success", "Application approved!", "success") 
                  setOpenView(false);
                  
              }else{
                console.error("Error approving the application:", response.data.message);
                swal("Failed", "Something Went Wrong", "error") 
              }
              setShowLoader(false)
           
            })
            .catch((error) => {
              setShowLoader(false)
              console.error("Error approving the application:", error.message);
            });
        } else {
          
          // setOpen(false);
        }
      });
    };
    // HANDLE REJECT:
    const handleReject = () => {
      swal({
        title: "Are you sure?",
        text: "Do you want to reject this vendor?",
        icon: "warning",
        buttons: ["Cancel", "Yes, reject it!"],
        dangerMode: true,
        content: {
          element: "input",
          attributes: {
            type: "text",
            placeholder: "Enter rejection remark",
          },
        },
      }).then((remark) => {
        if (remark) {
        
          const axiosInstance = axios.create({
            baseURL: `${SWIPELINC_API}MerchantAdminPanel/merchant/vendor`,
            headers: {
              Authentication: `Bearer ${token}`,
            },
          });
  
          const payload = {
            vendorStatus: "Rejected",
            vendorId: id,
            remark: remark,
          };
          setShowLoader(true)
          axiosInstance
            .post("/updateStatus", payload)
            .then((response) => {
              if(response.data.statusCode=== 200){

                swal("Success", "Vendor rejected successfully!", "success") 
                console.log("Vendor rejected!");
                setOpenView(false)
               
              }else{
                swal("Failed", "Something Went Wrong", "error") 
              }
              setShowLoader(false)
            })
            .catch((error) => {
              setShowLoader(false)
              console.error("Error rejecting the application:", error.message);
            });
        } else {
          
         
        }
      });
    };
  

    const handleBack=()=>{
      setOpenView(false)
    }

    return (
    <>
    
    <section className="admin-view-details">
    {showLoader ? <MyLoader /> : ""}
				<Container>
					<Card className="shadow border-0 mb-4 ds-header-card">
						<Card.Body>
							<Row>
							
								<Col xs={12} xl={12} lg={12} sm={12}>
                <div className="two-column-layout">
                <div className="vertical-table">
                  <div className="vertical-table-row">
                      <div className="vertical-table-cell  text-left">Vendor ID</div>
                      <div className="vertical-table-cell-value  text-left">{vendorDetails.vendorId}</div>
                  </div>
                  <div className="vertical-table-row">
                      <div className="vertical-table-cell  text-left">Vendor Name</div>
                      <div className="vertical-table-cell-value  text-left">{vendorDetails.fullName}</div>
                  </div>
                  <div className="vertical-table-row">
                      <div className="vertical-table-cell  text-left">Merchant Name</div>
                      <div className="vertical-table-cell-value  text-left">{vendorDetails.companyName}</div>
                  </div>
                 
                  <div className="vertical-table-row">
                      <div className="vertical-table-cell  text-left">e-Mail</div>
                      <div className="vertical-table-cell-value  text-left">{vendorDetails.email}</div>
                  </div>
                  <div className="vertical-table-row">
                      <div className="vertical-table-cell  text-left">Category</div>
                      <div className="vertical-table-cell-value  text-left">{vendorDetails.category}</div>
                  </div>
                  <div className="vertical-table-row">
                      <div className="vertical-table-cell  text-left">Mobile Number</div>
                      <div className="vertical-table-cell-value  text-left">{vendorDetails.mobile}</div>
                  </div>
                 
                  
                  <div className="vertical-table-row">
                      <div className="vertical-table-cell  text-left">Bank Name</div>
                      <div className="vertical-table-cell-value  text-left">{vendorDetails.bankName}</div>
                  </div>
                  
                  <div className="vertical-table-row">
                      <div className="vertical-table-cell  text-left"> Account Number</div>
                      <div className="vertical-table-cell-value  text-left">{vendorDetails.accountNumber}</div>
                  </div>
                  <div className="vertical-table-row">
                      <div className="vertical-table-cell  text-left"> IFSC Code</div>
                      <div className="vertical-table-cell-value  text-left">{vendorDetails.ifscCode}</div>
                  </div>
                  <div className="vertical-table-row">
                      <div className="vertical-table-cell  text-left"> Branch Name</div>
                      <div className="vertical-table-cell-value  text-left">{vendorDetails.branchName}</div>
                  </div>
                  <div className="vertical-table-row">
                      <div className="vertical-table-cell  text-left"> UPI</div>
                      <div className="vertical-table-cell-value  text-left">{vendorDetails.upi}</div>
                  </div>
                  <div className="vertical-table-row">
                      <div className="vertical-table-cell  text-left"> Vendor Status</div>
                      <div className="vertical-table-cell-value  text-left">{vendorDetails.vendorStatus}</div>
                  </div>
                  <div className="vertical-table-row">
                      <div className="vertical-table-cell  text-left"> Company Name</div>
                      <div className="vertical-table-cell-value  text-left">{vendorDetails.companyName}</div>
                  </div>
                  <div className="vertical-table-row">
                      <div className="vertical-table-cell  text-left">Merchant ID</div>
                      <div className="vertical-table-cell-value  text-left">{vendorDetails.parentMid}</div>
                  </div>
                  <div className="vertical-table-row">
                      <div className="vertical-table-cell  text-left"> PAN Number</div>
                      <div className="vertical-table-cell-value  text-left">{vendorDetails.panNumber}</div>
                  </div>
                  <div className="vertical-table-row">
                      <div className="vertical-table-cell  text-left"> Aadhaar Number </div>
                      <div className="vertical-table-cell-value  text-left">{vendorDetails.aadhaarNo}</div>
                  </div>
                  <div className="vertical-table-row">
                      <div className="vertical-table-cell  text-left">Mode Of Approval </div>
                      <div className="vertical-table-cell-value  text-left">{vendorDetails.isvendorAutoApproved}</div>
                  </div>
                
                  <div className="vertical-table-row">
                  <div className="d-flex gap-2">
                 <button className="btn btn-success" onClick={() => handleApproveConfirm(id)}>Approve</button>
                 <button className="btn btn-danger" onClick={() => handleReject()}>Reject</button>
           </div>
            </div>
           
                <div className="button-container">
                <button
										className="btn btn-primary" onClick={handleBack}>
                    Go Back
                  </button>
                  </div>
                    </div>
                    </div>
                    </Col>
							</Row>
						</Card.Body>
					</Card>
				</Container>
        </section>
      </>
    );
  }
  

export default ViewVendor