import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { USE_WITHDRAW_MULTIPLE_FILTER_API as getApiData } from "../../utils/ApiServices";

const WithdrawAdvanceFilter = ({
  setWithdrawlReportData,
  setIsAdvanceFilterOpen,
  setShowLoader,
  mid,
}) => {
  const token = localStorage.getItem("token");
  const [searchOption, setSearchOption] = useState("transactionId");
  const [searchValue, setSearchValue] = useState("");
  const [show, setShow] = useState(true);

  const [searchValueError, setSearchValueError] = useState("");
  const [isSearchValueError, setIsSearchValueError] = useState(false);

  const handleModalClose = () => {
    setShow(false);
    setIsAdvanceFilterOpen(false);
    //  setSearch(!search)
  };

  const handleClick = (option) => {
    setSearchOption(option);
    setSearchValue("");
  };

  const submitHandler = () => {
    // setSearch(!search)

    getApiData(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      searchOption === "rrn" ? searchValue : "",
      "",
      mid,
      token,
      setShowLoader,
      setWithdrawlReportData
    );

    handleModalClose();
  };

  const handelSearchData = (e) => {
    setSearchValue(e.target.value);
    setIsSearchValueError(false);
    setSearchValueError("");
    document.getElementById("searchBtn").disabled = false;
  };

  //   const handelValidations = (e) => {
  //     if (searchOption === "txnId") {
  //       if (!isTransactionIdRegexIsValid(searchValue)) {
  //         setIsSearchValueError(true);
  //         setSearchValueError("Enter Valid Transaction Id.");
  //         document.getElementById("searchBtn").disabled = true;
  //       }
  //       return;
  //     }

  //     // if (searchOption === "orderNo") {
  //     //   // if (!isOrderNumberRegexIsValid(searchValue)) {
  //     //     setIsSearchValueError(true);
  //     //     setSearchValueError("Enter Valid Order Number.");
  //     //     document.getElementById("searchBtn").disabled = true;
  //     //   // }
  //     //   // return;
  //     // }
  //   };

  useEffect(() => {
    handleClick("transactionId");
  }, []);

  return (
    <div className="advanced-search-popup">
      {/* <div className="topside">

        <div className="filter-list">
          <li>Advanced Filter</li>
        </div>
      </div>

      {searchOption !== "dateFilter" && (
        <>
          <select
            className="advanced-select-section form-control"
            value={searchOption}
            onChange={(e) => setSearchOption(e.target.value)}
          >
            <option value="transactionId">Transaction ID</option>
            <option value="orderNumber">Order Number</option>
            <option value="email">e-Mail</option>
            <option value="mobile">Mobile</option>
          </select>

          <input
            className="advanced-input-box form-control"
            type="text"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === " ") {
                e.preventDefault();
              }
            }}
            placeholder={
              searchOption === "transactionId"
                ? "Enter transaction ID"
                : searchOption === "orderNumber"
                  ? "Enter Order Number"
                  : searchOption === "email"
                    ? "Enter e-Mail"
                    : "Enter Mobile"
            }
          />

          <button className="search-button-reset" onClick={handleButtonReset}>
            Reset
          </button>
        </>
      )}
      {apiResponse && apiResponse.length === 0 && (
        <div className="no-data-message">No data available.</div>
      )} */}

      <Modal show={show} centered>
        <Modal.Header className="bg-dark text-white">
          <Modal.Title>Withdraw Advanced Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="filter-field-area">
            <div className="form-group mb-3">
              <select
                className="form-control"
                value={searchOption}
                onChange={(e) => {
                  setSearchOption(e.target.value);
                  setSearchValue("");
                  setIsSearchValueError(false);
                  setSearchValueError("");
                }}
              >
                <option type="number" value="">
                  Select
                </option>
                <option type="number" value="rrn">
                  UTR Number
                </option>
                 
              </select>
            </div>
            <div className="form-group mb-2">
              <input
                className="advanced-input-box form-control"
                
                value={searchValue}
                onChange={handelSearchData}
                // onBlur={handelValidations}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                  }
                }}
                placeholder="Enter UTR Number"
              />
              {isSearchValueError ? (
                <span id="searchValErr" className=" text-danger">
                  {`${searchValueError}`}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            size="sm"
            className="text-capitlize"
            onClick={handleModalClose}
          >
            Close
          </Button>
          <Button
            id="searchBtn"
            variant="outline-primary"
            size="sm"
            className="text-capitlize"
            onClick={submitHandler}
          >
            <i class="bi bi-search mx-1"></i> Search
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default WithdrawAdvanceFilter;
