import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState } from "react";
import { fetchSubmittedVehicleSubType } from "../utils/ApiServices";
import swal from "sweetalert";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { SWIPELINC_API } from "../utils/constant";
import axios from "axios";

function SubmittedVehicleSubType({ setShowLoader }) {
    const [applications, setApplications] = useState([]);
    const [callAgain, setCallAgain] = useState(false);
    const [formDataInputs, setFormDataInputs] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const [updateId, setUpdateId] = useState("");
   const [token, setToken] = useState(localStorage.getItem("token"));
   
  

  
    const columns = [
      { label: "Payment SubType Id", field: "paymentSubTypeId", width: "auto" },
      { label: "Payment Type", field: "paymentService", width: "auto" },
      { label: "Vehicle Sub-Type", field: "type", width: "auto" },
      { label: "Status", field: "active", width: "auto" },
      { label: "Action", field: "action", width: "200px" },
     
       ];
  
   const FetchData = async (token) => {
      setShowLoader(true);
      try {
        const apiResponse = await fetchSubmittedVehicleSubType(token);

        if (apiResponse.statusCode === 200) {
          setApplications(apiResponse?.data);
          console.log(apiResponse.data);

          
        } else {
          setApplications([])
        }
        setShowLoader(false);
      } catch (error) {
        // console.error(error);
        console.log(error);
        setShowLoader(false);
      }
    };
   
    const mappedData = applications.length ? applications?.map((item) => {
          const mappedItem = { ...item };

          mappedItem.active = item.active ?"Active" : "Not Active";

        if (mappedItem.active === "Active"){
           mappedItem.action = (
              <div className="d-flex gap-2 justify-content-evenly">
            
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => DisableVehicleSubType(item.paymentSubTypeId)}
                >
                  Disable
                </button>
                <button
            className="btn btn-warning btn-sm"
            onClick={() => openDetails({ ...item })}
             >
             Edit
             </button>
                </div>
           )}
              
         else{
          mappedItem.action=(
            <div className="d-flex gap-2 justify-content-evenly">
       
          <button
              className="btn btn-success btn-sm"
              onClick={() => EnableVehicleSubType(item.paymentSubTypeId)}
            >
              Enable
            </button>
            <button
          className="btn btn-warning btn-sm"
          onClick={() => openDetails({ ...item })}
           >
           Edit
           </button>
            
      </div>
          )}
          
      return mappedItem;
        }) 
        : [];
        const openDetails = ({ type, paymentSubTypeId,paymentTypeId }) => {
          setFormDataInputs({
            ...formDataInputs,
            type, 
            paymentTypeId
          });
          setUpdateId(paymentSubTypeId);
          setIsModalOpen(true);
        };
        const handleCloseModal = () => {
          setIsModalOpen(false);
        };

        const EnableVehicleSubType = (paymentSubTypeId) => {
          swal({
            title: "Alert",
            text: "Are you sure, do you want to enable?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDisable) => {
            if(willDisable) {
              setShowLoader(true);
          axios
            .put(
              `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleSubType/enable/${paymentSubTypeId}?id=${paymentSubTypeId}`,
              "",
              {
                headers: {
                  Authentication: `Bearer ${token}`,
                },
              }
            )
            .then((response) => {
              console.log(response);
      
              if (response.data.statusCode === 200) {
                swal({
                  title: "Success!",
                  text: "Vehicle sub-type enabled successfully",
                  icon: "success",
                });
               
                FetchData(token);
              } else {
                swal({
                  title: "Alert!",
                  text: response.data.message,
                  icon: "error",
                });
              }
              setCallAgain(!callAgain)
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
              setShowLoader(false);
            });
            }
          })
        };
      
        const DisableVehicleSubType = (paymentSubTypeId) => {
          swal({
            title: "Alert",
            text: "Are you sure, do you want to disable?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDisable) => {
            if(willDisable) {
              setShowLoader(true);
          axios
            .put(
              `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleSubType/disable/${paymentSubTypeId}`,
              "",
              {
                headers: {
                  Authentication: `Bearer ${token}`,
                },
              }
            )
            .then((response) => {
              console.log(response);
      
              if (response.data.statusCode === 200) {
                swal({
                  title: "Success!",
                  text: "Vehicle sub-type disabled successfully",
                  icon: "success",
                });
              
                FetchData(token);
              } else {
                swal({
                  title: "Alert!",
                  text: response.data.message,
                  icon: "error",
                });
              }
              setCallAgain(!callAgain)
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
              setShowLoader(false);
            });
            }
          })
        };
        function stopSpecialCharacterInput(input) {
          // Define a regular expression for alphanumeric characters
          const alphanumericRegex = /^[a-zA-Z0-9]+$/;
      
          // Test the input against the regular expression
          return alphanumericRegex.test(input);
        }
        const handelDataChange = (e) => {
          setFormDataInputs({
            ...formDataInputs,
            [e.target.name]: e.target.value,
          });
        
          if (
            e.target.name === "type"||
            e.target.name === "paymentTypeId"
          )
          {
            if (stopSpecialCharacterInput(e.target.value)) {
              console.log(stopSpecialCharacterInput(""));
              setFormDataInputs({
                ...formDataInputs,
                [e.target.name]: e.target.value,
              });
            } else {
              return;
            }
          }
          else {
            setFormDataInputs({
              ...formDataInputs,
              [e.target.name]: e.target.value === "" ? "" : e.target.value,
            });
          }
        }
      
        const handleUpdateSubmit = (e) => {

          console.log("edit form data", formDataInputs)
          e.preventDefault();
          const payload = {
            paymentTypeId:formDataInputs.paymentTypeId,
            type: formDataInputs.type,
          };
          if (formDataInputs.type === "") {
            swal({
              title: "Alert!",
              text: "Please Enter Vehicle Sub-Type",
              icon: "error",
            });
            return
          }
      
         
         setShowLoader(true);
          axios
            .put(
              `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleSubType/update/${updateId}`,
              payload,
              {
                headers: {
                  Authentication: `Bearer ${token}`,
                },
              }
            )
            .then((response) => {
              console.log(response);
      
              if (response.data.statusCode === 200) {
                swal({
                  title: "Success!",
                  text: response.data.message,
                  icon: "success",
                });
                setFormDataInputs({
                
                  id: "",
                });
                handleCloseModal();
                setCallAgain(!callAgain)
              } else {
                swal({
                  title: "Alert!",
                  text: response.data.message,
                  icon: "error",
                });
              }
              setShowLoader(false);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
              setShowLoader(false);
            });
        };
      
    useEffect(() => {
      FetchData(token);
    }, [token, callAgain]);
  
    return (
      <>
         <Row>
          <Col xs={12} lg={12} md={12}>
            <Card className="shadow border-0 mb-4">
              <Card.Body>
                <div className="report-grid-view">
                  <div className="material-table-records mb-3">
          <MDBDataTable
            className="dataTable"
            striped
            small
            bordered
            hover
            responsive
            data={{
              columns: columns,
              rows: mappedData,
            }}
            searching={true}
            entries={50}
            exportToCSV
            theadColor="#333"
            paginationLabel={["Previous", "Next"]}
            sortable={true}
            footer="none"
          />
        </div>
        <Modal show={isModalOpen} onHide={handleCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Edit Vehicle Sub-Type:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="shadow border-0 mb-4">
            <Card.Body>
              <div className="merchant-report-view">
                <div className="report-table-view">
                  <div className="containerflag ">
                    
                    <Row className="mt-2">
                      <Col xs={12} lg={4} md={6} className="mt-3">
                        <div className="form-group">
                          <label className="form-label">
                            Vehicle Sub-Type
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              className="form-control"
                              name="type"
                              value={formDataInputs.type}
                              onChange={handelDataChange}
                            />
                          </div>
                        </div>
                      
                      </Col>

                     
                   </Row>
                    <div  >
                      <div className="d-flex justify-content-center mt-3">
                        <Button id="UpdateFormBtn" onClick={handleUpdateSubmit}>Update</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
      </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
       
      </>
    );
  };

export default SubmittedVehicleSubType;