import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import FlagEditForm from "./FlagEditForm";
import { SWIPELINC_API } from "../../utils/constant";
import Modal from 'react-bootstrap/Modal';
import swal from "sweetalert";
import { BiEdit } from "react-icons/bi";

function FlagManagement({setShowLoader}) {
  const [merchants, setMerchants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isEditing, setIsEditing] = useState(false);
  const [selectedMerchantMid, setSelectedMerchantMid] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);


  
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsEditing(false);
    setSelectedMerchantMid("");
  };
  const onCloseModal = () => {
    setIsModalOpen(false);
    setIsEditing(false);
    setSelectedMerchantMid("");
  };

  useEffect(() => {
    setShowLoader(true)
    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/getallmerchantsFlags`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          setMerchants(response.data.data);
          
        } else if (response.data.statusCode === 304) {
          setMerchants(response.data.data);
         
        }
        setShowLoader(false)
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setShowLoader(false)
        
      });
  }, [isEditing]);

  const openDetails = (mid) => {
    const selectedMerchant = merchants.find((merchant) => merchant.mid === mid);
  
    if (selectedMerchant && selectedMerchant.isServiceChargeFound === true) {
      setSelectedMerchantMid(mid);
      setIsEditing(true);
      setIsModalOpen(true); 
    } else {
     
      swal({
        icon: 'error',
        title: 'Service Charge Not Active',
        text: 'You are not able to update this merchant as the service charge is not active.',
      });
    }
  };
  
  const columns = [
    { label: "Company Name", field: "companyName", width: "auto" },
    { label: "Merchant ID", field: "mid", width: "auto" },
    { label: "Withdrawl(A/M)", field: "isWithdrawlAuto", width: "auto" },
    { label: "Edit Profile(E/D)", field: "isProfileEdit", width: "auto" },
    { label: "Payout(A/M)", field: "isPayoutAutoApprovel", width: "auto" },
    { label: "Vendor(A/M)", field: "isVendorAutoApproved", width: "auto" },
    { label: "Hold(A/M)", field: "isAmountHold", width: "auto" },
    { label: "Login(E/D)", field: "isActive", width: "auto" },
    { label: "Transaction (E/D)", field: "isTransactionAllow", width: "auto" },
    { label: "Action", field: "action", width: "auto" },
  ];

  const mappedData = merchants?.map((item) => {
    const mappedItem = { ...item };
   

  
    mappedItem.isVendorAutoApproved =
      item.isVendorAutoApproved === true
        ? "Auto"
        : item.isVendorAutoApproved === false
        ? "Manual"
        : "-";
   
    mappedItem.isWithdrawlAuto =
      item.isWithdrawlAuto === true
        ? "Auto"
        : item.isWithdrawlAuto === false
        ? "Manual"
        : "-";
    mappedItem.isProfileEdit =
      item.isProfileEdit === true
        ? "Enable"
        : item.isProfileEdit === false
        ? "Disable"
        : "-";
    mappedItem.isPayoutAutoApprovel =
      item.isPayoutAutoApprovel === true
        ? "Auto"
        : item.isPayoutAutoApprovel === false
        ? "Manual"
        : "-";
    mappedItem.isAmountHold =
      item.isAmountHold === true
        ? "Auto"
        : item.isAmountHold === false
        ? "Manual"
        : "-";
    mappedItem.isActive =
      item.isActive === true
        ? "Enable"
        : item.isActive === false
        ? "Disable"
        : "-";
    mappedItem.isTransactionAllow =
      item.isTransactionAllow === true
        ? "Enable"
        : item.isTransactionAllow === false
        ? "Disable"
        : "-";
    mappedItem.action = (
      <button
        className="btn btn-warning"
        onClick={() => openDetails(item.mid)}
      >
        <BiEdit />
      </button>
    );
    return mappedItem;
  });

  return (
    <>
      <div className="flag-management-container">
       <div className="reportSection">
          <div className="container">
            <div className="tabs-content active" id="tab1">
              <div className="card py-3">
                <div className="transaction-report">
                  <MDBDataTable
                    className="dataTable overflow-x-scroll"
                    striped
                    small
                    bordered
                    hover
                    responsive
                    data={{
                      columns: columns,
                      rows: mappedData,
                    }}
                    searching={true}
                    entries={50}
                    paginationLabel={["Previous","Next"]}
                    sortable={true}
                    footer="none"
                  />
                </div>
          <Modal show={isModalOpen} onHide={handleCloseModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Flag Modification:</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FlagEditForm
              mid={selectedMerchantMid}
              showModal={isModalOpen}
              closeModal={handleCloseModal}
              data={merchants}
              onCloseModal={onCloseModal}
            />
          </Modal.Body>
        </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FlagManagement;