import React, { useEffect, useState } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";
// import { useParams } from "react-router-dom"; 
import { SWIPELINC_API } from "../utils/constant"; 

const ViewPending = ({setShowLoader}) => {
  const [applications, setApplications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    // Create Axios instance with the default header
    const axiosInstance = axios.create({
      baseURL: `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard`,
      headers: {
        Authentication: `Bearer ${token}`,
      },
    });
    
    setShowLoader(true)
    axiosInstance
      .get("/getallmerchant/Pending",{
        params:{
          applicationStatus:"Pending"
        }
      })
      .then((response) => {
        if (response.data.statusCode===200){
          console.log("Applications:",response.data.data);
          setApplications(response.data.data);
        }
        else if (response.data.statusCode===304){
          console.log("NO APPLICATIONS FOUND:", response.data.data);
          setApplications(response.data.data);
        }
        setShowLoader(false)
      })
      .catch((error) => {
        console.error("Error fetching applications:", error.message);
        setShowLoader(false)
      });
  }, []);

  
  const handleViewDetails = (registrationId) => {
   
    navigate(`/ViewFile1/${registrationId}`);
  };

  return (
    <>
      {/* <Header /> */}
      <div className="report-grid-view">
      <h2>Pending Applications</h2>
      <div className="table-responsive">
      <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                    <th>Company Name</th>
                    <th>Authorized Person Name</th>
                    <th>e-Mail ID</th>
                    <th>Mobile Number</th>
                    <th>Company PAN</th>
                    <th>GST Number</th>
                    <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {applications?(applications.map((application) => (
                      <tr key={application.registrationId}>
                         <td>{application.companyName}</td>
                        <td>{application.authorizedPersonName}</td>
                        <td>{application.email}</td>
                        <td>{application.mobile}</td>
                       <td>{application.companyPanNumber}</td>
                        <td>{application.gstNumber}</td>
                      <td>
                          <button className="btn btn-primary btn-sm"
                            type="button"
                            onClick={() => handleViewDetails(application.registrationId)}
                          >
                            View Details
                          </button>
                        </td>
                      </tr>
                    ))
                  ):(
                    <tr>
                      <td colspan = "8">NO DATA FOUND</td>
                    </tr>
                  )}
                  </tbody>
                 </table>
      </div>
      </div>
      
    </>
  );
};
export default ViewPending;