import React,{useState,useEffect} from 'react'
import axios from "axios";
import "../../../assets/css/style.css";
import { MDBDataTable } from 'mdbreact';
import { useNavigate } from 'react-router-dom';
import { SWIPELINC_API } from '../../../utils/constant';
import ViewVendor1 from './ViewVendor1';
import { Container, Card, Row, Col } from "react-bootstrap";

function RejectedVendor() {
  const [rejectedVendor, setrejectedVendor] = useState([]);
  const [id, setId] = useState('');
  const [open, setOpen] = useState(false);
  const [token , setToken] =  useState(localStorage.getItem("token"));

  const navigate = useNavigate();
  
    useEffect(() => {
   // Create Axios instance with the default header
      const axiosInstance = axios.create({
        baseURL: `${SWIPELINC_API}MerchantAdminPanel/merchant/vendor`,

        headers: {
          Authentication: `Bearer ${token}`,
        },
      });
      
      // Fetch applications using the Axios instance
      axiosInstance
        .get("/getAllVendors/Rejected",{
          params:{
            vendorStatus:"Rejected"
          }
        })
        .then((response) => {
          if (response.data.statusCode===200 ){
            setrejectedVendor(response.data.data)
          }
          if (response.data.statusCode===304){
            
            return
          }
          setrejectedVendor(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching vendor:", error.message);
        });
    }, []);

    const ViewVendor= (vendorId) => {
      setOpen(true)
      setId(vendorId)
    }; 
   
    const columns= [

      // {label: "ID",field: "vendorId",width:"auto",},
      {label: "Vendor Name",field: "fullName",width:"auto"},
      {label: "Merchant Name",field: "companyName",width:"auto"},
      {label: "e-Mail",field: "email",width:"auto",},
      // {label: "Category",field: "category",width:"auto",},
      {label: "Mobile",field: "mobile",width:"auto",},
      {label: "Company Name",field: "companyName",width:"auto"},
      {label: "Merchant ID",field: "parentMid", width:"auto"},
      { label: 'Action', field: 'action', width: '150px' },
     
    ]
   

    const mappedData = rejectedVendor && rejectedVendor.length > 0
    ? rejectedVendor.map((item) => {
  const mappedItem = {...item};
    mappedItem.action=(
      <button
    className="btn btn-primary"
      onClick={() => ViewVendor(item.vendorId)}
    >
      View Details
    </button>
  
  );
  return mappedItem;
  }):[];
    return (
      <>
       <Container>
       <Row>
      <Col xs={12} lg={12} md={12}>
      <Card className="shadow border-0 mb-4">
        <Card.Body>
        <div className="report-grid-view">
      <div className="material-table-records mb-3">
               
           {!open?(
                   <MDBDataTable
                   className='dataTable'
                  striped
                  small
                  bordered
                  hover
                  responsive
                  data={{
                    columns: columns,
                    rows: mappedData,
                  }}
                  searching={true}
                  entries={50}
                  exportToCSV
                  theadColor='#333'
                  paginationLabel={['Previous', 'Next']}
                  sortable={true}
                  footer="none"
                  
                />
                ):( <ViewVendor1  id={id}  setOpen={setOpen}/> )}
                </div>
             </div>
             </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container> 
    </>
  );
};
  

export default RejectedVendor
