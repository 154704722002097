export const headerLabelsForPayin = [
  "ID",
  "Transaction Id",
  "Amount",
  "Merchant ID",
  "Currency",
  "e-Mail ID",
  "Order Number",
  "Payment Type",
  "Transaction Date",
  "Transaction Time",
  "Transaction Response Code",
  "Response Date",
  "Response Time",
  "Transaction Status",
  "S2S Count",
  "S2S Date",
  "S2S Status",
  "UTR Number",
  "Mobile Number",
  "Processor",
  "Processor Transaction ID",
  "User VPA",
  "Customer IFSC Code",
  "Callback Response JSON",
  "Terminal ID",
  "Customer IP",
  "Customer Location",
  "Remark",
  "Bank Location",
  "Company Name",
  "SID",
  "Evok Transaction Code",
];

export const headerLabelsForSettlement = [
  "Merchant ID",
  "Net Amount",
  "Order Number",
  "Transaction ID",
  "VPA",
  "GST Rate",
  "Service Charge",
  "Service Charge Amount",
  "GST Amount",
  "Remark",
  "Payin Amount",
  "Transaction Status",
  "Response Code",
  "Collection Method",
  "Terminal ID",
  "Settlment Date",
  "Settlment Time",
];

export const headerLabelsForReconciliation = [
  "Date",
  "Transaction Id",
  "RRN",
  "Switch Rrn",
  "Amount",
  "Transaction Status",
  "Payer VPA",
  "Merchant ID",
  "Payin Status",
  "Remark",
  "Company Name",
  "Balance",
 ];

export const headerLabelsForChargeback = [
  "id",
  "UTR Number",
  "Charge Back Adjusment Date",
  "UPI Transaction ID",
  "Charge Back Amount",
  "Compensation Penalty",
  "Transaction Date",
  "Transaction Amount",
  "Payer VPA",
  "Payee Bank",
  "Transaction UID",
  "Mobile Number",
  "e-Mail ID",
  "IFSC Code",
  "Bank Location",
  "Create Date",
  "Create Time",
  "Payee IP Address",
  "Payee Location",
  "Merchant ID",
];

export const headerLabelsForPayout = [
  "ID",
  "Full Name",
  "e-Mail",
  "Category",
  "Mobile",
  "Bank Name",
  "Account Number",
  "IFSC Code",
  "Branch Name",
  "UPI",
  "Amount",
  "Merchant ID",
  "Remark",
  "Payout Status",
  "Company Name",
  "Transfer Type",
  "UTR Number",
  "Create Date",
  "Create Time",
  "Request ID",
  "Transaction Status",
  "Transation ID",
  "Payout Auto Approvel",
];

export const headerLabelsForWithdraw = [
  "Merchant ID",
  "Withdrawal ID",
  "Amount",
  "Transfer Type",
  "Virtual Account Type",
  "Status",
  "Full Name",
  "e-Mail ID",
  "UTR Number",
  "Transaction Date",
  "is Withdrawal Manual",
  "Transfer Mode",
  "is Deposite For Payout",
  "Virtual Account Number",
  "Virtual IFSC",
  "Bank Name",
  "Create Time",
  "Create Date",
];

export const headerLabelsForCredit = [
  "Payout Request ID",
  "Reference ID",
  "Deducted Amount",
  "Orignal Amount",
  "Remaning Amount",
  "Full Name",
  "Merchant ID",
  "Decentro Transaction ID",
  "Transaction Type",
  "Status",
  "Create Date",
  "Create Time",
  "Charge In Percentage",
];

export const headerLabelsForSettlementAdjustment = [
  "Merchant ID",
  "Net Amount",
  "Order Number",
  "Transaction ID",
  "VPA",
  " GST",
  "Service Charge",
  "Service Charge Amount",
  "GST Amount",
  "Remark",
  "Payin Amount",
  "Transaction Status",
  "Response Code",
  "Collection Method",
  "Terminal ID",
  "Settlment Date",
  "Settlment Time",
];

export const headerLabelsForVendor = [
  "Merchant ID",
  "Net Amount",
  "Order Number",
  "Transaction ID",
  "VPA",
  " GST",
  "Service Charge",
  "Service Charge Amount",
  "GST Amount",
  "Remark",
  "Payin Amount",
  "Transaction Status",
  "Response Code",
  "Collection Method",
  "Terminal ID",
  "Settlment Date",
  "Settlment Time",
];

export const headerLabelsForGaurantee = [
  "Gaurantee Amount",
  "Net Amount",
  "Transaction Type",
  "Transaction Date",
  "UTR Number",
  "CreateDate",
  "Create Time",
];

export const headerLabelsForRelease = [
  "Merchant ID",
  "Amount",
  "Release From",
  "Create Time",
  "Create Date",
];

export const headerLabelsForAllMerchant = [
  "Merchant ID",
  "Name",
  "Total Transaction Amount",
  "e-Mail ID",
  "Mobile",
  "Charge Back Amount",
  "Settlement Amount",
  "Payout Amount",
  "Hold Amount",
  "Security Deposit",
  "Service Charge",
  "Remaning Amount",
  "Withdrawal Amount",
  "Guarantee Amount",
  "Balance Payout UPI",
  "Balance Payout IMPS",
  "Total Vendors",
  "Credit Balance",
  "Sum Of Settlement Adjustment Amount",
  "Release Amount",
];

export const headerLabelsForDailyTransaction = [
  "Transaction ID",
  "Amount",
  "Merchant ID",
  "Currency",
  "e-Mail ID",
  "Order Number",
  "Payment Type",
  "Transaction Date",
  "Transaction Time",
  "Transaction Response Code",

  "Response Date",
  "Response Time",
  "Transaction Status",

  "UTR Number",
  "Mobile Number",

  "User VPA",
  "Customer IFSC",

  "Customer IP",
  "Customr Location",
  "Remark",
  "Actual Amount",
  "Bank Location",
  " SID  ",
  "Company Name",
  "Evok Transaction Code",
];
