import axios from 'axios';
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import swal from 'sweetalert';
import { SWIPELINC_API } from '../../../utils/constant';
import { DateTime } from 'luxon';
import MyLoader from '../../../common/MyLoader';

const MyModal = ({ id, showModal, setShowModal, type }) => {

  const [data, setData] = useState('');
  const [date, setDate] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [remark, setRemark] = useState('');

  const token = localStorage.getItem("token")


  const handleDateInputChange = (event) => {
    const newDate = event.target.value;
    const inputDateTime = DateTime.fromISO(newDate, { zone: "UTC" });


    if (!inputDateTime.isValid) {
      swal({
        title: "Invalid Date",
        text: "Please enter a valid date.",
        icon: "warning",
      });
      return;
    }


    const today = DateTime.now().startOf("day");
    console.log(today, inputDateTime)

    if (inputDateTime.toISODate() > today.toISODate()) {
      swal({
        title: "Invalid Date",
        text: "Please select a date that is not in the future.",
        icon: "warning",
      });
    } else {

      setDate(inputDateTime.toISODate());
    }
  };

  const RETURNPAYLOAD = (type) => {
    if (!type) {
      return {
        transactionDate: date,
        utr: data,
        status: "success",
        withdrawalId: id,
        remark: '',
      };

    } else if (type) {
      return {
        transactionDate: '',
        utr: '',
        status: "reject",
        withdrawalId: id,
        remark: remark,


      }
    }
  }
  const handleSubmit = () => {
    if (!type) {
      if (isSubmitting || data.length === 0 || date.length === 0) {
        swal({
          title: "Incomplete Information",
          text: "Please fill in all the required fields.",
          icon: "warning",
        });
        return;
      }

    } else { }



    const requestData = RETURNPAYLOAD(type)


    setIsSubmitting(true);

    try {
      setShowLoader(true)
      axios
        .put(
          `${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal/updateWithdrawal`,
          requestData,
          {
            headers: {
              Authentication: `Bearer ${token}`,
            },
          }
        ).then((response) => {
          if (response.data.statusCode === 200) {

            swal({
              title: "Saved",
              text: "Data Saved Successfully.",
              icon: "success",

            })
            setDate('')
            setData('')
            setIsSubmitting(false)
            setShowModal(false)
          }
          setShowLoader(false)
        })

    } catch (error) {
      setShowLoader(false)
      console.log(error);
      setData('')


      setIsSubmitting(false);
    }
  }



  return (<>
    {showLoader ? <MyLoader /> : ""}
    <Modal show={showModal} size='lg'>

      <Modal.Header closeButton>
        <Modal.Title>Enter Details</Modal.Title>

      </Modal.Header>
      <Modal.Body  >
        <Form className='w-100'>

          <Form.Group>
            {!type ? (<div>
              <label>Reference Number *</label>
              <Form.Control
                type="text"
                placeholder="Enter Reference Number"
                value={data}
                onChange={(e) => setData(e.target.value)}
              />
              <label className='mt-3'>Transaction Date *</label>
              <Form.Control
                type="date"
                placeholder="Enter Transaction Date"
                value={date}
                onChange={handleDateInputChange}
              />
            </div>) : null}


            {type ? (
              <div>
                <label className='mt-3'>Rejection Remark *</label>
                <Form.Control
                  type="text"
                  placeholder="Enter rejection remark"
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                />
              </div>
            ) : null}
          </Form.Group>
        </Form>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => handleSubmit()}>
          Submit
        </Button>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>

  </>);
};

export default MyModal;