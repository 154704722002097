import React, { useState } from 'react';
import { Card,Col,Row } from 'react-bootstrap';
import axios from 'axios';
import swal from 'sweetalert';
import { SWIPELINC_API } from '../utils/constant';

const VehicleSubTypeForm = ({ setShowLoader }) => {
  const [data, setData] = useState({
    type: "",
    paymentTypeId:""
    
  });
  const [paymentServices, setPaymentServices] = useState([]);
  const [selectedPaymentService, setSelectedPaymentService] = useState('');

  const [token, setToken] = useState(localStorage.getItem("token"));

  const fetchPaymentServices = () => {
    setShowLoader(true);
    axios
      .get(`${SWIPELINC_API}MerchantAdminPanel/merchant/paymentType/getAllAtiveAndDisable`, {
        headers: {
          Authentication: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setShowLoader(false);
        if (response.data.statusCode === 200) {
          setPaymentServices(response.data.data);
        } else {
          swal({
            title: 'Error!',
            text: 'Failed to fetch payment services',
            icon: 'error',
          });
        }
      })
      .catch((error) => {
        setShowLoader(false);
        console.error('Error fetching payment services:', error);
        swal({
          title: 'Error!',
          text: 'Failed to fetch payment services',
          icon: 'error',
        });
      });
  };

  const handlePaymentServiceChange = (e) => {
    setSelectedPaymentService(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedPaymentService === '') {
      swal({
        title: 'Alert!',
        text: 'Please select Payment Service',
        icon: 'error',
      });
      return;
    }

    if (data.type === '') {
      swal({
        title: 'Alert!',
        text: 'Please Enter Sub Type',
        icon: 'error',
      });
      return;
    }
    
    const selectedPayment = paymentServices.find(
      (service) => service.paymentService === selectedPaymentService
    );

  if (!selectedPayment) {
    swal({
      title: 'Alert!',
      text: 'Invalid Payment Service',
      icon: 'error',
    });
    return;
  }

    setShowLoader(true);

     axios
    .post(
      `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleSubType/save`,
      {
        type: data.type,
        paymentTypeId: selectedPayment.paymentTypeId, 
      },
      {
        headers: {
          Authentication: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      console.log(response);

      if (response.data.statusCode === 200) {
        swal({
          title: 'Success!',
          text: response.data.message,
          icon: 'success',
        });
        setData({ type: '' });
        setSelectedPaymentService('');
      } 
      else if(response.statusCode=== 309) {
        swal({
          title: "Alert",
          text: "Service already exists",
          icon: "alert"
        })
      }else {
        swal({
          title: 'Alert!',
          text: response.data.message,
          icon: 'error',
        });
      }
      setShowLoader(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setShowLoader(false);
      swal({
        title: 'Error!',
        text: 'Failed to save vehicle subtype',
        icon: 'error',
      });
    });
};
  const handleTypeChange = (e) => {
    setData({ ...data, type: e.target.value });
  };

  return (
    <section className="lincpay-dashboard-sec merchant-admin-info-form company-add-form">
        {/* <Container> */}
          <div className="cart-view-sec mt-4">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow-cst border-0 mb-3 chart-boxes merchant-info-form-sec">
                  <Card.Body>
                    <div className="merchant-form-info">
                      <form
                        className="form-section-form-group"
                       
                      >
                         <Row className="d-flex flex-column gap-4 py-2">
                          <Col xs={12} lg={6} md={6}>
                                    <label htmlFor="email">Select Vehicle Type</label>   
                                    <select
                          id="paymentServiceDropdown"
                          className="form-control"
                          onClick={fetchPaymentServices}
                          onChange={handlePaymentServiceChange}
                          value={selectedPaymentService}
                                        >
                            <option value="">Select</option>
                          {paymentServices.map((service) => (
                            <option key={service.paymentTypeId} value={service.paymentService}>
                              {service.paymentService}
                            </option>
                          ))}
                        </select>
                                    </Col>
                                    </Row>


                        <Row className="d-flex flex-column gap-4">
                          <Col xs={12} lg={6} md={6}>
                            <div className="form-group">
                          <label htmlFor="type">Enter Type</label>
                          <input
                            type="text"
                            className="form-control"
                            id="type"
                            name="type"
                            placeholder="Enter Type"
                            value={data.type}
                            onChange={handleTypeChange}
                          />
                        </div>
                    
                    </Col>
                  </Row>
                  </form>
                  <Col xs={12} lg={6} md={6}>
                    <div className="d-flex justify-content-end">
                    <div className="form-group  tool-bar-btn mb-0 mt-3">
                    <button className="submit-button submit_link_info btn btn-primary"
                     onClick={handleSubmit}
                      id="submitDetailsBtn"
                      >
                      Submit
                      </button>
                      </div>
                      </div>
                      </Col>
                      </div>
                  </Card.Body>
                  </Card>
                  </Col>
                  </Row>
                  </div>
                  </section>
  );
};
export default VehicleSubTypeForm;