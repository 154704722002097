import React, { useEffect, useState } from "react";
import { Navigate, Route, useLocation, useNavigate } from "react-router-dom";
import LoginForm from "./Login";
import Dashboard from "./Dashboard";
import Reports from "./reports/Reports";
import PayInReport from "./reports/PayInReport";
import { Routes } from "react-router-dom";
import InactiveServices from "./components/InactiveServices";
import ChargebackUpload from "./reports/ChargebackUpload";
// import SettlementUpload from "./reports/SettlementUpload";
import GetallChargebackFile from "./reports/GetallChargebackFile";
import GetallSettlementFile from "./reports/GetallSettlementFile";
import PayoutReport from "./reports/PayoutReport";
import ViewFile from "./applications/ViewFile";
import ViewSubmittedApplications from "./applications/SubmittedApplications";
import ViewApprove from "./applications/ViewApprove";
import ViewRejected from "./applications/ViewRejected";
import ViewPending from "./applications/ViewPending";
import Applications from "./applications/Applications";
import ViewFile1 from "./applications/ViewFile1";
import Stage2Applications from "./applicationsstage2/Stage2Applications";
import MoaDetails from "./applicationsstage2/MoaDetails";
import Stage2Approved from "./applicationsstage2/Stage2Approved";
import Stage2Rejected from "./applicationsstage2/Stage2Rejected";
import Stage2Pending from "./applicationsstage2/Stage2Pending";
import MoaDetails1 from "./applicationsstage2/MoaDetails1";
import MerchantDetails from "./Merchant/MerchantDetails";
import TransactionReport from "./Merchant/TransactionReport";
import OpenDetails from "./Merchant/OpenDetails";
import PayoutRequest from "./components/Tools/ALL PAYOUT REPORTS/PayoutRequest";
import AllPayoutReport from "./components/Tools/ALL PAYOUT REPORTS/AllPayoutReport";
import ApprovedPayout from "./components/Tools/ALL PAYOUT REPORTS/ApprovedPayout";
import RejectedPayout from "./components/Tools/ALL PAYOUT REPORTS/RejectedPayout";
import AllVendor from "./components/Tools/ALL VENDOR REGISTRATION REQUEST/AllVendor";
import ApprovedVendor from "./components/Tools/ALL VENDOR REGISTRATION REQUEST/ApprovedVendor";
import RejectedVendor from "./components/Tools/ALL VENDOR REGISTRATION REQUEST/RejectedVendor";
import ViewDetails from "./components/Tools/ALL PAYOUT REPORTS/ViewDetails";
import ViewDetails1 from "./components/Tools/ALL PAYOUT REPORTS/ViewDetails1";
import ViewVendor from "./components/Tools/ALL VENDOR REGISTRATION REQUEST/ViewVendor";
import SubmittedVendor from "./components/Tools/ALL VENDOR REGISTRATION REQUEST/SubmittedVendor";
import SubmittedPayout from "./components/Tools/ALL PAYOUT REPORTS/SubmittedPayout";
import ViewVendor1 from "./components/Tools/ALL VENDOR REGISTRATION REQUEST/ViewVendor1";
import Ekyc from "./reports/EKyc";
import Ledger from "./reports/Ledger";
import Withdraw from "./reports/Withdraw";
import Deposite from "./reports/Deposite";
import { config } from "./utils/helper";
import MerchantTransaction from "./Merchant/MerchantTransaction";

import FlagManagement from "./Merchant/MerchantManagement/FlagManagement";

import ManageAmount from "./Merchant/MerchantManagement/ManageAmount";
import ServiceCharge from "./Merchant/MerchantManagement/ServiceCharge";
import Settings from "./Settings";
import Payment from "./Payment";
import Earnings from "./Earnings";
import Header from "./common/Header";
import SingleChargeback from "./reports/SingleChargeback";
import SingleSettlement from "./reports/SingleSettlement";
import SingleWithdrawl from "./Merchant/MerchantManagement/SingleWithdrawl";
import ForgotPassword from "./ForgotPassword";
import SingleGuarantee from "./Merchant/MerchantManagement/SingleGaurantee";
import SinglePayout from "./Merchant/MerchantManagement/SinglePayout";
import SingleVendor from "./Merchant/MerchantManagement/SingleVendor";
import Support from "./components/Support/Support";
import ViewResponse from "./Merchant/MerchantManagement/ViewResponse";
import ViewSingleSubmittedApplication from "./applications/ViewSingleSubmittedApplication";
import Routing from "./Merchant/MerchantManagement/PayInRouting";
import Masters from "./masters/Masters";
import SingleCreditReport from "./Merchant/SingleCreditReport";
import SingleSettlementAdjustmentAmount from "./Merchant/SingleSettlementAdjustmentAmount";
import SingleReleaseAmount from "./Merchant/MerchantManagement/SingleReleaseAmount";
import SingleDepositAmount from "./Merchant/MerchantManagement/SingleDepositAmount";
import SingleHoldAmount from "./Merchant/MerchantManagement/SingleHoldAmount";
import SingleWalletBalance from "./Merchant/MerchantManagement/SingleWalletBalance";
import VehicleMasterFormSubTab from "./masters/VehicleMasterFormSubtab";
import { Container } from "react-bootstrap";

function App() {
  const location = useLocation();
  const [token, setToken] = useState(localStorage.getItem("token"));
  console.log(token);
  const routesWithoutHeader = ["/", "/ForgotPassword"];
  const navigate = useNavigate();
  const hideHeader = routesWithoutHeader.includes(location.pathname);

  const { logoPath, Name } = config(process.env.REACT_APP_SERVER_ENVIRONMENT);

  const AppNameCapitalized = Name;
  const AppName = AppNameCapitalized.toUpperCase();

  useEffect(() => {
    document.title = `${AppNameCapitalized}`;
    const favicon = document.getElementById("dynamic-favicon");
    if (favicon) {
      favicon.href = logoPath;
    }
  }, [Name]);
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);

  return (
    <>
      {!hideHeader && <Header />}
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route path="Dashboard" element={<Dashboard />} />
        <Route path="Reports" element={<Reports />} />
        <Route path="PayInReport" element={<PayInReport />} />
        <Route path="PayoutReport" element={<PayoutReport />} />
        <Route path="NotActivated" element={<InactiveServices />} />
        <Route path="ChargebackUpload" element={<ChargebackUpload />} />
        <Route path="GetallChargebookFile" element={<GetallChargebackFile />} />
        <Route path="GetallSettlementFile" element={<GetallSettlementFile />} />
        <Route
          path="SubmittedApplications"
          element={<ViewSubmittedApplications />}
        />
        <Route path="/ViewFile/:id" element={<ViewFile />} />
        <Route path="/ViewApprove" element={<ViewApprove />} />
        <Route path="/ViewRejected" element={<ViewRejected />} />
        <Route path="/ViewPending" element={<ViewPending />} />
        <Route path="/OnBoarding" element={<Applications />} />
        <Route path="/ViewFile1/:id" element={<ViewFile1 />} />
        <Route path="/Stage2Applications" element={<Stage2Applications />} />
        <Route path="/MoaDetails/:id" element={<MoaDetails />} />
        <Route path="/Stage2Approved" element={<Stage2Approved />} />
        <Route path="/Stage2Rejected" element={<Stage2Rejected />} />
        <Route path="/Stage2Pending" element={<Stage2Pending />} />
        <Route path="/MoaDetails1/:id" element={<MoaDetails1 />} />
        <Route path="/MerchantDetails" element={<MerchantDetails />} />
        <Route path="/TransactionReport/:mid" element={<TransactionReport />} />
        <Route path="/walletBalance/:mid" element={<SingleWalletBalance />} />
        <Route path="/chargeBackAmount/:mid" element={<SingleChargeback />} />
        <Route path="/settlmentAmount/:mid" element={<SingleSettlement />} />
        <Route
          path="/availableCreditAmount/:mid"
          element={<SingleCreditReport />}
        />
        <Route
          path="/SettlementAdjustmentAmount/:mid"
          element={<SingleSettlementAdjustmentAmount />}
        />
        <Route path="/withdrawalAmount/:mid" element={<SingleWithdrawl />} />
        <Route path="/guaranteeAmount/:mid" element={<SingleGuarantee />} />
        <Route path="/holdAmount/:mid" element={<SingleHoldAmount />} />
        <Route path="/securityDeposit/:mid" element={<SingleDepositAmount />} />
        <Route path="/payoutAmount/:mid" element={<SinglePayout />} />
        <Route path="/releaseAmount/:mid" element={<SingleReleaseAmount />} />
        <Route path="/merchantVendors/:mid" element={<SingleVendor />} />
        <Route
          path="/viewSubmittedAccontForm/:mid"
          element={<ViewSingleSubmittedApplication />}
        />
        <Route
          path="/VehicleMaster"
          element={
            <Container>
              <VehicleMasterFormSubTab />
            </Container>
          }
        />
        <Route path="/OpenDetails/:mid" element={<OpenDetails />} />
        <Route path="/ToolsSection" element={<PayoutRequest />} />
        {/* <Route path="/AllPayoutReport" element = {<AllPayoutReport/>}/> */}
        {/* <Route path="/ApprovedPayout" element = {<ApprovedPayout/>}/> */}
        {/* <Route path="/RejectedPayout" element = {<RejectedPayout/>}/> */}
        {/* <Route path="/ViewDetails/:id" element = {<ViewDetails/>}/> */}
        {/* <Route path="/AllVendor" element = {<AllVendor/>}/> */}
        {/* <Route path="/ApprovedVendor" element = {<ApprovedVendor/>}/> */}
        {/* <Route path="/RejectedVendor" element = {<RejectedVendor/>}/> */}
        {/* <Route path="/ViewVendor/:id" element = {<ViewVendor/>}/> */}
        <Route path="/SubmittedVendor" element={<SubmittedVendor />} />
        <Route path="/SubmittedPayout" element={<SubmittedPayout />} />
        {/* <Route path="/ViewDetails1/:id" element = {<ViewDetails1/>}/> */}
        {/* <Route path="/ViewVendor1/:vendorId" element = {<ViewVendor1/>}/> */}
        <Route path="/EKyc" element={<Ekyc />} />
        <Route path="/Withdraw" element={<Withdraw />} />
        <Route path="/Deposite" element={<Deposite />} />
        <Route path="/Ledger" element={<Ledger />} />
        {/* <Route path="/Merchant" element = {<Merchant/>}/> */}
        <Route path="/MerchantTransaction" element={<MerchantTransaction />} />
        <Route path="/Reconcilliation" element={<InactiveServices />} />
        <Route path="/ResellerManagement" element={<InactiveServices />} />
        <Route path="/InstrumentManagement" element={<InactiveServices />} />
        <Route path="/AggregatorManagement" element={<InactiveServices />} />
        <Route path="/FlagManagement" element={<FlagManagement />} />
        <Route path="/Routing" element={<InactiveServices />} />

        <Route path="/ManageAmount" element={<ManageAmount />} />
        <Route path="/ServiceCharge" element={<ServiceCharge />} />
        <Route path="/ALL" element={<InactiveServices />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/Payment" element={<Payment />} />
        <Route path="/Earnings" element={<Earnings />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/Masters" element={<Masters />} />

        <Route path="Support" element={<Support />} />
        <Route path="/ViewResponse/:orderNo/:mid" element={<ViewResponse />} />
        {/* <Route path="/Routing" element = {<Routing/>}/> */}
      </Routes>
    </>
  );
}
export default App;
