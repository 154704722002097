import React, { useEffect, useState } from 'react'
import { liveTxnReportAdvanceFilter as getApiData } from "../utils/ApiServices";
import { isEmailIdRegexIsValid, isTransactionIdRegexIsValid, isUtrNumRegexIsValid, isVpaUpiRegexIsValid } from '../utils/helper';
import { Button, Modal } from 'react-bootstrap';

const LivePayinAdvanceFilter = ({
    setShowLoader,
    setTransactionReportData,
    setIsAdvanceFilterOpen,
    
  }) => {
    const token = localStorage.getItem("token");
    // const token = tokenValue ? tokenValue.slice(1, -1) : null;
    //   const decodedToken = jwtDecode(token);
    //   const { mid } = decodedToken;
    const [searchOption, setSearchOption] = useState("transactionId");
    const [searchValue, setSearchValue] = useState("");
    const [show, setShow] = useState(true);
  
    const [searchValueError, setSearchValueError] = useState("");
    const [isSearchValueError, setIsSearchValueError] = useState(false);
  
    const handleModalClose = () => {
      setShow(false);
      setIsAdvanceFilterOpen(false);
       
    };
    const date= new Date().toJSON().slice(0,10)
  
    const handleClick = (option) => {
      setSearchOption(option);
      setSearchValue("");
    };
  
    const submitHandler = () => {
      // setSearch(!search)
    //   alert("hgsdjfjh")
      getApiData( 
        "",
        searchOption === "transactionId" ? searchValue : "",
        searchOption === "orderNumber" ? searchValue : "",
        searchOption === "email" ? searchValue : "",
        searchOption === "mobile" ? searchValue : "",
        searchOption === "payerVPA" ? searchValue : "",
        searchOption === "custRefNo" ? searchValue : "",
        "",
        token,
        setTransactionReportData,
        setShowLoader
      );
  
      handleModalClose();
    };
  
    const handelSearchData = (e) => {
      if (searchOption === "mobile") {
        const numericInput = /^\d*$/.test(e.target.value);
        if (numericInput) {
          setSearchValue(e.target.value.substring(0, 10)); // Only allow up to 10 digits
          setSearchValueError("");
        } else {
          setSearchValueError("Input must be a number.");
        }
        return;
      }
  
      setSearchValue(e.target.value);
      setIsSearchValueError(false);
      setSearchValueError("");
      document.getElementById("searchBtn").disabled = false;
    };
  
    const handelValidations = (e) => {
      if (searchOption === "payerVPA") {
        if (!isVpaUpiRegexIsValid(searchValue)) {
          setIsSearchValueError(true);
          setSearchValueError("Enter Valid VPA.");
          document.getElementById("searchBtn").disabled = true;
        }
        return;
      }
  
      if (searchOption === "transactionId") {
        if (!isTransactionIdRegexIsValid(searchValue)) {
          setIsSearchValueError(true);
          setSearchValueError("Enter Valid Transaction Id.");
          document.getElementById("searchBtn").disabled = true;
        }
        return;
      }
  
      if (searchOption === "email") {
        if (!isEmailIdRegexIsValid(searchValue)) {
          setIsSearchValueError(true);
          setSearchValueError("Enter Valid Email Id.");
          document.getElementById("searchBtn").disabled = true;
        }
        return;
      }
  
      if (searchOption === "custRefNo") {
        if (!isUtrNumRegexIsValid(searchValue)) {
          setIsSearchValueError(true);
          setSearchValueError("Enter Valid UTR Number.");
          document.getElementById("searchBtn").disabled = true;
        }
        return;
      }
    };
  
    useEffect(() => {
      handleClick("transactionId");
    }, []);
  
    return (
      <div className="advanced-search-popup">
        {/* <div className="topside">
  
          <div className="filter-list">
            <li>Advanced Filter</li>
          </div>
        </div>
  
        {searchOption !== "dateFilter" && (
          <>
            <select
              className="advanced-select-section form-control"
              value={searchOption}
              onChange={(e) => setSearchOption(e.target.value)}
            >
              <option value="transactionId">Transaction ID</option>
              <option value="orderNumber">Order Number</option>
              <option value="email">e-Mail</option>
              <option value="mobile">Mobile</option>
            </select>
  
            <input
              className="advanced-input-box form-control"
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === " ") {
                  e.preventDefault();
                }
              }}
              placeholder={
                searchOption === "transactionId"
                  ? "Enter transaction ID"
                  : searchOption === "orderNumber"
                    ? "Enter Order Number"
                    : searchOption === "email"
                      ? "Enter e-Mail"
                      : "Enter Mobile"
              }
            />
  
            <button className="search-button-reset" onClick={handleButtonReset}>
              Reset
            </button>
          </>
        )}
        {apiResponse && apiResponse.length === 0 && (
          <div className="no-data-message">No data available.</div>
        )} */}
  
        <Modal show={show} centered>
          <Modal.Header className="bg-dark text-white">
            <Modal.Title>Advanced Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="filter-field-area">
              <div className="form-group mb-3">
                <select
                  className="form-control"
                  value={searchOption}
                  onChange={(e) => {
                    setSearchOption(e.target.value);
                    setSearchValue("");
                    setIsSearchValueError(false);
                    setSearchValueError("");
                  }}
                >
                  <option value="transactionId">Transaction ID</option>
                  <option value="orderNumber">Order Number</option>
                  {/* <option type="email" value="email">
                    e-Mail
                  </option>
                  <option value="mobile">Mobile</option>
                  <option value="payerVPA">Payer VPA</option> */}
                  <option value="custRefNo">UTR No</option>
                </select>
              </div>
              <div className="form-group mb-2">
                <input
                  className="advanced-input-box form-control"
                  type={
                    searchOption === "transactionId"
                      ? "number"
                      : searchOption === "mobile"
                      ? "tel"
                      : "text"
                  }
                  value={searchValue}
                  onChange={handelSearchData}
                  onBlur={handelValidations}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                  placeholder={
                    searchOption === "transactionId"
                      ? "Enter transaction ID"
                      : searchOption === "orderNumber"
                      ? "Enter Order Number"
                      : searchOption === "email"
                      ? "Enter e-Mail"
                      : searchOption === "payerVPA"
                      ? "Enter Payer VPA"
                      : searchOption === "custRefNo"
                      ? "Enter UTR No"
                      : "Enter Mobile"
                  }
                />
                {isSearchValueError ? (
                  <span id="searchValErr" className=" text-danger">
                    {`${searchValueError}`}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-danger"
              size="sm"
              className="text-capitlize"
              onClick={handleModalClose}
            >
              Close
            </Button>
            <Button
              id="searchBtn"
              variant="outline-primary"
              size="sm"
              className="text-capitlize"
              onClick={submitHandler}
            >
              <i class="bi bi-search mx-1"></i> Search
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };
  

export default LivePayinAdvanceFilter