import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { SWIPELINC_API } from "../utils/constant";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Card, Row, Col } from "react-bootstrap";
import SettlementMultipleFilter from "../Merchant/MultipleFilter/SettlementMultipleFilter";
import SettlementAdvanceSearchPopup from "../Merchant/AdvanceFilter/SettlementAdvanceSearchPopup";
import DownloadCsv from "../common/DownloadCsv";
import { headerLabelsForSettlement } from "../utils/DownloadHeaders";
import CompanyNameAndMid from "../common/CompanyNameAndMid";
import MyLoader from "../common/MyLoader";

function SingleSettlement() {
  const [data, setData] = useState();

  const token = localStorage.getItem("token");
  const [showTable, setShowTable] = useState(false);
  const [isMultipleFilterOpen, setIsMultipleFilterOpen] = useState(false);
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false)

  const { mid } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (mid) {
      axios
        .get(
          `${SWIPELINC_API}MerchantAdminPanel/admin/settlement/findbymid/${mid}`,
          {
            headers: {
              Authentication: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setData(response.data.data);
          setShowTable(true);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setShowTable(false);
        });
    }
  }, [mid]);

  const columns = [
    { label: "Merchant ID", field: "mid", width: "auto" },
    { label: "Net Amount", field: "netAmount", width: "auto" },
    { label: "Order Number", field: "orderNo", width: "auto" },
    { label: "Transaction ID", field: "txnId", width: "auto" },
    { label: "VPA", field: "vpa", width: "auto" },
    { label: "Settlement Date", field: "settlmentDate", width: "auto" },
    { label: "Settlement Time", field: "settlmentTime", width: "auto" },
    { label: "GST", field: "gst", width: "auto" },
    { label: "Service Charge", field: "serviceChanrge", width: "auto" },
    {
      label: "Service Charge Amount",
      field: "serviceChargeAmount",
      width: "auto",
    },
    { label: "GST Amount", field: "gstAmount", width: "auto" },
    { label: "Remark", field: "remark", width: "auto" },
    { label: "PayInAmount", field: "payinAmount", width: "auto" },
    { label: "Transaction Status", field: "txnStatus", width: "auto" },
    { label: "Response Code", field: "respCode", width: "auto" },
    { label: "Collection Method", field: "collectionMethod", width: "auto" },
    { label: "Terminal ID", field: "terminalId", width: "auto" },
  ];
  const mappedData = data?.map((item) => {
    const mappedItem = {};
    columns.forEach((column) => {
      mappedItem[column.field] = item[column.field];
    });
    return mappedItem;
  });
  const handleBackButtonClick = () => {
    navigate(-1);
  };

  
  const handleMultipleFilter = () => {
    setIsMultipleFilterOpen(!isMultipleFilterOpen);
    setIsAdvancedSearchOpen(false);
    // setIsDateFilterOpen(false);
    // setShow2(!show2);
  };

  const handleAdvanceSearch = () => {
    setIsAdvancedSearchOpen(!isAdvancedSearchOpen);
    // setIsDateFilterOpen(false);
    setIsMultipleFilterOpen(false);
    // setShow(!show);
  };

  const handleReset = () => {
    window.location.reload();
  };
  return (
    <>
        {showLoader ? <MyLoader /> : ""}
      <Container>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <Card className="shadow border-0 my-4">
            <Card.Header>
          
              <CompanyNameAndMid mid={mid} reportType={"Settlement Report"} />
              </Card.Header>
              <Card.Body>

              <div className="d-flex justify-content-between mt-2">
                  <button
                    className="btn btn-primary"
                    onClick={handleBackButtonClick}
                  >
                    Go Back
                  </button>
                  <div className=" d-flex justify-content-end">
                    {/* multiple filter starts */}
                    <div className="Advanced-btn d-flex justify-content-end mx-1">
                      <button
                        className={`advanced-btn btn btn-primary   text-capitlize ${
                          isMultipleFilterOpen ? "active" : ""
                        }`}
                        onClick={handleMultipleFilter}
                      >
                        <i className="bi bi-funnel-fill mx-1"></i>More Filter
                      </button>
                    </div>
                    {/* multiple filter ends */}

                    {/* advance filter starts */}
                    <div className="Advanced-btn  d-flex justify-content-end mx-1">
                      <button
                        className={`advanced-btn btn btn-primary text-capitlize ${
                          isAdvancedSearchOpen ? "active" : ""
                        }`}
                        onClick={handleAdvanceSearch}
                      >
                        <i className="bi bi-funnel-fill mx-1"></i>
                        Advanced Filter
                      </button>
                    </div>
                    {/* advance filter ends */}

                    {/* reset btn code starts */}
                    <div className="Advanced-btn  d-flex justify-content-end mx-1">
                      <button
                        className={`advanced-btn btn btn-primary   text-capitlize`}
                        onClick={handleReset}
                      >
                        <i class="bi bi-arrow-clockwise mx-1"> </i>Reset
                      </button>
                    </div>
                    {/* reset btn coden ends  */}

                    {isMultipleFilterOpen ? (
                      <SettlementMultipleFilter
                        setSettlementReportData={setData}
                        setIsMultipleFilterOpen={setIsMultipleFilterOpen}
                        mid={mid}
                        setShowLoader={setShowLoader}
                      />
                    ) : (
                      ""
                    )}

                    {isAdvancedSearchOpen ? (
                      <div className="date-report">
                        {/* <AdvancedSearchPopup2 onClose={handleAdvancedSearchClose} setCustomApiResponse={handleCustomApiResponse} /> */}
                        <SettlementAdvanceSearchPopup
                          setShowLoader={setShowLoader}
                          setSettlementReportData={setData}
                          setIsAdvanceFilterOpen={setIsAdvancedSearchOpen}
                          mid={mid}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="report-grid-view mt-3">
                  <div className="material-table-records mb-3">
                    <MDBDataTable
                      className="dataTable"
                      striped
                      bordered
                      small
                      data={{
                        columns: columns,
                        rows: mappedData,
                      }}
                      searching={true}
                      responsive
                      sorting={true}
                      hover
                      footer="none"
                    />
                  </div>
                  <DownloadCsv DownLoadData={data} Header={headerLabelsForSettlement} Name={`${mid}-Settlement`}/>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SingleSettlement;
